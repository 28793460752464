import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-attribute-management',
  templateUrl: './attribute-management.component.html',
  styleUrls: ['./attribute-management.component.css']
})
export class AttributeManagementComponent implements OnInit {

  productAttribute: any;
  categoryList: any = [];
  contentEdit : any = []
  categoryFaq : any = []
  categoryId: any;
  categoryValue : any 
  showDeleteModal : boolean = false


  itemPerPage : any = 10
  currentPage : any = 1
  total : any
  constructor(public service: MainService, private router : Router) {}

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory() {
    let url = "product/view-attribute-by-list";

    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        console.log(res);
        this.categoryList = res.data
        this.total = this.categoryList.length
       
        for(let i = 0 ; i < this.categoryList.length ; i++){
          this.contentEdit[i] = false
          console.log("-=-=-=-=-=-=-=-=-=-=-=-=-=-",this.categoryList[i]);
          this.categoryFaq[i] = this.categoryList[i]["attribute"]
          
        }
        this.service.successToast(res.message);
        this.service.hideSpinner();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }

  addCategory() {
    if (!this.productAttribute) {
      this.service.warningToast("Please enter product attribute");
      return;
    }
    let url = "product/add-attribute";
    const data = {
      attribute: this.productAttribute,
      "productParentAttributeStatus": "ACTIVE" 
    };
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.productAttribute = "";
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  getId(id) {
    this.categoryId = id;
    this.showDeleteModal = true
    
  }
  deleteCategory(e) {
    if (e) {
      this.showDeleteModal = false
      
    } else {
      this.showDeleteModal = false
      return
    }
    let url = "product/delete-attribute?attributeId=" + this.categoryId;
    this.service.showSpinner();
    this.service.deleteApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  view(id){
this.router.navigate(['/child-attribute-list'],{queryParams : {id : id}})
  }
  editFaq(index, value, id){
    for(let i = 0 ; i < this.categoryList.length ; i++){
      this.contentEdit[i] = false
    }
    this.contentEdit[index]= true
    this.categoryId = id
    this.categoryValue = value
   
  }
  editFaqApi(value){
    if(!value){
      this.service.warningToast("Faq category can not be empty")
      return
    }

    let url = "product/update-attribute?attributeId=" + this.categoryId;
    const data = {
      "attribute": value,
      "productParentAttributeStatus": "ACTIVE"
    }
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  pagination(e) {
    this.currentPage = e
  }
}
