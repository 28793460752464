import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit {
  currentTab: any = 'GENERAL'
  
  constructor() { 
    if (localStorage.getItem("viewUserTab")) {
      this.selectTab(localStorage.getItem("viewUserTab"))
      localStorage.removeItem("viewUserTab")
    } 
  }

  ngOnInit(): void {
  }
  selectTab(tab){
    this.currentTab = tab
  }
  
}
