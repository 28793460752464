<main class="middle-content">
            <div class="page_title_block1">
            <h1 class="page_title float-left">Faq Category</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/faq">Back</a>
                </small>

       </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div>
                        <div>
                            <div class="tab-pane1">
                                <div class="sec_head_new sec_head_new_after">
                                    <form>
                                        <div class="row align-items-center">
                                            <div class="col-md-8 pr-0">
                                                <div class="filter_search mb20 width100">
                                                    <!-- <label class="d-flex align-items-center">Search:</label> -->
                                                    <div class="input-group filter_search_group">
                                                        <label for="" class="align-items-center mt-20"
                                                            style="margin-top: 6px;">Faq Category : </label>&nbsp;
                                                        <input type="text" class="form-control overflow-text"
                                                            [(ngModel)]="category"
                                                            (keypress)="service.preventSpace($event)"
                                                            [ngModelOptions]="{standalone: true}"
                                                            placeholder="Enter faq category" maxlength="60">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="filter_search mb20 width100">
                                                    <!-- <button type="button" class="btn  btn-theme">Search</button> -->
                                                    <!-- <button type="button" class="btn  btn-theme ">Reset</button> -->
                                                    <!-- <button type="button" class="btn  btn-theme "
                                                         routerLink="/add-brand">Add
                                                        Brand</button> -->
                                                    <div class="col-4 pull-right">
                                                        <button type="button" class="btn btn-theme "
                                                            (click)="addCategory()">Add
                                                            Category
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="table-responsive" style="margin-bottom: 60px;">
                                <table
                                    class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                    aria-describedby="Brand management list">
                                    <thead>
                                        <tr class="no_wrap_th text-center">
                                            <th scope="col" class="Sorting-img">S.No</th>


                                            <th scope="col" class="Sorting-img">Category</th>

                                            <th scope="col" class="action_td_btn3 Sorting-img" style="width: 1%;">
                                                Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="text-center" *ngFor="let item of categoryList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total};let i = index">
                                            <td>
                                                {{i +1}}
                                            </td>
                                            <td *ngIf="!contentEdit[i]">
                                                {{item?.category}}
                                            </td>
                                            <td *ngIf="contentEdit[i]">
                                                <input  type="text" class="form-control text-center" maxlength="60"
                                                    [(ngModel)]="categoryFaq[i]" [ngModelOptions]="{standalone: true}">
                                            </td>

                                            <td class="justify-content-center d-flex">

                                                <button *ngIf="!contentEdit[i]" class="btn btn-info ml-2 bth" title="Edit"
                                                    (click)="editFaq(i,categoryFaq[i],item?.categoryId)"><em
                                                        class="fa fa-edit" aria-hidden="true"></em></button>

                                                <button *ngIf="contentEdit[i]" class="btn btn-info ml-2 bth" title="Edit"
                                                    (click)="editFaqApi(categoryFaq[i])"><em
                                                        class="fa fa-check" aria-hidden="true"></em></button>

                                                <button class="btn btn-danger ml-2 bth" title="Edit"
                                                    (click)="getId(item?.categoryId)"><em class="fa fa-trash"
                                                        aria-hidden="true"></em></button>



                                            </td>
                                        </tr>
                                        <tr *ngIf="total == 0">
                                            <td class="text-center" colspan="3">
                                                No Record Found
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <div *ngIf="total > itemPerPage" class="custom-pagination mt-2 text-align-end">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>


                    </div>




                </div>
            </div>


        </div>
    </div>


</main>


<!-- delete Brand-management modal Start -->
<div class="modal fade global-modal reset-modal" [ngClass]="showDeleteModal ? 'showModal' : ''" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p>Are you sure you want to delete this category?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal"
                                        (click)="deleteCategory(1)" [(ngModel)]="showDeleteModal">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2" (click)="deleteCategory(0)"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">

                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">


                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>