<div >
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Offer Management

            </h1>
            <hr>

        </div>

        <div class="content-section">


            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">


                        <div>

                            <div>
                                <div class="tab-pane1">
                                    <div class="sec_head_new sec_head_new_after">

                                        <form [formGroup]="searchForm">
                                            <div class="row     align-items-center">
                                                <div class="col-md-3 pr-0">
                                                    <div class="filter_search mb20 width100">

                                                        <label class="d-flex align-items-center">offer Name:</label>
                                                        <div class="input-group filter_search_group">

                     <input type="text" class="form-control overflow-text"
                    formControlName="search" placeholder="Search by offer name"
                  maxlength="60">
                      <div class="input-group-append">
                         <button class="btn btn_search_group">
                     <img src="assets/img/icon-search.png">
                    </button>
                     </div>
                             </div>
                         </div>
                                 </div>        
                                               
                                                <div class="col-md-12 ">
                                                <div class="pull-right">
                                                    <div class="text-center admin-btn mb2 mt10">
                                                        <button type="button" class="btn  btn-theme" (click)="search()">Search</button>
                                                        <button type="button" class="btn  btn-theme " (click)="  reset()">Reset</button>
                                                        <button type="button" class="btn btn-theme" routerLink="/add-offer"
                                                            style="font-size: 12px;"> Add New
                                                        </button>


                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <!--   <div class="text-right">
                                                <button type="button" class="btn btn-theme"
                                                    routerLink="/add-management">Add
                                                    New
                                                </button>
                                            </div> -->
                                        </form>
                                    </div>
                                </div>

                                <div class="table-responsive" style="margin-bottom: 60px;">
                                    <table
                                        class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                        aria-describedby="user management list">
                                        <thead>
                                            <tr class="no_wrap_th text-center">
                                                <th scope="col" class="Sorting-img">S.No</th>

                                                <th scope="col" class="Sorting-img">Offer Name</th>
                                       
                                                <th scope="col" class="Sorting-img">offer(%)</th>
                                                <th scope="col" class="Sorting-img">Offer Range(minimum-maximum)</th>
                                                <th scope="col" class="Sorting-img">Start date</th>
                                                <th scope="col" class="Sorting-img">End date</th>
                                                <!-- <th scope="col" class="Sorting-img">Times used</th> -->
                                                <th scope="col" class="action_td_btn3 Sorting-img" style="width: 1%;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let item of customerData | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total} ; let i = index" class="text-center">
                                                <td>
                                                    {{itemPerPage * (currentPage - 1) + i+1}}</td>


                                                <td class="content">{{item?.offerName || '--'}}</td>
                                               
                                                <td class="content">{{item?.percetage  || '--'}}%</td>
                                                <td class="content">{{item?.minPrice ||'--'}} $-{{item?.maxPrice || '--'}} $</td>
                                                <td class="content">{{(item?.startDate | date : 'dd/MM/yyyy, hh:mm a') ||'--'}}</td>
                                                <td class="content">{{(item?.endDate | date : 'dd/MM/yyyy, hh:mm a') ||'--'}}</td>
                                                <!-- <td class="content">{{(item?.timesUsed ) ||'--'}}</td> -->

                                               
                                                <td class="justify-content-center d-flex" >
                                                    <!-- <button class="btn btn-info ml-2 bt-margin bth" (click)="viewUser()" title="view">
                                                      <em class="fa fa-eye" aria-hidden="true"></em></button> -->

                         <button class="btn btn-info ml-2 bth" (click) = "editOffer(item?.offerId)"
                        title="Edit"><em class="fa fa-edit" aria-hidden="true"></em></button>
                         <button class="btn btn-danger ml-2 bt-margin" title="Delete"
                            data-toggle="modal" data-target="#deletOffer"
                           (click)="deleteUserModal(item?.offerId)"><em class="fa fa-trash-o"
                           style="color:white"></em>
                       </button>  
                             </td>
                                      </tr>
                                            <tr *ngIf="customerData.length == 0">
                                                <td class="table-no-record" colspan="9">No Record Found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="custom-pagination mt-2 text-align-end" *ngIf="total > itemPerPage">
                                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>


                        </div>




                    </div>
                </div>


            </div>
        </div>


    </main>
</div>

<!-- delete user-management modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p>Are you sure you want to delete this user?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">

                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">


                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="container">
    <!-- Trigger the modal with a button -->
    <!-- Modal -->
    <div class="modal fade" id="myModal-export" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header" style="background: goldenrod;">
                    <h5 class="modal-title">Confirmation</h5>
                </div>
                <div class="modal-body">
                    <p>The EXCEL export file will be generated for download.</p>
                    <p>Disable any popup blockers in your browser to ensure proper download.</p>
                    <p>Ok to proceed?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success">OK</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>

</div>
<!-- delete hospital modal Start -->
<div class="modal fade global-modal reset-modal" id="deletOffer">

    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete Banner?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this offer?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" data-dismiss="modal"
                                        (click)="deleteOffer()">Yes</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2" (click)="hideModal()"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

