import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-offer',
  templateUrl: './add-offer.component.html',
  styleUrls: ['./add-offer.component.css']
})
export class AddOfferComponent implements OnInit {

  date: any;
  date2: any = new Date();
  catId: any;
  categoryList: any;
  addForm: FormGroup;
  imgBase64Path: any;
  productdata: any;
  imgSrc: any;
  categorydata: any;
  branddata: any;
  
  
  constructor(private activatedRouting: ActivatedRoute, public mainService: MainService, private router: Router,private datePipe : DatePipe) {
    this.activatedRouting.queryParams.subscribe((res: any) => {
      this.catId = res.id
    })
   }



  ngOnInit() {
    
    
    this.editFormApi()
       this.getProduct()
   
  }
  editFormApi() {
    this.addForm = new FormGroup({
      offerName: new FormControl("", [Validators.required]),
      product:new FormControl("",[Validators.required]),
      percentage: new FormControl("",[Validators.required,Validators.pattern(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i)]),
      offerMaxAmount: new FormControl("",[Validators.required]),
      offerMinAmount: new FormControl("",[Validators.required]),
      startDate: new FormControl("",[Validators.required]),
      endDate: new FormControl("",[Validators.required]),
      discription: new FormControl("",[]),
      quantity: new FormControl("",[]),

      // d: new FormControl("",[Validators.required]),
      // category:new FormControl("",[Validators.required]),
      // brand:new FormControl("",[Validators.required]),
      // couponCode:new FormControl("",[Validators.required]),


    })
  }


  getList(){
    var a = this.addForm.value.discountTypes
    if (a ==="ASSIGNED_TO_PRODUCT") {
      this.getProduct()
    }
    else if(a ==="ASSIGNED_TO_CATEGORIES"){
      this.getCategory()
    }
    else if(a ==="ASSIGNED_TO_BRAND"){
      this.getBrand()
    }
    else {
      
    }
  }
  
  getProduct(){
let url="product/get-Product-Details?page=0&pageSize=200"
this.mainService.postApi(url,{},1).subscribe((res)=>{
  if (res.status ==200) {
    this.productdata=res.data[0].categoryDetails
    console.log('product list',this.productdata)
    this.mainService.hideSpinner()
    // this.mainService.successToast(res.message)
  } else {
    this.mainService.hideSpinner()
    this.mainService.errorToast(res.message)
  }
})
  }
  getCategory(){
    let url="product/getAllCategoryList"
    this.mainService.getApi(url,1).subscribe((res)=>{
      if (res.status ==200) {
        this.categorydata=res.data[0].categoryDetails
        console.log(this.categorydata);
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }
  getBrand(){
    let url="product/getAllBrandList"
    this.mainService.getApi(url,1).subscribe((res)=>{
      if (res.status ==200) {
        this.branddata=res.data
        console.log(this.categorydata);
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }

public isSubmit =false
  addOffer(){
    this.isSubmit =true
    let url="product/add-Offer";
      if(this.addForm.valid){
    const startDate = new Date(this.addForm.value.startDate).toISOString()
    const endDate = new Date(this.addForm.value.endDate).toISOString()
    const body = {
        "createTime": "2021-11-08T06:42:06.384Z",
        "offerDescription": this.addForm.value.discription,
        "offerName": this.addForm.value.offerName,
        "offerStatus": "ACTIVE",
        "productId": this.addForm.value.product.productId,
        "startDate": startDate,
        "endDate": endDate,
        "quantity": this.addForm.value.quantity,
        "percetage": this.addForm.value.percentage,
        "maxPrice": this.addForm.value.offerMaxAmount,
        "minPrice": this.addForm.value.offerMinAmount,
        "updateTime": "2021-11-08T06:42:06.384Z"
    }

    this.mainService.postApi(url,body,1).subscribe((res)=>{
      if (res.status ==200) {
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
        this.router.navigate(['/offer-Management'])
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
}
  }

}
