<main class="middle-content">
    <div class="page_title_block1">
             <h1 class="page_title float-left"> View Testimonial</h1>
                 <small class="pad">
                     <i  class="fa fa-arrow-circle-left colr"></i>
                     <a class="po" routerLink="/testimonial">Back</a>
                 </small>
                    <div class="float-right">
                     <button type="submit" name="save"  (click)="edit()"  class="btn btn-primary">
                     <i class="fa fa-floppy-o
                     "></i>
                     Edit
                     </button>
                </div>
        </div>
     <div class="content-section">
         <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
             <div class="mb40">
                 <div class="admin-profile" style="margin:0 auto;">
                     <h4 class="mb20 text-center" >View Testimonial</h4>
                 </div>
 
 
                 <div class="add-store-block input-style mb20 mt20 ">
                     
                     <div class="sheet">
                         <label class="textaligncenter labelone  ">Name</label>
                         <label class="">:</label>
                         <div class="textaligncenter">
                             <p>{{categoryList?.name || '--'}}</p>
                         </div>
                     </div>
                     <div class="sheet">
                         <label class="textaligncenter labelone  ">Description</label>
                         <label class="">:</label>
                         <div class="textaligncenter">
                             <p>{{categoryList?.message || '--'}}</p>
                         </div>
                     </div>
                     <div class="sheet">
                         <label class="textaligncenter  labelone ">Category Image</label>
                         <label class="">:</label>
                         <div class="textaligncenter">
                             <p><img src={{categoryList?.image}} alt="category image" style="width: 143px;border-radius: 50%;"></p>
                         </div>
                     </div>
                     <div class="sheet">
                         <label class="textaligncenter labelone  ">Status
                         </label>
                         <label class="">:</label>
                         <div class="textaligncenter">
                             <p style="word-break: break-all;">{{categoryList?.testimonialsStatus || '--'}}</p>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
     
 </main>
 