import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryJsonService } from 'src/app/service/country-json.service';
import { EventEmitterService } from 'src/app/provider/event-emitter.service';
@Component({
  selector: 'app-edit-my-profile',
  templateUrl: './edit-my-profile.component.html',
  styleUrls: ['./edit-my-profile.component.css']
})
export class EditMyProfileComponent implements OnInit {
  editProfileForm: FormGroup
  stateArr : any = []
  id : any
  userDetail: any;
  editImage: any;
  imgSrc: string;
 
  constructor(private router: Router, public mainService: MainService,public active:ActivatedRoute,public country : CountryJsonService,private eventEmitterService: EventEmitterService)
  {
   
    this.active.queryParams.subscribe((params)=>{
      this.id=params.id
    })

   }

  ngOnInit() {
    this.editProfileFormValidation();
    this.getProfile()
  }
  editProfileFormValidation() {
    this.editProfileForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
    'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'phoneNo': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/),  Validators.maxLength(15)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'image': new FormControl(''),
      'country' : new FormControl('',Validators.required),
      'state' : new FormControl('',Validators.required),
      'city' :  new FormControl('',Validators.required),

      'address': new FormControl('',[Validators.required])
    })
  }

  // get profile
  getProfile() {
    var url = 'account/my-account';
    this.mainService.showSpinner();
    this.mainService.getApi(url,1).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.editImage = this.userDetail.imageUrl;
        this.editProfileForm .patchValue({
          'firstName': this.userDetail.firstName,
          'lastName':this.userDetail.lastName,
          'email': this.userDetail.email,
          'phoneNo': this.userDetail.phoneNo,
          'address': this.userDetail.address,
          'city': this.userDetail.city,
          'country': this.userDetail.country,
          'state':this.userDetail.state,
          
        })
        this.imgSrc = res.data.imageUrl
        this.getState()
        this.mainService.hideSpinner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res['message']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.mainService.onLogout();
        this.mainService.errorToast('Unauthorized Access');
      } else {
        this.mainService.errorToast('Something Went Wrong');
      }
      // this.service.hideSpinner();
    })
  } 
  getState(){
console.log(this.editProfileForm.value.country);
this.stateArr = this.country.countries.filter( (el) => {
  return el.country ===  this.editProfileForm.value.country
});
 console.log(this.editProfileForm.value.state);
 
  }

  editProfile() {
    let url="account/profile-update"
    var data = {
      "address": this.editProfileForm.value.address,
      "state":this.editProfileForm.value.state,
      "city": this.editProfileForm.value.city,
      "country": this.editProfileForm.value.country,
      "firstName": this.editProfileForm.value.firstName,
      "lastName": this.editProfileForm.value.lastName,
      "phoneNo": this.editProfileForm.value.phoneNo,
      "gender": this.editProfileForm.value.gender,
      "email": this.editProfileForm.value.email,
      "imageUrl": this.imgSrc? this.imgSrc : this.userDetail.imageUrl
    }
    console.log(data);
    
    this.mainService.showSpinner();
    this.mainService.postApi(url, data,1).subscribe(res => {
      // this.mainService.hideSpinner();
      if (res['status'] == 200) {
        // this.myProfile();
        this.mainService.changeLoginSub('login')
        this.mainService.successToast('Profile Updated Successfully');
        this.eventEmitterService.onFirstComponentButtonClick();
        this.router.navigate(['/my-profile'])
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res['message']);
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        this.mainService.onLogout();
        this.mainService.errorToast('Unauthorized Access');
      } else {
        this.mainService.errorToast('Something Went Wrong');
      }
    })
  }
// Image Functionality Start Here
uploadImg($event): void {
  var img = $event.target.files[0];
  this.uploadImageFunc(img);
}
uploadImageFunc(img) {
  var fb = new FormData();
  fb.append('file', img)
  this.imgSrc = "assets/loader/1488 (2).gif"

  // this.mainService.showSpinner();
  this.mainService.uploadFile('static/upload-file', fb).subscribe(res => {
    // this.mainService.hideSpinner();
    if (res['status'] == '200') {
      this.imgSrc = res['data'];
      this.mainService.hideSpinner();
    }
  }, err => {
    this.mainService.hideSpinner();
    if (err['status'] == '401') {
      this.mainService.onLogout();
      this.mainService.errorToast('Unauthorized Access');
    } else {
      this.mainService.errorToast('Something Went Wrong');
    }
  })
}
  
  back() {
    this.router.navigate(['my-profile'])
  }
}
