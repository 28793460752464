import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.css']
})
export class ViewTransactionComponent implements OnInit {
  txnId : any
  userDetail : any = []
  transactionlist: any;
  total: any;
  constructor(private activated : ActivatedRoute,private mainService : MainService) { 
      
    this.activated.queryParams.subscribe((res)=>{
      this.txnId = res.TxnId
      console.log(this.txnId)
    })
    
  }

  ngOnInit(): void {
    this.getViewTransactionDetail()
    
  }
getViewTransactionDetail(){
  let url = "wallet/admin/transaction-history/get-transaction-details?txnId="+this.txnId
  this.mainService.showSpinner()
  this.mainService.getApi(url,1).subscribe((res)=>{
    if(res.status == 200){
      this.transactionlist = res.data
      this.total = res.data.totalCount
     this.mainService.successToast(res.message)

     this.mainService.hideSpinner()
    }else{
     this.mainService.errorToast(res.message)
     this.mainService.hideSpinner()
    }
  },(err)=>{
   this.mainService.errorToast(err)
   this.mainService.hideSpinner()
  })
}
}
