import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
// module
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { StaticsContentComponent } from './pages/statics-content/statics-content.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { EditStaticContentComponent } from './pages/edit-static-content/edit-static-content.component';
import { OrderComponent } from './pages/View-User/userTabs/order/order.component';
import { GeneralComponent } from './pages/View-User/userTabs/general/general.component';
import { HeaderComponent } from './pages/header/header.component';
import { SidebarComponent } from './pages/sidebar/sidebar.component';
import { ViewUserComponent } from './pages/View-User/view-user/view-user.component';
import { FaqComponent } from './pages/faq/faq/faq.component';
import { AddFaqComponent } from './pages/faq/add-faq/add-faq.component';
import { EditFaqComponent } from './pages/faq/edit-faq/edit-faq.component';
import { ViewFaqComponent } from './pages/faq/view-faq/view-faq.component';
import { NgxPaginationModule} from 'ngx-pagination';
import { MyProfileComponent } from './pages/my-profile/my-profile/my-profile.component';
import { EditMyProfileComponent } from './pages/my-profile/edit-my-profile/edit-my-profile.component';
import { ChangePasswordComponent } from './pages/dashboard/change-password/change-password.component';
import { UserKycComponent } from './pages/View-User/userTabs/user-kyc/user-kyc.component';
import { ViewUserKycComponent } from './pages/View-User/userTabs/view-user-kyc/view-user-kyc.component';
import { ViewOrderComponent } from './pages/View-User/userTabs/view-order/view-order.component';
import { SubAdminManagementComponent } from './pages/Sub-Admin-Management/sub-admin-management/sub-admin-management.component';
import { AddSubAdminComponent } from './pages/Sub-Admin-Management/add-sub-admin/add-sub-admin.component';
import { ViewSubAdminComponent } from './pages/Sub-Admin-Management/view-sub-admin/view-sub-admin.component';
import { EditSubAdminComponent } from './pages/Sub-Admin-Management/edit-sub-admin/edit-sub-admin.component';
import { CategoryListComponent } from './pages/Category-Management/category-list/category-list.component';
import { AddCategoryComponent } from './pages/Category-Management/add-category/add-category.component';
import { ViewCategoryComponent } from './pages/Category-Management/view-category/view-category.component';
import { EditCategoryComponent } from './pages/Category-Management/edit-category/edit-category.component';
import { BrandManagementComponent } from './pages/brand-management/brand-management/brand-management.component';
import { ViewBrandComponent } from './pages/brand-management/view-brand/view-brand.component';
import { AddBrandComponent } from './pages/brand-management/add-brand/add-brand.component';
import { EditBrandComponent } from './pages/brand-management/edit-brand/edit-brand.component';
import { OrderManagementComponent } from './pages/order-management/order-management/order-management.component';
import { ViewOrderManagementComponent } from './pages/order-management/view-order-management/view-order-management.component';
import { EditOrderManagementComponent } from './pages/order-management/edit-order-management/edit-order-management.component';
import { FooterComponent } from './pages/footer/footer.component';
import { SubcategoryListComponent } from './pages/Category-Management/Sub-Category-Management/subcategory-list/subcategory-list.component';
import { TransactionManagementComponent } from './pages/transaction-management/transaction-management.component';
import { BannerManagementComponent } from './pages/banner-management/banner-management/banner-management.component';
import { AddBannerComponent } from './pages/banner-management/add-banner/add-banner.component';
import { EditBannerComponent } from './pages/banner-management/edit-banner/edit-banner.component';
import { ViewBannerComponent } from './pages/banner-management/view-banner/view-banner.component';
import { AddSubCategoryComponent } from './pages/Category-Management/Sub-Category-Management/add-sub-category/add-sub-category.component';
import { ViewSubCategoryComponent } from './pages/Category-Management/Sub-Category-Management/view-sub-category/view-sub-category.component';
import { EditSubCategoryComponent } from './pages/Category-Management/Sub-Category-Management/edit-sub-category/edit-sub-category.component';
import { DiscountAndPromotionListComponent } from './pages/Discount-And-Promotion/Discount/discount-and-promotion-list/discount-and-promotion-list.component';
import { AddDiscountAndPromotionComponent } from './pages/Discount-And-Promotion/Discount/add-discount-and-promotion/add-discount-and-promotion.component';
import { DatePipe } from '@angular/common';
import { EditDiscountAndPromotionComponent } from './pages/Discount-And-Promotion/Discount/edit-discount-and-promotion/edit-discount-and-promotion.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ViewTransactionComponent } from './pages/transaction-management/view-transaction/view-transaction.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ErrorInterceptor } from './error-handler/error.interceptor';

import { SplitPipe } from './pipes/split.pipe';
import { ProductManagementComponent } from './pages/product-management/product-management/product-management.component';
import { ViewProductComponent } from './pages/product-management/view-product/view-product.component';
import { EditProductComponent } from './pages/product-management/edit-product/edit-product.component';
import { AddProductComponent } from './pages/product-management/add-product/add-product.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FaqCategoryComponent } from './pages/faq/faq-category/faq-category.component';
import { EditAddressComponent } from './pages/order-management/edit-address/edit-address.component';
import { AttributeManagementComponent } from './pages/Attribute/attribute-management/attribute-management.component';
import { ViewAttributeComponent } from './pages/Attribute/view-attribute/view-attribute.component';
import { PreviewFooterComponent } from './pages/preview/preview-footer/preview-footer.component';
import { PreviewHeaderComponent } from './pages/preview/preview-header/preview-header.component';
import { UserAddressComponent } from './pages/View-User/userTabs/user-address/user-address.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SupportUsComponent } from './pages/support-us/support-us.component';
import { ViewSupportComponent } from './pages/support-us/view-support/view-support.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TestimonialManagementComponent } from './pages/Testimonial/testimonial-management/testimonial-management.component';
import { ViewTestimonialComponent } from './pages/Testimonial/view-testimonial/view-testimonial.component';
import { EditTestimonialComponent } from './pages/Testimonial/edit-testimonial/edit-testimonial.component';
import { AddTestimonialComponent } from './pages/Testimonial/add-testimonial/add-testimonial.component';

import { EditOfferComponent } from './pages/offer/offer/edit-offer/edit-offer.component';
import { AddOfferComponent } from './pages/offer/offer/add-offer/add-offer.component';
import { OfferListComponent } from './pages/offer/offer/offer-list/offer-list.component';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidebarComponent,
    HeaderComponent,
    UserManagementComponent,
    ViewUserComponent,
    GeneralComponent,
    OrderComponent,
    LoginComponent,
    DiscountAndPromotionListComponent,
    AddDiscountAndPromotionComponent,
    EditSubCategoryComponent,
    AddSubCategoryComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ViewSubCategoryComponent,
    StaticsContentComponent,
    EditStaticContentComponent,
  
    FaqComponent,
    AddFaqComponent,
    EditFaqComponent,
    ViewFaqComponent,
    MyProfileComponent,
    EditMyProfileComponent,
    ChangePasswordComponent,
    UserKycComponent,
    ViewUserKycComponent,
    ViewOrderComponent,
    SubAdminManagementComponent,
    AddSubAdminComponent,
    ViewSubAdminComponent,
    EditSubAdminComponent,
    CategoryListComponent,
    AddCategoryComponent,
    ViewCategoryComponent,
    EditCategoryComponent,
    BrandManagementComponent,
    ViewBrandComponent,
    AddBrandComponent,
    EditBrandComponent,
    OrderManagementComponent,
    ViewOrderManagementComponent,
    EditOrderManagementComponent,
    FooterComponent,
    SubcategoryListComponent,

    EditDiscountAndPromotionComponent,
   
    TransactionManagementComponent,
    BannerManagementComponent,
    AddBannerComponent,
    EditBannerComponent,
    ViewBannerComponent,
    ViewTransactionComponent,
    SplitPipe,
    ProductManagementComponent,
    ViewProductComponent,
    EditProductComponent,
    AddProductComponent,
    FaqCategoryComponent,
    EditAddressComponent,
    AttributeManagementComponent,
    ViewAttributeComponent,
    PreviewFooterComponent,
    PreviewHeaderComponent,
    UserAddressComponent,
    ContactUsComponent,
    SupportUsComponent,
    ViewSupportComponent,
    TestimonialManagementComponent,
    ViewTestimonialComponent,
    EditTestimonialComponent,
    AddTestimonialComponent,

    OfferListComponent,
    AddOfferComponent,
    EditOfferComponent
  ],
  imports: [
    BrowserModule,
    ImageCropperModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgxPaginationModule,
    HttpClientModule,
    CKEditorModule,
    NgOtpInputModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      timeOut: 2000
    }),
    FontAwesomeModule, 
        DragDropModule,
    
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
