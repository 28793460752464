import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
@Component({
  selector: 'app-view-banner',
  templateUrl: './view-banner.component.html',
  styleUrls: ['./view-banner.component.css']
})
export class ViewBannerComponent implements OnInit {
  bannerId : number
  bannerData:any
  description: any;
  constructor(private mainService : MainService,private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.bannerId = res.id
    })
  }

  ngOnInit(): void {
    this.getBanner()
    
  }

    getBanner(){
      let url ="static/view-banner-By-Id?bannerId="+this.bannerId
      
      // this.mainService.showSpinner()
      this.mainService.postApi(url,{},1).subscribe((res)=>{
        if (res.status ==200) {
          this.bannerData = res.data
          this.description=res.data.description
          this.mainService.hideSpinner()
          this.mainService.successToast(res.message)
        } else {
          this.mainService.hideSpinner()
          this.mainService.errorToast(res.message)
        }
      })
    
  }
}
