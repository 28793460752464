import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
declare var $;

@Component({
  selector: "app-offer-list",
  templateUrl: "./offer-list.component.html",
  styleUrls: ["./offer-list.component.css"],
})
export class OfferListComponent implements OnInit {
  searchForm: FormGroup;
  isSearched : boolean = false
  itemPerPage = 5;
  currentPage = 1;
  limit: any = 5;
  total: any;
  items = [];
  totalItems:any
  discountType: any = [
    {
      discountName: "Assigned to order total",
      value: "ASSIGNED_TO_TOTAL_ORDER",
    },
    {
      discountName: "Assigned to products",
      value: "ASSIGNED_TO_PRODUCT",
    },
    {
      discountName: "Assigned to categories",
      value: "ASSIGNED_TO_CATEGORIES",
    },
    {
      discountName: "Assigned to brand",
      value: "ASSIGNED_TO_BRAND",
    },
    {
      discountName: "Assigned to shipping",
      value: "ASSIGNED_TO_SHIPPING",
    },
    {
      discountName: "Assigned to order sub total",
      value: "ASSIGNED_TO_SUB_ORDER_TOTAL",
    },
  ];
  customerData: any = [];
 flip : boolean = false
  constructor(private router: Router, public mainService: MainService) {}

  ngOnInit() {
    this.searchFormValidation();
    this.getOfferList()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      // fromDate: new FormControl(""),
      // toDate: new FormControl(""),
      // discountType: new FormControl(""),
      // status: new FormControl(""),
    });
  }

  editOffer(id) {
    this.router.navigate(["/edit-offer"], { queryParams: { id: id } });
  }
  showModal() {
    $("#BlockModal").modal("show");
  }
  pagination(event) {
    this.currentPage = event;
    if(this.flip){
      this.search()
    }else{
      this.getOfferList()
    }
  }
  deleteId:any =0
   deleteUserModal(id) {
      this.deleteId = id
    }
 hideModal(){
       }
  deleteOffer() {
    let url = "product/delete-Offer?offerId="+this.deleteId
    const data = {}
    this.mainService.deleteApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
       this.getOfferList()
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }

  getOfferList(){
    // product/get-Offer-Details?offerStatus=
    let url="product/get-Offer-Details?offerStatus=ACTIVE&page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage
    this.mainService.postApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.customerData=res.data.activerDetails
        this.total=res.data.activeCount
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }

  search(){
    if(!this.searchForm.value.search  && !this.searchForm.value.status){
      return
    }
    this.isSearched = true
    this.flip = true
    let url =""
    

    if(this.searchForm.value.search){
      url ="product/get-Offer-Details?offerStatus=ACTIVE&offerName="+this.searchForm.value.search+"&page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage
    }
    // else if(this.searchForm.value.status){
    //   url ="product/get-Offer-Details?&offerStatus="+this.searchForm.value.status+"&page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage

    // }
 
    else{
    }
    this.mainService.postApi(url,{}, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          if(res.data.activerDetails){
            this.customerData=res.data.activerDetails
            this.total=res.data.activeCount
          }
           // if(res.data.activerDetails){
           //  this.customerData=res.data.activerDetails
           //  this.total=res.data.activeCount
          // }

          this.mainService.hideSpinner();
          // this.mainService.successToast(res.message);
        } 
        else if(res.status == 201){
             this.customerData=[]
        }
        else {
          this.mainService.hideSpinner();
          this.mainService.errorToast(res.message);
        }
      },
      (err) => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(err);
      }
    );
  }
  
  reset(){
    this.flip = false
    this.isSearched = false
    this.getOfferList()
    this.searchForm.reset()
    
  } 
}
