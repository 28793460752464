    <main class="middle-content">
               <div class="page_title_block1">
            <h1 class="page_title float-left"> Edit Subadmin</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/subAdmin">Back</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save"  (click)="editSubAdmin()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Update
                    </button>
               </div>
       </div>
        <div class="content-section">
            <div class="order-view  max-WT-700  setting-page mb-5">
                <div class="mb40">
                    <form [formGroup]='editform'>
                        <div class="add-store-block input-style mb20 mt20">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 textalignright">Name</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <input type="text" formControlName="name" class="form-control"
                                                    placeholder="Enter name" >
                                                <span class="text text-danger"
                                                    *ngIf="editform.controls.name.touched && editform.controls.name.invalid && editform.controls.name.errors.required">*
                                                    Name is Required</span>
                                                <span class="text text-danger"
                                                    *ngIf="editform.controls.name.invalid && editform.controls.name.errors.pattern">*Invalid
                                                    Name</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col-md-3 textalignright">Email</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <input type="text" formControlName="email" class="form-control"
                                                    placeholder="Enter email" >
                                                <span class="text text-danger"
                                                    *ngIf="editform.controls.email.touched && editform.controls.email.invalid && editform.controls.email.errors.required">*
                                                    Email is Required</span>
                                                <span class="text text-danger"
                                                    *ngIf="editform.controls.email.invalid && editform.controls.email.errors.email">*Invalid
                                                    Email</span>
                                            </div>
                                        </div>
                                    </div>
      
                                </div>

                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 textalignright">Phone No</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <input type="text" formControlName="phone" class="form-control"
                                                    placeholder="Enter mobile number" maxlength="18" minlength="8">
                                                <span class="text text-danger"
                                                    *ngIf="editform.controls.phone.touched && editform.controls.phone.invalid && editform.controls.phone.errors.required">*
                                                    Phone No is Required</span>
                                                <span class="text text-danger"
                                                    *ngIf="editform.controls.phone.invalid && editform.controls.phone.errors.pattern">*Invalid
                                                    Phone No</span>
                                            </div>
                                        </div>
                                    </div>
   
                           
                                </div>
                            </div>

                            <div class="text-center">
                                <h1 class="page_title">Permissions</h1>
                            </div>

                            <div class="row" style="margin-top: 3%;">

                                <div class="col-md-4" *ngFor = "let item of permissionArr ; let i = index">
                                    <label class="globalCheckBox" style="text-transform: capitalize;"> {{name[i] |lowercase}}
                                        <input type="checkbox" [(ngModel)] = "permArray[i]"
                                         [ngModelOptions]="{standalone: true}" (click) = "checkPerm(item,!permArray[i])">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                               
                            </div>

                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>

