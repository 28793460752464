import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';


import { LoginComponent } from './pages/login/login.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { StaticsContentComponent } from './pages/statics-content/statics-content.component';
import { EditStaticContentComponent } from './pages/edit-static-content/edit-static-content.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { ViewUserComponent } from './pages/View-User/view-user/view-user.component';
import { FaqComponent } from './pages/faq/faq/faq.component';
import { AddFaqComponent } from './pages/faq/add-faq/add-faq.component';
import { EditFaqComponent } from './pages/faq/edit-faq/edit-faq.component';
import { ViewFaqComponent } from './pages/faq/view-faq/view-faq.component';
import { MyProfileComponent } from './pages/my-profile/my-profile/my-profile.component';
import { EditMyProfileComponent } from './pages/my-profile/edit-my-profile/edit-my-profile.component';
import { ChangePasswordComponent } from './pages/dashboard/change-password/change-password.component';

import { ViewUserKycComponent } from './pages/View-User/userTabs/view-user-kyc/view-user-kyc.component';
import { ViewOrderComponent } from './pages/View-User/userTabs/view-order/view-order.component';
import { SubAdminManagementComponent } from './pages/Sub-Admin-Management/sub-admin-management/sub-admin-management.component';
import { AddSubAdminComponent } from './pages/Sub-Admin-Management/add-sub-admin/add-sub-admin.component';
import { ViewSubAdminComponent } from './pages/Sub-Admin-Management/view-sub-admin/view-sub-admin.component';
import { EditSubAdminComponent } from './pages/Sub-Admin-Management/edit-sub-admin/edit-sub-admin.component';
import { CategoryListComponent } from './pages/Category-Management/category-list/category-list.component';
import { AddCategoryComponent } from './pages/Category-Management/add-category/add-category.component';
import { ViewCategoryComponent } from './pages/Category-Management/view-category/view-category.component';
import { EditCategoryComponent } from './pages/Category-Management/edit-category/edit-category.component';
import { BrandManagementComponent } from './pages/brand-management/brand-management/brand-management.component';
import { ViewBrandComponent } from './pages/brand-management/view-brand/view-brand.component';
import { EditBrandComponent } from './pages/brand-management/edit-brand/edit-brand.component';
import { AddBrandComponent } from './pages/brand-management/add-brand/add-brand.component';
import { OrderManagementComponent } from './pages/order-management/order-management/order-management.component';
import { ViewOrderManagementComponent } from './pages/order-management/view-order-management/view-order-management.component';
import { EditOrderManagementComponent } from './pages/order-management/edit-order-management/edit-order-management.component';
import { TransactionManagementComponent } from './pages/transaction-management/transaction-management.component';
import { BannerManagementComponent } from './pages/banner-management/banner-management/banner-management.component';
import { AddBannerComponent } from './pages/banner-management/add-banner/add-banner.component';
import { ViewBannerComponent } from './pages/banner-management/view-banner/view-banner.component';
import { EditBannerComponent } from './pages/banner-management/edit-banner/edit-banner.component';
import { SubcategoryListComponent } from './pages/Category-Management/Sub-Category-Management/subcategory-list/subcategory-list.component';
import { AddSubCategoryComponent } from './pages/Category-Management/Sub-Category-Management/add-sub-category/add-sub-category.component';
import { ViewSubCategoryComponent } from './pages/Category-Management/Sub-Category-Management/view-sub-category/view-sub-category.component';
import { EditSubCategoryComponent } from './pages/Category-Management/Sub-Category-Management/edit-sub-category/edit-sub-category.component';
import { DiscountAndPromotionListComponent } from './pages/Discount-And-Promotion/Discount/discount-and-promotion-list/discount-and-promotion-list.component';
import { AddDiscountAndPromotionComponent } from './pages/Discount-And-Promotion/Discount/add-discount-and-promotion/add-discount-and-promotion.component';
import { EditDiscountAndPromotionComponent } from './pages/Discount-And-Promotion/Discount/edit-discount-and-promotion/edit-discount-and-promotion.component';
import { AuthGuard } from './Authentication/auth.guard';
import { ViewTransactionComponent } from './pages/transaction-management/view-transaction/view-transaction.component';
import { ProductManagementComponent } from './pages/product-management/product-management/product-management.component';
import { ViewProductComponent } from './pages/product-management/view-product/view-product.component';
import { EditProductComponent } from './pages/product-management/edit-product/edit-product.component';
import { AddProductComponent } from './pages/product-management/add-product/add-product.component';
import { FaqCategoryComponent } from './pages/faq/faq-category/faq-category.component';
import { EditAddressComponent } from './pages/order-management/edit-address/edit-address.component';
import { AttributeManagementComponent } from './pages/Attribute/attribute-management/attribute-management.component';
import { ViewAttributeComponent } from './pages/Attribute/view-attribute/view-attribute.component';
import { UserAddressComponent } from './pages/View-User/userTabs/user-address/user-address.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SupportUsComponent } from './pages/support-us/support-us.component';
import { ViewSupportComponent } from './pages/support-us/view-support/view-support.component';
import { TestimonialManagementComponent } from './pages/Testimonial/testimonial-management/testimonial-management.component';
import { ViewTestimonialComponent } from './pages/Testimonial/view-testimonial/view-testimonial.component';
import { EditTestimonialComponent } from './pages/Testimonial/edit-testimonial/edit-testimonial.component';
import { AddTestimonialComponent } from './pages/Testimonial/add-testimonial/add-testimonial.component';

import { EditOfferComponent } from './pages/offer/offer/edit-offer/edit-offer.component';
import { AddOfferComponent } from './pages/offer/offer/add-offer/add-offer.component';
import { OfferListComponent } from './pages/offer/offer/offer-list/offer-list.component';

const routes: Routes = [
  {path : 'dashboard',component : DashboardComponent,canActivate : [AuthGuard]},
  {path: '', redirectTo: 'login', pathMatch: 'full' },
  {path: 'login', component: LoginComponent },
  {path: 'forgot-password', component: ForgetPasswordComponent },
  {path: 'reset-password', component: ResetPasswordComponent },
  {path: 'change-password', component: ChangePasswordComponent },
 
   // // my profile
  {path: 'my-profile', component: MyProfileComponent,canActivate : [AuthGuard] },
  {path: 'edit-profile', component: EditMyProfileComponent,canActivate : [AuthGuard]},

  //static-content
  {path: 'statics-content', component : StaticsContentComponent,canActivate : [AuthGuard]},
  {path: 'edit-static-content/:staticList',component:EditStaticContentComponent,canActivate : [AuthGuard]},

  //user-management
  {path : 'user-mangement',component : UserManagementComponent,canActivate : [AuthGuard]},
  {path : 'view-user',component : ViewUserComponent,canActivate : [AuthGuard]},
  {path : 'view-user-kyc',component : ViewUserKycComponent,canActivate : [AuthGuard]},
  {path : 'view-order',component : ViewOrderComponent,canActivate : [AuthGuard]},
  
  // faq
  {path: 'faq', component: FaqComponent,canActivate : [AuthGuard]},
  {path: 'add-faq', component: AddFaqComponent ,canActivate : [AuthGuard]},
  {path: 'edit-faq', component: EditFaqComponent,canActivate : [AuthGuard]},
  {path: 'view-faq', component: ViewFaqComponent,canActivate : [AuthGuard]},

  //static-content
  {path: 'statics-content', component : StaticsContentComponent,canActivate : [AuthGuard]},
  {path: 'edit-static-content',component:EditStaticContentComponent,canActivate : [AuthGuard]},
   
  //transaction-management
  {path:'transaction-management',component:TransactionManagementComponent,canActivate : [AuthGuard]},
  // {path:'transaction-management',component:TransactionManagementComponent},
  {path:'view-transaction',component:ViewTransactionComponent},

  //banner management
  {path:'banner-management',component:BannerManagementComponent,canActivate : [AuthGuard]},
  {path:'add-banner',component:AddBannerComponent,canActivate : [AuthGuard]},
  {path:'view-banner',component:ViewBannerComponent,canActivate : [AuthGuard]},
  {path:'edit-banner',component:EditBannerComponent,canActivate : [AuthGuard]},

  //brand management
  {path:'brand',component:BrandManagementComponent,canActivate : [AuthGuard]},
  {path:'view-brand',component:ViewBrandComponent,canActivate : [AuthGuard]},
  {path:'edit-brand',component:EditBrandComponent,canActivate : [AuthGuard]},
  {path:'add-brand',component:AddBrandComponent,canActivate : [AuthGuard]},

  //order management
  {path:'order',component:OrderManagementComponent,canActivate : [AuthGuard]},
  {path:'view-order-list',component:ViewOrderManagementComponent,canActivate : [AuthGuard]},
  {path:'edit-order',component:EditOrderManagementComponent,canActivate : [AuthGuard]},
  
  //sub admin management
  {path : 'subAdmin',component : SubAdminManagementComponent,canActivate : [AuthGuard]},
  {path : 'add-sub-admin',component : AddSubAdminComponent,canActivate : [AuthGuard]},
  {path : 'view-sub-admin',component : ViewSubAdminComponent,canActivate : [AuthGuard]},
  {path : 'edit-sub-admin',component : EditSubAdminComponent,canActivate : [AuthGuard]},

  //product management
  {path:'product-list',component:ProductManagementComponent},
  {path:'view-product',component:ViewProductComponent},
  {path:'edit-product',component:EditProductComponent},
  {path:'add-product',component:AddProductComponent},
  //category-management
  {path : 'category-list',component : CategoryListComponent,canActivate : [AuthGuard]},
  {path : 'add-category',component : AddCategoryComponent,canActivate : [AuthGuard]},
  {path : 'view-category',component : ViewCategoryComponent,canActivate : [AuthGuard]},
  {path : 'edit-category', component : EditCategoryComponent,canActivate : [AuthGuard]},


{path : 'sub-category-list',component: SubcategoryListComponent,canActivate : [AuthGuard]},
{path : 'add-sub-category',component: AddSubCategoryComponent,canActivate : [AuthGuard]},
{path : 'view-sub-category',component: ViewSubCategoryComponent,canActivate : [AuthGuard]},
{path : 'edit-sub-category',component: EditSubCategoryComponent,canActivate : [AuthGuard]},


{path : 'discount-Management',component: DiscountAndPromotionListComponent,canActivate : [AuthGuard]},
{path : 'add-discount',component: AddDiscountAndPromotionComponent,canActivate : [AuthGuard]},
{path : 'edit-discount',component: EditDiscountAndPromotionComponent,canActivate : [AuthGuard]},



{path : 'add-faq-category',component: FaqCategoryComponent,canActivate : [AuthGuard]},

{path : 'edit-address',component: EditAddressComponent,canActivate : [AuthGuard]},

{path : "attribute-list",component: AttributeManagementComponent},
{path : "child-attribute-list",component: ViewAttributeComponent},
{path : "edit-coutomer-Address",component: UserAddressComponent},

{path : "contact-us",component: ContactUsComponent},
{path : "support-us",component: SupportUsComponent},
{path : "view-support",component: ViewSupportComponent},
//
{path : "testimonial",component: TestimonialManagementComponent},
{path : "view-testimonial",component: ViewTestimonialComponent},
{path : "edit-testimonial",component: EditTestimonialComponent},
{path : "add-testimonial",component: AddTestimonialComponent},

{path : "edit-offer",component: EditOfferComponent,canActivate : [AuthGuard]},
{path : "add-offer",component: AddOfferComponent,canActivate : [AuthGuard]},
{path : "offer-Management",component: OfferListComponent,canActivate : [AuthGuard]},



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
