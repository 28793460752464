<div>
  <main class="middle-content">
    <div class="page_title_block1">
      <h1 class="page_title float-left"> Add a new product</h1>
      <small class="pad">
        <i class="fa fa-arrow-circle-left colr"></i>
        <a class="po" routerLink="/product-list">Back to product list</a>
      </small>
      <div class="float-right">
        <button style="margin-right:6px;" (click)="getViewProduct()" type="button" data-toggle="modal"
          data-target=" #exampleModalLong" name="save" class="btn btn-primary">
          <i class="fa fa-floppy-o
                    "></i>
          Preview
        </button>
        <button type="submit" name="save" (click)="addProduct()" class="btn btn-primary">
          <i class="fa fa-floppy-o
                    "></i>
          Save
        </button>
      </div>
    </div>
    <div class="pading20" id="accordion">
      <form [formGroup]="addForm">
        <div class="card mb7">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                <i class="fa fa-info iconP"></i>Product Info
              </button>
            </h5>
          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Product Name <span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">

                    <input type="text" class="form-control " placeholder="Product Name"
                      formControlName="name">
                      <span *ngIf="addForm.get('name').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter product name.
                      </span>
                  </div>
                </div>


              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright "> Brand <span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <select class="form-control form-select" formControlName="brandId">
                      <option value="" selected>Select Brand</option>
                      <option *ngFor="let items of brandList" [value]="items.brandId">{{items.brandName}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Select Category <span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <select class="form-control form-select" formControlName="category"
                      (ngModelChange)="onSelectName($event)">
                      <option value="" selected>Category</option>
                      <option *ngFor="let category of categoryList"
                        [ngValue]="{ categoryId: category.categoryId, categoryName: category.categoryName }">
                        {{category.categoryName}}</option>

                    </select>
                     <span *ngIf="addForm.get('category').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter category name.
                      </span>

                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Serial Number<span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">

                    <input type="text" class="form-control " placeholder="Serial Number"
                       formControlName="serial">
                   <span *ngIf="addForm.get('serial').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter serial name.
                      </span>

                  </div>
                </div>
              </div>
             <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">SKU NUmber<span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <input type="text" class="form-control " placeholder="SKU Number"
                       formControlName="skuNumber">
                         <span *ngIf="addForm.get('skuNumber').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter SKU Number.
                      </span>

                  </div>
                </div>
              </div>

            <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Short Description <span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <textarea type="text" class="form-control " placeholder="Short Description"
                      formControlName="shortDescription">
                    </textarea>
                     <span *ngIf="addForm.get('shortDescription').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter short description .
                      </span>

                  </div>
                </div>
              </div>
             <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Quantity <span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                   <input type="number" class="form-control " placeholder="Quantity"
                      formControlName="quantity">
                     <span *ngIf="addForm.get('quantity').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter quantity.
                      </span>

                  </div>
                </div>
              </div>
              
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Product Description <span>:</span>
                </label>

                <!-- <div class="row"> -->
                <div class="col-md-8">
                  <ck-editor formControlName="editorValue" name="editor1" [(ngModel)]="editorValue" skin="moono-lisa"
                    language="en" [fullPage]="true"></ck-editor>
                     <span *ngIf="addForm.get('editorValue').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter product full description .
                      </span>
                </div>
              </div>
                 

            </div>
          </div>
        </div>
        <div class="card mb7">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed btncolor" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                <i class="fa fa-dollar-sign iconP"></i> Prices
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <div class="row" *ngFor="let it of variantProduct;let index = index">
                <div class="col-md-2">
                  <b>Combination {{index +1}}</b>

                </div>
                <div class="col-md-1">
                  <label for="">:</label>
                </div>

                <div class="col-md-2">
                  <span *ngIf="it?.attribute"><b>{{it?.attribute}}</b> = {{it?.childAttribute}} </span><br
                    *ngIf="it?.attribute">
                  <span *ngIf="it?.attribute1"><b>{{it?.attribute1}}</b> = {{it?.childAttribute1}}</span><br
                    *ngIf="it?.attribute1">
                  <span *ngIf="it?.attribute2"><b>{{it?.attribute2}}</b> = {{it?.childAttribute2}}</span>
                  <div><b>Price</b> : {{it.variantPrice}}</div>
                  <hr>
                </div>
                <div class="col-md-2"> <button class="btn btn-danger ml-4" (click)="removeVariant(index)"
                    title="Remove this variant"><i class="fa fa-trash"></i></button></div>
                <!-- <div class="col-md-2 mt-3"><b>Price</b> {{i.price}}</div> -->
              </div>

              <div class="row" *ngIf="addCombination.length == 0">
                <div class="col-md-12">
                  <a style="color: blue;cursor: pointer;" (click)="addMoreCombination()">Add Variant of product</a>
                </div>
              </div>
              <div class="row" *ngIf="addCombination.length != 0">
                <div class="col-md-6">

                  <b>Add Combination</b>
                </div>
              </div>

              <div class="card mb-2" *ngFor="let item of addCombination; let i = index">
                <div class="row mb-1 ">
                  <div class="col-md-12 text-right">

                    <i class="fa fa-times mr-1" (click)="removeAttribute(i)" style="cursor: pointer;"></i>
                  </div>
                </div>
                <div class="row mb-2 p-1">
                  <div class="col-md-3">
                    <b>Attribute</b>
                  </div>
                  <div class="col-md-1">
                    <label for="">:</label>
                  </div>
                  <div class="col-md-5">
                    <select class="form-control form-select" id="child" (change)="getchildAttribute(item,i)"
                      [(ngModel)]="attributeCategory[item]" [ngModelOptions]="{standalone: true}">
                      <option value="">Select Attribute</option>
                      <option value={{i.attributeId}}^&%{{i.attribute}} *ngFor="let i of attrributeList">{{i.attribute}}
                      </option>

                    </select>
                  </div>
                </div>
                <div class="row mb-2 p-1">
                  <div class="col-md-3">
                    <b> Attribute Value</b>
                  </div>
                  <div class="col-md-1">
                    <label for="">:</label>
                  </div>
                  <div class="col-md-5" *ngIf="i == 0">
                    <select class="form-control form-select" [(ngModel)]="childAttribute[item]"
                      (change)="isDisabled(price.value)" [ngModelOptions]="{standalone: true}">
                      <option [value]="i.childAttribute" *ngFor="let i of childProductAttribute0">{{i.childAttribute}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-5" *ngIf="i == 1">
                    <select class="form-control form-select" [(ngModel)]="childAttribute[item] "
                      (change)="isDisabled(price.value)" [ngModelOptions]="{standalone: true}">
                      <option [value]="i.childAttribute" *ngFor="let i of childProductAttribute1">{{i.childAttribute}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-5" *ngIf="i == 2">
                    <select class="form-control form-select" [(ngModel)]="childAttribute[item]"
                      (change)="isDisabled(price.value)" [ngModelOptions]="{standalone: true}">
                      <option [value]="i.childAttribute" *ngFor="let i of childProductAttribute2">{{i.childAttribute}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-5" *ngIf="i == 3">
                    <select class="form-control form-select" [(ngModel)]="childAttribute[item]"
                      (change)="isDisabled(price.value)" [ngModelOptions]="{standalone: true}">
                      <option [value]="i.childAttribute" *ngFor="let i of childProductAttribute3">{{i.childAttribute}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12 text-right">
                    <button class="btn btn-theme" (click)="addMoreCombination()">Add more attribute</button>
                  </div>
                </div>
              </div>
              <div class="row text-center mb-2">
                <div class="col-md-3">
                  <b>Price</b>
                </div>
                <div class="col-md-1">
                  <label for="">:</label>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" (input)="isDisabled(price.value)" #price>
                </div>
              </div>
              <div class="row text-center">
                <div class="col-md-12">
                  <button class="btn btn-theme" (click)="addPrice(price.value)" [disabled]="isEnable">Add Price</button>
                  <span *ngIf="variantProduct && variantProduct.length===0  && isSubmit"  class="text-danger" >
                        Please enter price.
                      </span>
                </div>
              </div>
               
            </div>
          </div>
        </div>
        <div class="card mb7">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed btncolor" data-toggle="collapse" data-target="#collapseThree"
                aria-expanded="false" aria-controls="collapseThree">
                <i class="fa fa-picture-o iconP"></i> Pictures
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body">
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright">Product Image <span>:</span>
                </label>
                <div class="col-lg-8">
                  <p style="margin-top: 4px;">
                    <input #file type="file" accept='image/*' accept=".jpeg,.png" multiple
                      (change)="productImage($event)" />
                    <i class="fa fa-question" data-tooltip></i>
                  </p>
                  <span *ngIf="proctImg.length===0 && isSubmit"  class="text-danger" >
                        Please add product image.
                      </span>
                  <div cdkDropList [cdkDropListData]="proctImg" (cdkDropListDropped)="onDrop($event)" class="row">
                    <div *ngFor="let item of proctImg ; let i=index;" class="col-md-2">
                      <div class="card mb-4 box-shadow">
                        <a cdkDrag>
                          <i (click)="deleteImage(item,i)" class="icon-x fa fa-times-circle cross-icon"
                            aria-hidden="true"></i>
                          <span class="gall-icon-bg">
                            <i (click)="selectDefaultImage(item,i)" class="icon-p fa fa-check  gall-icon "
                              [ngClass]="item?.selected== true ? 'home-active' : '' " aria-hidden="true"></i>
                          </span>
                          <img class="card-img-top" [src]="item?.image || 'assets/loader/1488 (2).gif'" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb7">
          <div class="card-header" id="heading4">
            <h5 class="mb-0">
              <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse4"
                aria-expanded="false" aria-controls="collapse4">
                <i class="fa fa-truck iconP"></i>Shipping Info
              </button>
            </h5>
          </div>

          <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
            <div class="card-body">
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Weight <span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <input type="number" class="form-control " placeholder="Weight"
                      style="width: 35%;" formControlName="weight">
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Length <span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <input type="number" class="form-control " placeholder="Length"
                      style="width: 35%;" formControlName="length">
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Width<span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <input type="number" class="form-control " placeholder="Width"
                      style="width: 35%;" formControlName="width">
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Height<span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <input type="number" class="form-control " placeholder="Height"
                      style="width: 35%;" formControlName="height">
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Free shipping<span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <input type="checkbox" class="form-control " placeholder="Height"
                    style="width: 3%;height: 17px;" (change)="checkIsFree($event.target.checked)" formControlName="freeShipping">
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Additional shipping charge<span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <input type="number" class="form-control " placeholder="shipping charge"
                      style="width: 35%;" formControlName="shippingCharge">
                  </div>
                <span *ngIf="!this.addForm.controls.freeShipping.value && this.addForm.controls.shippingCharge.value===0 && isSubmit"  class="text-danger" >
                        Please enter shipping charge.
                      </span>
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-4 textalignright ">Delivery days<span>:</span>
                </label>
                <div class="col-md-8 textaligncenter">
                  <div class="form-group">
                    <select style="width: 35%;" class="form-control form-select"
                      formControlName="deliveryDay">
                      <option value="" selected>Days</option>
                      <option *ngFor="let item of deleveryDatList" [value]="item.name">{{item.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </main>
</div>



<!--  Preview popup For view Product Details -->

<div class="modal fade bd-example-modal-lg" id="exampleModalLong" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog image-dialog modal-lg" role="document">
    <div class="modal-content" style="margin-top: -43px;">
      <app-preview-header></app-preview-header>
      <div class="modal-body bg-w ">
        <form>
          <div class="add-store-block input-style mb20 mt20">
            <div class="row">
              <div class="col-md-7">
                <div class="row">
                  <div style="    padding-left: 78px;" class="col-md-3">
                    <img class="crousel img-left" *ngFor="let item of productImageView" src="{{item.image}}" alt="img"
                      (click)="change(item.image)">
                  </div>
                  <div class="col-md-9" style="height: 650px;">
                    <img class="loadedimage df-c" style="width: 100%;height: 100%;object-fit: contain;"
                      [src]="defaultImage" alt="">
                  </div>
                </div>
              </div>
              <div class="col-md-5 ">
                <div class="jss1863 productDetailsPage">
                  <h3 class="ProductName">{{productData?.productName}}</h3>
                </div>
                <div class="jss1863 productDetailsPage">
                  <img class="card-img-top1" [src]="'assets/img/ystar3.png'" alt="" />
                  <h5 class="">
                    (242 customer review)</h5>
                </div>
                <div class="jss1863 productDetailsPage">
                  <h3 class="price" *ngIf="!showVariantPrice">{{productData?.price}}<span>$</span></h3>
                  <h3 class="price" *ngIf="showVariantPrice">{{priceL || "--"}}<span>$</span></h3>
                </div>
                <!-- ////////////////////////////////////// -->
                <div class=" stripe" *ngIf="variantProduct.length != 0">
                  <div class="card mb7">
                    <div class="card-header" id="heading8">

                      <h5 class="mb-0">
                        <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse8"
                          aria-expanded="true" aria-controls="collapse8">
                          <i class="fa fa-info iconP"></i>See More Variant
                        </button>
                      </h5>
                    </div>
                    <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordion">
                      <div class="card-body">
                        <div class="card p-2" *ngFor="let item of variantProduct">
                          <div class="row">
                            <div class="col-md-12 d-flex justify-content-between">
                              <input type="radio" class="form-control new-form" (click)="getItem(item)" name="Variant"
                                id="">
                              &nbsp;
                              <span class="new-para">
                                <p *ngIf="item.attribute"> <b>{{item.attribute}}</b> = {{item.childAttribute}} | </p>
                                &nbsp;
                                <p *ngIf="item.attribute1"> <b>{{item.attribute1}}</b> = {{item.childAttribute1}} | </p>
                                &nbsp;
                                <p *ngIf="item.attribute2"> <b>{{item.attribute2}}</b> = {{item.childAttribute2}}</p>
                              </span>
                            </div>

                          </div>


                          <!-- second card -->

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /////// -->

                <div class="jss1863 productDetailsPage">
                  <p class="description"
                    >{{productData?.shortDescription}}</p>
                </div>
                  <div class="jss18690 productDetailsPage">
                      <p *ngIf="productData?.freeShipping" class="free">Free shipping : Yes available</p>
                      <p  *ngIf="!productData?.freeShipping" class="free">Free shipping : Not available</p>
               </div>
                 <div *ngIf="!productData?.freeShipping" class="jss18690 productDetailsPage">
                    <p class="ship">Shipping charges : ${{productData?.additionalCharge}}</p>
                 </div>
                 <div class="jss18690 productDetailsPage">
                    <p class="ship">Delivery Days : {{productData?.deliveryDays}}</p>
                 </div>
                <div class="jss1866 ">
                  <div class="input-group">
                    <span class=" input-group-btn">
                      <button disabled type="button" class="btnqty1 quantity-left-minus btn btn-danger btn-number"
                        data-type="minus" data-field="">
                        <i class="gv fa fa-plus "></i>
                      </button>
                    </span>
                    <input disabled type="text" id="quantity" name="  quantity" class="qty input-number" value="1"
                      min="1" max="100">
                    <span class="input-group-btn">
                      <button disabled type="button" class="btnqty2 quantity-right-plus btn btn-success btn-number"
                        data-type="plus" data-field="">
                        <i class="gv fa fa-minus "></i>
                      </button>
                    </span>
                  </div>
                  <div class="">
                    <button type="button" class="addcard quantity-left-plus btn btn-success btn-number" data-type="plus"
                      data-field=""> <i class="gvp fa fa-shopping-bag "></i>Add To Card</button>
                  </div>
                </div>
                <div class="jss1863 productDetailsPage">
                  <button class="my-btn btn btn-success">Start Shopping</button>
                </div>
                <div class="jss1863 productDetailsPage">
                  <span style="padding-right: 111px;" class="name-sp">SKU</span> <span class="ans-sp">
                    {{productData?.skuNumber}}</span>
                </div>
                <div class="jss1863 productDetailsPage">
                  <span style="padding-right: 75px;" class="name-sp">Serial No.</span> <span class="ans-sp">
                    {{productData?.serialNumber}}</span>
                </div>
                <div class="jss1863 productDetailsPage">
                  <span style="padding-right: 81px;" class="name-sp">category</span> <span class="ans-sp">
                    {{productData?.category}}</span>
                </div>
                <!-- <div class="jss1863 productDetailsPage">
                  <span style="padding-right: 132px;" class="name-sp">Tags</span>
                  <span class="ans-sp"> solar , panel</span>
                </div> -->
                <div class="jss1863 productDetailsPage">
                  <span style="padding-right: 17px;" class="name-sp">Share This Product:</span>
                  <img class="card-img-top2" [src]="'assets/img/social-img.png'" alt="" />


                </div>
              </div>
              <div class="col-md-12 bg-wht newo">
                <nav>
                  <div class="nav nav-tabs mp-40 " id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active btn-dec mp-20 " id="nav-home-tab" data-toggle="tab"
                      href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Description</a>
                    <a class="nav-item nav-link btn-dec " id="nav-profile-tab " data-toggle="tab" href="#nav-profile"
                      role="tab" aria-controls="nav-profile" aria-selected="false">Additional Information</a>
                  </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active new-p pdmy" id="nav-home" role="tabpanel"
                    aria-labelledby="nav-home-tab"
                    [innerHtml]="sanitizer.bypassSecurityTrustHtml(productData?.productDescription)"></div>
                  <div class="tab-pane fade new-p pdmy" id="nav-profile" role="tabpanel"
                    aria-labelledby="nav-profile-tab ">
                    <div>
                      <h4>Dimensions:</h4>
                    <span> 
                        Length:{{productData?.productLength}} x Height:{{productData?.productHeight}} x 
                        Width:{{productData?.productWidth}}(cms)</span>
                    </div>
                    <div>
                      <h4>Other Details:</h4>
                      <span>Weight : {{productData?.productWeight}} (Grams)</span>
                      <!-- <span>Outdoor chair: synthetic fiber</span> -->
                      <!-- <span>Stand: powder coated iron (only for indoor use)</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <app-preview-footer></app-preview-footer>
    </div>
  </div>
</div>