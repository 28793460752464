

<div >
    <main class="middle-content">
      <div class="page_title_block">
        <h1 class="page_title">PROFILE / CHANGE PASSWORD</h1>
        <hr>
      </div>
      <div class="content-section">
        <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
          <div class="  mb40">
            <div class="admin-profile">
              <h4 class="mb20 text-center">Change Password</h4>
            </div>
            <div class="add-store-block input-style mb20 mt20 ">
              <form [formGroup]="form">
               
  
  
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright ">Current Password <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input [type]="oldPasswordType" class="form-control"  placeholder="Current Password" formControlName="oldPassword"
                        (keypress)="mainService.preventSpace($event)" maxlength="30">
                        <div class="input-group-text">
                        <i *ngIf="oldPasswordType == 'text'" class="fa fa-eye" aria-hidden="true" (click)="viewOld(0)" style="background-color: transparent;"></i>
                       <i *ngIf="oldPasswordType == 'password'" class="fa fa-eye-slash" aria-hidden="true" (click)="viewOld(1)" style="background-color: transparent;"></i>
                        </div>
                      <div
                        *ngIf="form.get('oldPassword').invalid && (form.get('oldPassword').touched || form.get('oldPassword').dirty)"
                        class="text-danger">
                        <span *ngIf="form.get('oldPassword').hasError('required') ">*Please enter old password.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright ">New Password <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input [type]="newPasswordType" class="form-control" placeholder="New Password" formControlName="newPassword"
                        (keypress)="mainService.preventSpace($event)" maxlength="30">
                        <div class="input-group-text">
                          <i *ngIf="newPasswordType == 'text'" class="fa fa-eye" aria-hidden="true" (click)="viewNew(0)" style="background-color: transparent;" ></i>
                         <i *ngIf="newPasswordType == 'password'" class="fa fa-eye-slash" aria-hidden="true" (click)="viewNew(1)" style="background-color: transparent;"></i>
  </div>
                      <div
                        *ngIf="form.get('newPassword').invalid && (form.get('newPassword').touched || form.get('newPassword').dirty)"
                        class="text-danger">
                        <span *ngIf="form.get('newPassword').hasError('required')">
                          *Please enter new password.</span>
                        <span *ngIf="form.get('newPassword').hasError('pattern')">
                          *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright ">Confirm New Password <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input type="password" class="form-control" placeholder="Confirm New Password" formControlName="confirmPassword"
                        (keypress)="mainService.preventSpace($event)" maxlength="30">
                      <div *ngIf="form.get('confirmPassword').dirty" class="text-danger">
                        <span *ngIf="form.get('confirmPassword').value != form.get('newPassword').value">*Password
                          and Confirm Password must match.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            
            <div class="mt40 mb40 text-center">
              <button class="btn btn-large  max-WT-150 btn-secondary" [disabled]="form.invalid"
              (click)="changePassword()">Change</button>
              <button class="btn btn-large  max-WT-150 btn-primary ml-2"  (click)="back()">Back</button>
          </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  
