<main class="middle-content">
  <div class="page_title_block1">
    <h1 class="page_title float-left"> Add FAQs</h1>
    <small class="pad">
      <i class="fa fa-arrow-circle-left colr"></i>
      <a class="po" routerLink="/faq">Back</a>
    </small>
    <div class="float-right">
      <button style="margin-right:6px;" (click)="preview()" type="button" data-toggle="modal"
        data-target=" #examppreview333" name="save" class="btn btn-primary">
        <i class="fa fa-floppy-o
                    "></i>
        Preview
      </button>
      <button *ngIf="isCategory=='category'" type="submit" name="save" [disabled]="!addParantForm.valid"
        (click)=" addCategoryFaq()" class="btn btn-primary">
        <i class="fa fa-floppy-o
                    "></i>
        Save
      </button>
      <button type="submit" name="save" [disabled]="!addForm.valid" (click)="addFaq()" class="btn btn-primary">
        <i class="fa fa-floppy-o
                    "></i>
        Save
      </button>
    </div>
  </div>
  <!-- Parant FAQ -->
  <!-- <div class="content-section" *ngIf="isCategory=='category'">
          <div class="order-view max-WT setting-page">
            <form [formGroup]="addParantForm">
            <div class="mb40">
              <div class="add-store-block input-style mb20 ">
                <div class="form-group row  view-label-align">
                  <label class="col-md-3 textalignright ">FAQ Type Category Name
                    <span>:</span></label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" maxlength="100"
                        formControlName="catName">
                    <div *ngIf="addParantForm.get('catName').invalid && (addParantForm.get('catName').dirty || addParantForm.get('catName').touched)" class="text-danger">
                        <span *ngIf="addParantForm.get('catName').hasError('required')"> *Please enter Name.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </div>
        </div> -->



  <!-- end parant  -->
  <div class="content-section">
    <div class="order-view max-WT setting-page">
      <form [formGroup]="addForm">
        <div class="mb40">
          <div class="add-store-block input-style mb20 ">
            <div class="form-group row  view-label-align">
              <label class="col-md-3 textalignright ">Faq Category Type
                <span>:</span></label>
              <div class="col-md-9">
                <select class="form-control form-select" formControlName="category"
                  (ngModelChange)="onSelectName($event)">
                  <option value="">Select Faq Category Type</option>
                  <option *ngFor="let item of parantFaqCatedoryList"
                    [ngValue]="{ id: item.categoryId, name: item.category }"> {{item.category}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row  view-label-align">
              <label class="col-md-3 textalignright ">Question
                <span>:</span></label>
              <div class="col-md-9">
                <input type="text" class="form-control" maxlength="100" formControlName="question">
                <div
                  *ngIf="addForm.get('question').invalid && (addForm.get('question').dirty || addForm.get('question').touched)"
                  class="text-danger">
                  <span *ngIf="addForm.get('question').hasError('required')"> *Please enter question.</span>
                  <span *ngIf="addForm.get('question').hasError('pattern')"> *Please enter valid question.</span>
                </div>
              </div>
            </div>
            <div class="form-group row align-items-baseline view-label-align">
              <label class="col-md-3 textalignright ">Answer
                <span>:</span></label>
              <div class="col-md-9">
                <textarea class="form-control" formControlName="answer" name="" id="" cols="30" rows="5"></textarea>


                <div
                  *ngIf="addForm.get('answer').invalid && (addForm.get('answer').dirty || addForm.get('answer').touched)"
                  class="text-danger">

                  <span *ngIf="addForm.get('answer').hasError('required')"> *Please enter answer.</span>
                </div>


              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>


<!--  Preview popup For view Product Details -->

<div class="modal fade bd-example-modal-lg" id="examppreview333" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog image-dialog modal-lg" role="document">
    <div class="modal-content" style="margin-top: -43px;">
      <app-preview-header></app-preview-header>
      <div class="modal-body bg-w height500">
        <form>
          <div class="add-store-block input-style mb20 mt20">
            <div class="row ptag">
              <div class="col-md-12">
                <h5><b>Help Center | 24x7 Customer Care Support</b></h5><br>
                <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                  release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                  software like Aldus PageMaker including versions of Lorem Ipsum.</p>
              </div>

              <!-- <div class="col-md-12  ">
                <span class="nmr">Order Details-</span>
                <div class="pading20" id="accordion">
                  <div class="card mb7">
                    <div class="card-header" id="heading3">
                      <h5 class="mb-0">
                        <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse3"
                          aria-expanded="false" aria-controls="collapse3">{{productData?.question}}
                        </button>
                      </h5>
                    </div>
                    <div id="collapse3" class="collapse " aria-labelledby="heading3" data-parent="#accordion">
                      <div style="font-size: 17px;" class="card-body">
                        {{productData?.answer}}
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row ptag">
              <div class="col-md-2">
                <li style="list-style: none;" >
                  
                  <ul *ngFor="let item of parantFaqCatedoryList" style="color: #52565c;font-size:medium" class="ripple">{{item.category}}</ul><br>
                </li>
              </div>
              <div class="col-md-10">
               
                  <!-- <span class="nmr">Order Details-</span> -->
                  <div class="" id="accordion" >
                    <div class="card mb7" *ngIf="productData?.question">
                      <div class="card-header" id="headingFirst">
                        <h6 class="mb-0">
                          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapseFirst"
                            aria-expanded="false" aria-controls="collapse3">{{productData?.question}}
                          </button>
                        </h6>
                      </div>
                      <div id="collapseFirst" class="collapse " aria-labelledby="headingFirst" data-parent="#accordion">
                        <div style="font-size: 17px;" class="card-body">
                          {{productData?.answer}}
                        </div>
                      </div>
                    </div>

                 

                    <div class="card mb7" *ngFor="let item of allFaqList;let i = index">
                      <div class="card-header" id="heading{{i}}">
                        <h6 class="mb-0">
                          <button class="btn btn-link btncolor" data-toggle="collapse" [attr.data-target]="'#collapse'+ i"
                            aria-expanded="false" aria-controls="collapse2">{{item?.product.question}}
                          </button>
                        </h6>
                      </div>
                      <div id="collapse{{i}}" class="collapse " [attr.aria-labelledby]="'heading'+i" data-parent="#accordion">
                        <div style="font-size: 17px;" class="card-body">
                          {{item?.product.answer}}
                        </div>
                      </div>
                    </div>

                    <!-- <div class="card mb7">
                      <div class="card-header" id="heading3">
                        <h6 class="mb-0">
                          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse3"
                            aria-expanded="false" aria-controls="collapse3">{{productData?.question}}
                          </button>
                        </h6>
                      </div>
                      <div id="collapse3" class="collapse " aria-labelledby="heading3" data-parent="#accordion">
                        <div style="font-size: 17px;" class="card-body">
                          {{productData?.answer}}
                        </div>
                      </div>
                    </div>

                    <div class="card mb7">
                      <div class="card-header" id="heading4">
                        <h6 class="mb-0">
                          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse4"
                            aria-expanded="false" aria-controls="collapse3">{{productData?.question}}
                          </button>
                        </h6>
                      </div>
                      <div id="collapse4" class="collapse " aria-labelledby="heading4" data-parent="#accordion">
                        <div style="font-size: 17px;" class="card-body">
                          {{productData?.answer}}
                        </div>
                      </div>
                    </div>

                    <div class="card mb7">
                      <div class="card-header" id="heading5">
                        <h6 class="mb-0">
                          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse5"
                            aria-expanded="false" aria-controls="collapse3">{{productData?.question}}
                          </button>
                        </h6>
                      </div>
                      <div id="collapse5" class="collapse " aria-labelledby="heading5" data-parent="#accordion">
                        <div style="font-size: 17px;" class="card-body">
                          {{productData?.answer}}
                        </div>
                      </div>
                    </div> -->
                  </div>
                
              </div>
            </div>
          </div>
        </form>
      </div>
      <app-preview-footer></app-preview-footer>
    </div>
  </div>
</div>