<div>
    <main class="middle-content">
       <div class="page_title_block1">
            <h1 class="page_title float-left">Edit Brand</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/brand">Back to brand list</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save" (click)="editBrand()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Update
                    </button>
               </div>
            </div>
        <div class="content-section">

            <div class="order-view  max-WT-700  setting-page">
                <div class="  mb40">
                    <form [formGroup]="editForm">
                        <div class="add-store-block input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">



                                <label class="col-md-4 textalignright ">Brand Name
                                    <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">


                                        <div class="">
                                            <div class="serch-boxuser">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <input type="text" class="form-control"
                                                            (keypress)="mainService.preventSpace($event)" maxlength="30"
                                                            formControlName="brandName" placeholder="Brand Name">
                                                      
                                                    </div>
                                                    <div *ngIf="editForm.get('brandName').invalid && (editForm.get('brandName').dirty || editForm.get('brandName').touched)"
                                                    class="text-danger">
                                                    <span
                                                        *ngIf="editForm.get('brandName').hasError('required')">
                                                        *Please enter brandName.</span>
                                                </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

<!--                               
                                <label class="col-md-4 textalignright ">Brand Description
                                    <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">


                                        <div class="">
                                            <div class="serch-boxuser">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                       <textarea class="form-control" placeholder="Brand Description" formControlName="description" name="" id=""  rows="5"></textarea>

                                                    </div>
                                                    <div class="text-danger">
                                                        <span
                                                            *ngIf="editForm.get('description').hasError('required') && (editForm.get('description').dirty || editForm.get('description').touched)">
                                                            *Please enter brand description.
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    
                                   
                                </div>

                                <label class="col-md-4 ">Upload Image <span>:</span>
                                </label>
                                <div class="col-md-8 ">
                                    <div class="form-group ">



                                        <p style="margin-top: 4px;">
                                            <input #file type="file" accept='image/*' (change)="getImage($event)" />
                                            <img [src]=" imgSrc ||'assets/img/dummy.png'" alt=""
                                                style="width: 14%; border: 1px solid black;" />

                                        </p>

                                    </div>

                                </div> -->

                               


                              <!--   <div class="col-md-12  mt40 mb40 text-center">
                                    <button class="btn btn-large  max-WT-150 btn-primary ml-2" (click)="editBrand()">Submit</button>&nbsp;
                                    <button class="btn btn-large  max-WT-150 btn-secondary"
                                        routerLink="/brand">Back</button>
                                </div> -->







                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</div>