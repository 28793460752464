import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryJsonService } from 'src/app/service/country-json.service';
@Component({
  selector: 'app-edit-order-management',
  templateUrl: './edit-order-management.component.html',
  styleUrls: ['./edit-order-management.component.css']
})
export class EditOrderManagementComponent implements OnInit {
  editProfileForm: FormGroup
  stateArr : any = []
  id : any
  orderId: number;
  orderdata: any;
   imgSrc: any;
  imageUrl: any;
  OrderDataList: any;
  CategoryData: any;
  productId: any;
  shippingAddressId: string;
  ratingId: any;
  totalquantity: any;
  count: any;
  RatingList: any;
  buyOrderId : any
    // pagination
    currentPage = 1;
    total: any;
    itemPerPage = 5;
    // pagination end
  constructor(private router: Router, public mainService: MainService,public active:ActivatedRoute,public country : CountryJsonService) { 
    this.active.queryParams.subscribe((params)=>{
      this.id=params.id
      this.productId=params.productId
      this.shippingAddressId=params.shippingAddressId
      this.ratingId=params.ratingId
      this.totalquantity=params.totalquantity
      this.buyOrderId = params.buyOrderId
      this.reponseParam = params
    })
  }

  ngOnInit() {
    this.editProfileFormValidation();
    this.getOrderList()
    this.getViewProductList()
    this.getCategoryList()
    this.getRatingList()
    this.getViewOrderList()
    this.getProductByOrderID()
    this.getViewOrder()
    this.getAddress()
    this.getBillingAddress()
  }
  buyOrderData : any = []
  getViewOrderList() {
    let url = "product/view-order-By-Id?randomOrderId=" + this.buyOrderId;

    // this.mainService.showSpinner()
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.buyOrderData = res.data;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
     addsum(arr){
    var sum =0;
    for(var z =0;z<arr.length;z++){
        sum+=arr[z].buyOrder.amount;
    }
    return sum;
}
   addShipping(arr){
    var sum =0;
    for(var z =0;z<arr.length;z++){
        sum+=arr[z].product[0].additionalCharge;
    }
    return sum;
}
  addQty(arr){
    var sum =0;
    for(var z =0;z<arr.length;z++){
        sum+=parseInt(arr[z].buyOrder.totalquantity);
    }
    return sum;
}
  orderProduct:any = []
  getProductByOrderID(){
    let url = "product/get-product-by-orderId?randomOrderId=" +this.buyOrderId
      // this.mainService.showSpinner()
      this.mainService.getApi(url, 1).subscribe((res) => {
        if (res.status == 200) {
             if(res.data[0].buyOrder){
             res.data[0].buyOrder['totalAmount']=this.addsum(res.data)
             res.data[0].buyOrder['totalShippingAmount']=this.addShipping(res.data)
             res.data[0].buyOrder['totalQty']=this.addQty(res.data)

             res.data[0].buyOrder['total']=parseInt(res.data[0].buyOrder['totalAmount'])+ parseInt(res.data[0].buyOrder['totalShippingAmount'])

           this.buyOrderData = res.data[0].buyOrder;
           }
          this.orderProduct = res.data;
          this.mainService.hideSpinner();
          // this.mainService.successToast(res.message)
        } else {
          this.mainService.hideSpinner();
          this.mainService.errorToast(res.message);
        }
      });
  }
  editProfileFormValidation() {
    this.editProfileForm = new FormGroup({
    //   'firstName': new FormControl('', [Validators.required]),
    // 'lastName': new FormControl('', [Validators.required]),
    // 'categoryName': new FormControl('', [Validators.required]),
    // 'subcategoryName': new FormControl('', [Validators.required]),
    // 'sellerName': new FormControl('', [Validators.required]),
    // 'totalQuantity': new FormControl(this.totalquantity, [Validators.required]),
    // 'costInDollar': new FormControl('', [Validators.required]),
    'status' : new FormControl(""),
      // 'country' : new FormControl('',Validators.required),
      // 'state' : new FormControl('',Validators.required),
      // 'city' :  new FormControl('',Validators.required),
      // 'totalNumberOfRating' :  new FormControl('',Validators.required),
      // 'comments' :  new FormControl('',Validators.required),
      //  'address': new FormControl('',[Validators.required]),
      //  'image':new FormControl('')
    })
  }
  reponseParam : any
  getViewProductList(){
    let url ="product/view-Product-by-Id?productId="+this.productId
        
    // this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      if (res.status ==200) {
        this.OrderDataList = res.data
        console.log(this.OrderDataList)
        this.editProfileForm.patchValue({
          status:res.data.productStatus,
         
        })
       
    
        this.mainService.hideSpinner()
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }
  getCategoryList(){
    let url ="product/view-category-By-Id?categoryId="+this.id
        
    // this.mainService.showSpinner()
    this.mainService.postApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.CategoryData = res.data
        

        this.mainService.hideSpinner()
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  
  }
  getRatingList(){
    let url ="product/view-Rating-By-Id?ratingId="+this.ratingId
        
    // this.mainService.showSpinner()
    this.mainService.postApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.RatingList=res.data
        
        this.mainService.hideSpinner()
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  
  }
  // get profile
  getOrderList() {
    let url ="product/view-shipping-By-Id?shippingAddressId="+this.shippingAddressId
    // this.mainService.showSpinner()
    this.mainService.postApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.orderdata=res.data
        console.log(this.orderdata)
        
        //this.getState()
        // this.bannerId = this.bannerId 
        this.mainService.hideSpinner()
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  } 
  getState(){
this.stateArr = this.country.countries.filter( (el) => {
  return el.country ===  this.editProfileForm.value.country
});
 
  }

  editOrderList() {
    if(this.imgSrc == "assets/loader/1488 (2).gif"){
      this.mainService.infoToast("Please wait while Order image is uploading.")
      return
    }
    let url = "product/edit-shipping-address?shippingAddressId="+this.shippingAddressId
    const data = {

        "status":this.editProfileForm.value.status,
      

    }

    this.mainService.postApi(url,data,1).subscribe((res)=>{
      if (res.status ==200) {

        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
        this.router.navigate(['/order'])
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }

  ValidateFileUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
     this.imageUrl =  file
    }
    console.log(this.imageUrl);

    this.uploadProfilePic()
  }
  uploadProfilePic(){
    let url = "static/upload-file"
    this.imgSrc = "assets/loader/1488 (2).gif"
    const formData = new FormData();
    formData.append('file',  this.imageUrl);
    this.mainService.uploadFile(url,formData).subscribe((res)=>{
      if(res.status == 200){
        this.imgSrc = res.data
        this.imageUrl = res.data

      }
      else{
        this.mainService.errorToast("Can't Upload This File")
      }
    })

  }
  back() {
    this.router.navigate(['order'])
  }
  OrderData : any = []
  getViewOrder() {
    let url =
      "product/view-shipping-By-Id?shippingAddressId=" + this.shippingAddressId;

    // this.mainService.showSpinner()
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.OrderData = res.data;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  address: any = [];
  getAddress() {
    let url =
      "product/get-ShippingAddress-By-OrderId?randomOrderId=" + this.buyOrderId;

    // this.mainService.showSpinner()
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.address = res.data[0];
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
       // this.mainService.errorToast(res.message);
      }
    });
  }
  billingAddress : any = []
  getBillingAddress() {
    let url =
      "product/view-ShippingAddress-By-Id?shippingAddressId=" + this.shippingAddressId;

    // this.mainService.showSpinner()
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {

        this.billingAddress = res.data;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  getUrl(e) {
    // this.backrouting.previousUrl.next(window.location.href);
    if(e == 0){
      localStorage.setItem('address',JSON.stringify(this.address))
    }else{
      localStorage.setItem('address',JSON.stringify(this.billingAddress))
    }
    localStorage.setItem("backUrl", JSON.stringify(this.reponseParam));
    // localStorage.setItem("backUrl", String(window.location.href).split("/")[3]);
  }
}
