<div>
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">DASHBOARD</h1>
            <hr>
        </div>
        <div class="content-section dashboard-block">
            <div class="row justify-content-end">
                <div class="col-md-4" style="display: contents;"><h6 style="margin-top: 0.5em;">Sort By Category :</h6></div>
                <div class="col-md-3 mr-3 pb-2">
                    
                    <select name="" class="form-control form-select" (change)="getTotalEarningByCategory()" [(ngModel)]="categoryId" id="">
                        <option value="">ALL</option>
                        <option [value]="item.categoryId" *ngFor = "let item of categoryList">{{item.categoryName}}</option>
                    </select>
                </div>
                
            </div>
            <ul class="dash_list1 d-flex w-100 flex-wrap ">
                <li class="dashbord-box" style="background: rgb(44, 154, 218);">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalUser || 0}}</h4>

                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total User</h6>


                    <div>
                        <em class="fa fa-user"
                            style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">

                        <a routerLink="/user-mangement" class="small-box-footer">More info <em
                                class="fa fa-arrow-circle-right"></em></a>
                    </div>


                </li>

                <li class="dashbord-box" style=" background-color: #00a65a !important;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalOrder || 0}}</h4>

                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Order</h6>


                    <div>

                        <em class="fa fa-shopping-cart"
                            style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">

                        <a routerLink="/order" class="small-box-footer">More info <em
                                class="fa fa-arrow-circle-right"></em></a>
                    </div>


                </li>
                <li class="dashbord-box" style="background-color: #f39c12 !important;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalOrderInAday || 0}}</h4>

                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Order In A day</h6>


                    <div>

                        <em class="fa fa-shopping-cart"
                            style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">

                        <a  (click)="navigate(0)" class="small-box-footer">More info <em
                                class="fa fa-arrow-circle-right"></em></a>
                    </div>


                </li>
            </ul>
            <ul class="dash_list1 d-flex w-100 flex-wrap ">
                <li class="dashbord-box" style="background-color: #dd4b39 !important">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalOrderInAWeek || 0}}</h4>

                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Order In A Week</h6>


                    <div>

                        <em class="fa fa-shopping-cart"
                            style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">

                        <a (click)="navigate(1)" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>


                </li>
                <li class="dashbord-box" style="background: rgb(185,182,10);">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalOrderInAMonth || 0}}</h4>

                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Order In A Month</h6>


                    <div>

                        <em class="fa fa-shopping-cart"
                            style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">

                        <a (click)="navigate(2)" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>


                </li>
                <li class="dashbord-box" style="background:rgb(44,208,218);">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">$ {{totalEarning || 0}}</h4>

                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Earning</h6>


                    <div>

                        <em class="fa fa-usd"
                            style="float: right;font-size: 73px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">

                        <a routerLink="/transaction-management" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>


                </li>
            </ul>

           

            <div  class="row dash_list1 d-flex w-100 flex-wrap " style="margin-left: unset;">
                <div class=" col-md-6 dashbord-box">
                    <div class="box box-primary">
                        <div class="box-header with-border">
                            <h6 style="font-size: 20px;">Total Order's</h6>
                        </div>
                        <div class="chart" ></div>
                        <canvas id="myChart" width="600" height="350">
                        <!-- <canvas id="clientChartContainer" width="600" height="350"> -->

                        </canvas>

                    </div>

                </div>
                <div class="col-md-6 dashbord-box">


                    <div class="box box-primary1">
                        <div class="box-header with-border">
                            <h6 style="font-size: 20px;">Total Earning</h6>
                        </div>
                        <div class="chart">
                            <canvas id="myChart2" width="600" height="350">
                                
                            </canvas>
                        </div>
                    </div>


                </div>

            </div>

        </div>
    </main>
</div>
