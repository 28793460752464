<div class="login-wrapper">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="d-flex align-items-center minheight70vh">
                    <form class="login_box_outer w-100"  [formGroup]="loginForm">
                        <div class="login-box max-WT-520">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <img style="font-size: 8px; max-height: 100px;width: 100%;"
                                        src="assets/Product image/MNL Technologies Logo.png" alt="ICON" class="lg-logo">
                                </div>
                                <div class="login-heading" >
                                    <h4 style="color: black;">Admin Login</h4>
                                   
                                </div>
                                <div class="login-box-body">
                                    <div class="form-group" style="margin-bottom: 6%;">

                                        <input type="text" formControlName="email" class="form-control" maxlength="50"
                                            placeholder="Email ID" (keypress)="mainService.preventSpace($event)" />
                                        <div class="text-danger"
                                            *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty  || loginForm.get('email').touched)"
                                            style="color: red;text-align: start">
                                            <span *ngIf="loginForm.get('email').hasError('required')">*Email address is
                                                required.</span>
                                            <span *ngIf="loginForm.get('email').hasError('pattern')">*Please enter
                                                valid email id.</span>
                                        </div>
                                    </div>
                                    <div class="form-group eyeicon">

                                        <input type="password" maxlength="18" formControlName="password"
                                            class="form-control " placeholder="Password"
                                            (keypress)="mainService.preventSpace($event)" />
                                        <div class="text-danger"
                                            *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty  || loginForm.get('password').touched)"
                                            style="color: red;text-align: start">
                                            <span *ngIf="loginForm.get('password').hasError('required')">*Password is
                                                required.</span>
                                        </div>
                                    </div>
                                    <div class="form-group row" style="padding-top: 3%;">
                                        <div class="col-6">
                                            <div class="remember-text ">
                                                <label class="checkbox-design" style="color:purple">
                                                  <input type="checkbox" formControlName='rememberMe'/><span></span>Remember me
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="forgot-links">
                                                <a [routerLink]="['/forgot-password']" routerLinkActive="router-link-active" style="color:purple">Forgot Password?</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt40">
                                    <button class="btn btn-login btn-large width100 font-100" [disabled]="!loginForm.valid" (click)="onLogin()">LOGIN</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


