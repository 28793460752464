import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent implements OnInit {


orderId: number;
  OrderData: any;
  OrderDataList: any;
  productId: any;
  shippingAddressId: any;
  buyOrderId: any;
  buyOrderData: any;
  CategoryData: any;
  ratingId: any;
  totalquantity: any;
  count: any;
  reponseParam : any
  itemPerPage = 3
  total
  currentPage =1
  constructor(private router: Router, public mainService: MainService,private activatedRouting:ActivatedRoute) { 
    this.activatedRouting.queryParams.subscribe((res:any)=>{
      this.orderId=res.id
      this.productId=res.productId
      this.shippingAddressId=res.shippingAddressId
      this.buyOrderId=res.buyOrderId
      this.ratingId=res.ratingId
      this.totalquantity=res.totalquantity
      this.reponseParam = res;
      if (localStorage.getItem("backUrl")) {
        localStorage.removeItem("backUrl");
      }
  
    })
  }

  ngOnInit(): void {
    this.getViewOrder()
    this.getViewOrderList()
    this.getViewProductList()
    this.getCategoryList()
    this.getRatingList()
    this.getAddress()
    this.getProductByOrderID()
  }
  
  getViewOrder() {
    let url ="product/view-shipping-By-Id?shippingAddressId="+this.shippingAddressId
      
    // this.mainService.showSpinner()
    this.mainService.postApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.OrderData = res.data
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  
}
getViewOrderList(){
  let url ="product/view-order-By-Id?orderId="+this.buyOrderId
      
  // this.mainService.showSpinner()
  this.mainService.postApi(url,{},1).subscribe((res)=>{
    if (res.status ==200) {
      this.buyOrderData = res.data
      this.mainService.hideSpinner()
      this.mainService.successToast(res.message)
    } else {
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.message)
    }
  })
}
getViewProductList(){
  let url ="product/view-Product-by-Id?productId="+this.productId
      
  // this.mainService.showSpinner()
  this.mainService.getApi(url,1).subscribe((res)=>{
    if (res.status ==200) {
      this.OrderDataList = res.data
      this.mainService.hideSpinner()
      this.mainService.successToast(res.message)
    } else {
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.message)
    }
  })
}
getCategoryList(){
  let url ="product/view-category-By-Id?categoryId="+this.orderId
      
  // this.mainService.showSpinner()
  this.mainService.postApi(url,{},1).subscribe((res)=>{
    if (res.status ==200) {
      this.CategoryData = res.data
      this.mainService.hideSpinner()
      this.mainService.successToast(res.message)
    } else {
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.message)
    }
  })

}
getRatingList(){
  let url ="product/view-Rating-By-Id?ratingId="+this.ratingId
      
  // this.mainService.showSpinner()
  this.mainService.postApi(url,{},1).subscribe((res)=>{
    if (res.status ==200) {
      this.count = res.data.count
      this.mainService.hideSpinner()
      this.mainService.successToast(res.message)
    } else {
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.message)
    }
  })

}
back(){
  this.router.navigate(['/view-user'])
  localStorage.setItem('viewUserTab','ORDER')
}
pagination(e){
  
}
address: any = [];
getAddress() {
  let url =
    "product/get-ShippingAddress-By-OrderId?orderId=" + this.buyOrderId;

  // this.mainService.showSpinner()
  this.mainService.getApi(url, 1).subscribe((res) => {
    if (res.status == 200) {
      this.address = res.data[0];
      this.mainService.hideSpinner();
      // this.mainService.successToast(res.message)
    } else {
      this.mainService.hideSpinner();
      this.mainService.errorToast(res.message);
    }
  });
}
orderProduct:any = []
getProductByOrderID(){
  let url = "product/get-product-by-orderId?orderId=" +this.buyOrderId
    // this.mainService.showSpinner()
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.orderProduct = res.data;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
}
getUrl(e) {
  // this.backrouting.previousUrl.next(window.location.href);
  if(e == 0){
    localStorage.setItem('address',JSON.stringify(this.address))
  }else{
    localStorage.setItem('address',JSON.stringify(this.OrderData))
  }
  localStorage.setItem("backUrl", JSON.stringify(this.reponseParam));
  // localStorage.setItem("backUrl", String(window.location.href).split("/")[3]);
}
}
