<main class="middle-content">
        <div class="page_title_block1">
            <h1 class="page_title float-left"> Edit Discount</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/discount-Management">Back</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save"  (click)="editDiscount()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Update
                    </button>
               </div>
       </div>
    <div class="content-section">
        <div class="order-view  max-WT-700  setting-page">
            <div class="  mb40">
<form    [formGroup]="editForm">
                    <div class="add-store-block input-style mb20 mt20 ">


                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Discount Name <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Discount Name"
                                         formControlName="name">
             <span *ngIf="editForm.get('name').hasError('required') && isSubmit"  class="text-danger" >Please enter discount name.
             </span>
                                </div>
                            </div>



                            <label class="col-md-4 textalignright ">Discount Type
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <select class="form-control" (change)="getList()" formControlName='discountTypes'>
                                        <option value="ASSIGNED_TO_TOTAL_ORDER">Assigned to order total</option>
                                        <option value="ASSIGNED_TO_PRODUCT">Assigned to products</option>
                                      <!--   <option value="ASSIGNED_TO_CATEGORIES">Assigned to categories</option> -->
                                        <!-- <option value="ASSIGNED_TO_BRAND">Assigned to brand</option> -->
                                        <!-- <option value="ASSIGNED_TO_SHIPPING">Assigned to shipping</option> -->
                                       <!--  <option value="ASSIGNED_TO_SUB_ORDER_TOTAL">Assigned to order sub total</option> -->
                                    </select>
                                    
                                </div>
                            </div>
                              
                            <label class="col-md-4 textalignright" *ngIf="editForm.value.discountTypes=== 'ASSIGNED_TO_CATEGORIES'">Category Name

                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" *ngIf="editForm.value.discountTypes === 'ASSIGNED_TO_CATEGORIES'" >
                                <div class="form-group"  >
                                    <select class="form-control" formControlName='category' >
                                        <option value="">Select Category</option>
                                        <option [value]="item.categoryName" *ngFor="let item of categorydata" selected>{{item.categoryName | titlecase}}</option>
                                    </select>
                                    <div *ngIf="category.errors && (category.dirty || category.touched)" class="text-danger">
                                        <span *ngIf="category.errors.required">
                                            *Please enter  category name
                                        </span>
                                        </div>
                                </div>
                            </div>

   
                            <label class="col-md-4 textalignright" *ngIf="editForm.value.discountTypes=== 'ASSIGNED_TO_PRODUCT'">Product Name

                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" *ngIf="editForm.controls.discountTypes.value === 'ASSIGNED_TO_PRODUCT'" >
                                <div class="form-group"  >
                                    <select class="form-control" formControlName='product'>
                                        <option value="" selected>Select Product</option>
                                        <option value={{product?.productName}} *ngFor="let product of productdata" >{{product?.productName}}</option>
                                    </select>
                                    <span *ngIf="editForm.get('product').hasError('required') && isSubmit"  class="text-danger" >Please select product.
                                    </span>
                                </div>
                            </div>

                            <label class="col-md-4 textalignright" *ngIf="editForm.value.discountTypes=== 'ASSIGNED_TO_BRAND'">Brand Name

                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" *ngIf="editForm.value.discountTypes === 'ASSIGNED_TO_BRAND'" >
                                <div class="form-group"  >
                                    <select class="form-control" formControlName='brand'>
                                        <option value="">Select Brand</option>
                                        <option [value]="item.brandName" *ngFor="let item of branddata" selected>{{item.brandName | titlecase}}</option>
                                    </select>
                                    <div *ngIf="brand.errors && (brand.dirty || brand.touched)" class="text-danger">
                                        <span *ngIf="brand.errors.required">
                                            *Please enter  brand name
                                        </span>
                                        </div>
                                </div>
                            </div>

                            <label class="col-md-4 textalignright" >Use Percentage

                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" >
                                <div class="form-group"  >
                                    <select class="form-control" formControlName='usePercent'>
                                        <option value="USE_PERCENTAGE">Use Percentage</option>
                                        <!-- <option value="USE_AMOUNT" >Use Amount</option> -->
                                    </select>
                                    
                                </div>
                            </div>


                            <label class="col-md-4 textalignright " *ngIf="editForm.value.usePercent === 'USE_PERCENTAGE'">Discount Percentage


                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" *ngIf="editForm.value.usePercent === 'USE_PERCENTAGE'">
                                <div class="form-group">
                                    
                                    <input type="text" class="form-control"  placeholder="Enter percentage"
                                        maxlength="20" formControlName="percentage">
                                        
                                    <span *ngIf="editForm.get('percentage').hasError('required') && isSubmit"  class="text-danger" >Please enter discount percentage .
                                    </span>
         <span *ngIf="editForm.get('percentage').hasError('pattern') && isSubmit"  class="text-danger" >his must be a number with up to 2 decimal places and/or %
                                    </span>
                                   
                                    
                                </div>
                                                                </div>
                            

                            <label class="col-md-4 textalignright " *ngIf="editForm.value.usePercent === 'USE_PERCENTAGE'">Maximum Discount Amount


                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" *ngIf="editForm.value.usePercent === 'USE_PERCENTAGE'">
                                <div class="form-group">
                                    <input type="text" class="form-control" (keypress)="mainService.numberOnly($event)" placeholder="Enter Maximum Discount Amount"
                                        maxlength="20" formControlName="maximumDiscount">
                                        
                                     <span *ngIf="editForm.get('maximumDiscount').hasError('required') && isSubmit"  class="text-danger" >Please enter maximum discount.
                                    </span>
                                    
                                </div>
                            </div>
                        

                            <label class="col-md-4 textalignright " *ngIf="editForm.value.usePercent === 'USE_AMOUNT'">Discount Amount


                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" *ngIf="editForm.value.usePercent === 'USE_AMOUNT'">
                                <div class="form-group">
                                    <input type="text" class="form-control" (keypress)="mainService.numberOnly($event)" placeholder="Enter Discount Amount"
                                        maxlength="20" formControlName="discountAmount">
                                        <!--  <span *ngIf="editForm.get('discountAmount').hasError('required') && isSubmit"  class="text-danger" >Please enter discount Amount.
                                    </span> -->
                                    
                                </div>
                                    
                            </div>


                            <label class="col-md-4 textalignright ">Start Date



                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="datetime-local" class="form-control" placeholder="Enter Category Name"
                                     formControlName="startDate">
                                     
                                    <span *ngIf="editForm.get('startDate').hasError('required') && isSubmit"  class="text-danger" >Please enter start date and time.
                                    </span>
                                </div>
                                   
                            </div>


                            <label class="col-md-4 textalignright ">End Date



                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="datetime-local" class="form-control" placeholder="Enter Category Name"
                                      formControlName="endDate">
                                     
                                     <span *ngIf="editForm.get('endDate').hasError('required') && isSubmit"  class="text-danger" >Please enter end date and time.
                                    </span>
                                </div>
                               
                                   
                            </div>


                            <label class="col-md-4 textalignright ">Discount Limitation



                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Discount Limitation"
                                        maxlength="20" formControlName="discountLimitation">
                                        
                                    <span *ngIf="editForm.get('discountLimitation').hasError('required') && isSubmit"  class="text-danger" >Please enter discount Limitation.
                                    </span>
                                    
                                </div>
                                    
                            </div>
   
                            <label class="col-md-4 textalignright ">Coupon Code
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Coupon Code"
                                        maxlength="20" formControlName="couponCode">
                                       
                                    <span *ngIf="editForm.get('couponCode').hasError('required') && isSubmit"  class="text-danger" >Please enter coupon code.
                                    </span>
                                    
                                </div>
                                    
                            </div>

                            <label class="col-md-4 textalignright ">Discription



                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <textarea class="form-control" cols="30" rows="5" formControlName="discription"
                                    placeholder="Enter discount discription"></textarea>
                                    <span *ngIf="editForm.get('discription').hasError('required') && isSubmit"  class="text-danger" >Please enter discription.
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>



                </form>
            </div>
        </div>
    </div>
</main>