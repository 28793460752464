import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-sub-admin',
  templateUrl: './view-sub-admin.component.html',
  styleUrls: ['./view-sub-admin.component.css']
})
export class ViewSubAdminComponent implements OnInit {

  subId: any;
 
   permissionArr: any = [
  "DASHBOARD_MANAGEMENT",
  "CUSTOMER_MANAGEMENT",
  "SUB_ADMIN_MANAGEMENT",
  "CATEGORY_MANAGEMENT",
  "PRODUCT_MANAGEMENT",
  "ATTRIBUTE_MANAGEMENT",
  "BRAND_MANAGEMENT",
  "DISCOUNT_MANAGEMENT",
  "BANNER_MANAGEMENT",
  "ORDER_MANAGEMENT",
  "TESTIMONIAL_MANAGEMENT",
  "STATIC_CONTENT_MANAGEMENT",
  "FAQ",
  "SUPPORT",
];
  name : any = []
  privilage : any = []
  permArray = []
  subAdmin : any = [
    {
      _id : 14,
      name : "Mohit Kumar",
      email : "mohit@mailinator.com",
      mobileNumber : 9896452310,
      status : 'ACTIVE',
      lastLoggedIn : '14/08/2021. 12:16 AM'
    }
  ]

 
 index = 0


  constructor(private mainservice: MainService, private router: Router, private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.subId = res.id
    })
  }

  ngOnInit() {
    for(let i of this.permissionArr){
 
      this.name[this.index] = i.split('_').join(' ')
      this.index++
    }
    this.viewgetApi()
  }
  viewgetApi() {
  let url = "account/admin/user-management/view-sub-admin?userId="+this.subId
  this.mainservice.showSpinner()
  this.mainservice.postApi(url,{},1).subscribe((res)=>{
    if(res.status == 200){
      this.mainservice.hideSpinner()
      this.subAdmin = res.data
      this.privilage = res.data.previlage
      console.log(this.privilage);
      this.permission()
      console.log(this.permArray);
      
      this.mainservice.successToast(res.message)
    }
    else{
      this.mainservice.hideSpinner()
      this.mainservice.errorToast(res.message)
    }
  },(err)=>{
    this.mainservice.hideSpinner()
    this.mainservice.errorToast(err)
  })
  }
  permission(){
    let index = 0
    for(let item of this.permissionArr){
      console.log(this.privilage.indexOf(item))
      if(this.privilage.indexOf(item) >= 0){
        this.permArray.push(true)
      }
      else{
        this.permArray.push(false)
      }
    }
  }
  edit(){
  this.router.navigate(['edit-sub-admin'], { queryParams: { id: this.subId} })
  }
}
