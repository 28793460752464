import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.css']
})
export class EditBannerComponent implements OnInit {
  bannerId: number;
  addBannerForm: FormGroup;
  imgSrc: any="assets/Product image/chargerImage-2.jpg";
  isActive: any;
  imageUrl: any;
  editValue:any
  bannerList:any =[]
  indexList:any =[]
  constructor(public sanitizer: DomSanitizer,public mainService: MainService, private router: Router,private activatedRoute : ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.bannerId = res.id
    })
   }

  ngOnInit(): void {
   this.bannerList =[{name:'Home'},{name:'Offer'},{name:'Other'}]
   this.indexList =[{id:'1',name:'Top'},{id:'2',name:'Bottom'},{id:'3',name:'Middle-Left'},{id:'4',name:'Middle-Right'}]

    this.addBannerForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      "bannerStatus" : new FormControl(),
      "description": new FormControl(''),
      "image": new FormControl(''),
      "bannerId":new FormControl(''),
      "indexNumber": new FormControl('', Validators.required),
      "pageType": new FormControl('', Validators.required),
      "isActive":new FormControl(''),
    });
    this.getBanner()
  }
  getBanner(){
    let url ="static/view-banner-By-Id?bannerId="+this.bannerId
    // this.mainService.showSpinner()
    this.mainService.postApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.editValue=res.data
     
        console.log(res.data.description);
        this.addBannerForm.patchValue({
          title : res.data.title,
          description : res.data.description,
          bannerStatus:res.data.bannerStatus,
          indexNumber:res.data.indexNumber,
          pageType:res.data.pageType,
          isActive:res.data.bannerStatus,


        })
        this.imgSrc = res.data.image
        // this.bannerId = this.bannerId 
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }

  updateBanner(){
    if(this.imgSrc == "assets/loader/1488 (2).gif"){
      this.mainService.infoToast("Please wait while Banner image is uploading.")
      return
    }
     if (this.addBannerForm.value.isActive) {
      status = "ACTIVE"
    } else {
      status = "DISACTIVE"
    }
    let url = "static/update-banner"
    const data = {
      "image":this.imgSrc,
      "title"  :this.addBannerForm.value.title,
      "bannerId": this.bannerId ,
      "bannerStatus" : status,
      "description": this.addBannerForm.value.description,
      "indexNumber": this.addBannerForm.value.indexNumber,
      "pageType": this.addBannerForm.value.pageType,
      
    }
    // console.log(data.bannerStatus);

    this.mainService.postApi(url,data,1).subscribe((res)=>{
      if (res.status ==200) {

        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
        this.router.navigate(['/banner-management'])
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }

  ValidateFileUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
     this.imageUrl =  file
    }
    console.log(this.imageUrl);

    this.uploadProfilePic()
  }
  uploadProfilePic(){
    let url = "static/upload-file"
    this.imgSrc = "assets/loader/1488 (2).gif"
    const formData = new FormData();
    formData.append('file',  this.imageUrl);
    this.mainService.uploadFile(url,formData).subscribe((res)=>{
      if(res.status == 200){
        this.imgSrc = res.data
        this.imageUrl = res.data

      }
      else{
        this.mainService.errorToast("Can't Upload This File")
      }
    })

  }
  selectBanner(){

  }
  defaultImage:any
    productData:any ={
           "description": '',
      "image": '',
      "title":'',
      "bannerStatus" : '',
      "indexNumber": '',
      "pageType": '',
    }
    productImageView:any=[]
    preview() {
      this.productImageView=[]
       const data = {
      "description": this.addBannerForm.value.description,
      "image": this.imgSrc,
      "title": this.addBannerForm.value.title,
      "bannerStatus" : status,
      "indexNumber": this.addBannerForm.value.indexNumber,
      "pageType": this.addBannerForm.value.pageType,
    }
         this.productData = data
        this.productImageView = data.image
        this.defaultImage =data.image
     }
  toggleVisibility(e) {
  }
}
