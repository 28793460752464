import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  otp : any
  form: FormGroup;
  email: any;
  showModal : boolean = false
  ipAddress : any
  agentName : any
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.agentName = this.getBrowserName()
    this.mainService.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
     
    })
    this.form = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)$/i)]))
    })
  }
  submit() {
    // this.router.navigate(['/reset-password'])
    this.showModal = true
    // $('#exampleModal').modal('show');

  }
  verfyEmail(){
    let mail = String(this.form.value.email).replace('@','%40')
    let url = "account/forget-password/otp?email="+mail
   
    this.mainService.showSpinner()
    this.mainService.getApi(url,0).subscribe((res)=>{
      if(res.status == 200){
        this.mainService.successToast(res.message)
        this.mainService.hideSpinner()
        this.showModal=true
        localStorage.setItem('email',btoa(this.form.value.email))
      }
      else{
        this.mainService.errorToast(res.message)
        this.mainService.hideSpinner()
      }
    },(err)=>{
      this.mainService.errorToast(err)
        this.mainService.hideSpinner()
    })
  
  }
  veryfyOtp(){
    let url = "account/verify-sms-code-otp"
    const data = {
      "code": this.otp,
      "ipAddress": this.ipAddress,
      "source": this.agentName
    }
    this.mainService.showSpinner()
    this.mainService.postApi(url,data,0).subscribe((res)=>{
      if(res.status == 200){
        this.mainService.successToast(res.message)
        this.showModal = false
        this.router.navigate(['/reset-password'])

        this.mainService.hideSpinner()
      }
      else{
        this.mainService.errorToast("Invalid Otp")
        this.mainService.hideSpinner()
      }
    },(err)=>{
      this.mainService.errorToast(err)
        this.mainService.hideSpinner()
    })
  }
  onOtpChange(e) {
    this.otp = e;
    console.log(this.otp)
  }
  
  hideModal(){
   this.showModal = false
  }
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
}
  
}
