import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
declare var $;
@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.css"],
})
export class UserManagementComponent implements OnInit {
  searchForm: FormGroup;

  itemPerPage = 5;
  currentPage = 1;
  limit: any = 5;
  total: any;
  items = [];
  flip : boolean = false
  isSearched : boolean = false
  statusString : any = ""
  
  customerData: any = [
   
  ];
  userId : any
  userStatus : any = ""
  constructor(private router: Router, public mainService: MainService,private datepipe : DatePipe) {}
  ngOnInit() {
    this.searchFormValidation();
    this.getAllUserList();

    
  }
  getAllUserList() {
    let url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage;
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.customerData = res.data.list
          this.total = res.data.totalCount
          this.mainService.hideSpinner();
          // this.mainService.successToast(res.message);
        } else {
          this.mainService.hideSpinner();
          // this.mainService.errorToast(res.message);
        }
      },
      (err) => {
        this.mainService.hideSpinner();
        // this.mainService.errorToast(err);
      }
    );
  }

 
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }
  pagination(event) {
    this.currentPage = event;
    if(this.flip){
      this.search()
    }
    else{
      this.getAllUserList()
    }
  }
  search(){
    
    
    if(!this.searchForm.value.search && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
     
      return
    }
    this.isSearched = true
    this.flip = true
    let url =""
    const fromDate = Date.parse(this.searchForm.value.fromDate)
    const toDate = Date.parse(this.searchForm.value.toDate)
    if(this.searchForm.value.search && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&search="+this.searchForm.value.search
     
    }
    else if(!this.searchForm.value.search && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate
     
    }
    else if(!this.searchForm.value.search && this.searchForm.value.fromDate && this.searchForm.value.toDate){
      url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&toDate="+toDate
     
    }
    else if(this.searchForm.value.search && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&search="+this.searchForm.value.search
     
    }
    else{
      url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&toDate="+toDate+"&search="+this.searchForm.value.search
    
    }
   
    
    
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.customerData = res.data.list
          this.total = res.data.totalCount
          this.mainService.hideSpinner();
          this.mainService.successToast(res.message);
        } else {
          this.mainService.hideSpinner();
          this.mainService.errorToast(res.message);
        }
      },
      (err) => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(err);
      }
    );
  }
  reset(){
    if(!this.isSearched){
      return
    }
    this.flip = false
    this.isSearched = false
    this.searchForm.reset()
    this.getAllUserList()
  }

  viewUser(id) {
    this.router.navigate(['/view-user'],
    {queryParams : {
      id : id,
    }})
   
  }
  
  getUserId(id,status){
    this.userId = id
    this.userStatus = status
    
    if(this.userStatus === 'BLOCK' || this.userStatus === 'UNVERIFIED'){
      this.statusString = "Active"
     
    }
    else{
      this.statusString = "Block"
     
    }
    
  }
  blockUnblockUser(){
    
    var url =""
    if(this.userStatus === 'BLOCK' || this.userStatus === 'UNVERIFIED'){
      this.statusString = "ACTIVE"
      url = "account/admin/user-management/unblock-user"
    }
    else{
      this.statusString = "BLOCK"
      url = "account/admin/user-management/block-user"
    }
    const body = {
      "blockedUserId": this.userId
    }
    this.mainService.hideSpinner()
    this.mainService.postApi(url,body,1).subscribe((res)=>{
      if (res.status == 200) {
     
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
        this.getAllUserList()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    },(err)=>{
      this.mainService.hideSpinner();
        this.mainService.errorToast(err);
    })


  }

  deleteUser(){
    let url ="account/admin/user-management/delete-User-By-id?userid="+this.userId
    
    const body = {
      "blockedUserId": this.userId
    }
    this.mainService.hideSpinner()
    this.mainService.deleteApi(url,body,1).subscribe((res)=>{
      if (res.status == 200) {
     
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
        this.getAllUserList()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    },(err)=>{
      this.mainService.hideSpinner();
        this.mainService.errorToast(err);
    })

  }
  exportAsXLSX() {
  
    let dataArr = [];
    this.customerData.forEach((element, ind) => {
      let obj = {}
      obj = {
        "S no": ind + 1,
        "Name" : element.firstName ? element.firstName +" "+ element?.lastName: 'N/A',
        "Email": element.email ? element.email : 'N/A',

        "Phone No": element.phoneNo ? element.phoneNo : 'N/A',
        "Created At": element.createTime ?this.datepipe.transform(element.createTime ,'dd/MM/yyyy, hh:mm a')  : 'N/A',
        "Status": element.userStatus ? element.userStatus  : 'N/A',

      }
      dataArr.push(obj)
    })

    this.mainService.exportAsExcelFile(dataArr, 'User Management');
  }
}
