import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-statics-content',
  templateUrl: './statics-content.component.html',
  styleUrls: ['./statics-content.component.css']
})
export class StaticsContentComponent implements OnInit {
  
  staticList:any;
  constructor(private mainService: MainService,private router: Router) { 
   
  }

  ngOnInit(): void {
    this.getContact()
  }
  listContact:any =[] 
  getContact(){
    let url = "static/get-static-data-list-admin"
    console.log(url)
    this.mainService.getApi(url , 0).subscribe((res)=>{
      if(res.status == 200 && res.data.length>0){
        this.listContact = res.data;
        this.mainService.hideSpinner()
        this.onload()
      }
       else{
         this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
        this.onload()
      }
    })
 
  }

onload() {
         this.staticList=[
      {staticContentId : 1,
        pageKey:  "About Us"
      },
      {staticContentId : this.listContact.length>0 ? this.listContact[0].staticId : 0,
        pageKey:  "Contact Us"
      },
      {staticContentId : 3,
        pageKey:  "Privacy Policy"
      },
      {staticContentId : 4,
        pageKey:  "Terms And Conditions"
      }
    ];
}

// edit static content data
editStatic(id, type) {
  if(type==='Contact Us'){
  this.router.navigate(['contact-us'], { queryParams: { id: id} })
    
  }else{
  this.router.navigate(['edit-static-content'], { queryParams: { id: id, type: type } })

  }
}

}
