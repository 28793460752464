import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Chart, CanvasJS } from "node_modules/chart.js";
import { MainService } from "src/app/provider/main.service";
declare var $;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  totalEarningData: any;

  constructor(private mainService: MainService, private date: DatePipe,private router : Router) {}
  totalUser: any;
  totalOrder: any = 0;
  totalOrderInAday: any = 0;
  totalOrderInAWeek: any = 0;
  totalOrderInAMonth: any = 0;
  totalEarning: any = 0;
  categoryList: any = [];
  categoryId: any = "";
  currentYear: any = new Date().getFullYear();

  // orderDataYear: any = [];
  // earningDataYear: any=[];

  showGraph: boolean = false;

  orderDataYear: any = [
    {
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
    },
  ];
  earningDataYear: any = [
    {
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
    },
  ];

  orderData: any = {};

  ngOnInit(): void {
    // this.mychart1()
    // this.mychart2()
    this.chart1();
    this.chart2();
    this.dashboard();

    this.getTotalEarningGraph();
    this.getTotalOrderGraph();
    // this.orderDataYear[0]['Jan'] = 5;
  }
  navigate(e){
    var today = new Date().toISOString()
    var weekDay = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()
    var lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString()
    if(e == 0){
      this.router.navigate(['/order'],{queryParams : {fromDate : today}})
    }
    else if (e ==1){
      this.router.navigate(['/order'],{queryParams : {fromDate : weekDay , toDate : today}})
    }
    else{
      this.router.navigate(['/order'],{queryParams : {fromDate : lastMonth, toDate : today}})
    }
  }
  dashboard() {
    this.mainService.showSpinner();
    this.gettotalUser();
    this.getTotalOrder();
    this.getTotalOrderInAday();
    this.getTotalOrderInAWeek();
    this.getTotalOrderInAMonth();
    this.getTotalEarning();
    this.getCategory();
    this.mainService.hideSpinner();
  }
  gettotalUser() {
    let url = "account/admin/dashboard/totalUserCount";
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.totalUser = res.data;
      } else {
        this.mainService.errorToast(res.message);
      }
    });
  }
  getTotalOrder() {
    let url = "product/total-order";
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.totalOrder = res.data.totalOrderCount;
      } else {
        this.mainService.errorToast(res.message);
      }
    });
  }
  getTotalOrderInAday() {
    let url = "product/total-order-in-day";
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.totalOrderInAday = res.data;
      } else {
        this.mainService.errorToast(res.message);
      }
    });
  }
  getTotalOrderInAWeek() {
    let url = "product/total-order-week-by-Category";
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.totalOrderInAWeek = res.data;
      } else {
        this.mainService.errorToast(res.message);
      }
    });
  }
  getTotalOrderInAMonth() {
    let url = "product/total-order-in-month";
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.totalOrderInAMonth = res.data;
        
      } else {
        this.mainService.errorToast(res.message);
      }
    });
  }
  getTotalEarning() {
    let url = "product/total-earning-by-category";
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.totalEarning = res.data;
      } else {
        this.mainService.errorToast(res.message);
      }
    });
  }
  getTotalEarningByCategory() {
    if (!this.categoryId) {
      this.dashboard();
      return;
    }
    let today = new Date().toISOString().split("T");
    let weak = new Date().setDate(new Date().getDate() - 8);
    let weaks = new Date(weak).toISOString().split("T");
    let month = new Date().setDate(new Date().getMonth() - 1)
    let months =  new Date(month).toISOString().split("T")

    this.totalEarning = 0;
    this.totalOrderInAday = 0;
    this.totalOrderInAWeek = 0
    this.totalOrderInAMonth = 0

    let url =
      "product/get-total-Order-By-categoryId?categoryId=" + this.categoryId;
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.totalOrder = res.data.length;
        console.log(weaks[0]);

        for (let item of res.data) {
          this.totalEarning += item.amount;
          if (today[0] == this.date.transform(item.createTime, "yyyy-MM-dd")) {
            this.totalOrderInAday++;
          }
          if(today[0] >= this.date.transform(item.createTime, "yyyy-MM-dd") && weaks[0] <= this.date.transform(item.createTime, "yyyy-MM-dd")){
              this.totalOrderInAWeek++
          }
          if(today[0] >= this.date.transform(item.createTime, "yyyy-MM-dd") && months[0] <= this.date.transform(item.createTime, "yyyy-MM-dd")){
            this.totalOrderInAMonth++
        }
        }
        // this.totalEarning = res.data;
      } else {
        this.mainService.errorToast(res.message);
      }
    });
  }

  getCategory() {
    let url = "product/getAllCategoryList";

    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.categoryList = res.data[0].categoryDetails;
      }
    });
  }
  getTotalEarningGraph() {
    let url = "product/total-Graph-earning";
    var key = [];
    var value = [];
    this.mainService.showSpinner();
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.showGraph = true;
        key = Object.keys(res.data[0]);
        value = Object.values(res.data[0]);
        for (let i = 0; i < key.length; i++) {
          console.log(key[i], value[i]);
          this.earningDataYear[0][key[i]] = value[i];
        }
        this.chart2();

        console.log(Object.values(this.earningDataYear[0]));
      } else {
        this.mainService.errorToast(res.message);
      }
    });
  }

  
  getTotalOrderGraph() {
    let url = "product/total-Graph-order";
    var key = [];
    var value = [];
    this.mainService.showSpinner();
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.showGraph = true;
        key = Object.keys(res.data[0]);
        value = Object.values(res.data[0]);
        for (let i = 0; i < key.length; i++) {
          console.log("order", key[i], value[i]);
          this.orderDataYear[0][key[i]] = value[i];
        }
        this.chart1();
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }

  /* -----------------------Chart UI --------------------------- */
  chart1() {
    var myChart = new Chart("myChart", {
      type: "bar",
      showInLegend: false,
      toolTipContent: "{legendText}",
      data: {
        labels: [
          "Jan-" + this.currentYear,
          "Feb-" + this.currentYear,
          "Mar-" + this.currentYear,
          "Apr-" + this.currentYear,
          "May-" + this.currentYear,
          "Jun-" + this.currentYear,
          "Jul-" + this.currentYear,
          "Aug-" + this.currentYear,
          "Sep-" + this.currentYear,
          "Oct-" + this.currentYear,
          "Nov-" + this.currentYear,
          "Dec-" + this.currentYear,
        ],
        datasets: [
          {
            label: "Order's",
            data: Object.values(this.orderDataYear[0]),
            backgroundColor: [
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
            ],
            borderColor: [
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },

        scales: {
          yAxes: [
            {
              scaleLabel: {
                labelString: "Unit",
                display: true,
              },
              valueFormatString: " ",
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                labelString: "Month",
                display: true,
              },
              valueFormatString: " ",
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
  chart2() {
    var myChart1 = new Chart("myChart2", {
      type: "line",
      showInLegend: false,
      toolTipContent: "{legendText}",
      data: {
        labels: [
          "Jan-" + this.currentYear,
          "Feb-" + this.currentYear,
          "Mar-" + this.currentYear,
          "Apr-" + this.currentYear,
          "May-" + this.currentYear,
          "June-" + this.currentYear,
          "July-" + this.currentYear,
          "Aug-" + this.currentYear,
          "Sep-" + this.currentYear,
          "Oct-" + this.currentYear,
          "Nov-" + this.currentYear,
          "Dec-" + this.currentYear,
        ],
        datasets: [
          {
            label: "Earning",

            data: Object.values(this.earningDataYear[0]),
            backgroundColor: ["transparent"],
            borderColor: ["#3c8dbc"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },

        scales: {
          yAxes: [
            {
              scaleLabel: {
                labelString: "Earning in dollar",
                display: true,
              },
              valueFormatString: " ",
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                labelString: "Month",
                display: true,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
}
