import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { ngxCsv } from 'ngx-csv/ngx-csv';
declare var $;

@Component({
  selector: "app-product-management",
  templateUrl: "./product-management.component.html",
  styleUrls: ["./product-management.component.css"],
})
export class ProductManagementComponent implements OnInit {
  itemPerPage: any = 5;
  currentPage: any = 1;


  productList: any;
  totalItems: any;

  proId : any
  productStatus: any;
  searchText: any;
  isSearched: boolean = false;

  searchForm : FormGroup
 
  constructor(private mainService: MainService, private router: Router) {}

  ngOnInit() {
    this.searchFormValidation()
    this.getBanner();
  }
  searchFormValidation(){
    this.searchForm = new FormGroup({
      productName: new FormControl(''),
      serialNumber: new FormControl(''),
      productId: new FormControl(''),
      status: new FormControl(''),
    })
  }
  get productName(){
    return this.searchForm.get('productName') as FormControl
  }
  get serialNumber(){
    return this.searchForm.get('serialNumber') as FormControl
  }
  get productId(){
    return this.searchForm.get('productId') as FormControl
  }
  get status(){
    return this.searchForm.get('status') as FormControl
  }

  getBanner() {
    let url =
      "product/getAllProductList"
      
      
    this.mainService.showSpinner();
    this.mainService.getApi(url,  1).subscribe((res) => {
      if (res.status == 200) {
        this.productList = res.data;

        this.totalItems = this.productList.length;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        // this.mainService.errorToast(res.message);
      }
    });
  }

  pagination(event) {
    this.currentPage = event;
    // this.getBanner();
  }
  search() {
    
    this.isSearched = true;
    this.currentPage = 1;
    
    if(this.searchForm.value.productName){
      this.productList =  this.productList.filter((res)=>{
        return String(res.product.productName).toLowerCase() === String(this.searchForm.value.productName).toLowerCase()
      })

    }
    if(this.searchForm.value.serialNumber){
      this.productList =  this.productList.filter((res)=>{
        return String(res.product.serialNumber).toLowerCase() === String(this.searchForm.value.serialNumber).toLowerCase()
      })
    }
    if(this.searchForm.value.productId){
      this.productList =  this.productList.filter((res)=>{
        return String(res.product.productId).toLowerCase() === String(this.searchForm.value.productId).toLowerCase()
      })
    }
    if(this.searchForm.value.status){
      
      this.productList =  this.productList.filter((res)=>{
        // alert(res.product.productStatus)
        // console.log(String(res?.product?.productStatus).toLowerCase());
        
        return String(res?.product?.productStatus).toLowerCase() === String(this.searchForm.value.status).toLowerCase()
      })
      
    }
  
   
    
    this.totalItems = this.productList.length;
    
  }
  reset() {
    if (!this.isSearched) {
      return;
    }
    this.isSearched = false;
    this.searchText = "";
    this.searchForm.reset()
    this.getBanner();
  }

  getProductId(id, status) {
    this.proId = id;
    this.productStatus = status;
  }
  blockUnblockProduct() {
    let url = ""
    if (this.productStatus == "BLOCK") {
      url = "product/block-Product?productId="+this.proId
    }else{
      url = "product/unblock-Product?productId="+this.proId
    }
    this.mainService.showSpinner()
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.getBanner()
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }

  deleteProduct(){
    let url = "product/delete-Product?productId="+this.proId
    this.mainService.showSpinner()
    this.mainService.deleteApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.getBanner()
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  viewcategory(id) {
    this.router.navigate(["/view-product"], { queryParams: { id: id } });
  }
  editcategory(id) {
    this.router.navigate(["/edit-product"], { queryParams: { id: id } });
  }
  exportAsCsv(){
    let data = []
    this.productList.forEach((element, ind) => {
      data.push({
        'S.No' : ind+1,
        'Product Name': element?.product?.productName ?  element?.product?.productName : 'N/A',
        'Product Id': element?.product?.productId ?  element?.product?.productId : 'N/A',
        'Serial Number': element?.product?.serialNumber ?  element?.product?.serialNumber : 'N/A',
        'Product Price': element?.product?.price ?  element?.product?.price : 'N/A',
        'Status': element?.product?.productStatus ?  element?.product?.productStatus : 'N/A',
       
    })
    })
    var options = { 
      fieldSeparator: ',    ',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Product List',
      useBom: true,
      
      headers: ["S.No", "Product Name", "Product Id","Serial Number","Product Price","Status"]
    };
    new ngxCsv(data, 'Product List',options);
    
  }
   
   
    
    
    
    


}