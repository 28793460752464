import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.css']
})
export class UserAddressComponent implements OnInit {

  editForm : FormGroup
  addressType : any
  formValidation(){
    this.editForm = new FormGroup({
      fullName : new FormControl('',[Validators.required]),
      email : new FormControl('',[Validators.required]),
      phone : new FormControl('',[Validators.required]),
      address : new FormControl('',[Validators.required]),
      area : new FormControl('',[Validators.required]),
      landMark : new FormControl('',[Validators.required]),
      city : new FormControl('',[Validators.required]),
      state : new FormControl('',[Validators.required]),
      zipcode : new FormControl('',[Validators.required]),
      country : new FormControl('',[Validators.required]),
     

    })
  }
  constructor(private activatedRoute : ActivatedRoute , public service : MainService , private router : Router) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.addressType = res.address_type
    })
    
   }
   address : any
  ngOnInit(): void {
    this.formValidation()
   this.address= JSON.parse(localStorage.getItem('address'))
   this.getAddress()
  }
  getAddress(){
    
 
        this.editForm.patchValue({
          fullName : this.address.name,
         
          phone : this.address.phoneNumber,
          address : this.address.address,
          area : this.address.area,
          landMark : this.address.landmark,
          city : this.address.city,
          state : this.address.state,
          zipcode : this.address.zipCode,
          country : this.address.country,

        })
  }

  updateAddress(url){
   
    const data = {}
    this.service.showSpinner()
    this.service.postApi(url,data, 1).subscribe((res)=>{
      if (res.status == 200) {
        this.service.hideSpinner()
        this.service.successToast(res.message)
      } else {
        this.service.hideSpinner()
        this.service.errorToast(res.message)
      }
    })
  }
  back(){
    // alert("yghjghj")
//  const url = this.backrouting.routinEvent()
let parms = JSON.parse(localStorage.getItem("backUrl"))
console.log(parms);

this.router.navigate(["/view-order-list"],{queryParams : { id : parms.id , productId : parms.productId , shippingAddressId : parms.shippingAddressId , buyOrderId : parms.buyOrderId , ratingId : parms.ratingId,totalquantity :parms.totalquantity} })
 
 
  
  }

}
