<main class="middle-content">
  <div class="page_title_block1">
    <h1 class="page_title float-left">View Order</h1>
    <small class="pad">
      <i class="fa fa-arrow-circle-left colr"></i>
      <a class="po" (click)="back()">Back to Order list</a>
    </small>
  </div>
  <div class="pading20" id="accordion">

    <!-- Info -->
    <div class="card mb7">
      <div class="card-header" id="heading8">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse8" aria-expanded="true"
            aria-controls="collapse8">
            <i class="fa fa-info iconP"></i>Info
          </button>
        </h5>
      </div>
      <div id="collapse8" class="collapse show" aria-labelledby="heading8" data-parent="#accordion">
        <div class="card-body">
          <div class="card p-2">
            <div class="row">
              <div class="col-md-3 text-left">
                <b>Order</b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                {{buyOrderData?.orderId}}
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 text-left">
                <b>Created on
                </b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                {{buyOrderData?.createTime | date : 'medium'}}
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 text-left">
                <b>Customer</b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
               {{buyOrderData?.userName}}
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 text-left">
                <b>Order status
                </b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                {{buyOrderData?.orderStatus}}
              </div>
            </div>
          </div>


          <!-- second card -->
          <div class="card p-2 mt-3">
            <div class="row">
              <div class="col-md-3 text-left">
                <b>Order subtotal
                </b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                {{buyOrderData?.amount}}
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 text-left">
                <b>Order shipping </b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                $0.00 excl tax
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 text-left">
                <b>Order total
                </b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                {{buyOrderData?.amount}}
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 text-left">
                <b>Profit
                </b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                {{buyOrderData?.amount}}
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 text-left">
                <b>Payment status
                </b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                {{buyOrderData?.paymentStatus}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb7">
      <div class="card-header" id="heading7">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse7" aria-expanded="false"
            aria-controls="collapse7">
            <i class="fa fa-info iconP"></i>Product Info
          </button>
        </h5>
      </div>
      <div id="collapse7" class="collapse " aria-labelledby="heading7" data-parent="#accordion">
        <div class="card-body">

          <div class="table-responsive" style="margin-bottom: 30px;">
            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
              aria-describedby="Brand management list">
              <thead>
                <tr class="no_wrap_th text-center">
                  <th scope="col" class="Sorting-img">Picture</th>
                  <th scope="col" class="Sorting-img">Product name</th>
                  <th scope="col" class="Sorting-img">Price</th>
                  <th scope="col" class="Sorting-img">Quantity</th>
                  <th scope="col" class="Sorting-img">Discount</th>
                  <th scope="col" class="Sorting-img">Total</th>


                  <th scope="col" class="action_td_btn3 Sorting-img" style="width: 1%;">
                    Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" *ngFor="let item of orderProduct | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total};let i = index">
                  <td>
                    <img [src]="item?.productImage[0]?.productImage" style="width: 20%;" alt="" srcset="">
                  </td>
                  <td>
                    <a style="color: blue;cursor: pointer;" routerLink="/view-product" [queryParams]="{id : item.buyOrder.productId}">{{item.buyOrder.productName}}</a>
                  </td>
                  <td>
                    {{item?.product[0]?.price}}
                  </td>
                  <td>
                    {{item.buyOrder?.quantity}}
                  </td>
                  <td>
                    {{item?.discount[0]?.discountPercentage}}%
                  </td>
                  <td>
                    {{item?.buyOrder?.amount}}
                  </td>
                  <td>
                    <button class="btn btn-danger ml-2 bth" title="Delete"><em class="fa fa-trash"
                      aria-hidden="true"></em></button>
                  </td>
                 
                </tr>

              </tbody>
            </table>
            <!-- <div class="custom-pagination mt-2 text-align-end">
              <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
            </div> -->
          </div>
        </div>
      </div>
    </div>


    <div class="card mb7">
      <div class="card-header" id="heading6">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse6" aria-expanded="false"
            aria-controls="collapse6">
            <i class="fa fa-truck iconP"></i>Delivery Date & Status
          </button>
        </h5>
      </div>
      <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
        <div class="card-body">
          <div class="sheet">
            <label class="textaligncenter labelone  ">Order Date
            </label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{buyOrderData?.createTime| date : 'dd/MM/yyyy, h:mm a'|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Delivery Date</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{buyOrderData?.updateTime| date : 'dd/MM/yyyy, h:mm a'|| '--'}}</p>


            </div>
          </div>



          <div class="sheet">
            <label class="textaligncenter labelone ">Status</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderDataList?.productStatus|| '--'}}</p>


            </div>
          </div>
        </div>
      </div>
    </div>

     <!-- Billing and shipping -->
     <div class="card mb7">
      <div class="card-header" id="heading9">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse9" aria-expanded="false"
            aria-controls="collapse6">
            <i class="fa fa-truck iconP"></i>Billing & shipping
          </button>
        </h5>
      </div>
      <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordion">
        <div class="card-body">

          <div class="row justify-content-around">
            <div class="col-md-5">

              <div class="table-responsive" style="margin-bottom: 10px;">
                <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                  aria-describedby="Brand management list">
                  <thead>
                    <tr class="no_wrap_th text-left">
                      <th scope="col" colspan="2" class="Sorting-img">Shipping address</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr class="text-center">
                      <td>
                        Full name
                      </td>
                      <td>
                        {{address.name}}
                      </td>
                    </tr>
                    <!-- <tr class="text-center">
                      <td>
                        Email
                      </td>
                      <td>
                        {{address.email || '--'}}
                      </td>
                    </tr> -->
                    <tr class="text-center">
                      <td>
                        Phone
                      </td>
                      <td>
                        {{address.phoneNumber || '--'}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        Address
                      </td>
                      <td>
                        {{address?.address || '--'}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        Area
                      </td>
                      <td>
                        {{address.area || '--'}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        Land Mark
                      </td>
                      <td>
                        {{address.landmark || '--'}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        City
                      </td>
                      <td>
                        {{address.city}}
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        State / province
                      </td>
                      <td>
                        {{address.state}}
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        Zip / postal code
                      </td>
                      <td>
                        {{address.zipCode}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        Country
                      </td>
                      <td>
                        {{address.country}}
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>

            <div class="col-md-5">

              <div class="table-responsive" style="margin-bottom: 10px;">
                <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                  aria-describedby="Brand management list">
                  <thead>
                    <tr class="no_wrap_th text-left">
                      <th scope="col" colspan="2" class="Sorting-img">Billing address</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr class="text-center">
                      <td>
                        Full name
                      </td>
                      <td>
                       {{OrderData.name}}
                      </td>
                    </tr>
                    <!-- <tr class="text-center">
                      <td>
                        Email
                      </td>
                      <td>
                        {{OrderData?.email || '--'}}
                      </td>
                    </tr> -->
                    <tr class="text-center">
                      <td>
                        Phone
                      </td>
                      <td>
                        {{OrderData?.phoneNumber}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        Address
                      </td>
                      <td>
                        {{OrderData?.address || '--'}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        Area
                      </td>
                      <td>
                        {{OrderData.area}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        Land Mark
                      </td>
                      <td>
                        {{OrderData.landmark}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        City
                      </td>
                      <td>
                        {{OrderData.city}}
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        State / province
                      </td>
                      <td>
                        {{OrderData.state}}
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        Zip / postal code
                      </td>
                      <td>
                       {{OrderData.zipCode}}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td>
                        Country
                      </td>
                      <td>
                        {{OrderData.country}}
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div class="row justify-content-around">
            <div class="col-md-5">
              <button class="btn btn-theme" (click)="getUrl(0)" routerLink="/edit-coutomer-Address" [queryParams]="{ address_type: 'billing'}">Edit</button>
            </div>
            <div class="col-md-5">
              <button class="btn btn-theme" (click)="getUrl(1)" routerLink="/edit-coutomer-Address" [queryParams]="{ address_type: 'shipping'}">Edit</button>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="card mb7">
      <div class="card-header" id="heading5">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse5" aria-expanded="false"
            aria-controls="collapse5">
            <i class="fa fa-usd iconP"></i>Prices
          </button>
        </h5>
      </div>
      <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
        <div class="card-body">
          <div class="sheet">
            <label class="textaligncenter labelone  ">Total quantity
            </label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p>{{totalquantity|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Product Price</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderDataList?.price|| '--'}}</p>


            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb7">
      <div class="card-header" id="heading4">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse4" aria-expanded="false"
            aria-controls="collapse4">
            <i class="fa fa-map-marker iconP"></i>Address
          </button>
        </h5>
      </div>
      <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
        <div class="card-body">
          <div class="sheet">
            <label class="textaligncenter labelone ">Country</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.country|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">State</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.state|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">City</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.city|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Area</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.area|| '--'}}</p>


            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="card mb7">
      <div class="card-header" id="heading2">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse2" aria-expanded="false"
            aria-controls="collapse2">
            <i class="fa fa-user iconP"></i>Buyer details information
          </button>
        </h5>
      </div>
      <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordion">
        <div class="card-body">
          <div class="sheet">
            <label class="textaligncenter labelone ">Name</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.name|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Contact Number</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.contactNumber|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Country</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.country|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">State</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.state|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">City</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.city|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Zip code</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.zipCode|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Area</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.area|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Alternate Phone number(Optional)</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.phoneNumber|| '--'}}</p>


            </div>
          </div>

          <div class="sheet">
            <label class="textaligncenter labelone ">Landmark
              (Optional)</label>
            <label class="">:</label>
            <div class="textaligncenter">

              <p style="word-break: break-all;">{{OrderData?.landmark|| '--'}}</p>


            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb7">
      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
            aria-controls="collapseOne">
            <i class="fa fa-commenting-o iconP"></i>Feedback
          </button>
        </h5>
      </div>
      <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body">
          <div class="sheet">
            <label class="textaligncenter labelone ">Total number of rating</label>
            <label class="">:</label>
            <div class="textaligncenter">
              <p style="word-break: break-all;">{{count|| '--'}}</p>
            </div>
          </div>
          <div class="sheet">
            <label class="textaligncenter labelone ">Comments</label>
            <label class="">:</label>
            <div class="textaligncenter">
              <p style="word-break: break-all;">{{OrderData?.comments|| '--'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Order Note -->
    <!-- <div class="card mb7">
      <div class="card-header" id="headingOneship">
        <h5 class="mb-0">
          <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapseOneshipping1"
            aria-expanded="false" aria-controls="collapseOneshipping">
            <i class="fa fa-truck iconP"></i>Order Notes
          </button>
        </h5>
      </div>
      <div id="collapseOneshipping1" class="collapse" aria-labelledby="headingOneship" data-parent="#accordion">
        <div class="card-body">
          <div class="table-responsive" style="margin-bottom: 10px;">
            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
              aria-describedby="Brand management list">
              <thead>
                <tr class="no_wrap_th text-center">
                  <th scope="col" class="Sorting-img">Created on</th>


                  <th scope="col" class="Sorting-img">Note</th>
                  <th scope="col" class="Sorting-img">Display to customer</th>
                  <th scope="col" class="Sorting-img">Delete</th>

                </tr>
              </thead>
              <tbody>

                <tr class="text-center">
                  <td>
                    10/14/2021 5:46:26 AM
                  </td>
                  <td>
                    Order status has been edited. New status: Complete
                  </td>
                  <td>
                    <i class="fa fa-check"></i>
                  </td>
                  <td>
                    <button class="btn btn-info ml-2 bth" title="Edit"><em class="fa fa-trash"
                        aria-hidden="true"></em></button>
                  </td>

                </tr>
              </tbody>
            </table>
            <div class="custom-pagination mt-2 text-align-end">
              <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
            </div>
          </div>
          <div class="card">
            <h5 class="card-title">Add order note</h5>
            <div class="row mb-3">
              <div class="col-md-3 text-right">
                <b>Note</b>
              </div>
              <div class="col-md-1">
                <label for="">:</label>
              </div>
              <div class="col-md-7">
                <textarea class="form-control" cols="50" rows="5"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 text-right">
                <b>Display to customer
                </b>
              </div>
              <div class="col-md-1">
                <label for="">:</label>
              </div>
              <div class="col-md-1">
                <input type="checkbox" class="form-control" style="margin-left: -33px;">
              </div>
            </div>
            <div class="row mb-5">
             <div class="col-md-12 text-center">
               <button class="btn btn-primary">Add order note</button>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</main>