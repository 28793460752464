<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">VIEW PROFILE </h1>
        <hr>
    </div>
    <div class="content-section">
        <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
            <div class="mb40">
                <div class="admin-profile">
                    <h4 class="mb20 text-center">View Profile</h4>
                </div>
                <div class="prfile-img" style="margin: auto;">
                    <div class="user-profile">
                        <div class="image-box">
                            <img [src]="myProfileData?.imageUrl|| 'assets/img/profile-img.jpg' "
                            alt=""    style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;" >


                        </div>
                    </div>
                </div>
                <div class="add-store-block input-style mb20 mt20 ">
                    <div class="sheet">
                        <label class="textaligncenter labelone ">First Name
                        </label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p>{{myProfileData?.firstName}}</p>
                        </div>

                    </div>
                    <div class="sheet">
                        <label class="textaligncenter labelone  ">Last Name</label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p>{{myProfileData?.lastName}}</p>
                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter labelone  ">Email</label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p>{{myProfileData?.email}}</p>
                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter  labelone ">Mobile Number</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p>{{myProfileData?.phoneNo}}</p>


                        </div>
                    </div>
                    <div class="sheet">
                        <label class="textaligncenter labelone  ">City</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p>{{myProfileData?.city}}</p>


                        </div>
                    </div>
                    <div class="sheet">
                        <label class="textaligncenter labelone  ">State
                        </label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p style="word-break: break-all;">{{myProfileData?.state}}</p>


                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter labelone ">Address</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p style="word-break: break-all;">{{myProfileData?.address}}</p>


                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter labelone  ">Country
                        </label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p>{{myProfileData?.country}}</p>


                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="text-center mt40 mb40">
                <button class="btn btn-large  max-WT-150 btn-theme" (click)="changePassword()"
                    style="padding: 5px;">Change Password</button>
                <button class="btn btn-large  max-WT-150 btn-theme" (click)="editProfile()">Edit</button>
            </div> -->
            <div class="mt40 mb40 text-center">
                <button class="btn btn-large  max-WT-170 btn-secondary" (click)="changePassword()">Change Password</button>
                <button class="btn btn-large  max-WT-150 btn-primary ml-2"  (click)="editProfile()">Edit</button>
            </div>
        </div>
    </div>
    
</main>