import { Component, OnInit } from "@angular/core";
import { MainService } from "src/app/provider/main.service";
declare var $: any;
@Component({
  selector: "app-faq-category",
  templateUrl: "./faq-category.component.html",
  styleUrls: ["./faq-category.component.css"],
})
export class FaqCategoryComponent implements OnInit {
  category: any;
  categoryList: any = [];
  contentEdit : any = []
  categoryFaq : any = []
  categoryId: any;
  categoryValue : any 
  showDeleteModal : boolean = false


  itemPerPage : any = 10
  currentPage : any = 1
  total : any
  constructor(public service: MainService) {}

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory() {
    let url = "static/get-category-list";

    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        console.log(res);
        this.categoryList = res.data
        this.total = this.categoryList.length
       
        for(let i = 0 ; i < this.categoryList.length ; i++){
          this.contentEdit[i] = false
          console.log("-=-=-=-=-=-=-=-=-=-=-=-=-=-",this.categoryList[i]);
          this.categoryFaq[i] = this.categoryList[i]["category"]
          
        }
        this.service.successToast(res.message);
        this.service.hideSpinner();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }

  addCategory() {
    if (!this.category) {
      this.service.warningToast("Please enter faq category");
      return;
    }
    let url = "static/add-faq-category";
    const data = {
      category: this.category,
    };
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.category = "";
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  getId(id) {
    this.categoryId = id;
    this.showDeleteModal = true
    
  }
  deleteCategory(e) {
    if (e) {
      this.showDeleteModal = false
      
    } else {
      this.showDeleteModal = false
      return
    }
    let url = "static/delete-category?categoryId=" + this.categoryId;
    this.service.showSpinner();
    this.service.deleteApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  editFaq(index, value, id){
    for(let i = 0 ; i < this.categoryList.length ; i++){
      this.contentEdit[i] = false
    }
    this.contentEdit[index]= true
    this.categoryId = id
    this.categoryValue = value
   
  }
  editFaqApi(value){
    if(!value){
      this.service.warningToast("Faq category can not be empty")
      return
    }

    let url = "static/update-faq-category?categoryId=" + this.categoryId;
    const data = {
      "category": value
    }
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  pagination(e) {
    this.currentPage = e
  }
}
