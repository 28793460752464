<div>
    <main class="middle-content">
        <div class="page_title_block1">
            <h1 class="page_title float-left"> View Product</h1>
            <small class="pad">
                <i class="fa fa-arrow-circle-left colr"></i>
                <a class="po" routerLink="/product-list">Back to product list</a>
            </small>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700  setting-page mb-5">
                <div class="mb40">
                    <form>
                        <div class="add-store-block input-style mb20 mt20">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <img class="crousel" *ngFor="let item of productImage"
                                                src="{{item.productImage}}" alt="img"
                                                (click)="change(item.productImage)">
                                        </div>

                                        <div class="col-md-9" style="height: 400px;">
                                            <img class="loadedimage"
                                                style="width: 100%;height: 100%;object-fit: contain;"
                                                [src]="defaultImage" alt="">
                                        </div>
                                    </div>
                                    <!-- 
                                    <div>
                                        <h3 class="mt-4">Description</h3>
                                        <p>description description description description description description
                                            description description description</p>
                                    </div> -->
                                </div>



                                <div class="col-md-5">
                                    <div class="row stripe">
                                        <label class="col-md-4 textalignright"><strong>Product Id</strong></label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{productId || "--"}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row stripe2">
                                        <label class="col-md-4 textalignright"><strong>Product Name</strong></label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{productData?.productName || "--"}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row stripe">
                                        <label class="col-md-4 textalignright"><strong>Product Status</strong></label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{productData?.productStatus || "--"}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row stripe2">
                                        <label class="col-md-4 textalignright"><strong>Serial Number</strong></label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{productData?.serialNumber || "--"}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row stripe">
                                        <label class="col-md-4 textalignright"><strong>Price</strong></label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label *ngIf="!showVariantPrice" class="lab">{{productData?.price || "--"}}</label>
                                                <label *ngIf="showVariantPrice" class="lab">{{price || "--"}}</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div class=" stripe" *ngIf="variantProduct.length != 0">
                                        <div class="card mb7">
                                            <div class="card-header" id="heading8">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link btncolor" data-toggle="collapse"
                                                        data-target="#collapse8" aria-expanded="true"
                                                        aria-controls="collapse8">
                                                        <i class="fa fa-info iconP"></i>See More Variant
                                                    </button>
                                                </h5>
                                            </div>
                                            <div  id="collapse8" class="collapse" aria-labelledby="heading8"
                                                data-parent="#accordion">
                                                <div class="card-body">
                                                    <div class="card p-2" *ngFor="let item of variantProduct">
                                                        <div class="row">
                                                            <div class="col-md-12 d-flex justify-content-between">
                                                                <input type="radio" class="form-control new-form" (click)="getItem(item)" name="Variant" id=""> 
                                                                &nbsp;
                                                                <span class="new-para">
                                                                <p *ngIf="item.attribute"> <b>{{item.attribute}}</b> = {{item.childAttribute}} | </p> &nbsp;
                                                                 <p *ngIf="item.attribute1"> <b>{{item.attribute1}}</b> = {{item.childAttribute1}} | </p> &nbsp;
                                                                 <p *ngIf="item.attribute2"> <b>{{item.attribute2}}</b> = {{item.childAttribute2}}</p>
                                                                </span>
                                                            </div>
                                                           
                                                        </div>

                                                        
                                                        <!-- second card -->

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-12">
                                    <h4 class="text-center">Product Description</h4>
                                    <div class="col-md-12">
                                        <!-- {{productData?.productDescription}} -->
                                        <div class="form-group"
                                            [innerHtml]="sanitizer.bypassSecurityTrustHtml(productData?.productDescription)">

                                        </div>


                                    </div>


                                    <!-- <div *ngIf = "description">    
                                            <ck-editor  name="editor1" [(ngModel)]="description" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                                        </div> -->

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</div>