import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
//
  staticList:any
  id: any;
  public editorValue: string = '';
  data: any;
  about: any;
  type: any;
  formContactus : FormGroup;
  staticData: any;
  constructor(public sanitizer: DomSanitizer,private activatedroute: ActivatedRoute , private router : Router, public mainService : MainService) { 
    this.activatedroute.queryParams.subscribe((res)=>{
      this.id = res.id;
      this.type = res.type

    });
  }
  headerImg:any
  ngOnInit(): void {
    this.headerImg ='assets/img/headerh.png'
    this.formContactus = new FormGroup({
        'address': new FormControl(''),
        'phoneNo': new FormControl(''),
        'email' : new FormControl(''),
        'otherEmail': new FormControl(''),
        'fbUrl': new FormControl(''),
        'instaUrl': new FormControl(''),
        'twitterUrl': new FormControl(''),
        'lindenUrl': new FormControl(''),
        'skypeUrl': new FormControl(''),

    });
    this.getContactUs()
  }
  getContactUs(){
    let title = this.type
    let url = "static/get-static-data-list-by-id-admin?staticId="+this.id
    console.log(url)
    this.mainService.getApi(url,0).subscribe((res)=>{
      if(res.status == 200){
        this.staticData = res.data

         this.formContactus.patchValue({
           "address": res.data.address,
           "phoneNo": JSON.parse(res.data.phone),
           "email" :res.data.emailAddress,
           "otherEmail":res.data.otherEmailAddress,
           "fbUrl":res.data.facebookUrl,
           "instaUrl":res.data.instagramUrl,
           "twitterUrl":res.data.twitterUrl,
           "lindenUrl":res.data.linkedInUrl,
           "skypeUrl":res.data.skypeUrl ,
         })
        
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
       else{
         this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
      console.log(res);

    })
  }

  submit(saveType){
    let title = this.type
    let url =''
    if(this.id>0){
     url = "static/update-static-data-admin?staticId="+this.id
    }else{
     url = "static/save-static-data-admin"
    }
    const data = {
       "pageName":'Contact Us',
       "address": this.formContactus.value.address,
       "adminStaticStatus": "ACTIVE",
       "phone": JSON.stringify(this.formContactus.value.phoneNo),
       "emailAddress" :this.formContactus.value.email,
       "otherEmailAddress":this.formContactus.value.otherEmail,
       "facebookUrl":this.formContactus.value.fbUrl,
       "instagramUrl":this.formContactus.value.instaUrl,
       "twitterUrl":this.formContactus.value.twitterUrl,
       "linkedInUrl":this.formContactus.value.lindenUrl,
       "skypeUrl":this.formContactus.value.skypeUrl ,
    }
    {

     this.mainService.postApi(url ,data, 0).subscribe((res)=>{
       if(res.status == 200){
         this.mainService.hideSpinner()
         this.mainService.successToast(res.message)
         this.router.navigate(['/statics-content'])
 
       }
       else{
          this.mainService.hideSpinner()
         this.mainService.errorToast(res.message)
       }
 
     })
  }
}


    productData:any 
    productImageView:any=[]
    preview() {
       let title = this.type
     title = this.type.replaceAll(" ","_")
      this.productImageView=[]
        const data = {
      "pageData": this.editorValue,
      "pageKey": title
    }
        this.productData = data
     }

}
