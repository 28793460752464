import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.css']
})
export class ViewCategoryComponent implements OnInit {

  catId: any;
  categoryList: any

  constructor(public router:Router,private activatedRouting:ActivatedRoute,private mainservice:MainService) {
    this.activatedRouting.queryParams.subscribe((res:any)=>{
      this.catId=res.id
    })
   }
   //

  ngOnInit() {
   
    this.getCatId()
  }
  getCatId() {
   let url = "product/view-category-By-Id?categoryId="+this.catId
   this.mainservice.showSpinner()
   this.mainservice.postApi(url,{},1).subscribe((res)=>{
      if(res.status ==200){
        console.log(res.data);
        this.categoryList = res.data
        
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res.message)
      }
      else{
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res.mesage)
      }
   },(err)=>{
    this.mainservice.hideSpinner()
    this.mainservice.errorToast(err)
   })
  }
edit(){
  this.router.navigate(['edit-category'], { queryParams: { id: this.catId} })
  }

}
