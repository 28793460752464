import { MainService } from 'src/app/provider/main.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ecommerce';
  type:any=''
  isHide:boolean =true
  constructor(public router : Router ,private activatedroute: ActivatedRoute ,public mainService : MainService){
  this.activatedroute.queryParams.subscribe((res)=>{
      this.type = res.type
    });

  }
  
}
