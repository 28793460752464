import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-discount-and-promotion',
  templateUrl: './add-discount-and-promotion.component.html',
  styleUrls: ['./add-discount-and-promotion.component.css']
})
export class AddDiscountAndPromotionComponent implements OnInit {

  date: any;
  date2: any = new Date();
  catId: any;
  categoryList: any;
  editForm: FormGroup;
  imgBase64Path: any;
  productdata: any;
  imgSrc: any;
  categorydata: any;
  branddata: any;
  
  
  constructor(private activatedRouting: ActivatedRoute, public mainService: MainService, private router: Router,private datePipe : DatePipe) {
    this.activatedRouting.queryParams.subscribe((res: any) => {
      this.catId = res.id
    })
   }



  ngOnInit() {
    
    
    this.editFormApi()
   
  }
  editFormApi() {
    this.editForm = new FormGroup({

      name: new FormControl("", [Validators.required]),
      discountTypes : new FormControl("ASSIGNED_TO_TOTAL_ORDER",[Validators.required]),
      usePercent: new FormControl("USE_PERCENTAGE",[Validators.required]),
      percentage: new FormControl("",[Validators.required,Validators.pattern(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i)]),
      maximumDiscount: new FormControl("",[Validators.required]),
      startDate: new FormControl("",[Validators.required]),
      endDate: new FormControl("",[Validators.required]),
      discountLimitation: new FormControl("",[Validators.required]),
      couponCode:new FormControl("",[Validators.required]),
      discription: new FormControl("",[Validators.required]),
      discountAmount: new FormControl("",[]),
      category:new FormControl("",[]),
      product:new FormControl("Product1",[]),
      brand:new FormControl("",[]),

    })
  }

  get name() {
    return this.editForm.get('name') as FormControl
  }
   get discountTypes() {
    return this.editForm.get('discountTypes') as FormControl
  }
   get usePercent() {
    return this.editForm.get('usePercent') as FormControl
  }
   get percentage() {
    return this.editForm.get('percentage') as FormControl
  }
   get maximumDiscount() {
    return this.editForm.get('maximumDiscount') as FormControl
  }
   get discountAmount() {
    return this.editForm.get('discountAmount') as FormControl
  }
   get startDate() {
    return this.editForm.get('startDate') as FormControl
  }
   get endDate() {
    return this.editForm.get('endDate') as FormControl
  }
   get discountLimitation() {
    return this.editForm.get('discountLimitation') as FormControl
  }
   get discription() {
    return this.editForm.get('discription') as FormControl
  }
  get category() {
    return this.editForm.get('category') as FormControl
  }
  get product() {
    return this.editForm.get('product') as FormControl
  }
  get brand() {
    return this.editForm.get('brand') as FormControl
  }
  get couponCode() {
    return this.editForm.get('couponCode') as FormControl
  }
  getCatId() {
   
  }
  EditBanner() {

   
  }
  getList(){
    var a = this.editForm.value.discountTypes
    if (a ==="ASSIGNED_TO_PRODUCT") {
      this.getProduct()
    }
    else if(a ==="ASSIGNED_TO_CATEGORIES"){
      this.getCategory()
    }
    else if(a ==="ASSIGNED_TO_BRAND"){
      this.getBrand()
    }
    else {
      
    }
  }
  
  getProduct(){
let url="product/get-Product-Details?page=0&pageSize=200"
this.mainService.postApi(url,{},1).subscribe((res)=>{
  if (res.status ==200) {
    this.productdata=res.data[0].categoryDetails
    this.mainService.hideSpinner()
    this.mainService.successToast(res.message)
  } else {
    this.mainService.hideSpinner()
    this.mainService.errorToast(res.message)
  }
})
  }
  getCategory(){
    let url="product/getAllCategoryList"
    this.mainService.getApi(url,1).subscribe((res)=>{
      if (res.status ==200) {
        this.categorydata=res.data[0].categoryDetails
        console.log(this.categorydata);
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }
  getBrand(){
    let url="product/getAllBrandList"
    this.mainService.getApi(url,1).subscribe((res)=>{
      if (res.status ==200) {
        this.branddata=res.data
        console.log(this.categorydata);
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }
isSubmit:boolean=false
  addDiscount(){
    this.isSubmit=true
    let url="product/add-discount";
    if(this.editForm.valid){
    const startDate = new Date(this.editForm.value.startDate).toISOString()
    const endDate = new Date(this.editForm.value.endDate).toISOString()
    const body = {
      "brandName": this.editForm.value.brand,
      "categoryName": this.editForm.value.category,
      "coupanCode": this.editForm.value.couponCode,
      "discountAmount": this.editForm.value.discountAmount,
      "discountLimitation": this.editForm.value.discountLimitation,
      "discountName": this.editForm.value.name,
      "discountPercentage": this.editForm.value.percentage,
      "discountType":this.editForm.value.discountTypes,
      "discription": this.editForm.value.discription,
      "endDate": endDate,
      "discountStatus": "ACTIVE",
      "maxDiscountAmount": this.editForm.value.maximumDiscount,
      "productName": this.editForm.value.product,
      "startDate": startDate,
      "usePercentage": this.editForm.value.usePercent
    }

    this.mainService.postApi(url,body,1).subscribe((res)=>{
      if (res.status ==200) {
       
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
        this.router.navigate(['/discount-Management'])
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }else{
        this.mainService.errorToast('please add all required data.')

  }
  }

}
