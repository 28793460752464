import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { EventEmitterService } from "src/app/provider/event-emitter.service";
import { MainService } from "src/app/provider/main.service";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  showSidebar: boolean;
  currentUrl: any;
  profileImage: any;
  profileData: any = [];
  showLogo: boolean = false;
  permissionArr: any = [
    "DASHBOARD_MANAGEMENT",
    "CUSTOMER_MANAGEMENT",
    "SUB_ADMIN_MANAGEMENT",
    "CATEGORY_MANAGEMENT",
    "PRODUCT_MANAGEMENT",
    "ATTRIBUTE_MANAGEMENT",
    "BRAND_MANAGEMENT",
    "DISCOUNT_MANAGEMENT",
    "BANNER_MANAGEMENT",
    "ORDER_MANAGEMENT",
    "TESTIMONIAL_MANAGEMENT",
    "STATIC_CONTENT_MANAGEMENT",
    "FAQ",
    "SUPPORT",
  ];
  showModal: boolean = false;
  role: any;
  viewprofileData: any = [];
  constructor(
    private router: Router,
    public mainService: MainService,
    private eventEmitterService: EventEmitterService
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = "/" + e.url.split("/")[1];
        console.log(this.currentUrl);
        if (
          this.currentUrl === "/" ||
          this.currentUrl === "/login" ||
          this.currentUrl == "/forgot-password"
        ) {
          if (localStorage.getItem("token")) {
            this.router.navigate(["dashboard"]);
          }
        }
      }
    });
  }

  showadmin(event) {
    console.log("event", event);
    if (this.showLogo == false) {
      if ((document.getElementById("admin").style.width = "45px")) {
        document.getElementById("admin").style.display = "none";
        this.showLogo = true;
      }
    }
  }

  ngOnInit() {
    this.temp();

    $(".btn-toggle,.close_panel").click(() => {
      $("body").toggleClass("toggle-wrapper");
    });

    this.getProfile();
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar =
        this.eventEmitterService.invokeFirstComponentFunction.subscribe(
          (res) => {
            this.getProfile();
          }
        );
    }
  }

  ///
  temp() {
    if (this.permissionArr.includes("USER_MANAGEMENT")) {
      console.log("permission exixt");
    }
    if (this.permissionArr.includes("DASHBOARD_MANAGEMENT")) {
      console.log("permission again exixt");
    }
    if (this.permissionArr.includes("DASHBOARD_MANAGEMENTE")) {
      console.log("permission again exixt not");
    } else {
      console.log("permission doesn't exist");
    }
  }

  // get profile
  getProfile() {
    let url = "account/my-account";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.profileData = res.data;
        console.log(this.profileData);
        if (res.data.role === "SUBADMIN") {
          this.permissionArr = [];
          this.permissionArr = res.data.previlage;
        }
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }

  adminProfile() {
    this.router.navigate(["my-profile"]);
  }

  // logout
  logoutModalOpen() {
    console.log("Hell0");

    $("#logoutModal").modal("show");
  }

  onLogout() {
    // $('#logoutModal').modal('hide');
    this.showModal = true;
    this.mainService.onLogout();
  }
}
