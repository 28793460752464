import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-kyc',
  templateUrl: './user-kyc.component.html',
  styleUrls: ['./user-kyc.component.css']
})
export class UserKycComponent implements OnInit {
  kycData : any = [
    {
      _id : 12,
    name : 'Tanveer',
    idNumber : "KLPH0052L",
    IdType : "PAN",
    kycStatus : 'PENDING',
    createdAt : '12/07/2021, 8:05 PM'
  }
  ]
  itemPerPage = 5;
  currentPage : any
  totalItem : any

  constructor(private route : Router) { }

  ngOnInit(): void {
    
  }
  viewKyc(id){
    this.route.navigate(['/view-user-kyc'],{queryParams : {id : id}})
  }
}
