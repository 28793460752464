import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';
declare var $
@Component({
  selector: 'app-banner-management',
  templateUrl: './banner-management.component.html',
  styleUrls: ['./banner-management.component.css']
})
export class BannerManagementComponent implements OnInit {
  
  searchForm : FormGroup
  itemPerPage = 10;
  currentPage = 1;
  totalItems : any;
  bannerId : number
  isActive : any = []
  userDataList: any;
  bannerStatus: any;
  constructor(private mainService : MainService,private route : Router) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getBanner()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      text: new FormControl('')
    });
  }

  getBanner(){
    let url = "static/get-Banner-Details?page="+(this.currentPage-1)+"&pageSize="+(this.itemPerPage)
    let i = 0
    
    // this.mainService.showSpinner()
    this.mainService.postApi(url ,{}, 1).subscribe((res)=>{
      if(res.status == 200){
          this.userDataList = res.data[0].bannerDetails
          console.log(this.userDataList)
          for (let item of res.data[0].bannerDetails) {
            if (item.bannerStatus == "ACTIVE") {
              this.isActive[i] = true
            } 
            if (item.bannerStatus == "BLOCK")
            {
              this.isActive[i] = false
            }
           
           i++
          }
        this.totalItems = res.data[0].count
        this.mainService.hideSpinner()
        // this.mainService.successToast(res.message)
      }
      else{
        this.mainService.hideSpinner()
        // this.mainService.errorToast(res.message)
      }
    })

  }
  
  deleteBanner() {
    let url = "static/delete-banner?bannerId="+this.bannerId
    const data = {}
    this.mainService.deleteApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
       this.getBanner()
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }
  
    // ------------------------------- delete user ----------------------------- //
    deleteUserModal(id) {
      this.bannerId = id
      // $('#deleteUser').modal('show');

    }
   
      hideModal(){
        // $('#deleteUser').modal('hide')
       }
    

    viewBanner(id){
      this.route.navigate(['/view-banner'],{queryParams : {
        id : id,
      }})
    }
    editBanner(id){
      this.route.navigate(['/edit-banner'],{queryParams : {id : id}})
    }

    changeBannerStatus(bannerStatus,bannerId){
      let status = bannerStatus
      if(bannerStatus){
        status = "ACTIVE"
        this.getActive(status,bannerId)
      }
      else{
        status = "BLOCK"
        this. getBlock(status,bannerId )
      }
    }
    getActive(status,bannerId){
        let url = "static/active-banner-status?bannerId="+bannerId
        const data = {
          
          "bannerId": bannerId,
            "bannerStatus" : status,
          
        }
        console.log(data.bannerStatus);
        this.mainService.postApi(url,data,1).subscribe((res)=>{
          if (res.status ==200) {
            this.mainService.hideSpinner()
            this.mainService.successToast(res.message)
          } else {
            this.mainService.hideSpinner()
            this.mainService.errorToast(res.message)
          }
        })
      }
      
      getBlock(status,bannerId){
          let url = "static/block-banner-status?bannerId="+bannerId
          const data = {
           
            "bannerId": bannerId,
              "bannerStatus" : status,
             
          }
          console.log(data.bannerStatus);
          this.mainService.postApi(url,data,1).subscribe((res)=>{
            if (res.status ==200) {
              this.mainService.hideSpinner()
              this.mainService.successToast(res.message)
            } else {
              this.mainService.hideSpinner()
              this.mainService.errorToast(res.message)
            }
          })
        }
        
    
    pagination(event){
      this.currentPage = event
      this.getBanner()
    }
}
