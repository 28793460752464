import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-transaction-management',
  templateUrl: './transaction-management.component.html',
  styleUrls: ['./transaction-management.component.css']
})
export class TransactionManagementComponent implements OnInit {
  searchForm: FormGroup;
 totalItems:any
  itemPerPage = 5;
    currentPage = 1; 
  limit: any = 5;
  total: any;
  items = [];
  flip : boolean = false
  isSearched : boolean = false
  customerData : any = [
  ]
  transactionlist: any;
  constructor(private router: Router, private mainService: MainService) {
    
   }

  ngOnInit() {
    this.searchFormValidation()
    this.getTransactionList()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({

      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      text: new FormControl('')
    });
  }
getTransactionList(){
  let url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage-1)+"&pageSize="+(this.itemPerPage)
  this.mainService.showSpinner()
  this.mainService.getApi(url,1).subscribe((res)=>{
    if(res.status == 200){
      this.transactionlist = res.data.resultlist
      this.total = res.data.totalCount
     // this.mainService.successToast(res.message)

     this.mainService.hideSpinner()
    }else{
     // this.mainService.errorToast(res.message)
     this.mainService.hideSpinner()
    }
  },(err)=>{
   // this.mainService.errorToast(err)
   this.mainService.hideSpinner()
  })
}

  pagination(event){
    this.currentPage = event;
    if(this.flip){
      this.search()
    }
    else{
      this.getTransactionList()
    }
  }

  viewUser(id) {
    this.router.navigate(['/view-transaction'], { queryParams: { TxnId : id } })
  }

  search(){
    
    
    if(!this.searchForm.value.text && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
     
      return
    }
    this.isSearched = true
    this.flip = true
    let url =""
    const fromDate = Date.parse(this.searchForm.value.fromDate)
    const toDate = Date.parse(this.searchForm.value.toDate)
    if(this.searchForm.value.text && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&txnType="+this.searchForm.value.text
     
    }
    else if(!this.searchForm.value.text && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate
     
    }
    else if(!this.searchForm.value.text && this.searchForm.value.fromDate && this.searchForm.value.toDate){
      url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&toDate="+toDate
     
    }
    else if(this.searchForm.value.text && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&txnType="+this.searchForm.value.text
     
    }
    else{
      url = "account/admin/user-management/filter-user-details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage+"&fromDate="+fromDate+"&toDate="+toDate+"&txnType="+this.searchForm.value.text
    
    }
   
    
    
    // this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.transactionlist = res.data.resultlist
          this.total = res.data.totalCount
          this.mainService.hideSpinner();
          this.mainService.successToast(res.message);
        } else {
          this.mainService.hideSpinner();
          this.mainService.errorToast(res.message);
        }
      },
      (err) => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(err);
      }
    );
  }
  reset(){
    if(!this.isSearched){
      return
    }
    this.flip = false
    this.isSearched = false
    this.getTransactionList()
    this.searchForm.reset()
    this.searchForm.patchValue({
      text : ""
    })
  }
}
