import { DatePipe, formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { RouteingUrlService } from "src/app/service/routeing-url.service";
declare var $;
@Component({
  selector: "app-order-management",
  templateUrl: "./order-management.component.html",
  styleUrls: ["./order-management.component.css"],
})
export class OrderManagementComponent implements OnInit {
  searchForm: FormGroup;
  flip: boolean = false;
  isSearched: boolean = false;
  itemPerPage = 5;
  currentPage = 1;
  limit: any = 5;
  total: any;
  items = [];

  orderList: any;
  totalItems: number;
  isParam: boolean = false;
  constructor(
    private router: Router,
    public mainService: MainService,
    private activated: ActivatedRoute,
    private date: DatePipe
  ) {
    this.searchFormValidation();

    if (
      String(window.location.href).includes("fromDate") ||
      String(window.location.href).includes("toDate")
    ) {
      this.isParam = true;
      this.activated.queryParams.subscribe((res) => {
        if (res.fromDate && !res.toDate) {
          this.searchForm.patchValue({
            fromDate: res.fromDate.split("T")[0],
          });
        } else {
          this.searchForm.patchValue({
            fromDate: res.fromDate.split("T")[0],
            toDate: res.toDate.split("T")[0],
          });
        }
      });
    }
  }

  ngOnInit() {
    this.getOrderList();
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      status: new FormControl(""),
      fromDate: new FormControl(),
      toDate: new FormControl(),
    });
  }
  getOrderList() {
    let url =
      "product/get-Order-Details-by-page?page=" +
      (this.currentPage - 1) +
      "&pageSize=" +
      this.itemPerPage;
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.orderList = res.data.getOrderHistoryDetails;
        this.totalItems = res.data.allCountDetails;

        this.mainService.hideSpinner();
        if (this.isParam) {
          this.search();
        }
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        // this.mainService.errorToast(res.message)
      }
    });
  }

  search() {
    // alert("called")

    if (
      !this.searchForm.value.status &&
      !this.searchForm.value.search &&
      !this.searchForm.value.fromDate &&
      !this.searchForm.value.toDate
    ) {
      return;
    }
    this.currentPage = 1;
    this.isSearched = true;
    var a = this.searchForm.value;
    console.log(this.searchForm.value);
    if (a.search && !a.status && !a.fromDate && !a.toDate) {
      // search by user name
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.userName).toLowerCase() ===
          String(this.searchForm.value.search).toLowerCase()
        );
      });
    } else if (!a.search && a.status && !a.fromDate && !a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.orderStatus).toLowerCase() ===
          String(this.searchForm.value.status).toLowerCase()
        );
      });
    } else if (!a.search && !a.status && a.fromDate && !a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        let fromDate = this.date.transform(res.createTime, "yyyy-MM-dd");
        return fromDate == this.searchForm.value.fromDate;
      });
    } else if (!a.search && !a.status && a.fromDate && a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        let fromDate = this.date.transform(res.createTime, "yyyy-MM-dd");
        return (
          fromDate >= this.searchForm.value.fromDate &&
          fromDate <= this.searchForm.value.toDate
        );
      });

      //  single end
    } else if (a.search && a.status && !a.fromDate && !a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.userName).toLowerCase() ===
          String(this.searchForm.value.search).toLowerCase()
        );
      });

      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.orderStatus).toLowerCase() ===
          String(this.searchForm.value.status).toLowerCase()
        );
      });
    } else if (a.search && !a.status && a.fromDate && !a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.userName).toLowerCase() ===
          String(this.searchForm.value.search).toLowerCase()
        );
      });
      this.orderList = this.orderList.filter((res) => {
        let fromDate = this.date.transform(res.createTime, "yyyy-MM-dd");
        return fromDate == this.searchForm.value.fromDate;
      });
    } else if (a.search && !a.status && a.fromDate && a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.userName).toLowerCase() ===
          String(this.searchForm.value.search).toLowerCase()
        );
      });
      this.orderList = this.orderList.filter((res) => {
        let fromDate = this.date.transform(res.createTime, "yyyy-MM-dd");
        return (
          fromDate >= this.searchForm.value.fromDate &&
          fromDate <= this.searchForm.value.toDate
        );
      });
    } else if (!a.search && a.status && a.fromDate && !a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.orderStatus).toLowerCase() ===
          String(this.searchForm.value.status).toLowerCase()
        );
      });
      this.orderList = this.orderList.filter((res) => {
        let fromDate = this.date.transform(res.createTime, "yyyy-MM-dd");
        return fromDate == this.searchForm.value.fromDate;
      });
    } else if (!a.search && a.status && a.fromDate && a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.orderStatus).toLowerCase() ===
          String(this.searchForm.value.status).toLowerCase()
        );
      });
      this.orderList = this.orderList.filter((res) => {
        let fromDate = this.date.transform(res.createTime, "yyyy-MM-dd");
        return (
          fromDate >= this.searchForm.value.fromDate &&
          fromDate <= this.searchForm.value.toDate
        );
      });
    } else if (a.search && a.status && a.fromDate && !a.toDate) {
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.userName).toLowerCase() ===
          String(this.searchForm.value.search).toLowerCase()
        );
      });
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.orderStatus).toLowerCase() ===
          String(this.searchForm.value.status).toLowerCase()
        );
      });
      this.orderList = this.orderList.filter((res) => {
        let fromDate = this.date.transform(res.createTime, "yyyy-MM-dd");
        return fromDate == this.searchForm.value.fromDate;
      });
    } else {
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.userName).toLowerCase() ===
          String(this.searchForm.value.search).toLowerCase()
        );
      });
      this.orderList = this.orderList.filter((res) => {
        return (
          String(res.orderStatus).toLowerCase() ===
          String(this.searchForm.value.status).toLowerCase()
        );
      });
      this.orderList = this.orderList.filter((res) => {
        let fromDate = this.date.transform(res.createTime, "yyyy-MM-dd");
        return (
          fromDate >= this.searchForm.value.fromDate &&
          fromDate <= this.searchForm.value.toDate
        );
      });
    }
    this.totalItems = this.orderList.length;
    console.log(this.orderList);
  }
  reset() {
    if (!this.isSearched) {
      return;
    }
    this.flip = false;
    this.isSearched = false;
    this.getOrderList();
    this.searchForm.reset();
    this.searchForm.patchValue({
      status: "",
    });
  }
  viewOrder(
    id,
    productId,
    shippingAddressId,
    buyOrderId,
    ratingId,
    totalquantity
  ) {
    this.router.navigate(["view-order-list"], {
      queryParams: {
        id: id,
        productId: productId,
        shippingAddressId: shippingAddressId,
        buyOrderId: buyOrderId,
        ratingId: ratingId,
        totalquantity: totalquantity,
      },
    });
  }
  editOrder(
    id,
    productId,
    shippingAddressId,
    ratingId,
    totalquantity,
    buyOrderId
  ) {
    this.router.navigate(["edit-order"], {
      queryParams: {
        id: id,
        productId: productId,
        shippingAddressId: shippingAddressId,
        ratingId,
        totalquantity,
        buyOrderId,
      },
    });
  }
  showModal() {
    alert("call");
    //  $('#BlockModal').modal('show')
  }
  pagination(event) {
    this.currentPage = event;
    // this.getOrderList();
  }
}
