import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-static-content',
  templateUrl: './edit-static-content.component.html',
  styleUrls: ['./edit-static-content.component.css']
})
export class EditStaticContentComponent implements OnInit {
//
  staticList:any
  id: any;
  public editorValue: string = '';
  data: any;
  about: any;
  type: any;
  form : FormGroup;
  staticData: any;
  constructor(public sanitizer: DomSanitizer,private activatedroute: ActivatedRoute , private router : Router, public mainService : MainService) { 
    this.activatedroute.queryParams.subscribe((res)=>{
      this.id = res.id;
      this.type = res.type

    });
  }
  headerImg:any
  ngOnInit(): void {
    this.headerImg ='assets/img/headerh.png'
    this.form = new FormGroup({
      "editorValue": new FormControl('', (Validators.required)),
       "Title": new FormControl('', (Validators.required))
    });
    this.viewStaticData()
  }
  viewStaticData(){
    let title = this.type
    title = this.type.replaceAll(" ","_")
    let url = "static/get-static-page-data-by-page-key?pageKey="+title
    console.log(url)
    // this.mainService.showSpinner()
    this.mainService.getApi(url , 1).subscribe((res)=>{
      if(res.status == 200){
        this.staticData = res.data
        this.editorValue = res.data.pageData
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
       else{
         this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
      console.log(res);

    })
  }

  updateStatic(saveType){
    let title = this.type
    title = this.type.replaceAll(" ","_")
    let url = "static/update-static-content-data"
    const data = {
      "pageData": this.editorValue,
      "pageKey": title,
      "staticContentStatus":saveType
    }
    // this.mainService.showSpinner()
     this.mainService.postApi(url ,data, 0).subscribe((res)=>{
       if(res.status == 200){
         this.mainService.hideSpinner()
         this.mainService.successToast(res.message)
         this.viewStaticData()
         this.router.navigate(['/statics-content'])
 
       }
       else{
          this.mainService.hideSpinner()
         this.mainService.errorToast(res.message)
       }
 
     })
  }


    productData:any 
    productImageView:any=[]
    preview() {
       let title = this.type
     title = this.type.replaceAll(" ","_")
      this.productImageView=[]
        const data = {
      "pageData": this.editorValue,
      "pageKey": title
    }
        this.productData = data
     }
}
