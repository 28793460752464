import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MainService } from '../provider/main.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public mainService: MainService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
          .pipe(
              retry(1),
              catchError((error: HttpErrorResponse) => {
                console.log(error);

                  this.mainService.hideSpinner();
                  let errorMessage = '';
                  if (error.error instanceof ErrorEvent) {
                      // client-side error
                      errorMessage = `Error: ${error.error.message}`;
                      this.mainService.errorToast(errorMessage)
                  } else {
                      // server-side error
                      // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                      errorMessage = `Error: ${error.error.message}`;
                      this.mainService.errorToast(errorMessage)
                  }
                  this.mainService.errorToast(errorMessage)
                  return throwError(errorMessage);
              })
          )
  }
}
