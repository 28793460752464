<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Banner Management </h1>
        <hr>
    </div>
    <div class="row d-flex justify-content-end mb20" style="float: right;
               margin-left: 71%; ">
        <div class="col-md-4">
            <div class="admin-btn justify-content-end ">
                <button type="button" class="btn  btn-theme" routerLink="/add-banner"
                    style="padding: 5px;margin-right: 25px;">Add Banner</button>
            </div>
        </div>
    </div>
    <div class="content-section" id="htmlData">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">



                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                            aria-describedby="section list">
                            <thead>
                                <tr class="no_wrap_th text-center">
                                    <th scope="col" class="Sorting-img">S.No</th>
                                    <th scope="col" class="Sorting-img">Banner Title</th>
                                    <th scope="col" class="Sorting-img">Banner Image</th>
                                    <th scope="col" class="Sorting-img">Updated At</th>

                                    <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center"
                                    *ngFor="let item of userDataList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems} ; let i = index">
                                    <td>{{itemPerPage * (currentPage-1)+ i+1}}</td>
                                    <td class="content">
                                        {{item?.title}}</td>

                                    <td class="content"><img src="{{item?.image}}" alt="Banner Image"
                                            style="width: 66px;">
                                    </td>
                                    <td class="content"> {{item?.updateAt| date : 'dd/MM/yyyy, h:mm a'}}</td>
                                    <td>
                                        <div class="action-btn-alignment">
                                            <button (click)="viewBanner(item?.bannerId)" class="btn btn-info"
                                                title="View"><em class="fa fa-eye" aria-hidden="true"
                                                    style="color:white"></em></button>
                                            <button (click)="editBanner(item?.bannerId)" title="Edit"
                                                class="btn btn-info ml-2">
                                                <em class="fa fa-edit" aria-hidden="true" style="color:white"></em>
                                            </button>
                                            <button class="btn btn-danger ml-2 bt-margin" title="Delete"
                                                data-toggle="modal" data-target="#deleteUser"
                                                (click)="deleteUserModal(item?.bannerId)"><em class="fa fa-trash-o"
                                                    style="color:white"></em></button>


                                            <label class="switch">
                                                <input type="checkbox" [value] = "isActive[i]" [(ngModel)]="isActive[i]">
                                                <span class="slider round"
                                                    (click)="changeBannerStatus(!isActive[i],item?.bannerId)"
                                                    [title]="(isActive[i]==true)?'Enabled':'Disabled'"></span>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <!-- <tr *ngIf="totalItems == 0">
                                        <td class="table-no-record" colspan="4">No Record Found</td>
                                    </tr> -->
                            </tbody>
                        </table>
                        <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > itemPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<!-- delete hospital modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteUser">

    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete Banner?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this banner?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" data-dismiss="modal"
                                        (click)="deleteBanner()">Yes</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2" (click)="hideModal()"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>