<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Support Enquiry</h1>
        <hr>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div>
                        <div>
                            <div class="tab-pane1">
                                <div class="sec_head_new sec_head_new_after">
                                    <form [formGroup]="searchForm">
                                        <div class="row align-items-center justify-content-between">
                                            <div class="col-md-6">
                                                <div class="row mb-2">
                                                    <div class="col-md-4 ">
                                                        <b>Name </b>



                                                    </div>
                                                    <div class="col-md-1">
                                                        <label for="">:</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input type="text" class="form-control" formControlName="name"
                                                            placeholder="Name">
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-6">
                                                <div class="row mb-2">
                                                    <div class="col-md-4 ">
                                                        <b>Subject</b>



                                                    </div>
                                                    <div class="col-md-1">
                                                        <label for="">:</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input type="text" class="form-control"
                                                            formControlName="subject" placeholder="Subject">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="row mb-2">
                                                    <div class="col-md-4 ">
                                                        <b>Email </b>



                                                    </div>
                                                    <div class="col-md-1">
                                                        <label for="">:</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input type="text" class="form-control" formControlName="email"
                                                            placeholder="Email">
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-6">
                                                <div class="row mb-2">
                                                    <div class="col-md-4 ">
                                                        <b>Phone Number</b>



                                                    </div>
                                                    <div class="col-md-1">
                                                        <label for="">:</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input type="text" class="form-control" formControlName="phone"
                                                            placeholder="Phone Number">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row mb-2">
                                                    <div class="col-md-4 ">
                                                        <b>From Date </b>



                                                    </div>
                                                    <div class="col-md-1">
                                                        <label for="">:</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input type="date" [max]="mainService.today()"
                                                            formControlName="fromDate" class="form-control date-pick" id="datepicker">
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-6">
                                                <div class="row mb-2">
                                                    <div class="col-md-4 ">
                                                        <b>To Date</b>



                                                    </div>
                                                    <div class="col-md-1">
                                                        <label for="">:</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <input type="date" [readonly]="!searchForm.value.fromDate"
                                                            [min]="searchForm.value.fromDate" formControlName="toDate"
                                                           class="form-control">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="row mb-2">
                                                    <div class="col-md-4 ">
                                                        <b>Status</b>



                                                    </div>
                                                    <div class="col-md-1">
                                                        <label for="">:</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <select class="form-control form-select" formControlName="status" >
                                                            <option value="" aria-placeholder="Select Status">Select Status</option>
                                                            <option value="PENDING">Pending</option>
                                                            <option value="RESOLVED">Resolved</option>
                                                            <option value="INPROGRESS">In Progress</option>
                                                            <option value="CANCELLED">Cancelled</option>
                                                            <option value="CLOSED">Closed</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <button type="button" class="btn  btn-theme"
                                                    (click)="search()">Search</button>
                                                <button type="button" class="btn  btn-theme "
                                                    (click)="reset()">Reset</button>

                                            </div>
                                        </div>


                                        <!-- <div class="row align-items-center">
                                                <div class="col-md-2 pr-0">
                                                    <div class="filter_search mb20 width100">
                                                        <label class="d-flex align-items-center">Search:</label>
                                                        <div class="input-group filter_search_group">
                                                            <input type="text" class="form-control overflow-text"
                                                                formControlName="search"
                                                                placeholder="Search by user name"
                                                                maxlength="60">
                                                            <div class="input-group-append">
                                                                <button class="btn btn_search_group">
                                                                    <img src="assets/img/icon-search.png"
                                                                    (click)="search()"   alt="Search"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div class="col-md-2 pr-0">
                                                    <label class="d-flex align-items-center">From Date:</label>
                                                    <input type="date" class="form-control mb20" formControlName="fromDate" [max]="mainService.today()"> 
                                                </div>
                                                <div class="col-md-2 pr-0">
                                                    <label class="d-flex align-items-center">To Date:</label>
                                                    <input type="date" formControlName="toDate" [readonly] = "!this.searchForm.controls['fromDate'].value"
                                                        [min]="this.searchForm.controls['fromDate'].value"
                                                        class="form-control mb20">
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="text-center admin-btn mb2 mt10">
                                                        <button type="button" class="btn  btn-theme" (click)="search()">Search</button>
                                                        <button type="button" class="btn  btn-theme " (click)="reset()">Reset</button>
                                                         </div>
                                                </div>
                                            </div> -->
                                    </form>
                                </div>
                            </div>

                            <div class="table-responsive" style="margin-bottom: 60px;">
                                <table
                                    class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                    aria-describedby="Brand management list">
                                    <thead>
                                        <tr class="no_wrap_th text-center">
                                            <th scope="col" class="Sorting-img">S.No</th>
                                            <th scope="col" class="Sorting-img">Name</th>
                                            <th scope="col" class="Sorting-img">Subject </th>
                                            <th scope="col" class="Sorting-img">Email</th>
                                            <th scope="col" class="Sorting-img">Phone Number </th>
                                            <th scope="col" class="Sorting-img">Message</th>
                                            <th scope="col" class="Sorting-img">Date</th>
                                            <th scope="col" class="Sorting-img">Status</th>
                                            <th scope="col" class="action_td_btn3 Sorting-img" style="width: 1%;">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let item of staticlist | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems}; ; let i = index"
                                            class="text-center">
                                            <td>
                                                {{itemPerPage * (currentPage - 1) + i+1}}</td>


                                            <td class="content">{{item?.name || '--'}}</td>
                                            <td class="content">{{item?.subject||'--'}}</td>
                                            <!--  <td class="content" >{{item?.createTime| date : 'dd/MM/yyyy, h:mm a' || '--'}}</td> -->
                                            <td class="content">{{item?.emailAddress||'--'}}</td>

                                            <td class="content">{{item?.phone||'--'}}</td>
                                            <td class="content">{{item?.message||'--'}}</td>
                                            <td class="content">{{(item?.createTime | date : 'medium')||'--'}}</td>
                                            <td class="content">
                                                <select class="form-control" name="" id="" [value]="item?.staticStatus"
                                                    (change)="updateStatus($event.target.value, item)">
                                                    <option value="PENDING">Pending</option>
                                                    <option value="RESOLVED">Resolved</option>
                                                    <option value="INPROGRESS">In Progress</option>
                                                    <option value="CANCELLED">Cancelled</option>
                                                    <option value="CLOSED">Closed</option>
                                                </select>

                                            </td>


                                            <td class="justify-content-center d-flex">
                                                <button class="btn btn-info ml-2 bt-margin bth"
                                                    (click)="viewDetails(item.staticId)" title="View">
                                                    <em class="fa fa-eye" aria-hidden="true"></em></button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="staticlist?.length== 0">
                                            <td class="table-no-record" colspan="10">No Record Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > itemPerPage">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>


                    </div>




                </div>
            </div>


        </div>
    </div>


</main>


<!-- delete Brand-management modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p>Are you sure you want to delete this user?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">

                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">


                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>