import { Injectable } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { Subject } from "rxjs";
import { filter, pairwise } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RouteingUrlService {
  public previousUrl = new  Subject<any>()
  constructor(private router: Router) {}
  // routinEvent() : String {
  //   let url 
  //  url = this.router.events
  //     .pipe(
  //       filter((evt: any) => evt instanceof RoutesRecognized),
  //       pairwise()
  //     )
  //     .subscribe((events: RoutesRecognized[]) => {
  //       console.log("previous url", events[0].urlAfterRedirects);
  //        url = events[0].urlAfterRedirects
  //       // this.previousUrl.next(events[0].urlAfterRedirects)
       
      
       
  //     });
  //     return url
  // }
}
