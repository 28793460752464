import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { RouteingUrlService } from "src/app/service/routeing-url.service";

@Component({
  selector: "app-edit-address",
  templateUrl: "./edit-address.component.html",
  styleUrls: ["./edit-address.component.css"],
})
export class EditAddressComponent implements OnInit {
  editForm: FormGroup;
  addressType: any;
  formValidation() {
    this.editForm = new FormGroup({
      fullName: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required]),
      area: new FormControl("", [Validators.required]),
      landMark: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      state: new FormControl("", [Validators.required]),
      zipcode: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
    });
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    public service: MainService,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.addressType = res.address_type;
    });
  }
  address: any;
  ngOnInit(): void {
    this.formValidation();
    this.address = JSON.parse(localStorage.getItem("address"));
    this.getAddress();
  }
  getAddress() {
    this.editForm.patchValue({
      fullName: this.address.name,

      phone: this.address.phoneNumber,
      address: this.address.address,
      area: this.address.area,
      landMark: this.address.landmark,
      city: this.address.city,
      state: this.address.state,
      zipcode: this.address.zipCode,
      country: this.address.country,
    });
  }
shippingAddressId : any
  updateAddress() {
    let url = "product/edit-shipping-address?shippingAddressId="+this.address.shippingAddressId;

    const data = {
      address: this.editForm.value.address,
      addressTypeStatus: this.address.addressTypeStatus,
      area: this.editForm.value.area,
      city: this.editForm.value.city,
      // comments: this.editForm.value,
      // contactNumber: this.editForm.value,
      country: this.editForm.value.country,
      landmark: this.editForm.value.landMark,
      name: this.editForm.value.fullName,
      orderId: this.address.orderId,
      phoneNumber: this.editForm.value.phone,
      productId: this.address.productId,
      state: this.editForm.value.state,
      userId: this.address.userId,
      zipCode: this.editForm.value.zipcode,
    };
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.successToast(res.message);
        this.back()
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.message);
      }
    });
  }
  back() {
    // alert("yghjghj")
    //  const url = this.backrouting.routinEvent()
    let parms = JSON.parse(localStorage.getItem("backUrl"));
    console.log(parms);

    this.router.navigate(["/view-order-list"], {
      queryParams: {
        id: parms.id,
        productId: parms.productId,
        shippingAddressId: parms.shippingAddressId,
        buyOrderId: parms.buyOrderId,
        ratingId: parms.ratingId,
        totalquantity: parms.totalquantity,
      },
    });
  }
}
