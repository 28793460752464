import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {

  productId:number
  loadImage: any;
  bannerId: any;
  
  // image: any = "assets/Product image/chargerImage-1.jpeg";
  // image1 : any = "assets/Product image/chargerImage-2.jpg"
  // image2 : any = "assets/Product image/chargerImage-3.jpeg"
  // image3 : any = "assets/Product image/chargerImage-4.jpg"
  productData: any = [];
  productImage : any = [];
  defaultImage : any = [];
  description : any
  constructor(private activatedRouting:ActivatedRoute,private mainService:MainService,public sanitizer: DomSanitizer) {
    this.activatedRouting.queryParams.subscribe((res:any)=>{
      this.productId=res.id
    })
   }

  ngOnInit() {
   
    this.getViewProduct()
  }
  variantProduct : any = []
  getViewProduct() {
    let url ="product/view-Product-by-Id?productId="+this.productId
      
    // this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      if (res.status ==200) {
        this.productData = res.data
        this.productImage = res.data.productImageDto
        this.defaultImage = res.data.productImageDto[0].productImage
        this.description = res.data.productDescription
        this.variantProduct = res.data.productAttributeDto
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  
}
change(item) {
  this.defaultImage = item
} 
price : any
showVariantPrice : boolean =false
getItem(e){
  console.log(e.variantPrice);
  this.showVariantPrice = true
  this.price = e.variantPrice
  
}
}
