import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFaqComponent implements OnInit {
  editForm: FormGroup ;
  faqList: any;
  faqId: any;
  updateButton : boolean = false
  constructor(public mainService : MainService,private activatedroute : ActivatedRoute , private router : Router) { 
    this.activatedroute.queryParams.subscribe((res) => {
      this.faqId = res.id;
    })
  }

  ngOnInit(): void {
   
    this.editForm = new FormGroup({
      "question" : new FormControl('' , Validators.required) ,
      "answer" : new FormControl('', Validators.required) ,
      "category" : new FormControl('', Validators.required) ,
    });
    this.parantFaqList()
  
    this.getAllFaq()
  }

    categoryId :any 
  onSelectName(result){
    console.log(this.editForm.value.category);
    
    this.categoryId=result.categoryId
  }
patchCategory : any = []
  parantFaqList() {
    let url = "static/get-category-list";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.parantFaqCatedoryList = res.data
        this.patchCategory = this.parantFaqCatedoryList.filter((res)=>{
          return this.faqId == res.categoryId
        })


        this.editForm.patchValue({
          category : { "categoryId" :this.patchCategory[0]?.categoryId,"category" :this.patchCategory[0]?.category,},
          
        })
        console.log("-=-=-=-=-=-=-=-=-=",this.editForm.value.category);
        
        this.getFaq()
        
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      }
    });
  }
parantFaqCatedoryList:any =[]
  getFaq(){
    let url = "static/get-faq-detail?faqId="+this.faqId
    this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      if(res.status == 200){
       
        console.log(res.data.categoryId)
        this.editForm.patchValue({
          // category : {"category" :this.patchCategory[0]?.category, "categoryId" :this.patchCategory[0]?.categoryId},
          question : res.data.question,
          answer : res.data.answer
        })
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
   }
  
   updateFaq(){
    let url = "static/update-faq?faqId="+this.faqId
    console.log(this.editForm.touched,this.editForm.dirty);
    const data = {
      "answer": this.editForm.value.answer,
      "question": this.editForm.value.question,
      'category': this.editForm.value.category.category,
      'categoryId': this.editForm.value.category.categoryId,
    }
    this.mainService.showSpinner()
    this.mainService.postApi(url,data,1).subscribe((res)=>{
      if(res.status == 200){
  
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
        this.router.navigate(['faq'])
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
   }
  inputFunct(){
    console.log(this.updateButton);
 
    if(this.editForm.valid && (this.editForm.touched || this.editForm.dirty)){
     this.updateButton = true
    }
    else{
      this.updateButton = false
    }
  }
      productData:any 
    productImageView:any=[]
    preview() {
      this.productImageView=[]
      const data = {
        question: this.editForm.value.question,
        answer: this.editForm.value.answer,
        category: this.editForm.value.category.name,
        categoryId: this.editForm.value.category.id,
      };
        this.productData = data
     }
     allFaqList : any = []
     getAllFaq(){
      let url = "static/get-faq-detail-list"
     this.mainService.showSpinner()
     this.mainService.getApi(url,1).subscribe((res)=>{
       if(res.status == 200){
         this.allFaqList = res.data.filter((res)=>{
          
          return res.category[0].categoryStatus != 'DELETE' 
         })
         
         this.allFaqList = this.allFaqList.filter((res)=>{
          
          return res.product.isDeleted == false
         })
         this.allFaqList = this.allFaqList.filter((res)=>{
          
          return res.product.faqId != this.faqId
         })
         let i = 0
         let temp = []
          for(let item of this.allFaqList){
            if(i == 8){
              
              break
            }
            temp[i] = item
            i++
          }
          console.log(temp);
          
        this.allFaqList = temp
         this.mainService.hideSpinner()
         this.mainService.successToast(res.message)
       }
       else{
          this.mainService.hideSpinner()
         this.mainService.errorToast(res.message)
       }
     })
    }
}
