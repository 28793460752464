import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  view: boolean=false;
 oldPasswordType:any="password"
  newPasswordType: any="password"
  constructor(public mainService: MainService, private router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      oldPassword: new FormControl('', Validators.compose([Validators.required])),
      newPassword: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)])),
      confirmPassword: new FormControl('', Validators.compose([Validators.required])),
    })
  }
  back() {
    this.router.navigate(['my-profile'])
  }
  viewOld(val){
 if(val)
 {
   this.oldPasswordType="text"
 }
 else{
   this.oldPasswordType="password"
 }
}
 viewNew(val){
  if(val)
  {
    this.newPasswordType="text"
  }
  else{
    this.newPasswordType="password"
  }
  }
  changePassword() {
    var data = {
      'newPassword': this.form.value.confirmPassword,
      'oldPassword': this.form.value.oldPassword
    }
    let url="account/change-password"
    this.mainService.showSpinner();
    this.mainService.postApi(url, data,1).subscribe(res => {
      this.mainService.hideSpinner();
      if (res['status'] == 200) {
        this.mainService.successToast('Password updated successfully.');
        this.mainService.onLogout()
        // this.router.navigate(['/login'])
      } else {
        this.mainService.errorToast(res['message']);
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        this.mainService.errorToast('Unauthorized Access.');
      } else {
        this.mainService.errorToast('Something Went Wrong.');
      }
    })
  }
}
