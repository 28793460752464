    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title"> Sub Admin Management
            </h1>
            <hr>

        </div>

        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">

                        <!-- tab link customer start -->
                        <div>

                            <div>
                                <div class="tab-pane1">
                                    <div class="sec_head_new sec_head_new_after">
                                        <form [formGroup]="subAdminForm">
                                            <div class="row align-items-center">
                                                <div class="col-md-3 pr-0">
                                                    <div class="filter_search mb20 width100">

                                                        <label class="d-flex align-items-center">Search
                                                            Email/Name</label>
                                                        <div class="input-group filter_search_group">

                                                            <input type="text" class="form-control overflow-text"
                                                                formControlName="search" placeholder="Search by email"
                                                                maxlength="60">
                                                            <div class="input-group-append">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-2 pr-0">
                                                    <label class="d-flex align-items-center">From Date</label>
                                                    <input type="date" class="form-control mb20" formControlName="toDate" [max]="getToday()">
                                                </div>
                                                <div class="col-md-2 pr-0">
                                                    <label class="d-flex align-items-center">To Date</label>
                                                    <input type="date" [max]="getToday()" class="form-control mb20">
                                                </div> -->
                                                <div class="col-md-5">
                                                    <div class="text-center admin-btn mb2 mt10">
                                                        <button type="button" class="btn  btn-theme"
                                                            (click)="search()">Search</button>
                                                        <button type="button" class="btn  btn-theme "
                                                            (click)="reset()">Reset</button>
                                                        <!-- <button type="button" class="btn btn-theme"
                                                            (click)="exportCSVModal()" style="font-size: 12px;">EXPORT
                                                            CSV
                                                        </button> -->
                                                        <button type="button" class="btn btn-theme"
                                                            (click)="exportAsXLSX()" style="font-size: 12px; padding: 5px;">EXPORT
                                                            EXCEL
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-right">
                                                <button type="button" class="btn btn-theme"
                                                    routerLink="/add-sub-admin">Add
                                                    New
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table
                                        class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                        aria-describedby="user management list">
                                        <thead>
                                            <tr class="no_wrap_th text-center">
                                                <th scope="col" class="Sorting-img">S.No</th>
                                                <th scope="col" class="Sorting-img">Name</th>
                                                <th scope="col" class="Sorting-img">Email</th>
                                                <!--  <th scope="col" class="Sorting-img">Role</th> -->
                                                <th scope="col" class="Sorting-img">Phone Number</th>
                                                <th scope="col" class="Sorting-img">Status</th>
                                                <th scope="col" class="Sorting-img">Last logged In</th>
                                                <th scope="col" class="Sorting-img">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let item of subAdminList| paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total} ; let i = index"
                                                class="text-center">
                                                <td>{{itemPerPage * (currentPage - 1) + i+1}}</td>
                                                <td class="content">{{item.firstName || "--"}}</td>
                                                <td class="content">{{item.email || "--"}}</td>
                                                <!-- <td class="content">{{item.status}}</td>  -->
                                                <td class="content">{{item.phoneNo || "--"}}</td>
                                                <td class="content">{{item.userStatus || "--"}}</td>
                                                <td class="content">{{(item.lastLoginTime | date : 'medium') || "--"}}</td>


                                                <td class="justify-content-center d-flex">
                                                    <button class="btn btn-info ml-2 bt-margin" title="View"
                                                        (click)="viewSubAdmin(item?.userId)">
                                                        <em class="fa fa-eye" aria-hidden="true"></em></button>
                                                    <button class="btn btn-info ml-2 bt-margin" title="Edit"
                                                        (click)="editSubAdmin(item?.userId)"><em class="fa fa-edit"
                                                            aria-hidden="true"></em></button>
                                                    <button *ngIf="item.userStatus == 'ACTIVE'"
                                                        class="btn btn-success ml-2 bt-margin"  title="Activate"
                                                        data-target="#block" data-toggle="modal"
                                                        (click)="blockModal(item.userId,item.userStatus)"><em class="fa fa-ban"
                                                            aria-hidden="true"></em></button>
                                                    <button *ngIf="item.userStatus == 'BLOCK'"
                                                    class="btn btn-danger ml-2" title="Deactivated"
                                                        data-target="#block" data-toggle="modal"
                                                        (click)="blockModal(item.userId,item.userStatus)"><em class="fa fa-ban"
                                                            aria-hidden="true"></em></button>
                                                    <button class="btn btn-danger ml-2 bt-margin" title="Delete"
                                                        data-target="#delete" data-toggle="modal"
                                                        (click)="deleteModal(item.userId)"><em class="fa fa-trash"
                                                            aria-hidden="true"></em></button>
                                                </td>
                                            </tr>
                                            <tr *ngIf = "!subAdminList.length" class="text-center" >
                                                <td colspan="7">
                                                    No Record Found
                                                </td>
                                                
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div class="custom-pagination mt-2 text-align-end" *ngIf="total > itemPerPage">
                                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </div>
    </main>


<!-- Delete Modal -->
<div class="modal fade global-modal reset-modal" id="delete">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">DELETE</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this subadmin?</p>
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="deleteSubAdmin()"
                                        data-dismiss="modal">YES</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">BLOCK</h5>
                   
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p >Are you sure you want to block this subadmin?</p>
                               
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="blockSubAdmin()"
                                        data-dismiss="modal">YES</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-----------------export csv modal ------------------->
<div class="container">
    <!-- Trigger the modal with a button -->
    <!-- Modal -->
    <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header" style="background: goldenrod;">
                    <h5 class="modal-title">Confirmation</h5>
                </div>
                <div class="modal-body">
                    <p>The CSV export file will be generated for download.</p>
                    <p>Disable any popup blockers in your browser to ensure proper download.</p>
                    <p>Ok to proceed?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" >OK</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>

</div>
<!-----------------export excel modal ---------------->
<div class="container">
    <!-- Trigger the modal with a button -->
    <!-- Modal -->
    <div class="modal fade" id="myModal-export" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header" style="background: goldenrod;">
                    <h5 class="modal-title">Confirmation</h5>
                </div>
                <div class="modal-body">
                    <p>The EXCEL export file will be generated for download.</p>
                    <p>Disable any popup blockers in your browser to ensure proper download.</p>
                    <p>Ok to proceed?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="exportAsXLSX()">OK</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>

</div>
