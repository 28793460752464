import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-edit-product",
  templateUrl: "./edit-product.component.html",
  styleUrls: ["./edit-product.component.css"],
})
export class EditProductComponent implements OnInit {
  addForm: FormGroup;
  proctImg: any = [];

  public editorValue: string = "";
  imageUrl: any = [];
  imgSrc: any = [];
  productId: any;
  categoryList: any = [];
  brandList: any = [];
  constructor(
    public sanitizer: DomSanitizer,
    private route: Router,
    public mainService: MainService,
    private activated: ActivatedRoute
  ) {
    this.activated.queryParams.subscribe((res) => {
      this.productId = res.id;
    });
  }
  deleveryDatList: any = [];
  ngOnInit() {
    this.addFormvalidation();
    this.deleveryDatList = [
      { id: 1, name: "1-2 days" },
      { id: 2, name: "5-7 days" },
      { id: 3, name: "1 week" },
      { id: 4, name: "2 week" },
      { id: 5, name: "3-4 week" },
    ];
    this.getBrandList();
    this.getViewProduct();
    this.getCategoryList();
    this.getAttribute();
  }
    categoryIdmm: any = 0;
  onSelectName(result) {
    this.categoryIdmm = result.id;
  }
    getCategoryList() {
    let url = "product/getAllCategoryList";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.categoryList = res.data[0].categoryDetails;
        this.mainService.hideSpinner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  getBrandList() {
    let url = "product/getAllBrandList";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.brandList = res.data;
          this.mainService.hideSpinner();
        } else {
          this.mainService.hideSpinner();
        }
      },
      (err) => {
        this.mainService.hideSpinner();
      }
    );
  }
  addFormvalidation() {
    this.addForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      category: new FormControl("", [Validators.required]),
      editorValue: new FormControl("", Validators.required),
      serial: new FormControl("", Validators.required),
      skuNumber: new FormControl("", Validators.required),
      shortDescription: new FormControl("", Validators.required),
      quantity: new FormControl("", [Validators.required]),
      weight: new FormControl(0),
      length: new FormControl(0),
      width: new FormControl(0),
      height: new FormControl(0),
      freeShipping: new FormControl(false),
      shippingCharge: new FormControl(0),
      deliveryDay: new FormControl(""),
      brandId: new FormControl(""),
    });
  }

  get name() {
    return this.addForm.get("name");
  }

  get category() {
    return this.addForm.get("category");
  }

  get price() {
    return this.addForm.get("price");
  }
  get serial() {
    return this.addForm.get("serial");
  }
  get shortDescription(){
    return this.addForm.get("shortDescription");

  } get skuNumber(){
    return this.addForm.get("skuNumber");

  }

   checkIsFree(isChecked){
    if(isChecked){
       this.addForm.controls.shippingCharge.setValue(0)
    }

  }

    isShipRate(){
    let isValid =true
    if(this.addForm.value.freeShipping){
       isValid= true
        }else{
          if(this.addForm.value.shippingCharge===0){
            isValid= false
          }else{
            isValid= true
          }
        }
   return isValid 
  }
  isSubmit:boolean = false
  editProduct() {
      this.isSubmit=true
    let url = "product/update-product";
   if(this.addForm.valid){
      if(this.proctImg.length>0){
         if(this.variantProduct.length>0){
           if(this.isShipRate()){ 
    const data = {
      price: this.variantProduct[0].variantPrice,
      productDescription: this.editorValue,
      productImageDto: this.productImageDto,
      categoryId:this.addForm.value.category.categoryId,
      categoryName:this.addForm.value.category.categoryName,
      quantity :JSON.stringify(this.addForm.value.quantity),
      productId: this.productId,
      productName: this.addForm.value.name,
      serialNumber: this.addForm.value.serial,
      brandId: this.addForm.value.brandId,
      skuNumber: this.addForm.value.skuNumber,
      shortDescription: this.addForm.value.shortDescription,
      productWeight: this.addForm.value.weight,
      productLength: this.addForm.value.length,
      productWidth: this.addForm.value.width,
      productHeight: this.addForm.value.height,
      freeShipping: this.addForm.value.freeShipping,
      additionalCharge: this.addForm.value.shippingCharge,
      deliveryDays: this.addForm.value.deliveryDay,
      productDefaultImage: this.byDefaultImageUrl,
      "productAttributeDto" : this.variantProduct,
    };

    this.mainService.showSpinner();

    this.mainService.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
        this.route.navigate(["/product-list"]);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
    }else{
     this.mainService.warningToast('Please enter shipping charge')
   }
    }
  else{
     this.mainService.warningToast('Please add price');
  }
      }else{
     this.mainService.warningToast('Please add product image');
  }
  }else{
 this.mainService.warningToast('Please add all required fields');
  }
  }
  getViewProduct() {
    let url = "product/view-Product-by-Id?productId=" + this.productId;
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.addForm.patchValue({
          name: res.data.productName,
          price: res.data.price,
          serial: res.data.serialNumber,
          brandId: res.data.brandId,
          shippingCharge: res.data.additionalCharge,
          weight: res.data.productWeight,
          length: res.data.productLength,
          width: res.data.productWidth,
          height: res.data.productHeight,
          freeShipping: res.data.freeShipping,
          deliveryDay: res.data.deliveryDays,
          editorValue: res.data.productDescription,
          skuNumber: res.data.skuNumber,
          shortDescription: res.data.shortDescription,
          quantity: res.data.quantity,
          category:{categoryId:res.data.categoryId,categoryName: res.data.categoryName}
        });
        var images = [];
        for (let i = 0; i < res.data.productAttributeDto.length; i++) {
          this.variantProduct[i] = res.data.productAttributeDto[i];
          this.indexL++
        }
        images = res.data.productImageDto;
        let i = 0;
        for (let img of images) {
          console.log(img.productImage);
          // this.proctImg[i] = img.productImage
          this.proctImg[i] = {
            image: img.productImage,
            selected: false,
          };
          this.productImageDto[i] = {
            productImage: img.productImage,
            serialNumber: res.data.serialNumber,
          };

          i++;
        }

        this.byDefaultImageUrl = res.data.productDefaultImage;
        this.defaultImage(this.proctImg, res.data.productDefaultImage);
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  defaultImage(ImgList, defaultImage) {
    ImgList.forEach((element) => {
      if (element.image === defaultImage) {
        element.selected = true;
      }
    });
  }
  productImage(event) {
    this.proctImg = [];
    var img = [];
    img = event.target.files;
    console.log(img);
    if (img.length > 5) {
      this.mainService.infoToast("Please select maximum 5 image");
      return;
    }
    for (let index = 0; index < img.length; index++) {
      const file = event.target.files[index];
      this.imageUrl = file;
      this.proctImg[index] = this.uploadProfilePic(img, index);
    }

    console.log(this.proctImg);
  }
  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  productImageDto: any = [];

  deleteImage(data, i) {
    this.proctImg.forEach((element, index) => {
      if (data.image === element.image) {
        this.proctImg.splice(i, 1);
      }
    });
    if (this.productImageDto.length > 0) {
      let deletedaimage = this.productImageDto.findIndex(
        (objectModified) => objectModified.productImage === data.image
      );
      if (deletedaimage !== -1) {
        this.productImageDto.splice(deletedaimage, 1);
      }
    }
  }
  byDefaultImageUrl: any = "";

  public selectDefaultImage(data, i) {
    this.byDefaultImageUrl = data.image;
    this.proctImg.forEach((element, index) => {
      if (index == i) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
  }

  uploadProfilePic(img, i) {
    let url = "static/upload-file";
    this.proctImg[i] = "assets/loader/1488 (2).gif";
    const formData = new FormData();
    formData.append("file", this.imageUrl);
    this.mainService.uploadFile(url, formData).subscribe((res) => {
      if (res.status == 200) {
        this.imgSrc = res.data;
        // this.proctImg[i] = res.data
        this.proctImg[i] = {
          image: res.data,
          selected: false,
        };
        this.productImageDto[i] = {
          productImage: res.data,
          serialNumber: this.addForm.value.serial,
        };
      } else {
        this.mainService.errorToast("Can't Upload This File");
      }
    });
  }

  defaultImage1: any;
  productData: any;
  // defaultImage:any
  description: any;
  productImageView: any = [];
  previewProduct() {
    this.isSubmit=true
    this.productImageView = [];

      if(this.proctImg.length>0){
        if(this.variantProduct.length>0){
              let price =0
    if(this.variantProduct.length>0){
    price = this.variantProduct[0].variantPrice
    }
    const data = {
      price: price,
      productDescription: this.editorValue,
      productImageDto: this.productImageDto,
      productName: this.addForm.value.name,
      productStatus: "ACTIVE",
      serialNumber: this.addForm.value.serial,
      brandId: this.addForm.value.brandId,
      category: this.addForm.value.category.categoryName,
      categoryName: this.addForm.value.category.categoryName,
      categoryId: this.addForm.value.category.categoryId,
      productWeight: this.addForm.value.weight,
      productLength: this.addForm.value.length,
      productWidth: this.addForm.value.width,
      productHeight: this.addForm.value.height,
      freeShipping: this.addForm.value.freeShipping,
      additionalCharge: this.addForm.value.shippingCharge,
      deliveryDays: this.addForm.value.deliveryDay,
      productDefaultImage: this.byDefaultImageUrl,
      skuNumber: this.addForm.value.skuNumber,
      shortDescription: this.addForm.value.shortDescription,
    };

    this.productData = data;
    this.productImageView = this.proctImg;
    this.defaultImage1 = this.byDefaultImageUrl;
    this.description = data.productDescription;
         }
  else{
     this.mainService.warningToast('Please add price');
  }
  }else{
     this.mainService.warningToast('Please add product image');
  }
  

  }

  change(item) {
    this.defaultImage1 = item;
  }

  index = 0;
  addCombination: any = [];
  variantProduct: any = [];
  addMoreCombination() {
   if (this.index == 3) {
      this.mainService.warningToast("Cannot add more than 4 attribute");
      return;
    }
    this.addCombination.push(++this.index);
  }
  removeAttribute(i) {
    this.addCombination.splice(i, 1);
    this.index--;
    this.attributeCategory[i] = "";
    this.childAttribute[i] = "";
    console.log(this.index);
  }
  removeVariant(i) {
    this.variantProduct.splice(i, 1);
    this.indexL--
  }
  attributeCategory: any = [];
  childAttribute: any = [];
  indexL = 0;
  addPrice(price) {
    let parent = [];
    let child = [];
    let parentName;
    let childName;
    parent = this.attributeCategory.filter((res) => {
      return res != "";
    });
    child = this.childAttribute.filter((res) => {
      return res != "";
    });
    let combination = [];

    combination[this.indexL] = {
      attribute: parent[0] ? parent[0].split("^&%")[1] : "",
      attribute1: parent[1] ? parent[1].split("^&%")[1] : "",
      attribute2: parent[2] ? parent[2].split("^&%")[1] : "",
      combinationId: this.indexL + 1,
      childAttribute: child[0] ? child[0] : "",
      childAttribute1: child[1] ? child[1] : "",
      childAttribute2: child[2] ? child[2] : "",

      productAttributeStatus: "ACTIVE",

      variantPrice: price,
    };

    this.variantProduct[this.indexL] = combination[this.indexL];
    this.indexL++;
    console.log(this.variantProduct);
  }
  attrributeList: any = [];
  getAttribute() {
    let url = "product/view-attribute-by-list";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.attrributeList = res.data;
        this.mainService.hideSpinner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      }
    });
  }
  childProductAttribute0: any = [];
  childProductAttribute1: any = [];

  childProductAttribute2: any = [];

  childProductAttribute3: any = [];

  getchildAttribute(i, ind) {
    console.log(ind);

    let url =
      "product/view-child-attribute-by-list-by-attribute-id?attributeId=" +
      this.attributeCategory[i].split("^&%")[0];
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        if (ind == 0) {
          this.childProductAttribute0 = res.data[0].child_attribute;
        } else if (ind == 1) {
          this.childProductAttribute1 = res.data[0].child_attribute;
        } else if (ind == 2) {
          this.childProductAttribute2 = res.data[0].child_attribute;
        } else {
          this.childProductAttribute3 = res.data[0].child_attribute;
        }
        // this.childProductAttribute = res.data[0].child_attribute
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      }
    });
  }

  priceL : any
  showVariantPrice : boolean =false
  getItem(e){
    console.log(e.variantPrice);
    this.showVariantPrice = true
    this.priceL = e.variantPrice
    
  }
}
