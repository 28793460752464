 <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Brand Management </h1>
            <hr>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div>
                            <div>
                                <div class="tab-pane1">
                                    <div class="sec_head_new sec_head_new_after">
                                        <form [formGroup]="searchForm">
                                            <div class="row align-items-center">
                                                <div class="col-md-5 pr-0">
                                                    <div class="filter_search mb20 width100">
                                                        <label class="d-flex align-items-center">Search:</label>
                                                        <div class="input-group filter_search_group">
                                                            <input type="text" class="form-control overflow-text"
                                                                formControlName="search"
                                                                placeholder="Search by brand name"
                                                                maxlength="60">
                                                            <div class="input-group-append">
                                                                <button class="btn btn_search_group" (click)="search()">
                                                                    <img src="assets/img/icon-search.png"
                                                                        alt="Search"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-5">
                                                    <div class="text-center admin-btn mb2 mt10">
                                                        <button type="button" class="btn  btn-theme" (click)="search()">Search</button>
                                                        <button type="button" class="btn  btn-theme " (click)="reset()">Reset</button>
                                                        <!-- <button type="button" class="btn  btn-theme "
                                                             routerLink="/add-brand">Add
                                                            Brand</button> -->
                                                            <div class="col-4 pull-right">
                                                                <button type="button" class="btn btn-theme "
                                                                    [routerLink]="['/add-brand']">Add
                                                                    Brand
                                                                </button>
                                                            </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="table-responsive" style="margin-bottom: 60px;">
                                    <table
                                        class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                        aria-describedby="Brand management list">
                                        <thead>
                                            <tr class="no_wrap_th text-center">
                                                <th scope="col" class="Sorting-img">S.No</th>

                                                <th scope="col" class="Sorting-img">Brand Name</th>
                                                <!-- <th scope="col" class="Sorting-img">Brand Image</th> -->
                                                <th scope="col" class="Sorting-img">Created On</th>
                                                <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let item of brandList| paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total} ; let i = index" class="text-center">
                                                <td>
                                                    {{itemPerPage * (currentPage - 1) + i+1}}</td>


                                                <td class="content">{{item?.brandName || '--'}}</td>
                                                <!-- <td class="content">
                                                    <img src={{item?.brandImage}} alt="N/A"
                                            style="width: 66px;">
                                                  </td> -->
                                                <td class="content">{{(item?.createTime | date : 'dd/MM/yyyy, hh:mm a')||'--'}}</td>

                                                <td class="justify-content-center d-flex">
                                                    <button class="btn btn-info ml-2 bt-margin bth"
                                                        (click)="viewBrand(item?.brandId)" title="View">
                                                        <em class="fa fa-eye" aria-hidden="true"></em></button>
                                                    <button class="btn btn-info ml-2 bth" (click)="editBrand(item?.brandId)"
                                                        title="Edit"><em class="fa fa-edit"
                                                            aria-hidden="true"></em></button>

                                                   
                                                    <button class="btn btn-danger ml-2 bth" title="Delete" data-toggle="modal" data-target="#deleteModal"   (click)="getBrandId(item?.brandId)"><em
                                                           class="fa fa-trash"
                                                            aria-hidden="true"></em></button>



                                                </td>
                                            </tr>
                                            <tr *ngIf="brandList?.length == 0">
                                                <td class="table-no-record" colspan="7">No Record Found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="custom-pagination mt-2 text-align-end" >
                                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>


                        </div>




                    </div>
                </div>


            </div>
        </div>


    </main>


<!-- delete Brand-management modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p>Are you sure you want to delete this brand?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="deleteBarand()">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">

                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">


                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


