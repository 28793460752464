
    <div class="outer-box">
        <div class="global-table no-radius p0">
            <div class="tab-content1">
            
                <div class="table-responsive">
                    <table
                        class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                        aria-describedby="section list">
                        <thead>
                            <tr class="no_wrap_th text-center">
                                <th scope="col" class="Sorting-img">S.No</th>
                                
                                <th scope="col" class="Sorting-img">Name</th>
                                <th scope="col" class="Sorting-img">Id Number</th>
                                <th scope="col" class="Sorting-img">Id Type</th>
                                <th scope="col" class="Sorting-img">Kyc Status</th>
                                <th scope="col" class="Sorting-img">Created At</th>
                                <!-- <th scope="col" class="Sorting-img">Status</th>   -->
                                <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                           
                            
                         
                   
                             
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr class="text-center"
                            *ngFor="let item of kycData ;let i = index">

                                <td >{{itemPerPage * (currentPage - 1) + i+1}}</td>
                                <td class="content">{{item?.name || '---'}}</td>
                                <td class="content">{{item?.idNumber || '---'}}</td>
                                <td class="content">{{item?.IdType || '---'}}</td>
                                <td class="content">{{item?.kycStatus || '---'}}</td>
                                <td class="content">{{(item?.createdAt ) || '---'}}</td>
                            
                               
                                <!-- <td class="content">{{item?.status || '---'}}</td> -->
                                <td class="justify-content-center d-flex">

                                    <button class="btn btn-info ml-2 bt-margin"
                                        (click)="viewKyc(item?._id)"> <em class="fa fa-eye"
                                            aria-hidden="true"></em></button>

                                </td>
                            </tr>
                            
                            <tr *ngIf="kycData == 0">
                                <td class="table-no-record" colspan="8">No Record Found</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="custom-pagination mt-2 text-align-end">
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div> -->

                </div>
            </div>
        </div>
    </div>

