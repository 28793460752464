import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-testimonial',
  templateUrl: './view-testimonial.component.html',
  styleUrls: ['./view-testimonial.component.css']
})
export class ViewTestimonialComponent implements OnInit {

  catId: any;
  categoryList: any = []

  constructor(public router:Router,private activatedRouting:ActivatedRoute,private mainservice:MainService) {
   
   }
   //

  ngOnInit() {
   
    this.categoryList = JSON.parse(localStorage.getItem("testimonial"))
  }
  getCatId() {
   
  }
edit(){
  this.router.navigate(['edit-testimonial'])
  }


}
