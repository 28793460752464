import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $ : any
@Component({
  selector: 'app-add-testimonial',
  templateUrl: './add-testimonial.component.html',
  styleUrls: ['./add-testimonial.component.css','./add-testimonial.component.scss']
})
export class AddTestimonialComponent implements OnInit {

  addForm: FormGroup
  imgSrc : any
  
  
    constructor(private route: Router, private mainservice: MainService) { 
   
    }
  
    ngOnInit() {
      this.addFormvalidation()
  
    }
    addFormvalidation() {
      this.addForm = new FormGroup({
        name: new FormControl("", [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]),
        description: new FormControl("", [Validators.required]),
      })
    }
  
    get name() {
      return this.addForm.get('name')
    }
    get description() {
      return this.addForm.get('description')
    }
  
    addNewCategory(){
      if(this.imgSrc == "assets/loader/1488 (2).gif" ){
        this.mainservice.infoToast("Please wait while uplaoding category image.")
        return
      }
      let url = "static/add-Testimonials"
      const body = {
        "image": this.imgSrc,
        "message": this.addForm.value.description,
        "name": this.addForm.value.name,
       
        "testimonialsStatus": "ACTIVE"
      }
      this.mainservice.showSpinner()
      this.mainservice.postApi(url,body,1).subscribe((res)=>{
        if(res.status == 200){
          this.mainservice.hideSpinner()
          this.mainservice.successToast(res.message)
          this.route.navigate(['/testimonial'])
        }
        else{
          this.mainservice.hideSpinner()
          this.mainservice.errorToast(res.message)
        }
      },(err)=>{
        this.mainservice.hideSpinner()
          this.mainservice.errorToast(err)
      })
    }
   getImage(event){
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = rs => {
        if (rs.currentTarget['height'] ==  250&&  rs.currentTarget['width'] == 250){
          
            const file = event.target.files[0];
          
         this.uploadImage(file) 
        }
        else{
          this.imgSrc = ''
          // this.addBannerForm.controls['bannerFile'].reset()
          this.mainservice.errorToast('Please upload image of size 250 x 250px only.')
        }
        
      }
      
    }
   }
 
  
    uploadImage(img){
      let endUrl = "static/upload-file"
      this.imgSrc ="assets/loader/1488 (2).gif"
      var fb = new FormData()
      fb.append('file',img)
      this.mainservice.uploadFile(endUrl,fb).subscribe((res)=>{
        if(res.status == 200){
          this.imgSrc = res.data
        }
        else{
          this.mainservice.errorToast("something went wrong while uploading image")
        }
      })
    }
    maxChar = 300
    charCount(){
      this.maxChar = 300
      var a = String(this.addForm.value.description).length
      console.log(a);
      
      this.maxChar = this.maxChar - a
    }

}
