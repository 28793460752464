<main class="middle-content">
        <div class="page_title_block1">
            <h1 class="page_title float-left"> Edit Offer</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/offer-Management">Back</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save" (click)="editOffer()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Update
                    </button>
               </div>
       </div>
    <div class="content-section">
        <div class="order-view  max-WT-700  setting-page">
            <div class="  mb40">
                  <form [formGroup]="editForm">
                    <div class="add-store-block input-style mb20 mt20 ">
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Offer Name <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Offer Name"
                                        maxlength="300" formControlName="offerName">
                             <span *ngIf="editForm.get('offerName').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter offer name.
                      </span>
                                </div>
                            </div>
                            <label class="col-md-4 textalignright" >Product Name
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter"  >
                                <div class="form-group"  >
                                    <select class="form-control" formControlName='product'>
                                        <option value="" selected>Select Product</option>
                                     <option  *ngFor="let product of productdata"
                                        [value]="product.productId"
                                       >{{product?.productName}}</option>
                                    </select>
                            <span *ngIf="editForm.get('product').hasError('required') && isSubmit"  class="text-danger" >
                        Please select product.
                      </span>
                                </div>
                            </div>

                            <!-- <label class="col-md-4 textalignright" *ngIf="editForm.value.discountTypes=== 'ASSIGNED_TO_BRAND'">Brand Name

                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" *ngIf="editForm.value.discountTypes === 'ASSIGNED_TO_BRAND'" >
                                <div class="form-group"  >
                                    <select class="form-control" formControlName='brand'>
                                        <option value="">Select Brand</option>
                                        <option [value]="item.brandName" *ngFor="let item of branddata" selected>{{item.brandName | titlecase}}</option>
                                    </select>
                                    <div *ngIf="brand.errors && (brand.dirty || brand.touched)" class="text-danger">
                                        <span *ngIf="brand.errors.required">
                                            *Please enter  brand name
                                        </span>
                                        </div>
                                </div>
                            </div> -->
                            <label class="col-md-4 textalignright " > Percentage
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" >
                                <div class="form-group">
                         <input type="text" class="form-control"  placeholder="Enter percentage"
                             formControlName="percentage">
                         <span *ngIf="editForm.get('percentage').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter Percentage.
                      </span>
                         
                                </div>

                            </div>
                            
                           <label class="col-md-4 textalignright ">Minimum offer on Amount
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter  minimum range of amount"
                                        maxlength="340" formControlName="offerMinAmount">
                                        <span *ngIf="editForm.get('offerMinAmount').hasError('required') && isSubmit"  class="text-danger" >
                        Please select product.
                      </span>
                                </div>
                                
                            </div>
                            <label class="col-md-4 textalignright " >Maximum offer on Amount
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter" >
                                <div class="form-group">
                                    <input type="text" class="form-control" (keypress)="mainService.numberOnly($event)" placeholder="Maximum range of amount"
                                        maxlength="20" formControlName="offerMaxAmount">
                                      <span *ngIf="editForm.get('offerMaxAmount').hasError('required') && isSubmit"  class="text-danger" >
                             Please select product.
                          </span>
                                </div>
                           
                            </div>
                            
                           <label class="col-md-4 textalignright ">Quantity 
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter quantity"
                                        maxlength="340" formControlName="quantity">
                                        <span *ngIf="editForm.get('quantity').hasError('required') && isSubmit"  class="text-danger" >
                        Please enter minimum amount.
                      </span>
                                </div>
                                
                            </div>
                            <label class="col-md-4 textalignright ">Start Date
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="datetime-local" class="form-control" placeholder="Enter Date"
                                     formControlName="startDate">
                                      <span *ngIf="editForm.get('startDate').hasError('required') && isSubmit"  class="text-danger" >
                        Please select start date.
                      </span>
                                </div>
                                
                            </div>

                            <label class="col-md-4 textalignright ">End Date
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="datetime-local" class="form-control" placeholder="Enter Date "
                                      formControlName="endDate">
                                     <span *ngIf="editForm.get('endDate').hasError('required') && isSubmit"  class="text-danger" >
                        Please select end date.
                      </span>
                                </div>
                             
                            </div>
                            <label class="col-md-4 textalignright ">Offer Discription
                                <span>:</span>
                            </label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <textarea class="form-control" cols="30" rows="5" formControlName="discription"
                                    placeholder="Enter discription"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>