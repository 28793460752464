
        <div class="add-store-block input-style view-label-align" style="width: 90%;">
            <div class="form-group row align-items-baseline">
                <label class="col-md-4 textalignright "> Name<span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.firstName +" "+ userData?.lastName|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Email<span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.email|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Contact Number <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.phoneNo|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Gender <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.gender|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Country <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.country|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">State <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.state|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">City <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.city|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Zip Code <span>:</span></label>
                <div class="col-md-8 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.zipCode|| '--'}}</p>
                    </div>
                </div>
            </div>
        </div>

