<div>
    <main class="middle-content">
       <div class="page_title_block1">
            <h1 class="page_title float-left">Edit testimonial</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/testimonial">Back</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save"  (click)="updateTestimonial()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Update
                    </button>
               </div>
       </div>       
        <div class="content-section">
            <div class="order-view  max-WT-700  setting-page">
                <div class="mb40">
                    <form [formGroup]="addForm">
                        <div class="add-store-block input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright "> Name <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">

                                        <input type="text" class="form-control " placeholder="Enter Category Name"
                                            style="text-transform: capitalize;" formControlName="name">
                                        <div *ngIf="name.errors &&(name.touched|| name.dirty)" class="text-danger">
                                            <span *ngIf="name.errors.required">
                                                *Please enter Categories Name
                                            </span>

                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div class="row justify-content-end">
                                <div class="col-md-6 text-right">
                                    <b>Characters Remaining:{{maxChar}}</b>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">Description <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">

                                        <textarea maxlength="300" class="form-control" (input)="charCount()" formControlName="description" placeholder="Description"  cols="30" rows="6"></textarea>
                                        <div *ngIf="description.errors &&(description.touched|| description.dirty)" class="text-danger">
                                            <span *ngIf="name.errors.required">
                                                *Please enter description
                                            </span>

                                        </div>

                                    </div>
                                </div>


                            </div>






                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Upload Image <span>:</span>
                                </label>

                                <div class="col-lg-8">





                                    <p style="margin-top: 4px;">
                                        <input #file type="file" (change)="getImage($event)" accept='image/*'
                                            accept=".jpeg,.png" />
                                        
                                           
                                                
                                            
                                           
                                                <i class="fa fa-question" data-tooltip ></i>
                                           
                                    </p>
                                </div>



                            </div>
                            <div class="row justify-content-end" *ngIf="imgSrc">
                                <div class="col-md-6 text-left">
                                    <img [src]=" imgSrc || 'assets/img/dummy.png'" alt="" style="width: 50%; border-radius: 50%;" />
                                </div>
                            </div>
                        </div>
                    </form>

                </div>



            </div>
        </div>
    </main>
</div>