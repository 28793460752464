    <main class="middle-content">
                <div class="page_title_block1">
              <h1 class="page_title float-left"> View Brand</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/brand">Back to brand list</a>
                </small>
             </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="mb40">
                    <div class="admin-profile">
                        <h4 class="mb20 text-center">View Brand</h4>
                    </div>
                    
                    <div class="add-store-block input-style mb20 mt20 ">
                        
                        
                        <div class="sheet">
                            <label class="textaligncenter labelone">Brand Name</label>
                            <label class="">:</label>
                            <div class="textaligncenter">
                            <p>{{brandData?.brandName || '--'}}</p>
                            </div>
                        </div>
                        
                        <!-- <div class="sheet">
                            <label class="textaligncenter labelone ">Brand Description</label>
                            <label class="">:</label>
                            <div class="textaligncenter">
                            <p>{{brandData?.brandDescription || '--'}}</p>
                            </div>
                        </div>
                        <div class="sheet">
                            <label class="textaligncenter labelone ">Brand Image</label>
                            <label class="">:</label>
                            <div class="textaligncenter">
                                <img  [src]="brandData?.brandImage" alt="Brand Image" width = "30%" />
                            </div>
                        </div> -->
                    </div>
       
                </div>
            </div>
        </div>
    </main>


