<main class="middle-content">
         <div class="page_title_block1">
            <h1 class="page_title float-left"> Edit Contact Us</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" [routerLink]="['/statics-content']">Back</a>
                </small>
   
                <div  class="float-right ">
               <!--   <button style="margin-right:6px;" (click)="preview()" type="button" data-toggle="modal" data-target=" #examppreview3" name="save"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Preview
                  </button> -->
                 <!--    <button style="margin-right: 5px;" type="submit" name="save"  (click)="updateStatic('UNPUBLISHED')"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Draft
                   </button> -->
                    <button type="submit" name="save"  (click)="submit('PUBLISHED')"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Save
               </button>
         </div>
       </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
            	<form [formGroup]="formContactus" >
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                         <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Page 
                                Name</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text" disabled  value='Contact Us' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>
                                 <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Address</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                	<textarea placeholder='Type here..' class="form-control common-textarea"  formControlName="address" rows="5"  ></textarea>

                                </div>
                            </div>
                        </div>
                              <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Phone Number</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="number"  formControlName="phoneNo"  placeholder='Phone Number' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>
                           <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Email</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text" formControlName="email"  placeholder='Email' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Other Email</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text" formControlName="otherEmail"   placeholder='Other Email' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Facebook</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text" formControlName="fbUrl"  placeholder='Facebook Url' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Instagram</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text" formControlName="instaUrl"  placeholder='Instagram Url' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Twitter</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text" formControlName="twitterUrl"  placeholder='Twitter url' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Linkedin </label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text"  formControlName="lindenUrl"  placeholder=' Linkedin Url' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Skype</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text"   formControlName="skypeUrl" placeholder='Skype Url' class="form-control" maxlength="30"
                                         >
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
            </div>
        </div>
    </main>


<!--  Preview popup For view Product Details -->

<div class="modal fade bd-example-modal-lg" id="examppreview3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog image-dialog modal-lg" role="document">
    <div class="modal-content" style="margin-top: -43px;">
 <app-preview-header></app-preview-header>
      <div class="modal-body bg-w height500">
        <form>
               <div class="add-store-block input-style mb20 mt20">
                        <div class="row " >
                                <div class="col-md-12 ">
                                <p class="description ptag" [innerHtml]="sanitizer.bypassSecurityTrustHtml(editorValue)"></p>
                                </div>
                            </div>
                     </div>
             </form>
         </div>
        <app-preview-footer></app-preview-footer> 
    </div>
  </div>
</div>
  


