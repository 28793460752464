<div >
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">ADD SUB CATEGORY</h1>
            <hr>

        </div>
        <div class="content-section">

            <div class="order-view  max-WT-700  setting-page">
                <div class="  mb40">
                    <form [formGroup]="addSubCategoryForm">
                        <div class="add-store-block input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">

                                <label class="col-md-4 textalignright ">Select Category
                                    <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">


                                        <div class="selecting">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <select class="form-control" formControlName="categoryName" name=""
                                                        id="">
                                                        <option class="form-control" [selected]="true" value=""
                                                            disabled>Category
                                                            Name</option>
                                                        <option class="form-control" *ngFor="let item of categoryList"
                                                            [value]="item.categoryName">{{item.categoryName}}</option>

                                                    </select>

                                                </div>
                                                <span *ngIf="categoryName.errors && categoryName.touched" class="error">
                                                    <span *ngIf="categoryName.errors.required">*Please Select Category
                                                        name.</span>
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <label class="col-md-4 textalignright ">Sub Category Name
                                    <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">


                                        <div class="">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <input class="form-control" type="text" style="text-transform: capitalize;"
                                                        
                                                        formControlName="subCategory" placeholder="Enter sub category">

                                                </div>
                                                <span
                                                    *ngIf="subCategory.errors && (subCategory.touched || subCategory.dirty)"
                                                    class="error">
                                                    <span *ngIf="subCategory.errors.required">*Please enter Category
                                                        name.</span>
                                                  
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <label class="col-md-4 textalignright">Upload Image
                                    <span>:</span>
                                </label>

                                <div class="col-lg-8">




                                    <p style="margin-top: 4px;">
                                        <input #file type="file" accept='image/*' 
                                            accept=".jpeg,.png" />
                                        <img [src]=" 'assets/img/dummy.png'" alt=""
                                            style="width: 14%; border: 1px solid black;" />

                                    </p>


                                    <div class="mt40 mb40">
                                        <button class="btn btn-large  max-WT-150 btn-secondary"
                                            routerLink="/sub-category-list">Cancel</button>
                                        <button class="btn btn-large  max-WT-150 btn-primary ml-2"
                                            (click)="subAddApi()">Submit</button>
                                    </div>

                                </div>




                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</div>