import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {

  date: any;
  date2: any = new Date();
  catId: any;
  categoryList: any;
  editForm: FormGroup;
    imgSrc : any
  constructor(private activatedRouting: ActivatedRoute, private mainservice: MainService, private router: Router) {
    this.activatedRouting.queryParams.subscribe((res: any) => {
      this.catId = res.id
    })
   }



  ngOnInit() {
    
    this.getCatId()
    this.editFormApi()
  }
  editFormApi() {
    this.editForm = new FormGroup({

      name: new FormControl("", [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]),
      image: new FormControl("", [Validators.required]),

    })
  }

  get name() {
    return this.editForm.get('name')
  }
  get image() {
    return this.editForm.get('image')
  }
  getCatId() {
    let url = "product/view-category-By-Id?categoryId="+this.catId
    this.mainservice.showSpinner()
    this.mainservice.postApi(url,{},1).subscribe((res)=>{
       if(res.status ==200){
         
         this.editForm.patchValue({
          name : res.data.categoryName
         })
         this.imgSrc = res.data.categoryImage
         this.mainservice.hideSpinner()
         this.mainservice.successToast(res.message)
       }
       else{
         this.mainservice.hideSpinner()
         this.mainservice.errorToast(res.mesage)
       }
    },(err)=>{
     this.mainservice.hideSpinner()
     this.mainservice.errorToast(err)
    })
   }
 
  editCategory() {
    if(this.imgSrc == "assets/loader/1488 (2).gif" ){
      this.mainservice.infoToast("Please wait while uplaoding category image.")
      return
    }
    let url = "product/edit-category"
    const body = {
      "categoryId": this.catId,
      "categoryImage": this.imgSrc,
      "categoryName": this.editForm.value.name
    }
    this.mainservice.showSpinner()
    this.mainservice.postApi(url,body,1).subscribe((res)=>{
      if(res.status == 200){
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res.message)
        this.router.navigate(['/category-list'])
      }
      else{
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res.message)
      }
    },(err)=>{
      this.mainservice.hideSpinner()
        this.mainservice.errorToast(err)
    })
   
  }
  getImage(event){
    var img = event.target.files[0]
    
    this.uploadImage(img)
   }
  
    uploadImage(img){
      let endUrl = "static/upload-file"
      this.imgSrc ="assets/loader/1488 (2).gif"
      var fb = new FormData()
      fb.append('file',img)
      this.mainservice.uploadFile(endUrl,fb).subscribe((res)=>{
        if(res.status == 200){
          this.imgSrc = res.data
        }
        else{
          this.mainservice.errorToast("something went wrong while uploading image")
        }
      })
    }
  

}
