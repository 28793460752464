<div class="outer-box">

    <div class="global-table no-radius p0">
        <div class="tab-content1">


            <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                    aria-describedby="user management list">
                    <thead>
                        <tr class="no_wrap_th text-center">
                            <th scope="col" class="Sorting-img">S No.</th>
                            <th scope="col" class="Sorting-img">Order Id</th>
                            <!-- <th scope="col" class="Sorting-img">Order Item</th> -->
                            <th scope="col" class="Sorting-img">Order Quantity</th>
                            <th scope="col" class="Sorting-img">Order Price</th>
                            <th scope="col" class="Sorting-img">Date & Time</th>
                            <th scope="col" class="Sorting-img">Payment Method</th>
                            <th scope="col" class="Sorting-img">Order Status</th>
                           

                            
                            <th scope="col">Actions</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center"
                            *ngFor="let i of orderList| paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems} ; let item = index">
                            <td>{{itemPerPage * (currentPage - 1) + item+1}}</td>
                            <td class="content">{{i?.product.buyOrderId || "--"}}</td>
                            <!-- <td class="content">{{i?.productName || "--"}}</td> -->
                            <td class="content">{{i?.product.totalquantity|| "--"}}</td>
                            <td class="content">{{i?.product.amount|| "--"}}</td>
                            <td class="content">{{i?.product.createTime || "--"}}</td>
                            <td class="content">{{(i?.product.paymentMethod) || "--"}}</td>
                            <td class="content">{{i?.product.orderStatus || "--"}}</td>
                            
                            <td class="justify-content-center d-flex">

                                <button class="btn btn-info ml-2 bt-margin"
                                    (click)="viewOrder(i?.product.categoryId, i?.product.productId, i?.product.shippingAddressId,i?.product.buyOrderId,i?.product.ratingId,i?.product.totalquantity)"> <em class="fa fa-eye"
                                        aria-hidden="true"></em></button>

                            </td>
                            <!-- <td class="justify-content-center d-flex">
                                <button class="btn btn-info ml-2 bt-margin" (click)="viewTransaction(i?._id)"
                                    title="view">
                                    <i class="fa fa-eye" aria-hidden="true" style="color:white"></i></button>


                            </td> -->
                        </tr>

                    </tbody>
                    <tbody>

                        <tr *ngIf="orderList == 0">
                            <td class="table-no-record" colspan="8">No Record Found</td>
                        </tr>
                    </tbody>
                </table>
                <div class="custom-pagination mt-2 text-align-end">
                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

</div>