import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-sub-category',
  templateUrl: './add-sub-category.component.html',
  styleUrls: ['./add-sub-category.component.css']
})
export class AddSubCategoryComponent implements OnInit {

  addSubCategoryForm: FormGroup;

  categoryList : any = [
    {categoryName :"E-V Charger"},
    {categoryName :"eVehicle"}
    ]

  
  constructor(private service: MainService, private router: Router) { }
 






  ngOnInit() {
    this.addSubCategory()
    this.listApi()
  }
  addSubCategory() {

    this.addSubCategoryForm = new FormGroup({
      categoryName: new FormControl("", [Validators.required]),
      subCategory: new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z]*')]),
      cateId: new FormControl(null, [Validators.required])
    })
  }

  get categoryName() { return this.addSubCategoryForm.get('categoryName') }
  get subCategory() { return this.addSubCategoryForm.get('subCategory') }

  listApi() {
    
  }

  subAddApi() {

    
  }

  

}
