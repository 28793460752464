<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">SUB CATEGORY</h1>
        <hr>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="shadow p-3 mb-5 bg-white rounded">
                        <div class="table-responsive">

                            <table class="table table-striped table-bordered detail-view"
                                aria-describedby="user management list">

                                <tbody>
                                    <th scope="" colspan="2" style="font-size: large;">
                                        <!-- Category detail : Motors
 -->
                                    </th>
                                    <tr class="no_wrap_th">
                                        <td style="width: 25%;" class="Sorting-img"><strong> Id</strong></td>
                                        <td class="content">{{subcategory?.categoryId}}</td>
                                    </tr>
                                    <tr class="no_wrap_th">
                                        <td class="Sorting-img"> <strong> Category Name</strong></td>
                                        <td class="content">{{subcategory?.categoryName}} </td>
                                    </tr>
                                    <tr class="no_wrap_th">
                                        <td class="Sorting-img"> <strong> Sub Category Image</strong></td>
                                        <td class="content"><img src={{subcategory?.subCategoryPic}} alt="N/A" style="width: 143px;"></td>
                                    </tr>
                                    <tr class="no_wrap_th">
                                        <td class="Sorting-img"><strong> Sub Category Name</strong></td>
                                        <td class="content">{{subcategory?.subCategoryName}}</td>
                                    </tr>
                                    <tr class="no_wrap_th">

                                        <td class="Sorting-img"><strong>Created At</strong></td>
                                        <td class="content">{{(subcategory?.createdAt )}}</td>

                                    </tr>
                                    <tr class="no_wrap_th">

                                        <td class="Sorting-img"><strong>Status</strong></td>
                                        <td class="content">{{subcategory?.status}}</td>

                                    </tr>
                                </tbody>
                                <tbody>


                                    <p class="mt40" style="display: flex;">
                              
                                        <button type="submit" class="btn btn-large  max-WT-150 btn-primary ml-2"
                                          routerLink="/sub-category-list"  >Back</button>
                                    </p>





                                    <tr *ngIf="false">
                                        <td class="table-no-record" colspan="5">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
