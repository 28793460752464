import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $

@Component({
  selector: 'app-subcategory-list',
  templateUrl: './subcategory-list.component.html',
  styleUrls: ['./subcategory-list.component.css']
})
export class SubcategoryListComponent implements OnInit {

  
  total: any
  itemPerPage = 5
  currentPage = 1

  subCategory : any = [
{
  _id : 1,
  categoryName : 'E-V Charger',
  subCategoryName : 'eCar Charger',
  subCategoryPic : 'assets/Product image/chargerImage-1.jpeg',
  createdAt  : '22/02/2021',
  status : 'ACTIVE'
},
{
  _id : 1,
  categoryName : 'eVehicle',
  subCategoryName : 'E-scooters',
  subCategoryPic : 'assets/Product image/eScooty.jpeg',
  createdAt  : '02/05/2021',
  status : 'ACTIVE'
}
  ]
 
  constructor(private service: MainService, private router: Router) { }

  ngOnInit() {

    this.getApi()


  }




  /* integration of apis */

  getApi() {
   
  }

  search() {
   
  }
  reset() {
  
  }


  deleteFunction(id) {
   
    // $('#deleteModal').modal('show')
  }
  deleteModal() {
   
  }


  blockFunction(id, status) {
  
  }
  blockUser() {
 
  }
  pagination(event) {
    this.currentPage = event;
   
  }

  /*   navigation */
  viewsubCate(id) {
    this.router.navigate(['/view-sub-category'], { queryParams: { id: id } })
  }
  editCategory(id) {
    this.router.navigate(['/edit-sub-category'], { queryParams: { id: id } })
  }


}
