import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-brand-management',
  templateUrl: './brand-management.component.html',
  styleUrls: ['./brand-management.component.css']
})
export class BrandManagementComponent implements OnInit {
  searchForm: FormGroup;
 
  itemPerPage = 5;
    currentPage = 1; 
  limit: any = 5;
  total: any;
  items = [];
  brandList : any = []
  isSearched : boolean = false
  brandId : any
  
  constructor(private router: Router,private mainService : MainService) { }

  ngOnInit() {
    this.searchFormValidation()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      
    });
    this.getBrandList()
  }
  getBrandList(){
    let url = "product/get-Brand-Details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage + "&brandStatus=ACTIVE"
    const body = {

    }
    this.mainService.showSpinner()
    this.mainService.postApi(url,body,1).subscribe((res)=>{
      if(res.status == 200){
       
        this.brandList = res.data.activerDetails
        this.total = res.data.activeCount
        this.mainService.hideSpinner()
        // this.mainService.successToast(res.message)
      }
      else{
        this.mainService.hideSpinner()
        // this.mainService.errorToast(res.message)
      }
      
    },(err)=>{
      this.mainService.hideSpinner()
      // this.mainService.errorToast(err)
    })
  }

  search(){
    this.currentPage = 1
    this.isSearched = true
    let url = "product/get-Brand-Details?BrandName="+this.searchForm.value.search
    const body = {

    }
    
    // this.mainService.showSpinner()
    this.mainService.postApi(url,body,1).subscribe((res)=>{
      if(res.status == 200){
       
        this.brandList = res.data.getBrandDetails
        this.total = res.data.allCountDetails
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else if(res.status == 205){
        this.brandList =[]
        console.log(this.brandList.length)
   }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
      
    },(err)=>{
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })
  }
reset(){
  if(!this.isSearched){
    return
  }
  this.getBrandList()
  this.searchForm.reset()
}
  
  viewBrand(id){
     this.router.navigate(['view-brand'],{queryParams : {id : id}})
  }
  editBrand(id){
    this.router.navigate(['edit-brand'],{queryParams : {id : id}})
  }
  getBrandId(id){
    this.brandId = id
  }
  deleteBarand(){

    let url = "product/delete-brand?brandId="+this.brandId
    const body = {

    }
    this.mainService.showSpinner()
    this.mainService.deleteApi(url,body,1).subscribe((res)=>{
      if(res.status == 200){
       
        
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
        this.getBrandList()
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
      
    },(err)=>{
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })

  }
  showModal(){
    // alert('call')
    // $('#BlockModal').modal('show')
  }
  pagination(event){
    this.currentPage = event
    this.getBrandList()
  }
}
