import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.css']
})
export class AddBrandComponent implements OnInit {
  imgSrc: any
  editProfileForm: FormGroup;
  categoryList: any = []
  categoryID: any;
  categoryName: any;
  productList: any = [];

  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit(): void {
    this.editProfileForm = new FormGroup({
      'image': new FormControl('', [Validators.required]),
      "brandName": new FormControl('', Validators.required),
      "category": new FormControl(''),
      "subCategory": new FormControl(''),
      "description": new FormControl('', Validators.required),

    });
    this.getCategoryList()
  }
  
  ValidateFileUpload(event) {

  }
  getCategoryList() {
    let url = "product/getAllCategoryList"
    this.mainService.showSpinner()
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {

        this.categoryList = res.data[0].categoryDetails
        console.log(this.categoryList)
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    }, (err) => {
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })


  }
  getProductList(categoryId) {
    // const categoryId=$event.target.value
    let url = "product/product-by-category-id?categoryId=" + categoryId
    this.mainService.showSpinner()
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {

        this.productList = res.data
        console.log(this.categoryList)
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    }, (err) => {
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })
  }
  addBrand() {
    let url = "product/add-Brand"
    const data = {
      "brandDescription": this.editProfileForm.value.description,

      "brandImage": this.imgSrc,
      "brandName": this.editProfileForm.value.brandName,
      "brandStatus": "ACTIVE",

      "productId": 0,

    }
    console.log(data);


    this.mainService.showSpinner()
    this.mainService.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    }, (err) => {
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })
  }
  getImage(event) {
    var img = event.target.files[0]

    this.uploadImage(img)
  }

  uploadImage(img) {
    let endUrl = "static/upload-file"
    this.imgSrc = "assets/loader/1488 (2).gif"
    var fb = new FormData()
    fb.append('file', img)
    this.mainService.uploadFile(endUrl, fb).subscribe((res) => {
      if (res.status == 200) {
        this.imgSrc = res.data
      }
      else {
        this.mainService.errorToast("something went wrong while uploading image")
      }
    })
  }
}
