import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-sub-category',
  templateUrl: './edit-sub-category.component.html',
  styleUrls: ['./edit-sub-category.component.css']
})
export class EditSubCategoryComponent implements OnInit {

  
  editForm: FormGroup;
  subID: any;
  subcategory: any;
  subCatName: any;

  constructor(private mainService:MainService,
    private activatedRoute:ActivatedRoute,
    private router:Router) { 
      this.activatedRoute.queryParams.subscribe((res:any)=>{
        this.subID = res.id
        
      })
    }


  ngOnInit() {
   
   this.editFormGroup()
    
  }
  editFormGroup(){  
    this.editForm = new FormGroup({
      categoryName : new FormControl(this.subCatName),
      subCategoryName : new FormControl(null,[Validators.required,Validators.pattern('[A-za-z]*')]),
    uploadImage: new FormControl('',[Validators.required,]),
  /*   jobCategory : new FormControl('',[Validators.required,]), */
  })}

  get categoryName(){
    return this.editForm.get('categoryName')
  }
  get subCategoryName(){
    return this.editForm.get('subCategoryName')
  }
  get uploadImage(){
    return this.editForm.get('uploadImage')
  }
               
  viewApi(){
    
  }
                                /* Edit value */
  editValueApi(){
   
  }                       

  

}
