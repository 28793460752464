import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.css','./add-banner.component.scss']
})
export class AddBannerComponent implements OnInit {
  addBannerForm: FormGroup;
  imgSrc : any ='assets/Product image/default-banner'
  imageUrl: any;
  marked = false;
  defaultImage:any
  bannerList:any =[]
  indexList:any =[]
  constructor(public sanitizer: DomSanitizer,public mainService: MainService, private router: Router) { }

  ngOnInit(): void {
   this.bannerList =[{name:'Home'},{name:'Offer'},{name:'Other'}]
  this.defaultImage= 'https://whitepaper-development.mobiloitte.org/images/scooter.png'
   this.indexList =[{id:'1',name:'Top'},{id:'2',name:'Bottom'},{id:'3',name:'Middle-Left'},{id:'4',name:'Middle-Right'}]

    this.addBannerForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'image' : new FormControl(''),
      "description": new FormControl(''),
      "isActive" : new FormControl(true),
      "indexNumber": new FormControl('', Validators.required),
      "pageType": new FormControl('', Validators.required),

    });
  }
  selectBanner(){

  }
  addBanner(){
    if(this.imgSrc == "assets/loader/1488 (2).gif"){
      this.mainService.infoToast("Please wait while Banner image is uploading.")
      return
    }
    let status = ""
    if (this.addBannerForm.value.isActive) {
      status = "ACTIVE"
    } else {
      status = "DISACTIVE"
    }
    let url = "static/save-banner"
    const data = {
      "description": this.addBannerForm.value.description,
      "image": this.imgSrc,
      "title": this.addBannerForm.value.title,
      "bannerStatus" : status,
      "indexNumber": this.addBannerForm.value.indexNumber,
      "pageType": this.addBannerForm.value.pageType,
    }
    console.log("isActive",data.bannerStatus);

    this.mainService.showSpinner()
      this.mainService.postApi(url,data,1).subscribe((res)=>{
        if(res.status == 200){
          this.mainService.hideSpinner()
          this.mainService.successToast(res.message)
          this.router.navigate(['/banner-management'])
        }
        else{
          this.mainService.hideSpinner()
          this.mainService.errorToast(res.message)
        }
      })
    }
  //  cropper start 
  imgChangeEvent(event) {
      if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpg') {
         this.openCropper=true
         this.imageChangedEvent = event;
      }
      else {
        // this.server.errorNotification('Please upload only png ,jpg ,jpeg.')
      }
    
  }
  imageCropped(event: ImageCroppedEvent) {
  //console.log(event)
  this.croppedImage = event.base64;
  this.xaxis=event.imagePosition.x2 - event.imagePosition.x1
  this.yaxis=event.imagePosition.y2 - event.imagePosition.y1
}
  imageChangedEvent: any =  '';
  croppedImage: any = '';
  xaxis: number;
  yaxis: number;
  openCropper: boolean=false;
checkPixcel(){
  if(this.xaxis > 600 &&  this.yaxis < 500){
    return true
  }
}
    DataURIToBlob(imgBase64) {
    const splitDataURI = imgBase64.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString });
    }
 // const num = 8;
     randomNameGenerator = (num) => {
       let res = '';
       for(let i = 0; i < num; i++){
          const random = Math.floor(Math.random() * 27);
          res += String.fromCharCode(97 + random);
       };
       return res+'.jpg';
    };
   saveBaseTofile(){
      var imageBase64 = this.croppedImage
     const file = this.DataURIToBlob(imageBase64)
     const formData = new FormData();
     formData.append('file', file, this.randomNameGenerator(8))
      let imageFile;
    if(this.imageChangedEvent && this.imageChangedEvent.target && this.imageChangedEvent.target.files && this.imageChangedEvent.target.files[0] && this.imageChangedEvent.target.files[0]){
       imageFile =this.imageChangedEvent.target.files[0]
    }
      this.sendFormData(formData)
    }
  ValidateFileUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
     this.imageUrl =  file
    }

   this.uploadProfilePic()
  }
   sendFormData(filedata) {
     let url = "static/upload-file"
    this.imgSrc = "assets/loader/1488 (2).gif"
   this.mainService.successToast('Please wait image uploading.')
    this.mainService.uploadFile(url, filedata).subscribe(resp => {
      if (resp['status'] == 200) {
           this.imgSrc = resp.data
           this.imageUrl = resp.data
           this.openCropper=false
      } else {
      }
    }, error => {

    })
  }
  uploadProfilePic(){
    let url = "static/upload-file"
    this.imgSrc = "assets/loader/1488 (2).gif"
    const formData = new FormData();
    formData.append('file',  this.imageUrl);
    this.mainService.uploadFile(url,formData).subscribe((res)=>{
      if(res.status == 200){
        this.imgSrc = res.data
        this.imageUrl = res.data
      }
      else{
        this.mainService.errorToast("Can't Upload This File")
      }
    })

  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
  } 
    productData:any ={
           "description": '',
      "image": '',
      "title":'',
      "bannerStatus" : '',
      "indexNumber": '',
      "pageType": '',
    }
    productImageView:any=[]
    preview() {
      this.productImageView=[]
       const data = {
      "description": this.addBannerForm.value.description,
      "image": this.imgSrc,
      "title": this.addBannerForm.value.title,
      "bannerStatus" : status,
      "indexNumber": this.addBannerForm.value.indexNumber,
      "pageType": this.addBannerForm.value.pageType,
    }
     
         this.productData = data
        this.productImageView = data.image
        this.defaultImage =data.image
     }

     openpreview(){
       // window.open('http://localhost:2000/preview-banner','_blank');
       // this.router.navigate(['banner'])
     }
}
