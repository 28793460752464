<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Sub-category Management</h1>
        <hr>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                          
                                <div class="row form-group">

                                   <div class="col-md-3 pr-0">
                                    <div class="filter_search mb20 width100">

                                        <label class="d-flex align-items-center">Search:</label>
                                        <div class="input-group filter_search_group">

                                            <input type="text" class="form-control overflow-text"
                                                placeholder="Search by subCategories">
                                            <div class="input-group-append">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div class="col-md-1 user-lst" style="margin-top: 33px">
                                        <div class="two-btnline">
                                            <div class="text-left">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="search()">Search</button>&nbsp;
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-1 user-lst" style="margin-left: 20px;margin-top: 33px;">
                                        <div class="two-btnline">
                                            <div class="text-left">
                                                <button type="submit" class="btn  btn-theme cus-mr" (click)="reset()"
                                                    style="margin-left: 1rem;">Reset</button>

                                            </div>


                                        </div>

                                    </div>
                                    <div class="col-md-1 user-lst" style="margin-left: 20px;margin-top: 33px;">
                                        <div class="two-btnline">
                                            <div class="text-left">
                                                <button type="button" class="btn btn-theme  "
                                                    routerLink="/add-sub-category" style="margin-left: 84%;">Add
                                                    New
                                                </button>

                                            </div>


                                        </div>

                                    </div>

                                </div>


                          
                        </div>
                    </div>
                    <div class="table-responsive">

                        <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                            aria-describedby="user management list">
                            <thead>
                                <tr class="no_wrap_th text-center">
                                    <th scope="col" class="Sorting-img">S.No</th>
                                    <th scope="col" class="Sorting-img">Category Name</th>
                                    <th scope="col" class="Sorting-img">Sub Category Name</th>
                                    <th scope="col" class="Sorting-img">Sub Category Image</th>
                                    <th scope="col" class="Sorting-img">Created At</th>
                                    <th scope="col" class="Sorting-img">Status</th>
                                    <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center"
                                    *ngFor="let item of subCategory | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total}; let i = index ">

                                    <td>{{(currentPage -1) * itemPerPage + i + 1}}</td>
                                    <td class="content">{{item?.categoryName}}</td>
                                    <td class="content">{{item?.subCategoryName}}</td>
                                    <td class="content"><img src={{item?.subCategoryPic}} alt="N/A"
                                            style="width: 66px;"></td>

                                    <td class="content">{{item?.createdAt  }}</td>
                                    <td class="content">{{item?.status}}</td>
                                    <td class="justify-content-center d-flex">
                                        <button class="btn btn-info ml-2 bt-margin" title="view"
                                            (click)="viewsubCate(item?._id)">
                                            <em class="fa fa-eye" aria-hidden="true"></em></button>
                                        <button class="btn btn-info ml-2 bt-margin" title="edit"
                                            (click)="editCategory(item?._id)"><em class="fa fa-edit"
                                                aria-hidden="true"></em></button>
                                        <button *ngIf="item?.status == 'ACTIVE'" class="btn btn-green ml-2 bt-margin" data-toggle="modal" data-target="#blockModal"
                                            (click)="blockFunction(item?._id,'BLOCKED')" title="deactivate"><em class="fa fa-ban"
                                                aria-hidden="true"></em></button>
                                        <button class="btn btn-danger ml-2 bt-margin" *ngIf="item?.status == 'BLOCKED'" data-toggle="modal" data-target="#blockModal"
                                            (click)="blockFunction(item?._id,'ACTIVE')" title="activate"><em class="fa fa-ban"
                                                aria-hidden="true"></em></button>
                                        <button class="btn btn-danger ml-2 bt-margin" title="delete" data-toggle="modal" data-target="#deleteModal"
                                            (click)="deleteFunction(item?._id)"><em class="fa fa-trash"
                                                aria-hidden="true"></em></button>
                                    </td>
                                <tr *ngIf="total == 0">
                                    <td class="table-no-record" colspan="5">No Record Found</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="custom-pagination mt-2 text-align-end">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this sub category?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" (click)="deleteModal()"
                                        data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="blockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                 
                    <h5  class="modal-title d-inline-block">Block</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                
                                <p >Are you sure you want to
                                     block this subcategory?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" (click)="blockUser()" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
