import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";

@Component({
  selector: "app-add-sub-admin",
  templateUrl: "./add-sub-admin.component.html",
  styleUrls: ["./add-sub-admin.component.css"],
})
export class AddSubAdminComponent implements OnInit {
  editform: FormGroup;
  array: any = [];

  permissionArr: any = [
    "DASHBOARD_MANAGEMENT",
    "CUSTOMER_MANAGEMENT",
    "SUB_ADMIN_MANAGEMENT",
    "CATEGORY_MANAGEMENT",
    "PRODUCT_MANAGEMENT",
    "ATTRIBUTE_MANAGEMENT",
    "BRAND_MANAGEMENT",
    "DISCOUNT_MANAGEMENT",
    "BANNER_MANAGEMENT",
    "ORDER_MANAGEMENT",
    "TESTIMONIAL_MANAGEMENT",
    "STATIC_CONTENT_MANAGEMENT",
    "FAQ",
    "SUPPORT",
  ];
  privilage: any = [];

  name: any = [];
  check: any = [];

  index = 0;
  constructor(public service: MainService, private route: Router) {}

  ngOnInit() {
    for (let i of this.permissionArr) {
      this.name[this.index] = i.split("_").join(" ");
      this.index++;
    }
    this.editform = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/i),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
        ),
      ]),
    });
  }
  addSubAdmin() {
    let url = "account/admin/user-management/create-sub-admin";
    const body = {
      email: this.editform.value.email,
      firstName: this.editform.value.name,

      roleStatus: "SUBADMIN",
      phoneNo: this.editform.value.phone,

      password: this.editform.value.password,
      previlage: this.privilage,
    };
    this.service.showSpinner();
    this.service.postApi(url, body, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          this.service.successToast(res.message);
          this.route.navigate(["/subAdmin"]);
        } else {
          this.service.hideSpinner();
          this.service.errorToast(res.message);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.errorToast(err);
      }
    );
  }

  checkPerm(permName, ch) {
    console.log(permName, ch);
    if (ch) {
      this.privilage.push(permName);
    } else {
      this.privilage.splice(this.privilage.indexOf(permName), 1);
    }
    console.log(this.privilage);
  }
}
