import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  
  userName : any
  itemPerPage = 5;
  currentPage = 1; 
  limit: any = 5;
  total: any;
  items = [];
  id : any
  totalItems: any;
  orderList: any = [];
  constructor(private router : Router,private mainService : MainService,private activated : ActivatedRoute) { 
    this.activated.queryParams.subscribe((res)=>{
      this.id = res.id
    })
  }

  ngOnInit(){
    this.getOrderList()
  }
 
  getOrderList(){
    // http://182.72.203.247:4090/product/get-orderHistory-By-userId-admin?userId=65
    let url="product/get-orderHistory-By-userId-admin?userId="+this.id
   
    this.mainService.getApi(url , 1).subscribe((res)=>{
      if(res.status == 200){
        this.orderList = res.data
        this.totalItems = this.orderList.length
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })

  }
  viewOrder(id,productId,shippingAddressId,buyOrderId,ratingId,totalquantity){
    this.router.navigate(['/view-order'],{queryParams : {
      id : id,
      productId:productId,
      shippingAddressId:shippingAddressId,
      buyOrderId:buyOrderId,
      ratingId:ratingId,
      totalquantity:totalquantity
    }})
  }
  pagination(e){
    this.currentPage = e
  }
}
