<div >

    <!-- Header End -->


    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">VIEW KYC</h1>
 
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="outer-box" style="margin-top: -5%;">
                <!-- Gloabl Table Box Start -->
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="card mt-5" style="width: 80%;margin-left: 10%;">
                                <div class="card-header">
                                    KYC DETAILS
                                </div>
                                <div class="card-body">
                                    <div class="row ">
                                        <div class="col-md-6">
                                            <div class="row mt-2">
                                                <label class="col-md-4" style="font-weight: 600;">KYC ID </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6" style="font-weight: 200;">{{kycData[0]?.kycUserId || '--'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4" style="font-weight: 600;" >KYC Status </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6" style="font-weight: 200;">{{kycData[0]?.kycStatus || '--'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4" style="font-weight: 600;">Created At</label>
                                                <span class="col-md-1">:</span>
                                                <label
                                                    class="col-md-6" style="font-weight: 200;">{{(kycData[0]?.createdAt ) || "--"}}</label>
                                            </div>
                                        
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mt-2">
                                                <label class="col-md-4" style="font-weight: 600;">ID Document Type </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6" style="font-weight: 200;">{{kycData[0]?.IdType || '--'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4" style="font-weight: 600;">ID Number </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6" style="font-weight: 200;">{{kycData[0]?.idNumber || '--'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4" style="font-weight: 600;">Document Status </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6" style="font-weight: 200;">{{kycData[0]?.status || '--'}}</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 3%">
                                <div class="col" style="text-align: center;position: relative; left: 7%;">
                                    <img src="{{kycData[0]?.docFrontPage || 'assets/laptop image/No_Image_Available.jpg'}} "class="zoom" alt="" style="max-width: 60%;"
                                    data-toggle="modal"
                                    data-target="#showDoc" (click)="showDocument('Front',kycData[0]?.docFrontPage)"><br>
                                    <label>(Front)</label>
                                </div>
                                <div class="col" style="text-align: center;">
                                    <img src="{{kycData[0]?.docBackPage || 'assets/laptop image/No_Image_Available.jpg'}}" class="zoom" alt=""
                                        style="max-width: 60%;"  data-toggle="modal"
                                        data-target="#showDoc" (click)="showDocument('Back',kycData[0]?.docBackPage)"><br>
                                    <label>(Back)</label>
                                </div>
                                <!-- <div class="col" style="text-align: center;">
                                    <img src="{{kycData[0]?.selfie || 'assets/laptop image/No_Image_Available.jpg'}}" width="50%" class="zoom" alt=""
                                        style="max-width: 60%;margin-right: 40%;"><br>
                                    <label style="margin-right: 40%;">(Selfie)</label>
                                </div> -->
                            </div>
                        </div>
        
                        <div style="text-align: center; margin-top: 3%;margin-bottom:3% ;">
                            <div><button *ngIf="kycData[0]?.kycStatus == 'PENDING'" class="btn btn-theme ml-1" style="width: 15%" data-toggle="modal"
                                    data-target="#approveModal">Approve</button>
                                <button *ngIf= "kycData[0]?.kycStatus == 'PENDING'" class="btn btn-theme ml-1 btn-danger" style="width: 15%" data-toggle="modal"
                                    data-target="#reject">Reject</button>
                                    <button class="btn btn-theme ml-1" style="width: 15%"  (click)="backKyc()"
                                    >Back</button>
                      
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </main>
</div>
<!-- Wrapper End -->
<!-- approve_modal Start -->

<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to approve this document?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" (click)="approval()"  data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<div class="modal fade global-modal reset-modal" id="reject">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to reject this document?</p>
                                <textarea type="text" placeholder="Specify reason for rejection.." class="form-control"
                                  maxlength="50" rows="" cols="5"></textarea>
                                <div class="modal-btn-box" style="margin-top: 8%;">
                                    <button type="submit" class="btn btn-primary" (click)="rejectKyc()"  data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="showDoc">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                
                <div class="modal-inner-content">
                    <div class="">
                        <em class="fa fa-times"  data-dismiss="modal" style="float: right;font-size: x-large;"></em>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>{{docSide}} Image</p>
                               
                                <img [src]="img" alt="" style="width: 27rem;">
                                <!-- <div class="modal-btn-box" style="margin-top: 8%;">
                                    <button type="submit" class="btn btn-primary" (click)="rejectKyc()"  data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2"
                                        data-dismiss="modal">No</button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
