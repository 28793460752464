<main class="middle-content">
        <div class="page_title_block1">
            <h1 class="page_title float-left"> Add Subadmin</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/subAdmin">Back</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save" [disabled]="editform.invalid" (click)="addSubAdmin()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Save
                    </button>
               </div>
       </div>
    <div class="content-section">
        <div class="order-view  max-WT-700  setting-page mb-5">
            <div class="mb40">
                <form [formGroup]='editform'>
                    <div class="add-store-block input-style mb20 mt20">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-3 textalignright">Name</label>
                                    <span class="col-md-1">:</span>
                                    <div class="col-md-7 textaligncenter">
                                        <div class="form-group">
                                            <input type="text" formControlName="name" class="form-control"
                                                style="text-transform: capitalize;" placeholder="Enter name">
                                            <span class="text text-danger"
                                                *ngIf="editform.controls.name.touched && editform.controls.name.invalid && editform.controls.name.errors.required">*
                                                Name is Required</span>

                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-md-3 textalignright">Email</label>
                                    <span class="col-md-1">:</span>
                                    <div class="col-md-7 textaligncenter">
                                        <div class="form-group">
                                            <input type="text" formControlName="email" class="form-control"
                                                placeholder="Enter Email">
                                            <span class="text text-danger"
                                                *ngIf="editform.controls.email.touched && editform.controls.email.invalid && editform.controls.email.errors.required">*
                                                Email is Required</span>
                                            <span class="text text-danger"
                                                *ngIf="editform.controls.email.invalid && editform.controls.email.errors.email">*Please Enter Valid
                                                Email</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                        <label class="col-md-3 textalignright">Role</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <input type="text" class="form-control"> </div>
                                        </div>
                                    </div> -->
                            </div>




                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-3 textalignright">Phone No</label>
                                    <span class="col-md-1">:</span>
                                    <div class="col-md-7 textaligncenter">
                                        <div class="form-group">
                                            <input type="text" formControlName="phone" (keypress)="service.numberOnly($event)" class="form-control"
                                                placeholder="Enter mobile number" maxlength="18" minlength="8">
                                            <span class="text text-danger"
                                                *ngIf="editform.controls.phone.touched && editform.controls.phone.invalid && editform.controls.phone.errors.required">*
                                                Phone No is Required</span>
                                            <span class="text text-danger"
                                                *ngIf="editform.controls.phone.invalid && editform.controls.phone.errors.pattern">*Invalid
                                                Phone No</span>
                                        </div>
                                    </div>
                                    
                                </div>


                                <div class="row">
                                    <label class="col-md-3 textalignright">Password</label>
                                    <span class="col-md-1">:</span>
                                    <div class="col-md-7 textaligncenter">
                                        <div class="form-group">
                                            <input type="text" formControlName="password" class="form-control"
                                                placeholder="Enter password"  maxlength="18" minlength="8">
                                            <span class="text text-danger"
                                                *ngIf="editform.controls.password.touched && editform.controls.password.invalid && editform.controls.password.errors.required">*
                                                Password is Required</span>
                                            <span class="text text-danger"
                                                *ngIf="editform.controls.password.invalid && editform.controls.password.errors.pattern">*Password must contain atleast 8 characters including one digit and one special character.</span>
                                        </div>
                                    </div>
                                </div>
                                


                            </div>


                        </div>
                        <div class="text-center">
                            <h1 class="page_title">Permissions</h1>
                        </div>

                        <div class="row" style="margin-top: 3%;">

                            <div class="col-md-4" *ngFor="let item of permissionArr ; let i = index">
                                <label class="globalCheckBox" style="text-transform:capitalize;"> {{name[i] | lowercase}}
                                    <input type="checkbox" [(ngModel)]="check[i]" [ngModelOptions]="{standalone: true}"
                                        (click)="checkPerm(item,!check[i])">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                        </div>

<!--                         <div class="text-center mt40 mb40">
                            <button class="btn btn-large  max-WT-150 ml-1 btn-secondary"
                                routerLink="/subAdmin">Back</button>
                            <button class="btn btn-large  max-WT-150 ml-1 btn-primary" [disabled]="editform.invalid"
                                (click)="addSubAdmin()">Submit</button>
                        </div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>