import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { DomSanitizer } from "@angular/platform-browser";
declare const $: any;
import * as $ from 'jquery';
@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.css"],
})
export class AddProductComponent implements OnInit {
  addForm: FormGroup;
  proctImg: any = [];

  public editorValue: string = "";
  imageUrl: any = [];
  imgSrc: any = [];
  productImageDto: any = [];
  deleveryDatList: any = [];
  categoryList: any = [];
  brandList: any = [];
  index = 0;
  addCombination: any = [];
  variantProduct : any = []
  addMoreCombination() {
    if (this.index == 3) {
      this.mainService.warningToast("Cannot add more than 3 attribute");
      return;
    }
    this.addCombination.push(++this.index);
  }
  removeAttribute(i) {
    this.addCombination.splice(i,1);
    this.index--;
    this.attributeCategory[i] =""
this.childAttribute[i] = ""
    console.log(this.index);
  }
  constructor(
    public sanitizer: DomSanitizer,
    private route: Router,
    public mainService: MainService
  ) {}

  ngOnInit() { 
    this.addFormvalidation();
    this.getCategoryList();
    this.getBrandList();
    this.getAttribute()
    this.deleveryDatList = [
      { id: 1, name: "1-2 days" },
      { id: 2, name: "5-7 days" },
      { id: 3, name: "1 week" },
      { id: 4, name: "2 week" },
      { id: 5, name: "3-4 week" },
    ];
  }
  getBrandList() {
    let url = "product/getAllBrandList";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.brandList = res.data;
          this.mainService.hideSpinner();
        } else {
          this.mainService.hideSpinner();
        }
      },
      (err) => {
        this.mainService.hideSpinner();
      }
    );
  }
  addFormvalidation() {
    this.addForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      quantity: new FormControl("", [Validators.required]),
      category: new FormControl("",[Validators.required]),
      editorValue: new FormControl("", Validators.required),
      serial: new FormControl("", Validators.required),
      skuNumber: new FormControl("", Validators.required),
      shortDescription: new FormControl("", Validators.required),
      weight: new FormControl(0),
      length: new FormControl(0),
      width: new FormControl(0),
      height: new FormControl(0),
      freeShipping: new FormControl(false),
      shippingCharge: new FormControl(0),
      deliveryDay: new FormControl(""),
      brandId: new FormControl(""),
    });
  }

  get name() {
    return this.addForm.get("name");
  }

  get category() {
    return this.addForm.get("category");
  }

  get price() {
    return this.addForm.get("price");
  }
  get serial() {
    return this.addForm.get("serial");
  }
  get shortDescription(){
    return this.addForm.get("shortDescription");

  } get skuNumber(){
    return this.addForm.get("skuNumber");

  }

  getCategoryList() {
    let url = "product/getAllCategoryList";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.categoryList = res.data[0].categoryDetails;
        this.mainService.hideSpinner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }

  isShipRate(){
    let isValid =true
    if(this.addForm.value.freeShipping){
       isValid= true
        }else{
          if(this.addForm.value.shippingCharge===0){
            isValid= false
          }else{
            isValid= true
          }
        }
   return isValid 
  }
isSubmit:boolean = false
  addProduct() {
    this.isSubmit=true
    let url =
      "product/add-product?categoryId=" +
     this.addForm.value.category.categoryId;
      if(this.proctImg.length>0){
        if(this.variantProduct.length>0){
           if(this.isShipRate()){
    const data = {
      price: this.variantProduct[0].variantPrice,
      productDescription: this.editorValue,
      productImageDto: this.productImageDto,
      quantity :JSON.stringify(this.addForm.value.quantity),
      productName: this.addForm.value.name,
      skuNumber: this.addForm.value.skuNumber,
      shortDescription: this.addForm.value.shortDescription,
      productStatus: "ACTIVE",
      serialNumber: this.addForm.value.serial,
      brandId: this.addForm.value.brandId,
      "productAttributeDto" : this.variantProduct,
      productWeight: this.addForm.value.weight,
      productLength: this.addForm.value.length,
      productWidth: this.addForm.value.width,
      productHeight: this.addForm.value.height,
      freeShipping: this.addForm.value.freeShipping,
      additionalCharge: this.addForm.value.shippingCharge,
      deliveryDays: this.addForm.value.deliveryDay,
      productDefaultImage: this.byDefaultImageUrl,
    };
    
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
        this.route.navigate(["/product-list"]);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }else{
     this.mainService.warningToast('Please enter shipping charge')
   }
  }
  else{
     this.mainService.warningToast('Please add price');
  }
  }else{
     this.mainService.warningToast('Please add product image');
  }
  
  }

  checkIsFree(isChecked){
    if(isChecked){
       this.addForm.controls.shippingCharge.setValue(0)
    }

  }

  productImage(event) {
    this.proctImg = [];
    var img = [];
    img = event.target.files;
    console.log(img);
    if (img.length > 5) {
      this.mainService.infoToast("Please select maximum 5 image");
      return;
    }
    for (let index = 0; index < img.length; index++) {
      const file = event.target.files[index];
      this.imageUrl = file;
      this.proctImg[index] = this.uploadProfilePic(img, index);
    }

    console.log(this.productImageDto);
  }
  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  deleteImage(data, i) {
    this.proctImg.forEach((element, index) => {
      if (data.image === element.image) {
        this.proctImg.splice(i, 1);
      }
    });
    if (this.productImageDto.length > 0) {
      let deletedaimage = this.productImageDto.findIndex(
        (objectModified) => objectModified.productImage === data.image
      );
      if (deletedaimage !== -1) {
        this.productImageDto.splice(deletedaimage, 1);
      }
    }
  }
  byDefaultImageUrl: any = "";

  public selectDefaultImage(data, i) {
    this.byDefaultImageUrl = data.image;
    this.proctImg.forEach((element, index) => {
      if (index == i) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    // this.index = i
    //console.log("index::::", i)
  }
  categoryIdmm: any = 0;
  onSelectName(result) {
    this.categoryIdmm = result.id;
  }
  uploadProfilePic(img, i) {
    let url = "static/upload-file";
    this.proctImg[i] = "assets/loader/1488 (2).gif";
    const formData = new FormData();
    formData.append("file", this.imageUrl);
    this.mainService.uploadFile(url, formData).subscribe((res) => {
      if (res.status == 200) {
        this.imgSrc = res.data;
        //{ 'image': resp['data'], 'selected': false, }

        //this.proctImg[i] = res.data
        this.proctImg[i] = {
          image: res.data,
          selected: false,
        };
        this.productImageDto[i] = {
          productImage: res.data,
          serialNumber: this.addForm.value.serial,
        };
      } else {
        this.mainService.errorToast("Can't Upload This File");
      }
    });
  }
  productData: any;
  defaultImage: any;
  description: any;
  productImageView: any = [];
  getViewProduct() {
    this.isSubmit=true
    this.productImageView = [];
     if(this.addForm.valid){
      if(this.proctImg.length>0){
        if(this.variantProduct.length>0){

    const data = {
      price: this.variantProduct[0].variantPrice,
      productDescription: String(this.editorValue),
      productImageDto: this.productImageDto,
      productName: this.addForm.value.name,
      productStatus: "ACTIVE",
      serialNumber: this.addForm.value.serial,
      brandId: this.addForm.value.brandId,
      productWeight: this.addForm.value.weight,
      category: this.addForm.value.category.categoryName,
      skuNumber: this.addForm.value.skuNumber,
      shortDescription: this.addForm.value.shortDescription,
      productLength: this.addForm.value.length,
      productWidth: this.addForm.value.width,
      productHeight: this.addForm.value.height,
      freeShipping: this.addForm.value.freeShipping,
      additionalCharge: this.addForm.value.shippingCharge,
      deliveryDays: this.addForm.value.deliveryDay,
      productDefaultImage: this.byDefaultImageUrl
  }
        this.productData = data
        this.productImageView = this.proctImg
        this.defaultImage = this.byDefaultImageUrl
        this.description = data.productDescription
     }
  else{
     this.mainService.warningToast('Please add price');
  }
  }else{
     this.mainService.warningToast('Please add product image');
  }
  }else{
    
 this.mainService.warningToast('Please add all required fields');
  }

  }
  change(item) {
    this.defaultImage = item;
  }

  attributeCategory: any = [];
  childAttribute: any = [];
  indexL = 0
  addPrice(price) {
    this.isEnable = true
    let parent = []
    let child = []
    let parentName
    let childName
    parent = this.attributeCategory.filter((res)=>{
      return res != ""
    })
    child = this.childAttribute.filter((res)=>{
      return res != ""
    })
    let combination =[]
      combination[this.indexL] = {
        "attribute": parent[0] ? parent[0].split('^&%')[1] : '',
        "attribute1":  parent[1] ? parent[1].split('^&%')[1] : '',
        "attribute2":  parent[2] ? parent[2].split('^&%')[1] : '',
        "combinationId": this.indexL+1,
        "childAttribute": child[0]?child[0] :'',
        "childAttribute1": child[1]?child[1] :'',
        "childAttribute2": child[2]?child[2] :'',
        "productAttributeStatus": "ACTIVE",       
        "variantPrice": price
        }
    this.variantProduct[this.indexL] = combination[this.indexL]
    this.indexL++   
  }
  attrributeList: any = [];
  getAttribute() {
    let url = "product/view-attribute-by-list";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.attrributeList = res.data
        this.mainService.hideSpinner();
        //this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      }
    });
  }
  childProductAttribute0 : any = []
  childProductAttribute1 : any = []

  childProductAttribute2 : any = []

  childProductAttribute3 : any = []


  getchildAttribute(i,ind) {
    console.log(ind);
    
    let url = "product/view-child-attribute-by-list-by-attribute-id?attributeId="+this.attributeCategory[i].split('^&%')[0];
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        if(ind == 0){
          this.childProductAttribute0 = res.data[0].child_attribute

        }else if(ind == 1){
          this.childProductAttribute1 = res.data[0].child_attribute

        }
        else if(ind == 2){
          this.childProductAttribute2 = res.data[0].child_attribute

        }
        else{
          this.childProductAttribute3 = res.data[0].child_attribute

        }
        // this.childProductAttribute = res.data[0].child_attribute
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      }
    });
  }
  isEnable : boolean = true
  isDisabled(price){
    if(!price){
      return
    }

    this.isEnable = false
  }
  removeVariant(i) {
    this.variantProduct.splice(i, 1);
    this.indexL--
  }
  priceL : any
showVariantPrice : boolean =false
getItem(e){
  console.log(e.variantPrice);
  this.showVariantPrice = true
  this.priceL = e.variantPrice
  
}
}
