import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { RouteingUrlService } from "src/app/service/routeing-url.service";
@Component({
  selector: "app-view-order-management",
  templateUrl: "./view-order-management.component.html",
  styleUrls: ["./view-order-management.component.css"],
})
export class ViewOrderManagementComponent implements OnInit {
  orderId: number;
  OrderData: any;
  OrderDataList: any;
  productId: any;
  shippingAddressId: any;
  buyOrderId: any;
  buyOrderData: any;
  CategoryData: any;
  ratingId: any;
  totalquantity: any;
  count: any;
  // pagination
  currentPage = 1;
  total: any;
  itemPerPage = 5;
  // pagination end
  productDetail: any = [];

  reponseParam: any;
  constructor(
    private router: Router,
    public mainService: MainService,
    private activatedRouting: ActivatedRoute,
    private backrouting: RouteingUrlService
  ) {
    this.activatedRouting.queryParams.subscribe((res: any) => {
      this.orderId = res.id;

      this.productId = res.productId;
      this.shippingAddressId = res.shippingAddressId;
      this.buyOrderId = res.buyOrderId;
      this.ratingId = res.ratingId;
      this.totalquantity = res.totalquantity;
      this.reponseParam = res;
    

    });
    if (localStorage.getItem("backUrl")) {
      localStorage.removeItem("backUrl");
    }
    if(localStorage.getItem("address")){
      localStorage.removeItem("address");
    }
  }

  ngOnInit(): void {
    this.getViewOrder();
   // this.getViewOrderList();
    this.getViewProductList();
    this.getCategoryList();
    this.getRatingList();
    this.getAddress();
    this.getBillingAddress()
    this.getProductByOrderID()
  }
  back() {
    this.router.navigate(["/order"]);
  }
  orderProduct:any = []
   addsum(arr){
    var sum =0;
    for(var z =0;z<arr.length;z++){
        sum+=arr[z].buyOrder.amount;
    }
    return sum;
}
   addShipping(arr){
    var sum =0;
    for(var z =0;z<arr.length;z++){
        sum+=arr[z].product[0].additionalCharge;
    }
    return sum;
}
  addQty(arr){
    var sum =0;
    for(var z =0;z<arr.length;z++){
        sum+=parseInt(arr[z].buyOrder.totalquantity);
    }
    return sum;
}
  getProductByOrderID(){
    let url = "product/get-product-by-orderId?randomOrderId=" +this.buyOrderId
      // this.mainService.showSpinner()
      this.mainService.getApi(url, 1).subscribe((res) => {
        if (res.status == 200) {
   
           if(res.data[0].buyOrder){
             res.data[0].buyOrder['totalAmount']=this.addsum(res.data)
             res.data[0].buyOrder['totalShippingAmount']=this.addShipping(res.data)
             res.data[0].buyOrder['totalQty']=this.addQty(res.data)

             res.data[0].buyOrder['total']=parseInt(res.data[0].buyOrder['totalAmount'])+ parseInt(res.data[0].buyOrder['totalShippingAmount'])

           this.buyOrderData = res.data[0].buyOrder;
           }
          this.orderProduct = res.data;
          this.mainService.hideSpinner();
        } else {
          this.mainService.hideSpinner();
          this.mainService.errorToast(res.message);
        }
      });
  }
  getViewOrder() {
    let url =
      "product/view-shipping-By-Id?shippingAddressId=" + this.shippingAddressId;

    // this.mainService.showSpinner()
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.OrderData = res.data;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  getViewOrderList() {
    let url = "product/view-order-By-Id?randomOrderId=" + this.buyOrderId;

    // this.mainService.showSpinner()
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.buyOrderData = res.data;
        this.mainService.hideSpinner();
        console.log("-=-=-=-=-=-=-=-=-=>>>>>>>>>>>>>>>>>>",res);
        
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  getViewProductList() {
    let url = "product/view-Product-by-Id?productId=" + this.productId;

    // this.mainService.showSpinner()
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.OrderDataList = res.data;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  getCategoryList() {
    let url = "product/view-category-By-Id?categoryId=" + this.orderId;

    // this.mainService.showSpinner()
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.CategoryData = res.data;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  getRatingList() {
    let url = "product/view-Rating-By-Id?ratingId=" + this.ratingId;

    // this.mainService.showSpinner()
    this.mainService.postApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.count = res.data.count;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  address: any = [];
  getAddress() {
    let url =
      "product/get-ShippingAddress-By-OrderId?randomOrderId=" + this.buyOrderId;

    // this.mainService.showSpinner()
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.address = res.data[0];
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        //this.mainService.errorToast(res.message);
      }
    });
  }
  billingAddress : any = []
  getBillingAddress() {
    let url =
      "product/view-ShippingAddress-By-Id?shippingAddressId=" + this.shippingAddressId;

    // this.mainService.showSpinner()
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {

        this.billingAddress = res.data;
        this.mainService.hideSpinner();
        // this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
  pagination(e) {}
  getUrl(e) {
    // this.backrouting.previousUrl.next(window.location.href);
    if(e == 0){
      localStorage.setItem('address',JSON.stringify(this.address))
    }else{
      localStorage.setItem('address',JSON.stringify(this.billingAddress))
    }
    localStorage.setItem("backUrl", JSON.stringify(this.reponseParam));
    // localStorage.setItem("backUrl", String(window.location.href).split("/")[3]);
  }
}
