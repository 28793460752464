import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-testimonial-management',
  templateUrl: './testimonial-management.component.html',
  styleUrls: ['./testimonial-management.component.css']
})
export class TestimonialManagementComponent implements OnInit {

  itemPerPage: any = 5;
  currentPage: any = 1;
  total: any;
  categoryStatus: any;
  categoryList: any = [];
  flip: boolean = false;
  isSearched : boolean = false
  catId: any;
  searchText : any 
  constructor(private mainservice: MainService, private router: Router) {}

  ngOnInit() {
    if(localStorage.getItem("testimonial")){
      localStorage.removeItem("testimonial")
    }
    this.getAllCategoryList();
  }

  getAllCategoryList() {
    let url = "static/get-testimonials-data-list";
    this.mainservice.showSpinner();
    this.mainservice.getApi(url, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.categoryList = res.data.Details;
          this.total = this.categoryList.length
          
          this.mainservice.hideSpinner();
          this.mainservice.successToast(res.message);
        } else {
          this.mainservice.hideSpinner();
          this.mainservice.errorToast(res.message);
        }
      },
      (err) => {
        this.mainservice.hideSpinner();
        this.mainservice.errorToast(err);
      }
    );
  }

  pagination(event) {
    this.currentPage = event;
    if (this.flip) {
      this.search();
    } else {
      this.getAllCategoryList();
    }
  }


 
  search() {
    if(!this.searchText){
      return
    }
   var a = String(this.searchText).toLowerCase()
    this.isSearched = true
    this.categoryList = this.categoryList.filter((res)=>{
      let name = String(res.name).toLowerCase();
      return name.includes(a)
    })
    this.total = this.categoryList.length
  }
  reset() {
    if(!this.isSearched){
      return
    }
    this.isSearched = false
    this.flip = false
    this.getAllCategoryList()
    this.searchText = ""
  }

  delete(id) {
    this.catId = id;
  }
  deteleCategory() {
    let url = "static/delete-testimonials-data?testimonialId=" + this.catId;
    this.mainservice.showSpinner();
    this.mainservice.deleteApi(url, {}, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.mainservice.hideSpinner();
          this.mainservice.successToast(res.message);
          this.getAllCategoryList();
        } else {
          this.mainservice.hideSpinner();
          this.mainservice.errorToast(res.message);
        }
      },
      (err) => {
        this.mainservice.hideSpinner();
        this.mainservice.errorToast(err);
      }
    );
  }
  viewcategory(obj) {
    localStorage.setItem('testimonial',JSON.stringify(obj))
    this.router.navigate(["/view-testimonial"]);
  }
  editcategory(obj) {
    localStorage.setItem('testimonial',JSON.stringify(obj))
    this.router.navigate(["/edit-testimonial"]);
  }
  


}
