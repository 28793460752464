import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
declare var $;

@Component({
  selector: "app-category-list",
  templateUrl: "./category-list.component.html",
  styleUrls: ["./category-list.component.css"],
})
export class CategoryListComponent implements OnInit {
  itemPerPage: any = 5;
  currentPage: any = 1;
  total: any;
  categoryStatus: any;
  categoryList: any = [];
  flip: boolean = false;
  isSearched : boolean = false
  catId: any;
  searchText : any 
  constructor(private mainservice: MainService, private router: Router) {}

  ngOnInit() {
    this.getAllCategoryList();
  }

  getAllCategoryList() {
    let url = "product/get-Category-Details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage;
    this.mainservice.showSpinner();
    this.mainservice.postApi(url,{}, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.categoryList = res.data[0].categoryDetails;
          this.total = res.data[0].count
          
          this.mainservice.hideSpinner();
          this.mainservice.successToast(res.message);
        } else {
          this.mainservice.hideSpinner();
          this.mainservice.errorToast(res.message);
        }
      },
      (err) => {
        this.mainservice.hideSpinner();
        this.mainservice.errorToast(err);
      }
    );
  }

  pagination(event) {
    this.currentPage = event;
    if (this.flip) {
      this.search();
    } else {
      this.getAllCategoryList();
    }
  }

  block(id, stat) {
    this.catId = id;
    this.categoryStatus = stat;
  }
  blockUnblockCategory() {
    let url = "";
    if (this.categoryStatus == "ACTIVE") {
      url = "product/unblock-category?categoryId=" + this.catId;

     
    } else {
      url = "product/block-category?categoryId=" + this.catId;
     
    }

    this.mainservice.showSpinner();
    this.mainservice.postApi(url, {}, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.mainservice.hideSpinner();
          this.mainservice.successToast(res.message);
          this.getAllCategoryList();
        } else {
          this.mainservice.hideSpinner();
          this.mainservice.errorToast(res.message);
        }
      },
      (err) => {
        this.mainservice.hideSpinner();
        this.mainservice.errorToast(err);
      }
    );
  }
  search() {
    if(!this.searchText){
      return
    }
    
    this.isSearched = true
    let url = "product/get-Category-Details?categoryName="+this.searchText;
    // this.mainservice.showSpinner();
    this.mainservice.postApi(url,{}, 1).subscribe(
      (res) => {
        if (res.status == 200) {
         
          this.categoryList = [res.data];
          console.log(this.categoryList)
          this.mainservice.hideSpinner();
          this.mainservice.successToast(res.message);
        } 
        else if(res.status == 205){
          this.categoryList =[]
          console.log(this.categoryList.length)
     }
        else {
          this.mainservice.hideSpinner();
          this.mainservice.errorToast(res.message);
        }
      },
      (err) => {
        this.mainservice.hideSpinner();
        this.mainservice.errorToast(err);
      }
    );
  }
  reset() {
    if(!this.isSearched){
      return
    }
    this.isSearched = false
    this.flip = false
    this.getAllCategoryList()
    this.searchText = ""
  }

  delete(id) {
    this.catId = id;
  }
  deteleCategory() {
    let url = "product/delete-category?categoryId=" + this.catId;
    this.mainservice.showSpinner();
    this.mainservice.deleteApi(url, {}, 1).subscribe(
      (res) => {
        if (res.status == 200) {
          this.mainservice.hideSpinner();
          this.mainservice.successToast(res.message);
          this.getAllCategoryList();
        } else {
          this.mainservice.hideSpinner();
          this.mainservice.errorToast(res.message);
        }
      },
      (err) => {
        this.mainservice.hideSpinner();
        this.mainservice.errorToast(err);
      }
    );
  }
  viewcategory(id) {
    this.router.navigate(["/view-category"], { queryParams: { id: id } });
  }
  editcategory(id) {
    this.router.navigate(["/edit-category"], { queryParams: { id: id } });
  }
  editIndex:any =false
 setIndex(index,data) {
   console.log(index);
   
    let url = "product/edit-category"
    const body = {
      "categoryId": data.categoryId,
      "categoryImage": data.categoryImage,
      "categoryName": data.categoryName,
      "indexNumber":index
    }
    this.mainservice.showSpinner()
    this.mainservice.postApi(url,body,1).subscribe((res)=>{
      if(res.status == 200){
        this.mainservice.hideSpinner()
        this.mainservice.successToast('update index')
        this.getAllCategoryList();
      }
      else{
        this.mainservice.hideSpinner()
      }
    },(err)=>{
      this.mainservice.hideSpinner()
    })
   
  }
}
