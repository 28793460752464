import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-footer',
  templateUrl: './preview-footer.component.html',
  styleUrls: ['./preview-footer.component.css']
})
export class PreviewFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
