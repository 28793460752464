import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-support',
  templateUrl: './view-support.component.html',
  styleUrls: ['./view-support.component.css']
})
export class ViewSupportComponent implements OnInit {
  brandId : any 
  replyFrom : FormGroup
 viewSupport : any = []
  constructor(private activatedRoute : ActivatedRoute, private mainService : MainService) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.brandId = res.id
    })
  }

  ngOnInit(): void {
    this.getViewSupport()
    this.formValidation()
  }
  formValidation(){
    this.replyFrom = new FormGroup({
      subject : new FormControl('',[Validators.required]),
      message : new FormControl('',[Validators.required])
    })
  }
  get subject(){
    return this.replyFrom.get('subject') as FormControl
  }
  get message(){
    return this.replyFrom.get('message') as FormControl
  }
  getViewSupport(){
    let url = "static/get-static-data-list-by-id?staticId="+this.brandId
    const data = { 
    }
    this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      if(res.status == 200){
        this.viewSupport = res.data
        this.mainService.hideSpinner()
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    },(err)=>{
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })
  }
  replyUser(email,subject,body){
    var mail = document.createElement("a");
   
    mail.href = `mailto:${email}?Subject=${subject}&body=${body}`
    mail.click()
    // this.service.toasterSucc("Replied Successfully")
    
  }

}
