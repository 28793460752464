import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';


const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable({
  providedIn: 'root'
})
export class MainService {
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  loginSub = new BehaviorSubject(``);
  
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: true,
  };
  baseURL = 'https://java-whitepaper.mobiloitte.org/';
  // baseURL="http://182.72.203.247:4090/"

  // baseURL="http://172.16.16.247:5076/admin/"
  constructor(private routes:Router,public httpClient: HttpClient,private spinnerService: NgxSpinnerService,private toastrService: ToastrService) { }

// ---------------- get Api function -------------------- //
getApi(endPointURL, isHeader): Observable<any> {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }
  }
  return this.httpClient.get(this.baseURL + endPointURL, httpHeaders)
}



// ---------------- post Api Function ------------------- //
postApi(endPointURL, data, isHeader): Observable<any> {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    }
  }
  return this.httpClient.post(this.baseURL + endPointURL, data, httpHeaders)
}


// ------------------ put Api Function ----------------- //
putApi(endPointURL, data, isHeader): Observable<any> {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer'+localStorage.getItem('token')
      })
    }
  }
  return this.httpClient.put(this.baseURL + endPointURL, data, httpHeaders)
}


// ------------------ delete Api Function -------------- //
deleteApi(endPointURL, bodyData, isHeader): Observable<any> {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }),
      body: bodyData
    }
  }
  return this.httpClient.delete(this.baseURL + endPointURL, httpHeaders)
}

// spinner service
showSpinner() {
  this.spinnerService.show();
}
hideSpinner() {
  this.spinnerService.hide();
}

// toastr service
successToast(msg) {
  this.toastrService.success(msg);
}
errorToast(msg) {
  this.toastrService.error(msg);
}
warningToast(msg) {
  this.toastrService.warning(msg);
}
infoToast(msg) {
  this.toastrService.info(msg);
}




  /** to prevent first space */
  preventSpace(event) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  // Header Managment
  changeLoginSub(msg) {
    this.loginSub.next(msg);
  }

  // logout
  onLogout() {
    localStorage.removeItem('token');
    this.changeLoginSub('logout');
    this.routes.navigate(['/login']);
  }

/** Function to restrict character */
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}
// remove empty key from object
removeEmptyKey(obj) {
  Object.entries(obj).forEach(([key, val]) => val === '' && delete obj[key]);
  return obj
}
/** Function to restrict space */
restrictSpace(event) {
  var k = event.charCode;
  if (k === 32) return false;
}
public isLoggedIn() {
  return localStorage.getItem("token") !== null;
}

public getIPAddress()  
  {  
    return this.httpClient.get("https://jsonip.com/?format=json");  
  } 
  
  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }
  uploadFile(endPointURL, data): Observable<any> {



    return this.httpClient.post<any>(this.baseURL + endPointURL, data)
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }


  public today(){
    return new Date().toISOString().split('T')[0]
  }
  public onFirstComponentButtonClick() {
    this.invokeFirstComponentFunction.emit();
  }
}