import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
declare var $;

@Component({
  selector: "app-discount-and-promotion-list",
  templateUrl: "./discount-and-promotion-list.component.html",
  styleUrls: ["./discount-and-promotion-list.component.css"],
})
export class DiscountAndPromotionListComponent implements OnInit {
  searchForm: FormGroup;
  isSearched : boolean = false
  itemPerPage = 5;
  currentPage = 1;
  limit: any = 5;
  total: any;
  items = [];
  totalItems:any
  discountType: any = [
    {
      discountName: "Assigned to order total",
      value: "ASSIGNED_TO_TOTAL_ORDER",
    },
    {
      discountName: "Assigned to products",
      value: "ASSIGNED_TO_PRODUCT",
    },
    // {
    //   discountName: "Assigned to categories",
    //   value: "ASSIGNED_TO_CATEGORIES",
    // },
    // {
    //   discountName: "Assigned to brand",
    //   value: "ASSIGNED_TO_BRAND",
    // },
    // {
    //   discountName: "Assigned to shipping",
    //   value: "ASSIGNED_TO_SHIPPING",
    // },
    // {
    //   discountName: "Assigned to order sub total",
    //   value: "ASSIGNED_TO_SUB_ORDER_TOTAL",
    // },
  ];
  customerData: any = [];
 flip : boolean = false
  constructor(private router: Router, public mainService: MainService) {
    this.customerData =[]
  }

  ngOnInit() {
    this.searchFormValidation();
    this.getDiscountList()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
      discountType: new FormControl(""),
      couponCode: new FormControl(""),
    });
  }

  editDiscount(id) {
    this.router.navigate(["/edit-discount"], { queryParams: { id: id } });
  }
  showModal() {
    $("#BlockModal").modal("show");
  }
  pagination(event) {
    this.currentPage = event;
    if(this.flip){
      this.search()
    }else{
      this.getDiscountList()
    }
  }
  getDiscountList(){
    let url="product/get-Discount-Details?page="+(this.currentPage - 1)+"&pageSize="+this.itemPerPage
    this.mainService.postApi(url,{},1).subscribe((res)=>{
      if (res.status ===200) {
        this.customerData=res.data.DiscountDetails
        this.total=res.data.allCountDetails
        this.mainService.hideSpinner()
        //this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }

  search(){
    if(!this.searchForm.value.search && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && !this.searchForm.value.discountType && !this.searchForm.value.couponCode){
      return
    }
    const fromDate = Date.parse(this.searchForm.value.fromDate)
    const toDate = Date.parse(this.searchForm.value.toDate)
    this.isSearched = true
    this.flip = true
    let url =""
    
    if(this.searchForm.value.search && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && !this.searchForm.value.discountType && !this.searchForm.value.couponCode){
      console.log("only discount name");
      url ="product/get-Discount-Details?discountName="+this.searchForm.value.search
    }
    else if(!this.searchForm.value.search && this.searchForm.value.fromDate && !this.searchForm.value.toDate && !this.searchForm.value.discountType && !this.searchForm.value.couponCode){
      console.log("only from date");
      url ="product/get-Discount-Details?startDate="+fromDate

    }
    else if(!this.searchForm.value.search && this.searchForm.value.fromDate && this.searchForm.value.toDate && !this.searchForm.value.discountType && !this.searchForm.value.couponCode){
      console.log("only from date and to date");
      url ="product/get-Discount-Details?endDate="+toDate+this.itemPerPage+"&startDate="+fromDate

    }
    else if(!this.searchForm.value.search && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && this.searchForm.value.discountType && !this.searchForm.value.couponCode){
      console.log("only discount Type");
      url ="product/get-Discount-Details?discountType="+this.searchForm.value.discountType

    }
    else if(!this.searchForm.value.search && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && !this.searchForm.value.discountType && this.searchForm.value.couponCode){
      console.log("only coupon code");
      url ="product/get-Discount-Details?coupanCode="+this.searchForm.value.couponCode

    }
    else if(this.searchForm.value.search && this.searchForm.value.fromDate && !this.searchForm.value.toDate && !this.searchForm.value.discountType && !this.searchForm.value.couponCode){
      console.log("discount name and from date");
      url ="product/get-Discount-Details?discountName="+this.searchForm.value.search+"&startDate="+fromDate

    }
    else if(this.searchForm.value.search && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && this.searchForm.value.discountType && !this.searchForm.value.couponCode){
      console.log("discount name and discount type");
      url ="product/get-Discount-Details?discountName="+this.searchForm.value.search+"&discountType="+this.searchForm.value.discountType

    }
    else if(this.searchForm.value.search && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && !this.searchForm.value.discountType && this.searchForm.value.couponCode){
      console.log("discount name and coupon code");
      url ="product/get-Discount-Details?coupanCode="+this.searchForm.value.couponCode+"&discountName="+this.searchForm.value.search

    }
    else{
      console.log("search by all");
    }
    this.mainService.postApi(url,{}, 1).subscribe(
      (res) => {
        if (res.status ===200) {
          this.customerData = res.data.DiscountDetails
          console.log(this.customerData)
          this.total = res.data.allCountDetails
          this.mainService.hideSpinner();
          this.mainService.successToast(res.message);
        } 
        else if(res.status === 201){
             this.customerData=[]
             console.log(this.customerData.length)
        }
        else {
          this.mainService.hideSpinner();
          this.mainService.errorToast(res.message);
        }
      },
      (err) => {
        this.mainService.hideSpinner();
        this.mainService.errorToast(err);
      }
    );
  }
   deleteId:any =0
   deleteUserModal(id) {
      this.deleteId = id
    }
    hideModal(){
       }
  deleteDiscount() {
    let url = "product/delete-Discount?discountId="+this.deleteId
    const data = {}
    this.mainService.deleteApi(url,{},1).subscribe((res)=>{
      if (res.status ==200) {
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
       this.getDiscountList()
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }
  reset(){
    if(!this.isSearched){
      return
    }
    this.flip = false
    this.isSearched = false
    this.getDiscountList()
    this.searchForm.reset()
    this.searchForm.patchValue({
      discountType : ""
    })
  } 
}
