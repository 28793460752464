<div>
<main class="middle-content" >
     <div class="page_title_block1">
            <h1 class="page_title float-left">Customer Details</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/user-mangement">Back to Customer list</a>
                </small>
       </div>
       <div class="pading20" id="accordion">
          <div class="card mb7">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <i class="fa fa-info iconP"></i>General Info
                </button>
              </h5>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                  <app-general ></app-general>
              </div>
            </div>
       </div>
         <div class="card mb7">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  <i class="fa fa-paper-plane iconP"></i>Order 
                </button>
              </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              <div class="card-body">
                  <app-order ></app-order>
                  <app-user-kyc  *ngIf="false"></app-user-kyc>
              </div>
            </div>
       </div>
     </div>
 </main>
</div>