
<footer style="background: #F2EDED;color:#494949;padding-right: 270px;" class="page-footer font-small stylish-color-dark pt-4">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-3">

        <!-- Content -->
        <img class="image" src="assets/Product image/MNL Technologies Logo.png" alt=""><br>
        <img class="image" style="width: 188px;
    padding-left: 40px;" src="assets/Product image/img-so.png" alt="">
        
        

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

      <!-- Grid column -->
      <div class="col-md-3 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold  mt-3 mb-4 MuiTypography-h4">
Customer Services</h5>

        <ul class="list-unstyled">
          <li>
            <a href="javascript:void(0)">My Account</a>
          </li>
          <li>
            <a href="javascript:void(0)">FAQs</a>
          </li>
          <li>
            <a href="javascript:void(0)">Payment Methods</a>
          </li>
          <li>
            <a href="javascript:void(0)">Shipping Guide</a>
          </li>
          <li>
            <a href="javascript:void(0)">Products Support</a>
          </li>
          
        </ul>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

      <!-- Grid column -->
      <div class="col-md-3 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold  mt-3 mb-4 MuiTypography-h4">More From MNL</h5>

        <ul class="list-unstyled">
          <li>
            <a href="javascript:void(0)">Terms & Conditions</a>
          </li>
          <li>
            <a href="javascript:void(0)">Privacy Policy</a>
          </li>
          <li>
            <a href="javascript:void(0)">About Us</a>
          </li>
        </ul>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

      <!-- Grid column -->
      <div class="col-md-3 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold  mt-3 mb-4 MuiTypography-h4">Let’s Talk</h5>

        <ul class="list-unstyled">
          <li>
            <a style="font-weight: 500;" href="javascript:void(0)"> 
             +391 (0)35 2568 4593
              hello@domain.com
             502 New Design Str
             Melbourne, Australia</a>
          </li>
         
        </ul>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->
  <hr>
  <!-- Social buttons -->
 <!--  <ul class="list-unstyled list-inline text-center">
    <li class="list-inline-item">
      <a class="btn-floating btn-fb mx-1">
        <i class="fab fa-facebook-f"> </i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="btn-floating btn-tw mx-1">
        <i class="fab fa-twitter"> </i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="btn-floating btn-gplus mx-1">
        <i class="fab fa-google-plus-g"> </i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="btn-floating btn-li mx-1">
        <i class="fab fa-linkedin-in"> </i>
      </a>
    </li>
    <li class="list-inline-item">
      <a class="btn-floating btn-dribbble mx-1">
        <i class="fab fa-dribbble"> </i>
      </a>
    </li>
  </ul> -->
  <!-- Social buttons -->

  <!-- Copyright -->
  <div class="footer-copyright text-left py-3 c-name">© 2020 Copyright ©
    <a > MNL all rights reserved. Powered by MNL Team</a>
  </div>
  <!-- Copyright -->

</footer>
