<nav class="navbar navbar-expand-lg navbar-light bg-upr">
  <a class="navbar-brand" href="javascript:void(0)"></a>
<!--   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button> -->
  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link bg-name" href="javascript:void(0)"> <i class="fa fa-volume-control-phone" aria-hidden="true"></i>(+391)35 2568 4593 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link bg-name" href="javascript:void(0)"><i class="fa fa-envelope-o" aria-hidden="true"></i> info@Mnltechnology.com</a>
      </li>
    </ul>
    <span class="navbar-text">
  <ul class="navbar-nav mr-auto">
       <li class="nav-item bg-name">
        <a class="nav-link" href="javascript:void(0)">language</a>
      </li>
       <li class="nav-item bg-name">
        <a class="nav-link" href="javascript:void(0)">Currency</a>
      </li>
      <li class="nav-item bg-name">
        <a class="nav-link" href="javascript:void(0)">
          <img style="width: 90px;" class="image" src="assets/Product image/img-so.png" alt="">
        </a>
      </li>
         <li class="nav-item bg-name">
        <a class="nav-link" href="javascript:void(0)">
          <!-- <img style="width: 90px;" class="image" src="assets/Product image/img-so.png" alt=""> -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        </a>
      </li>

      

    </ul>
    </span>
  </div>
</nav>
<nav class="navbar navbar-expand-lg navbar-light bg-upr2">
  <a class="navbar-brand" href="javascript:void(0)"><img style="width: 120px;" class="image" src="assets/Product image/MNL Technologies Logo.png" alt=""></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">
      
      <br>
    </span>
  </button>
  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto ml-400">
      <li class="nav-item active">
        <a class="nav-link main-name" href="javascript:void(0)">Home </a>
      </li>
      <li class="nav-item">
        <a class="nav-link main-name" href="javascript:void(0)">Shop</a>
      </li>
      <li class="nav-item">
        <a class="nav-link main-name" href="javascript:void(0)">Category</a>
      </li>
       <li class="nav-item">
        <a class="nav-link main-name" href="javascript:void(0)">Whitepaper</a>
      </li>
       <li class="nav-item">
        <a class="nav-link main-name" href="javascript:void(0)">Contact</a>
      </li>
    </ul>
    <span class="navbar-text">
       <ul class="navbar-nav mr-auto ">
      <li class="nav-item active">
        <a class="nav-link main-name" href="javascript:void(0)">Login / Register </a>
      </li>
      <li class="nav-item">
        <a class="nav-link main-name" href="javascript:void(0)">
          <img style="width: 100px;margin-top: -8px;" class="image" src="assets/Product image/img-tool.png" alt="">
        </a>
        
      </li>
    </ul>
    </span>
  </div>
</nav>
