import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-brand',
  templateUrl: './view-brand.component.html',
  styleUrls: ['./view-brand.component.css']
})
export class ViewBrandComponent implements OnInit {
  brandId : any 
 brandData : any = []
  constructor(private activatedRoute : ActivatedRoute, private mainService : MainService) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.brandId = res.id
    })
  }

  ngOnInit(): void {
    this.getbrandData()
  }
  getbrandData(){
    let url = "product/view-brand-By-Id?brandId="+this.brandId
    const data = {
      
      
    }
    console.log(data);
   
    
    this.mainService.showSpinner()
    this.mainService.postApi(url,data,1).subscribe((res)=>{
      if(res.status == 200){
        this.brandData = res.data
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    },(err)=>{
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })
  }

}
