import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-add-faq",
  templateUrl: "./add-faq.component.html",
  styleUrls: ["./add-faq.component.css"],
})
export class AddFaqComponent implements OnInit {
  addForm: FormGroup;
  addParantForm: FormGroup;
  constructor(
    public active: ActivatedRoute,
    private router: Router,
    public mainService: MainService
  ) {}
  isCategory: any = "";
  parantFaqCatedoryList: any = [];
  ngOnInit() {
    this.addForm = new FormGroup({
      question: new FormControl("", Validators.required),
      answer: new FormControl("", Validators.required),
      category: new FormControl("", Validators.required),
    });
    this.addParantForm = new FormGroup({
      catName: new FormControl("", Validators.required),
    });
    this.parantFaqList();
    this.getAllFaq()
  }
  categoryId: any;
  onSelectName(result) {
    this.categoryId = result.id;
  }

  parantFaqList() {
    let url = "static/get-category-list";
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        this.parantFaqCatedoryList = res.data
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      }
    });
  }
  // add faq
  addFaq() {
    let url = "static/add-new-faq";
    const data = {
      question: this.addForm.value.question,
      answer: this.addForm.value.answer,
      category: this.addForm.value.category.name,
      categoryId: this.addForm.value.category.id,
    };
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log("add faq response ==>", res);
      if (res.status == 200) {
        this.router.navigate(["faq"]);
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  }
    productData:any 
    productImageView:any=[]
    preview() {
      this.productImageView=[]
      const data = {
        question: this.addForm.value.question,
        answer: this.addForm.value.answer,
        category: this.addForm.value.category.name,
        categoryId: this.addForm.value.category.id,
      };
        this.productData = data
     }
     
  addCategoryFaq = () => {
    let url = "static/add-parent-faq-category";
    const data = {
      category: this.addForm.value.catName,
    };
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.router.navigate(["faq"]);
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    });
  };
  allFaqList : any = []
  getAllFaq(){
   let url = "static/get-faq-detail-list"
  this.mainService.showSpinner()
  this.mainService.getApi(url,1).subscribe((res)=>{
    if(res.status == 200){
      this.allFaqList = res.data.filter((res)=>{
       
       return res.category[0].categoryStatus != 'DELETE' 
      })
      
      this.allFaqList = this.allFaqList.filter((res)=>{
       
       return res.product.isDeleted == false
      })
      
      let i = 0
      let temp = []
       for(let item of this.allFaqList){
         if(i == 8){
           
           break
         }
         temp[i] = item
         i++
       }
       console.log(temp);
       
     this.allFaqList = temp
      this.mainService.hideSpinner()
      this.mainService.successToast(res.message)
    }
    else{
       this.mainService.hideSpinner()
      this.mainService.errorToast(res.message)
    }
  })
 }
}
