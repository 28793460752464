import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-user-kyc',
  templateUrl: './view-user-kyc.component.html',
  styleUrls: ['./view-user-kyc.component.css']
})
export class ViewUserKycComponent implements OnInit {

  userId: any;
  viewKycList: any;
docSide : any
img : any
  constructor(private activatedRoute:ActivatedRoute,private router :Router) { }
  kycData : any = [
    {
      kycUserId: 11873,
      _id : 12,
    name : 'Tanveer',
    idNumber : "KLPH0052L",
    IdType : "PAN",
    kycStatus : 'PENDING',
    createdAt : '12/07/2021, 8:05 PM',
    status : 'PENDING',
    docFrontPage :"assets/img/adhar-front.png",
    docBackPage :"assets/img/adhar-back.png",
  }]
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res:any)=>{
      this.userId = res.kycid
    })
    this.viewKyc()
   
  }
  viewKyc(){
   
  }
  approval() {
    
  }
  
  rejectKyc(){
    
  }
  backKyc(){
   this.router.navigate(['/view-user'])
   localStorage.setItem('viewUserTab','KYC')
  }
  showDocument(side,img){
this.docSide = side
this.img = img
  }
}
