import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  constructor(private router: Router, public mainService: MainService) { 
    
  }
  myProfileData: any = []

  ngOnInit(): void {
    this.myProfile()
  }
  
  // get profile
  myProfile() {
    var url = 'account/my-account';
    this.mainService.showSpinner();
    this.mainService.getApi(url,1).subscribe(res => {
      if (res['status'] == 200) {
        this.myProfileData = res['data']
        this.mainService.hideSpinner();
        this.mainService.successToast(res['message']);

      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res['message']);
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        this.mainService.onLogout();
        this.mainService.errorToast('Unauthorized Access');
      } else {
        this.mainService.errorToast('Something Went Wrong');
      }
    })
  }

  // navigate to change password
  changePassword() {
    this.router.navigate(['/change-password']);
  }


  editProfile() {
    this.router.navigate(['/edit-profile'])
  }

}
