import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-sub-category',
  templateUrl: './view-sub-category.component.html',
  styleUrls: ['./view-sub-category.component.css']
})
export class ViewSubCategoryComponent implements OnInit {

  subcategory: any={

    categoryId : 1,
    categoryName : 'E-V Charger',
    subCategoryPic : 'assets/Product image/chargerImage-1.jpeg',
    subCategoryName : 'eCar Charger',
    createdAt : '22/02/2021',
    status: 'ACTIVE',
  };
  subID: any;

  constructor(private mainService:MainService,private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res:any)=>{
      this.subID=res.id
    })
    this.viewApi()
  }
viewApi(){
}
}
