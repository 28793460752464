<main class="middle-content">
            <div class="page_title_block1">
            <h1 class="page_title float-left">Add Brand</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/brand">Back to brand list</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save" (click)="addBrand()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Save
                    </button>
               </div>
            </div>
    <div class="content-section">
        <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
            <div class="mb40">
                <form [formGroup]="editProfileForm">
                    <div class="admin-profile">
                        <h4 class="mb20 text-center">Add Brand</h4>
                    </div>
                

                    <div class="add-store-block input-style mb20 mt20 ">
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Brand
                                Name</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control"
                                        (keypress)="mainService.preventSpace($event)" maxlength="30"
                                        formControlName="brandName" placeholder="Brand Name">
                                    <div *ngIf="editProfileForm.get('brandName').invalid && (editProfileForm.get('brandName').dirty || editProfileForm.get('brandName').touched)"
                                        class="text-danger">
                                        <span *ngIf="editProfileForm.get('brandName').hasError('required')">
                                            *Please enter brand name.</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">
                                Brand Description</label><span>:</span>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <textarea type="textarea" class="form-control" rows="6"
                                        (keypress)="mainService.preventSpace($event)" maxlength="30"
                                        formControlName="description" placeholder="Brand Description"></textarea>
                                    <div *ngIf="editProfileForm.get('description').invalid && (editProfileForm.get('description').dirty || editProfileForm.get('description').touched)"
                                        class="text-danger">
                                        <span *ngIf="editProfileForm.get('description').hasError('required')">
                                            *Please enter brand description.</span>
                                    </div>
                                </div>
                               
                            </div>
                        </div> -->
                        <!-- <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">
                                Brand Image</label><span>:</span>
                            <div class="col-md-4 textaligncenter">
                                <div class="form-group">
                                    <input #file type="file" accept='image/*' (change)="getImage($event)" accept=".jpeg,.png" formControlName="image" />
                                </div>
                                <div class="text-danger">
                                    <span
                                        *ngIf="editProfileForm.get('image').hasError('required') && (editProfileForm.get('image').dirty || editProfileForm.get('image').touched)">
                                        *Please select brand image.
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-2 textaligncenter">
                                <div class="form-group">
                                    
                                    <img [src]=" imgSrc || 'assets/img/dummy.png'" alt="" style="width: 70%; " />
                                </div>
                            </div>

                        </div> -->


                        
                    </div>

     
                </form>
            </div>
        </div>
    </div>
</main>