
    <main class="middle-content">
               <div class="page_title_block1">
            <h1 class="page_title float-left"> Add Banner</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/banner-management">Back</a>
                </small>
       </div>
        <div class="content-section">
            <div class="order-view  max-WT-700  setting-page">
                <div class="  mb40">
                    <form>
                        <div class="add-store-block input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">Banner Title
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">

                                        <label style="font-weight: 400;">{{bannerData?.title}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row view-label-align">
                                <label class="col-md-4 textalignright">Banner Image
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">
                                        <label for="fileInput" class="hoverable">
                                            <img [src]="bannerData?.image"
                                            alt="" style="
                                                width : 200px;object-fit: cover;" />



                                        </label>


                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                              <label class="col-md-4 textalignright ">Banner Status
                                  <span>:</span></label>
                              <div class="col-md-8 textaligncenter">
                                  <div class="form-group">

                                      <label style="font-weight: 400;">{{bannerData?.bannerStatus ? 'Active' : 'Deactive'}}</label>
                                  </div>
                              </div>
                          </div>
                            <div class="form-group row  view-label-align">
                                <label class="col-md-4 textalignright">Description 
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group " [innerHTML]="description">
                                        <!-- <label style="font-weight: 400;">{{bannerData?.description}}</label> -->

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row  view-label-align">
                                <div class="col-md-12">
                                    <div class="form-group " style="font-size: 13px;" [innerHTML]="description">
                                </div>
                            </div> -->
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </main>


