    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">&nbsp;FAQ's</h1>
            <hr>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div>
                                <form>
                                    <div class="row d-flex justify-content-end mb20">
                                        <div class="col-md-4">
                                            <div class="admin-btn justify-content-end ">
                                                    <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                   routerLink="/add-faq-category" >Add Category 
                                                </button>
                                                <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                    routerLink="/add-faq">Add Faq
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap">
                                <caption></caption>
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img"> S.No.</th>
                                         <th scope="col" class="Sorting-img"> category</th>
                                        
                                        <th scope="col" class="Sorting-img">Question</th>
                                        <th scope="col" class="Sorting-img">Answer</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of faqList| paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total}; let i = index "
                                        class="text-center">
                                        <!-- <td class="content">{{data?.staticContentId }}</td> -->
                                        <td> {{itemPerPage * (currentPage -1)+ i +1 }}</td>
                                        <td class="content">{{data?.category[0].category}}</td>
                                         <td class="content">{{data?.product.question}}</td>
                                        <td class="content">{{data?.product.answer}}</td>
                                        <td class="justify-content-center d-flex">
                                            <button class="btn btn-info  bt-margin bth" title="View"
                                                (click)="viewUser(data?.product.faqId)">
                                                <em class="fa fa-eye" style="color:white"></em></button>
                                            <button class="btn btn-info  bt-margin bth" title="Edit"
                                                (click)="editFaq(data?.product.faqId)">
                                                <em class="fa fa-edit" style="color:white"></em></button>
                                            <button class="btn btn-danger  bt-margin bth" title="Delete"
                                            data-toggle="modal" data-target="#delete" (click)="deleteFaqModal(data?.product.faqId)">
                                                <em class="fa fa-trash" style="color:white"></em></button>

                                        </td>
                                    </tr>
                                    <!-- <tr *ngIf="data.length=0">
                                        <td class="table-no-record" colspan="4">No Record Found</td>
                                    </tr> -->
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-align-end" *ngIf="total >itemPerPage">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="delete">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete FAQs?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this faq?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" data-dismiss="modal" (click)="deleteFaq()">Yes</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2" data-dismiss="modal" (click)="hideModal()">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->