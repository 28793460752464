import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MainService } from "src/app/provider/main.service";

@Component({
  selector: "app-view-attribute",
  templateUrl: "./view-attribute.component.html",
  styleUrls: ["./view-attribute.component.css"],
})
export class ViewAttributeComponent implements OnInit {
  productAttribute: any;
  categoryList: any = [];
  contentEdit: any = [];
  categoryFaq: any = [];
  categoryId: any;
  categoryValue: any;
  showDeleteModal: boolean = false;

  itemPerPage: any = 10;
  currentPage: any = 1;
  total: any;
  attributeId: any;
  constructor(
    public service: MainService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.attributeId = res.id;
    });
  }

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory() {
    let url = "product/view-child-attribute-by-list-by-attribute-id?attributeId="+this.attributeId;

    this.service.showSpinner();
    this.service.getApi(url, 1).subscribe((res) => {
      if (res.status == 200) {
        console.log(res);
        this.categoryList = res.data[0].child_attribute;
        this.total = this.categoryList.length;

        for (let i = 0; i < this.categoryList.length; i++) {
          this.contentEdit[i] = false;
          console.log("-=-=-=-=-=-=-=-=-=-=-=-=-=-", this.categoryList[i]);
          this.categoryFaq[i] = this.categoryList[i]["childAttribute"];
        }
        this.service.successToast(res.message);
        this.service.hideSpinner();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }

  addCategory() {
    if (!this.productAttribute) {
      this.service.warningToast("Please enter product attribute");
      return;
    }
    let url = "product/add-child-attribute";
    const data = {
      attributeId: this.attributeId,
      "productChildAttributeStatus": "ACTIVE",
      childAttribute: this.productAttribute,
    };
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.productAttribute = "";
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  getId(id) {
    this.categoryId = id;
    this.showDeleteModal = true;
  }
  deleteCategory(e) {
    if (e) {
      this.showDeleteModal = false;
    } else {
      this.showDeleteModal = false;
      return;
    }
    let url = "product/delete-child-attribute?childAttributeId=" + this.categoryId;
    this.service.showSpinner();
    this.service.deleteApi(url, {}, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  editFaq(index, value, id) {
    for (let i = 0; i < this.categoryList.length; i++) {
      this.contentEdit[i] = false;
    }
    this.contentEdit[index] = true;
    this.categoryId = id;
    this.categoryValue = value;
  }
  editFaqApi(value) {
    if (!value) {
      this.service.warningToast("Faq category can not be empty");
      return;
    }

    let url = "product/update-child-attribute?childAttributeId=" + this.categoryId;
    const data = {
      childAttribute: value,
    };
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.successToast(res.message);
        this.service.hideSpinner();
        this.getCategory();
      } else {
        this.service.errorToast(res.message);
        this.service.hideSpinner();
      }
    });
  }
  pagination(e) {
    this.currentPage = e;
  }
}
