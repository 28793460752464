<main class="middle-content">
   <div class="page_title_block1">
            <h1 class="page_title float-left"> View category</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/category-list">Back</a>
                </small>
                   <div class="float-right">
                    <button type="submit" name="save"  (click)="edit()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Edit
                    </button>
               </div>
       </div>
    <div class="content-section">
        <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
            <div class="mb40">
                <div class="admin-profile" style="margin:0 auto;">
                    <h4 class="mb20 text-center" >View Category</h4>
                </div>


                <div class="add-store-block input-style mb20 mt20 ">
                    <div class="sheet">
                        <label class="textaligncenter labelone ">Category Id
                        </label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p>{{categoryList?.categoryId || '--'}}</p>
                        </div>

                    </div>
                    <div class="sheet">
                        <label class="textaligncenter labelone  ">Category Name</label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p>{{categoryList?.categoryName || '--'}}</p>
                        </div>
                    </div>
                    <div class="sheet">
                        <label class="textaligncenter labelone  ">Created At</label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p>{{(categoryList?.createTime | date : 'dd/MM/yyyy, hh:mm a' )|| '--'}}</p>
                        </div>
                    </div>
                    <div class="sheet">
                        <label class="textaligncenter  labelone ">Category Image</label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p><img src={{categoryList?.categoryImage}} alt="category image" style="width: 143px;"></p>
                        </div>
                    </div>
                    <div class="sheet">
                        <label class="textaligncenter labelone  ">Status
                        </label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p style="word-break: break-all;">{{categoryList?.categoryStatus || '--'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</main>
