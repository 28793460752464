    <main class="middle-content">
               <div class="page_title_block1">
            <h1 class="page_title float-left"> View Subadmin</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/subAdmin">Back</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save"  (click)="edit()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Edit
                    </button>
               </div>
       </div>
        <div class="content-section">
            <div class="order-view  max-WT-700  setting-page mb-5">
                <div class="mb40">
                    <form>
                        <div class="add-store-block input-style mb20 mt20">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row stripe " >
                                        <label class="col-md-3 textalignright">Name</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{subAdmin?.firstName || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row stripe2">
                                        <label class="col-md-3 textalignright">Email</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{subAdmin?.email || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                   <div class="row stripe">
                                        <label class="col-md-3 textalignright">Role</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{subAdmin?.roleName?.role}}</label>
                                            </div>
                                        </div>
                                    </div> 
                                </div>

                                <div class="col-md-6">
                                    <div class="row stripe">
                                        <label class="col-md-3 textalignright">Phone No</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{subAdmin?.phoneNo  || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row stripe2">
                                        <label class="col-md-3 textalignright">Status</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{subAdmin?.userStatus  || '--'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row stripe">
                                        <label class="col-md-3 textalignright">Last Logged In</label>
                                        <span class="col-md-1">:</span>
                                        <div class="col-md-7 textaligncenter">
                                            <div class="form-group">
                                                <label class="lab">{{(subAdmin?.lastLoggedIn ) || '--'}}</label>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div style="text-align: center;">
                                <h1 class="page_title">Permissions</h1>
                            </div>

                            <div class="row " style="margin-top: 3%;">

                                
                                    <div class="col-md-4" *ngFor = "let item of permissionArr; let i = index">
                                        <label class="globalCheckBox" style="text-transform: capitalize;"> {{name[i] |lowercase}}
                                            <input type="checkbox" disabled [(ngModel)]="permArray[i]"  [ngModelOptions]= "{standalone: true}"
                                             >
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                  
                              
                             
                            </div>

                            <div class="text-center mt40 mb40">
                                
                               <!--  <button class="btn btn-large  max-WT-150 ml-1 btn-secondary"
                                    routerLink="/subAdmin">Back</button> -->
                                    <!--  <button class="btn btn-large  max-WT-150 ml-1 btn-primary" routerLink="/edit-sub-admin?id={{subId}}"
                               >Edit</button> -->
                             
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>


