
    <main class="middle-content">

                <div class="page_title_block1">
            <h1 class="page_title float-left"> Edit Banner</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/banner-management">Back</a>
                </small>
                <div class="float-right">
                  <button style="margin-right:6px;" (click)="preview()" type="button"  data-toggle="modal" data-target=" #examppreview"  name="save"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Preview
                    </button>
                    <button type="submit" name="save" [disabled]="!addBannerForm.valid" (click)="updateBanner()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Update
                    </button>
               </div>
       </div>
        <div class="content-section">
            <div class="order-view  max-WT-700  setting-page" >
                <div class="  mb40">
                    <form  [formGroup]="addBannerForm">
                        <div class="add-store-block input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Banner Title
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter banner title"
                                            formControlName="title" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addBannerForm.get('title').hasError('required') && (addBannerForm.get('title').dirty || addBannerForm.get('title').touched )">
                                                *Please enter banner title.
                                            </span>

                                        </div> 
                                    </div>
                                </div> 
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Apply on page Banner
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                       <select style='width: 50%;' name="" class="form-control form-select" (change)="selectBanner()" formControlName="pageType" id="">
                        <option value="">Select</option>
                        <option [value]="item.name" *ngFor = "let item of bannerList">{{item.name}}</option>
                    </select>
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addBannerForm.get('pageType').hasError('required') && (addBannerForm.get('pageType').dirty || addBannerForm.get('pageType').touched )">
                                                *Please enter banner page name.
                                            </span>

                                        </div> 
                                    </div>
                                </div> 
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Index 
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                         <select style='width: 50%;' name="" class="form-control form-select" formControlName="indexNumber" id="">
                        <option value="">Select</option>
                        <option [value]="item.id" *ngFor = "let item of indexList">{{item.name}}</option>
                    </select>
                                        <!-- <input type="text" class="form-control" placeholder="Enter banner index"
                                            formControlName="indexNumber" 
                                            maxlength="50"> -->
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addBannerForm.get('indexNumber').hasError('required') && (addBannerForm.get('indexNumber').dirty || addBannerForm.get('indexNumber').touched )">
                                                *Please enter banner index.
                                            </span>

                                        </div> 
                                    </div>
                                </div> 
                            </div>

                            <div class="form-group row view-label-align">
                              <label class="col-md-4 textalignright">Upload Image
                                  <span>:</span></label>
                              <div class="col-md-4 textaligncenter">
                                  <div class="user-profile hoverable ">
                                          <!-- <img class="image-boxs" src={{imgSrc}} id="sub-admin-image" alt=""> -->

                                  <label class="upload-label">
                                      <input type="file" formControlName="image" name="image"
                                          accept="image/jpg,image/jpeg,image/png" id="image"
                                          (change)="ValidateFileUpload($event)" maxlength="56">
                           
                                  </label>
        
                                  </div>
                              </div>
                          </div>
                            <div class="form-group row align-items-baseline view-label-align" style="margin-top: 6%;">
                                <label class="col-md-4 textalignright">Activate
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div>

                                        <input type="checkbox"
                                            data-md-icheck
                                            formControlName = "isActive"  (click)="toggleVisibility($event)">

                                    </div>
                                </div>
                            </div>
                            <div  *ngIf="imgSrc" class="image-boxs mp" > 
                                            <img  class="img2" src= {{imgSrc}} id="sub-admin-image" alt="" > 
                                        </div>
<!-- 
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">Description<span>:</span>
                                </label>
                            </div>

                            <div class="form-group row  view-label-align">

                                <div class="col-md-12 textaligncenter">
                                    <div class="form-group " *ngIf="this.addBannerForm.value.description">
                                        <ck-editor formControlName="description"></ck-editor>
                                        <div
                                          *ngIf="addBannerForm.get('description').invalid && (addBannerForm.get('description').dirty || addBannerForm.get('description').touched)"
                                          class="text-danger">
                                          <span *ngIf="addBannerForm.get('description').hasError('required')">
                                            *Please enter description.</span>
                                        </div>
                                      </div>
                                </div>
                            </div> -->
                        </div>

                        
                     <!--    <div class="mt40 mb40 text-center">
                            <button class="btn btn-large  max-WT-150 btn-secondary" routerLink="/banner-management">Cancel</button>
                            <button class="btn btn-large  max-WT-150 btn-primary ml-2"
                            [disabled]="!addBannerForm.valid" (click)="updateBanner()">Submit</button>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </main>
<!--  Preview popup For view Product Details -->

<div class="modal fade bd-example-modal-lg" id="examppreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog image-dialog modal-lg" role="document">
    <div class="modal-content" style="margin-top: -43px;">
 <app-preview-header></app-preview-header>
      <div  *ngIf="imgSrc">
      <img [ngClass]="{'mid-left': productData.indexNumber === '3', 'mid-right' : productData.indexNumber === '4', 'imgprev' : productData.indexNumber === '1', 'imgprev2' : productData.indexNumber === '2' }"  src= {{defaultImage}} id="sub-admin-image" alt="" >  
       <!--  <form>
               <div class="add-store-block input-style mb20 mt20">
                        <div class="row">
                           <div class="col-md-12 image-boxs" *ngIf="imgSrc">
                                            <img  class="image-boxs" src= {{imgSrc}} id="sub-admin-image" alt="" > 
                                <p class="description" [innerHtml]="sanitizer.bypassSecurityTrustHtml(productData?.description)"></p>
                                </div>
                            </div>
                     </div>
             </form> -->
         </div>
        <app-preview-footer></app-preview-footer> 
    </div>
  </div>
</div>
 