<div>
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title"> Product Management</h1>
            <hr>
        </div>

        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">



                              <form [formGroup]="searchForm">
                                <div class="row align-items-center justify-content-between">
                                   

                                   
                                    <div class="col-md-6">
                                        <div class="row mb-2">
                                            <div class="col-md-4 ">
                                                <b>Product Name </b>



                                            </div>
                                            <div class="col-md-1">
                                                <label for="">:</label>
                                            </div>
                                            <div class="col-md-7">
                                                <input type="text" class="form-control" formControlName="productName" placeholder="Product name">
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-md-6">
                                        <div class="row mb-2">
                                            <div class="col-md-4 ">
                                                <b>Serial Number</b>



                                            </div>
                                            <div class="col-md-1">
                                                <label for="">:</label>
                                            </div>
                                            <div class="col-md-7">
                                                <input type="text" class="form-control" formControlName="serialNumber" placeholder="Serial number">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row mb-2">
                                            <div class="col-md-4 ">
                                                <b>Product Id</b>



                                            </div>
                                            <div class="col-md-1">
                                                <label for="">:</label>
                                            </div>
                                            <div class="col-md-7">
                                                <input type="text" class="form-control" formControlName="productId" placeholder="Product id">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row mb-2">
                                            <div class="col-md-4 ">
                                                <b>Status</b>



                                            </div>
                                            <div class="col-md-1">
                                                <label for="">:</label>
                                            </div>
                                            <div class="col-md-7">
                                                <select class="form-control form-select" formControlName="status">
                                                    <option value="">Select Status</option>
                                                    <option value="ACTIVE">ACTIVE</option>
                                                    <option value="BLOCK">BLOCK</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                              </form>
                                <div class="row justify-content-center">
                                    <div class="col-md-12">
                                        <button type="button" class="btn  btn-theme" (click)="search()">Search</button>
                                        <button type="button" class="btn  btn-theme " (click)="reset()">Reset</button>
                                        <button type="button" class="btn btn-theme pull-right" [routerLink]="['/add-product']">Add New Product</button>
                                        <button type="button" class="btn btn-theme pull-right" (click)="exportAsCsv()"><i class="fa fa-download" aria-hidden="true"></i>&nbsp;Export CSV</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table
                                    class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                    aria-describedby="user management list">
                                    <thead class="kv-align-center kv-align-middle kv-merged-header">
                                        <tr class="no_wrap_th text-center">
                                            <th scope="col" class="Sorting-img">S.no</th>

                                            <th scope="col" class="Sorting-img">Product Name</th>
                                            <th scope="col" class="Sorting-img">Product Id</th>
                                            <th scope="col" class="Sorting-img">Serial Number</th>
                                            <!-- <th scope="col" class="Sorting-img">Stock</th> -->
                                            <th scope="col" class="Sorting-img">Product Price</th>
                                            <th scope="col" class="Sorting-img">Status</th>
                                            <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center"
                                            *ngFor="let item of productList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems} ;let i = index"
                                            class="text-center">


                                            <td>{{itemPerPage * (currentPage - 1) + i+1}}</td>
                                            <td class="content">{{item?.product.productName || '--'}} </td>
                                            <td class="content"> {{item?.product.productId}}</td>
                                            <td class="content">{{item?.product.serialNumber || '--'}} </td>

                                            <td class="content">{{(item?.product.price ) || '--'}} </td>
                                            <td class="content">{{(item?.product.productStatus) || '--'}} </td>

                                            <!-- <td class="content">{{item?.status || '--'}} -->
                                            <!-- </td> -->
                                            <td class="justify-content-center d-flex">
                                                <button (click)="viewcategory(item?.product.productId)" data-toggle="modal"
                                                    class="btn btn-info ml-2 bt-margin" title="View">
                                                    <em class="fa fa-eye" aria-hidden="true"></em></button>
                                                <button (click)="editcategory(item?.product.productId)"
                                                    class="btn btn-info ml-2 bt-margin" title="Edit"><em
                                                        class="fa fa-edit" aria-hidden="true"></em></button>

                                                <button class="btn btn-success ml-2"
                                                    *ngIf="item?.product.productStatus == 'ACTIVE'" data-toggle="modal"
                                                    data-target="#BlockModal"
                                                    (click)="getProductId(item?.product.productId,'BLOCK')" title="Activate"><em
                                                        class="fa fa-ban" aria-hidden="true"></em></button>
                                                <button class="btn btn-danger ml-2"
                                                    *ngIf="item?.product.productStatus == 'BLOCK'" data-toggle="modal"
                                                    data-target="#BlockModal"
                                                    (click)="getProductId(item?.product.productId,'ACTIVE')"
                                                    title="Deactivate"><em class="fa fa-ban"
                                                        aria-hidden="true"></em></button>

                                                <button class="btn btn-danger ml-2 bt-margin" title="Delete"
                                                    data-toggle="modal" data-target="#deleteModal"
                                                    (click)="getProductId(item?.product.productId,'DELETE')"><em
                                                        class="fa fa-trash" aria-hidden="true"></em></button>

                                            </td>
                                        <tr *ngIf="productList?.length== 0">
                                            <td class="table-no-record" colspan="7">No Record Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > itemPerPage">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

<!-- delete gift-card-management modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this product?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal"
                                        (click)="deleteProduct()">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">

                    <h5 class="modal-title d-inline-block" style="text-transform: capitalize;">{{productStatus |
                        lowercase}}</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p>Are you sure you want to
                                    {{productStatus | lowercase}} this product?</p>

                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" (click)="blockUnblockProduct()"
                                        data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>