<aside class="sidebar" style="z-index: 7">
    <div class="adminprofile">
        <a class="navbar-brand logo" style="width: 100%; display: inline-flex; justify-content: center;">
            <img class="lg-logo side-img" [src]="profileData?.imageUrl|| 'assets/Product image/user.png'" alt="image"
                (click)="adminProfile()">
        </a>
        <div id="admin" class="profile-data-section">
            <label (click)="adminProfile()" style="word-break: break-word;">{{profileData?.firstName || 'Admin' }} <br
                    *ngIf="[profileData?.firstName].length > 10"> {{profileData?.lastName}}</label>
            <p (click)="adminProfile()">{{profileData?.email || 'admin@gmail.com' }}</p>
        </div>
    </div>
    <div class="sidebar-scroller">

        <div id="accordion" class="sidebar-menu">

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/dashboard' ? 'active' : ''"
                *ngIf="permissionArr.includes('DASHBOARD_MANAGEMENT')" style="border-top: 1px solid #1975a7;">
                <div class="card-header clearfix" [routerLink]="['/dashboard']" id="headingThree" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-dashboard" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Dashboard</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/user-mangement' || currentUrl === '/view-user' ||currentUrl ==='/view-order'? 'active' : '' 
            " *ngIf="permissionArr.includes('CUSTOMER_MANAGEMENT')" style="border-top: 1px solid #1975a7;">
                <div class="card-header clearfix" [routerLink]="['/user-mangement']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-user" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">
                            Customer Management</span></a>
                </div>
            </div>


            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/subAdmin' || currentUrl?.includes('view-sub-admin')
            || currentUrl === 'add-sub-admin' || currentUrl?.includes('edit-sub-admin')? 'active' : ''"
                style="border-top: 1px solid #1975a7;" *ngIf="permissionArr.includes('SUB_ADMIN_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/subAdmin']" id="headingThree" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-users" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Sub Admin Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/category-list'
            || currentUrl === '/add-category' || currentUrl?.includes('/view-category') || currentUrl?.includes('/edit-category') ? 'active': ''"
                style="border-top: 1px solid #1975a7;" *ngIf="permissionArr.includes('CATEGORY_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/category-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-bullhorn" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Category Management</span></a>
                </div>
            </div>



            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/product-list' || currentUrl?.includes('/view-product') || currentUrl?.includes('/edit-product') || currentUrl === '/add-product'? 'active' : ''"
                style="border-top: 1px solid #1975a7;" *ngIf="permissionArr.includes('PRODUCT_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/product-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-product-hunt" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Product
                            Management</span></a>
                </div>
            </div>
            
            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/attribute-list' || currentUrl?.includes('/attribute') ? 'active' : ''"
                style="border-top: 1px solid #1975a7;" *ngIf="permissionArr.includes('ATTRIBUTE_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/attribute-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-paperclip" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Attribute
                            Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/brand' || currentUrl?.includes('/view-brand') || currentUrl?.includes('/edit-brand') || currentUrl === '/add-brand'? 'active' : ''"
                style="border-top: 1px solid #1975a7;" *ngIf="permissionArr.includes('BRAND_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/brand']" id="headingThree" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-bold" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Brand
                            Management</span></a>
                </div>
            </div>


           


            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/discount-Management' || currentUrl?.includes('discount') ? 'active' : ''"
                style="border-top: 1px solid #1975a7;" *ngIf="permissionArr.includes('DISCOUNT_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/discount-Management']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-percent" aria-hidden="true"></i></span><span
                            class="side_page_name head_color"> Discount
                            Management</span></a>
                </div>
            </div>
              <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/offer-Management' || currentUrl?.includes('offer') ? 'active' : ''"
                style="border-top: 1px solid #1975a7;" *ngIf="permissionArr.includes('DISCOUNT_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/offer-Management']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseoffer" aria-expanded="false"
                    aria-controls="collapseoffer">
                 <a class="side_menu">
                 <span class="side_menu_icon"><i class="fa fa-percent" aria-hidden="true"></i></span><span
                      class="side_page_name head_color"> Offer
                            Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu adsmennu"
                [ngClass]="currentUrl === '/banner-management' || currentUrl?.includes('banner')  ? 'active': '' "
                *ngIf="permissionArr.includes('BANNER_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/banner-management']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseads" aria-expanded="false"
                    aria-controls="collapseads6">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-file-image-o" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Banner Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu adsmennu"
                [ngClass]="currentUrl === '/order' || currentUrl === '/view-order-list' || currentUrl === '/edit-order'? 'active': '' "
                *ngIf="permissionArr.includes('ORDER_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/order']" id="headingThree" data-toggle="collapse"
                    data-target="#collapseads" aria-expanded="false" aria-controls="collapseads6">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Order Management</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu adsmennu"
            [ngClass]="currentUrl === '/testimonial' || currentUrl?.includes('testimonial')? 'active': '' "
            *ngIf="permissionArr.includes('TESTIMONIAL_MANAGEMENT')">
            <div class="card-header clearfix" [routerLink]="['/testimonial']" id="headingThree" data-toggle="collapse"
                data-target="#collapseads" aria-expanded="false" aria-controls="collapseads6">
                <a class="side_menu">
                    <span class="side_menu_icon"><i class="fa fa-quote-left" aria-hidden="true"></i></span><span
                        class="side_page_name head_color">Testimonial Management</span></a>
            </div>
        </div>


            <div class="menu-box toggle-menu adsmennu"
                [ngClass]="currentUrl === '/statics-content' || currentUrl?.includes('static')  ? 'active': '' "
                *ngIf="permissionArr.includes('STATIC_CONTENT_MANAGEMENT')">
                <div class="card-header clearfix" [routerLink]="['/statics-content']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseads" aria-expanded="false"
                    aria-controls="collapseads6">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-file" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Static
                            Content Management</span></a>
                </div>
            </div>



            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/faq' ? 'active' : '' || currentUrl === '/add-faq' ? 'active': ''
                                   || currentUrl === '/edit-faq' ? 'active': ''
                            || currentUrl === '/view-faq' ? 'active': ''" *ngIf="permissionArr.includes('FAQ')">
                <div class="card-header clearfix" [routerLink]="['/faq']" id="headingsixs" data-toggle="collapseoneplus"
                    data-target="#collapseoneplus" aria-expanded="false" aria-controls="collapseoneplus">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-question-circle"
                                aria-hidden="true"></i></span><span class="side_page_name head_color">Faq</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" 
            [ngClass]="
            currentUrl === '/support-us' ? 'active' : ''" 
            *ngIf="permissionArr.includes('SUPPORT')">
                <div class="card-header clearfix" [routerLink]="['/support-us']" id="headingsixs" data-toggle="collapseoneplussupportus"
                    data-target="#collapseoneplussupportus" aria-expanded="false" aria-controls="collapseoneplussupportus">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-address-book"
                                aria-hidden="true"></i></span><span class="side_page_name head_color">Support </span></a>
                </div>

            </div>







            <div class="menu-box">
                <div class="card-header clearfix" id="headingOne" data-toggle="modal" data-target="#logoutModal1">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Logout</span>
                    </a>
                </div>

            </div>
        </div>


    </div>





</aside>




<!-- logout modal -->
<div class="modal fade global-modal reset-modal" id="logoutModal1" [ngClass]="showModal ? '' : 'showModal'">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Logout?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to logout?</p>
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="mainService.onLogout()"
                                        data-dismiss="modal">YES</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>