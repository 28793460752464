<div>
    <main class="middle-content">
       <div class="page_title_block1">
            <h1 class="page_title float-left"> Add new category</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/category-list">Back</a>
                </small>
                <div class="float-right">
                    <button type="submit" name="save"  (click)="addNewCategory()"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Save
                    </button>
               </div>
       </div>       
        <div class="content-section">
            <div class="order-view  max-WT-700  setting-page">
                <div class="mb40">
                    <form [formGroup]="addForm">
                        <div class="add-store-block input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">Enter Category Name <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">

                                        <input type="text" class="form-control " placeholder="Enter Category Name"
                                            style="text-transform: capitalize;" formControlName="name">
                                        <div *ngIf="name.errors &&(name.touched|| name.dirty)" class="text-danger">
                                            <span *ngIf="name.errors.required">
                                                *Please enter Categories Name
                                            </span>

                                        </div>

                                    </div>
                                </div>


                            </div>






                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Upload Image <span>:</span>
                                </label>

                                <div class="col-lg-8">





                                    <p style="margin-top: 4px;">
                                        <input #file type="file" (change)="getImage($event)" accept='image/*'
                                            accept=".jpeg,.png" />
                                        <img [src]=" imgSrc || 'assets/img/dummy.png'" alt="" style="width: 14%; " />

                                    </p>
                                </div>



                            </div>
                        </div>
                    </form>

                </div>



            </div>
        </div>
    </main>
</div>