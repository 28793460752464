import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
 ipAddress : any
 userAgent : any
 currLat : any
 currLng : any
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    
    if(this.mainService.isLoggedIn()){
      this.router.navigate(['/dashboard'])
    }
    
    this.loginFormValidation();
    this.getCurrentLocation()
    this.mainService.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
     
    })
   this.userAgent = this.getBrowserName()
   
   
    
  }

  //-------  login form validation -------- //
  loginFormValidation() {
    var data
    if(localStorage.getItem("loginData")){
      data = JSON.parse(localStorage.getItem("loginData"))
    }
    
    
    this.loginForm = new FormGroup({
      email: new FormControl(data?.email || '' , [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)$/i)]),
      password: new FormControl(atob(data?.password || ''), Validators.required),
      rememberMe: new FormControl(data?.rememberMe || '')
    });
  }

  // ---------  login form submit ------------- //

  onLogin(){
    const body = {
      "email":this.loginForm.value.email,
      "ipAddress":this.ipAddress,
      "userAgent":this.userAgent,
      "location":this.currLat+","+this.currLng, 
      "password":this.loginForm.value.password
      
    }
    console.log(body);
    // 
    this.mainService.showSpinner()
   
    this.mainService.postApi('auth',body,0).subscribe((res)=>{
      if(res.status == 200){
        localStorage.setItem("token",res.data.token)
        this.mainService.successToast(res.message)
        if(this.loginForm.value.rememberMe){
          var loginData = {
            email : this.loginForm.value.email,
            password : btoa(this.loginForm.value.password),
            rememberMe : this.loginForm.value.rememberMe
          }
          localStorage.setItem('loginData',JSON.stringify(loginData))
        }else{
          localStorage.removeItem('loginData')
        }
        this.mainService.hideSpinner()
        this.router.navigate(['dashboard'])
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    }, (err)=>{
      this.mainService.hideSpinner()
        this.mainService.errorToast(err)
    })
  }
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
}
getCurrentLocation() {
  this.mainService.getPosition().then(pos=>
    {
      this.currLat = pos.lat
      this.currLng = pos.lng
      
    });
}
  //  get profile
 
}
