    <main class="middle-content">
              <div class="page_title_block1">
            <h1 class="page_title float-left"> View FAQs</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/faq">Back</a>
                </small>
       </div>

        <div class="content-section">
            <div class="order-view max-WT setting-page">
                <div class="mb40">
                    <div class="add-store-block input-style mb20 ">
                        <form>
                            <div class="form-group row align-items-baseline">
                                <div class="col-md-12">
                                    <h4 class="mt20">Question</h4>
                                    <p style="word-break: break-all;">{{faqView.question}}</p>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <div class="col-md-12">
                                    <h4 class="mt20">Answer</h4>
                                    <p style="word-break: break-all;"> {{faqView.answer}}</p>
                                    <p class="mt30">{{(faqView?.updatedAt | date : 'd/M/yyyy, h:mm a') || '--'}}</p>
                                </div>
                            </div>
                        </form>
                        <!-- <div class="text-center mt40 mb40">
                            <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/faq" >Back</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </main>


