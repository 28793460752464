<div >
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">EDIT SUB CATEGORY</h1>
            <hr>

        </div>
        <div class="content-section">

            <div class="order-view  max-WT-700  setting-page">
                <div class="  mb40">
                    <form [formGroup]="editForm">
                        <div class="add-store-block input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">Category Name
                                    <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">


                                        <div class="">
                                            <div class="serch-boxuser">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <input class="form-control" type="text"
                                                           value="E-V Charger"
                                                           readonly
                                                            placeholder="Enter sub category Name">

                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                               
                               
                                <label class="col-md-4 textalignright ">Sub Category Name
                                    <span>:</span>
                                </label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">


                                        <div class="">
                                            <div class="serch-boxuser">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <input class="form-control" type="text"
                                                            formControlName="subCategoryName"
                                                           
                                                            placeholder="Enter sub category Name">

                                                    </div>
                                                    <span
                                                        *ngIf="subCategoryName.errors && (subCategoryName.touched || subCategoryName.dirty)"
                                                        class="error">
                                                        <span *ngIf="subCategoryName.errors.required">*Please enter sub
                                                            Category name.</span>
                                                       
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                               
                                <label class="col-md-4 ">Upload Image <span>:</span>
                                </label>
                                <div class="col-md-8 ">
                                    <div class="form-group ">



                                        <p style="margin-top: 4px;">
                                            <input #file type="file" accept='image/*'
                                                />
                                            <img [src]="'assets/img/dummy.png'" alt=""
                                                style="width: 14%; border: 1px solid black;" />

                                        </p>

                                    </div>
                                    <div class=" mt40 mb40">
                                        <button class="btn btn-large  max-WT-150 btn-primary ml-2"
                                            (click)="editValueApi()">Submit</button>&nbsp;
                                        <button class="btn btn-large  max-WT-150 btn-secondary"
                                            routerLink="/sub-category-list">Back</button>
                                    </div>
                                </div>







                            </div>
                        </div>
                       
                    </form>
                </div>
            </div>
        </div>
    </main>
</div>
