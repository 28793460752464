<main class="middle-content">
         <div class="page_title_block1">
            <h1 class="page_title float-left"> Edit {{type | lowercase}}</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" [routerLink]="['/statics-content']">Back</a>
                </small>
   
                <div  class="float-right ">
                 <button style="margin-right:6px;" (click)="preview()" type="button" data-toggle="modal" data-target=" #examppreview3" name="save"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Preview
                  </button>
                      <button style="margin-right: 5px;" type="submit" name="save"  (click)="updateStatic('UNPUBLISHED')"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Draft
               </button>
                    <button type="submit" name="save"  (click)="updateStatic('PUBLISHED')"  class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Save
               </button>
         </div>
       </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row ">
                            <label class="col-md-4">Page Name </label>
                            <div class="col-md-8">
                                <textarea class="form-control common-textarea"  rows="2" disabled >{{type}}</textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Description</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12" *ngIf = "editorValue">    
                                <ck-editor  name="editor1" [(ngModel)]="editorValue" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>


<!--  Preview popup For view Product Details -->

<div class="modal fade bd-example-modal-lg" id="examppreview3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog image-dialog modal-lg" role="document">
    <div class="modal-content" style="margin-top: -43px;">
 <app-preview-header></app-preview-header>
      <div class="modal-body bg-w height500">
        <form>
               <div class="add-store-block input-style mb20 mt20">
                        <div class="row " >
                                <div class="col-md-12 ">
                                <p class="description ptag" [innerHtml]="sanitizer.bypassSecurityTrustHtml(editorValue)"></p>
                                </div>
                            </div>
                     </div>
             </form>
         </div>
        <app-preview-footer></app-preview-footer> 
    </div>
  </div>
</div>
  


