import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  addForm: FormGroup
imgSrc : any


  constructor(private route: Router, private mainservice: MainService) { }

  ngOnInit() {
    this.addFormvalidation()

  }
  addFormvalidation() {
    this.addForm = new FormGroup({
      name: new FormControl("", [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]),
    
    })
  }

  get name() {
    return this.addForm.get('name')
  }

  addNewCategory(){
    if(this.imgSrc == "assets/loader/1488 (2).gif" ){
      this.mainservice.infoToast("Please wait while uplaoding category image.")
      return
    }
    let url = "product/add-Category"
    const body = {
  
      "categoryImage": this.imgSrc,
      "categoryName":this.addForm.value.name,
      "categoryStatus": "ACTIVE",
     
    }
    this.mainservice.showSpinner()
    this.mainservice.postApi(url,body,1).subscribe((res)=>{
      if(res.status == 200){
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res.message)
        this.route.navigate(['/category-list'])
      }
      else{
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res.message)
      }
    },(err)=>{
      this.mainservice.hideSpinner()
        this.mainservice.errorToast(err)
    })
  }
 getImage(event){
  var img = event.target.files[0]
  
  this.uploadImage(img)
 }

  uploadImage(img){
    let endUrl = "static/upload-file"
    this.imgSrc ="assets/loader/1488 (2).gif"
    var fb = new FormData()
    fb.append('file',img)
    this.mainservice.uploadFile(endUrl,fb).subscribe((res)=>{
      if(res.status == 200){
        this.imgSrc = res.data
      }
      else{
        this.mainservice.errorToast("something went wrong while uploading image")
      }
    })
  }
}
