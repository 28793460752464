<div >
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Testimonial Management</h1>
            <hr>
        </div>

        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                           
                                <div class="sec_head_new sec_head_new_after">



                                    <div class="row align-items-center">
                                        <div class="col-md-3 pr-0">
                                            <div class="filter_search mb20 width100">

                                                <label class="d-flex align-items-center">Search:</label>
                                                <div class="input-group filter_search_group">

                                                    <input type="text" class="form-control overflow-text"

                                                        placeholder="Search by category name"  [(ngModel)]="searchText">
                                                    <div class="input-group-append">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   
                                        <div class="col-md-5">
                                            <div class="text-center admin-btn mb2 mt10">
                                                <button type="button" class="btn  btn-theme" (click)="search()">Search</button>
                                               
                                                <button type="button" class="btn  btn-theme " (click)="reset()">Reset</button>
                                                <!-- <button type="button" class="btn  btn-theme "
                                                     routerLink="/add-brand">Add
                                                    Brand</button> -->
                                                    <div class="col-4 pull-right">
                                                        <button type="button" class="btn btn-theme "
                                                            [routerLink]="['/add-testimonial']">Add
                                                            Testimonial
                                                        </button>
                                                    </div>

                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                           <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                aria-describedby="user management list">
                                <thead class="kv-align-center kv-align-middle kv-merged-header">
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img">S.no</th>
                                     
                                        <th scope="col" class="Sorting-img">Name</th>
                                        <th scope="col" class="Sorting-img">Image</th>
                                        <th scope="col" class="Sorting-img">Description</th>
                                        <!-- <th scope="col" class="Sorting-img">Created At</th> -->
                                        <th scope="col" class="Sorting-img">Status</th>                      
                                         <!-- <th scope="col" class="Sorting-img">Index</th> -->
                                        <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center"
                                        *ngFor="let item of categoryList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total} ;let i = index"
                                        class="text-center">


                                        <td>{{itemPerPage * (currentPage - 1) + i+1}}</td>
                                     
                                        <td class="content">{{item?.name || '--'}} </td>
                                        <td class="content"> <img src={{item?.image}} alt="N/A"
                                            style="width: 66px;border-radius: 50%;"></td>
                                            <td class="content">{{item?.message || '--'}} </td>
                                        <!-- <td class="content">{{(item?.createTime | date : 'dd/MM/yyyy, hh:mm a' ) || '--'}} </td> -->
                                        <td class="content">{{item?.testimonialsStatus || '--'}}
                                        </td>
                                        <!-- <td><input type="number" min="1" [max]="total" class="form-control" 
                                            value="{{item.indexNumber}}" #f
                                            (change)="setIndex(f.value,item)" 
                                            placeholder="v"  style="margin: auto;min-width: 5em;padding-left: 20px;">
                                            

                                        </td> -->
                                        <td class="justify-content-center d-flex">
                                            <button (click)="viewcategory(item)" data-toggle="modal" 
                                                class="btn btn-info ml-2 bt-margin" title="View">
                                                <em class="fa fa-eye" aria-hidden="true"></em></button>
                                            <button (click)="editcategory(item)" class="btn btn-info ml-2 bt-margin"
                                                title="Edit"><em class="fa fa-edit" aria-hidden="true"></em></button>

                                            <!-- <button class="btn btn-success ml-2" *ngIf="item?.categoryStatus == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal"
                                                (click)="block(item?.testimonialId,'BLOCK')"
                                                title="Activate"><em class="fa fa-ban"
                                                    aria-hidden="true"></em></button>
                                            <button class="btn btn-danger ml-2" *ngIf="item?.categoryStatus == 'BLOCK'" data-toggle="modal" data-target="#BlockModal"
                                                (click)="block(item?.testimonialId,'ACTIVE')" title="Deactivated"><em
                                                    class="fa fa-ban" aria-hidden="true"></em></button> -->
                                                    
                                            <button class="btn btn-danger ml-2 bt-margin" title="Delete" data-toggle="modal" data-target="#deleteModal"
                                                (click)="delete(item?.testimonialId)"><em class="fa fa-trash"
                                                    aria-hidden="true"></em></button>

                                        </td>
                                    <tr *ngIf="categoryList?.length == 0">
                                        <td class="table-no-record" colspan="6">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-align-end">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>

                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

<!-- delete gift-card-management modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this category?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" 
                                        data-dismiss="modal" (click)="deteleCategory()">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


