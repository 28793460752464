    <main class="middle-content">
                <div class="page_title_block1">
              <h1 class="page_title float-left"> Support Details</h1>
                <small class="pad">
                    <i  class="fa fa-arrow-circle-left colr"></i>
                    <a class="po" routerLink="/support-us">Back</a>
                </small>
             </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="mb40">
                    <div class="admin-profile">
                        <h4 class="mb20 text-center">View Support Details</h4>
                    </div>
                    
                    <div class="add-store-block input-style mb20 mt20 ">
                        
                        
                        <div class="sheet">
                            <label class="textaligncenter labelone"> Name</label>
                            <label class="">:</label>
                            <div class="textaligncenter">
                            <p>{{viewSupport?.name || '--'}}</p>
                            </div>
                        </div>
                        <div class="sheet">
                            <label class="textaligncenter labelone ">Subject </label>
                            <label class="">:</label>
                            <div class="textaligncenter">
                            <p>{{viewSupport?.subject || '--'}}</p>
                            </div>
                        </div>
                        <div class="sheet">
                            <label class="textaligncenter labelone"> Email</label>
                            <label class="">:</label>
                            <div class="textaligncenter">
                            <p>{{viewSupport?.emailAddress || '--'}}</p>
                            </div>
                        </div>
                        <div class="sheet">
                            <label class="textaligncenter labelone ">Phone Number </label>
                            <label class="">:</label>
                            <div class="textaligncenter">
                            <p>{{viewSupport?.phone || '--'}}</p>
                            </div>
                        </div>
                        <div class="sheet">
                            <label class="textaligncenter labelone ">Message </label>
                            <label class="">:</label>
                            <div class="textaligncenter">
                            <p>{{viewSupport?.message || '--'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button class="btn btn-theme " title="Reply" data-toggle="modal" data-target="#deleteModal"
                           >Reply</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <div class="modal fade global-modal reset-modal" id="deleteModal">
        <div class="modal-dialog" style="width: 50em;">
            <form class="change_password">
                <div class="modal-content">
                    <div class="modal-header d-block text-center modal-header-custm">
                        <h5 class="modal-title d-inline-block">Reply</h5>
                    </div>
                    <div class="modal-inner-content">
                        <form [formGroup]="replyFrom">
                        <div class="modal-body">
                            <div class="row mb-3">
                                <div class="col-md-12 inp">
                                    <input type="text" class="form-control"  formControlName="subject" placeholder="Subject">
                                    <span class="error" *ngIf="subject.errors">
                                        <p *ngIf="subject.errors.required && (subject.touched || subject.dirty)">*Please Enter Subject </p>
                                    </span>
                                </div>
                               
                               
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-12 inp">
                                    <textarea name="" class="form-control" placeholder="Message"  formControlName="message" id="" cols="30" rows="10"></textarea>
                                    <span class="error" *ngIf="message.errors">
                                        <p *ngIf="message.errors.required && (message.touched || message.dirty)">*Please Enter Message </p>
                                    </span>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-2">
                                  <button class="btn btn-theme" (click)="replyUser(viewSupport?.emailAddress,replyFrom.value.subject,replyFrom.value.message)" data-modal="dismiss">Send </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </form>
        </div>
    </div>
