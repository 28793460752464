import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
@Component({
  selector: 'app-edit-brand',
  templateUrl: './edit-brand.component.html',
  styleUrls: ['./edit-brand.component.css']
})
export class EditBrandComponent implements OnInit {
  imgSrc : any
  editForm: FormGroup;
  brandId : any
  constructor(private router: Router, public mainService: MainService,private activated : ActivatedRoute) { 
    this.activated.queryParams.subscribe((res)=>{
      this.brandId = res.id
    })
  }

  ngOnInit(): void {
    this.editForm = new FormGroup({
      'image': new FormControl('', [Validators.required]),
      "brandName": new FormControl('', Validators.required),
      "category": new FormControl('', Validators.required),
      "subCategory":new FormControl('', Validators.required),
      "description":new FormControl('', Validators.required),
    });
    this.getbrandData()
  }
  getbrandData(){
    let url = "product/view-brand-By-Id?brandId="+this.brandId
    const data = {
      
      
    }
    console.log(data);
   
    
    this.mainService.showSpinner()
    this.mainService.postApi(url,data,1).subscribe((res)=>{
      if(res.status == 200){
        // this.brandData = res.data
        this.editForm.patchValue({
          'brandName' : res.data.brandName,
          'category' : res.data.productName || 'N/A',
          'description' : res.data.brandDescription || 'N/A',
        })
        this.imgSrc = res.data.brandImage
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    },(err)=>{
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })
  }
  editBrand(){
    let url = "product/update-brand"
    const data = {
      "brandDescription": this.editForm.value.description,
      "brandId": this.brandId,
      "brandImage":this.imgSrc,
      "brandName": this.editForm.value.brandName
    }
    console.log(data);
   
 
    this.mainService.showSpinner()
    this.mainService.postApi(url,data,1).subscribe((res)=>{
      if(res.status == 200){
        
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    },(err)=>{
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })

  }
  getImage(event){
    var img = event.target.files[0]
    
    this.uploadImage(img)
   }
  
    uploadImage(img){
      let endUrl = "static/upload-file"
      this.imgSrc ="assets/loader/1488 (2).gif"
      var fb = new FormData()
      fb.append('file',img)
      this.mainService.uploadFile(endUrl,fb).subscribe((res)=>{
        if(res.status == 200){
          this.imgSrc = res.data
        }
        else{
          this.mainService.errorToast("something went wrong while uploading image")
        }
      })
    }
}
