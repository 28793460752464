import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { RouteingUrlService } from 'src/app/service/routeing-url.service';
declare var $
@Component({
  selector: 'app-support-us',
  templateUrl: './support-us.component.html',
  styleUrls: ['./support-us.component.css']
})
export class SupportUsComponent implements OnInit {
  searchForm: FormGroup;
  flip : boolean = false
  isSearched : boolean = false
  itemPerPage = 5;
  currentPage = 1; 
  limit: any = 5;
  total: any;
  items = [];
  staticlist:any =[]
  orderList: any;
  totalItems: number;
  constructor(private router: Router,public mainService: MainService,private activated : ActivatedRoute,private date : DatePipe) { 
    this.searchFormValidation()
  // console.log(this.activated.queryParams);
  // if(this.activated.queryParams){
  //   this.activated.queryParams.subscribe((res)=>{
  //     if(res.fromDate && !res.toDate){
  //       this.searchForm.patchValue({
  //         fromDate : res.fromDate.split('T')[0]
  //       })
  //     }
  //     else{
  //       this.searchForm.patchValue({
  //         fromDate : res.fromDate.split('T')[0],
  //         toDate : res.toDate.split('T')[0]
  //       })
  //     }
  //   })
  //  }
  }

  ngOnInit() {
    this.getSupportList()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      subject: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
      fromDate : new FormControl(),
      toDate : new FormControl(),
      status :  new FormControl('')
    });
  }
  getSupportList(){
    let url2 ='static/get-static-data-list'
    let url="static/get-static-data-list?page="+(this.currentPage-1)+"&pageSize="+(this.itemPerPage)
    this.mainService.getApi(url ,0).subscribe((res)=>{
      if(res.status == 200){
        this.staticlist = res.data.Details
        
        this.totalItems = this.staticlist.length
        this.mainService.hideSpinner()
      }
      else{
        this.mainService.hideSpinner()
      }
    })

  }

  search(){
    
    this.isSearched = true
    this.flip = true
    this.currentPage = 1
   
      if(this.searchForm.value.name){
        this.staticlist = this.staticlist.filter((res)=>{
          return String(this.searchForm.value.name).toLowerCase() == String(res.name).toLowerCase()
        })
      }
      if(this.searchForm.value.subject){
        this.staticlist = this.staticlist.filter((res)=>{
          return String(this.searchForm.value.subject).toLowerCase() == String(res.subject).toLowerCase()
        })
      }
      if(this.searchForm.value.email){
        this.staticlist = this.staticlist.filter((res)=>{
          return String(this.searchForm.value.email).toLowerCase() == String(res.emailAddress).toLowerCase()
        })
      }
      if(this.searchForm.value.phone){
        this.staticlist = this.staticlist.filter((res)=>{
          return String(this.searchForm.value.phone).toLowerCase() == String(res.phone).toLowerCase()
        })
      }
      if(this.searchForm.value.fromDate && !this.searchForm.value.toDate){
        this.staticlist = this.staticlist.filter((res)=>{
          
          return this.date.transform(res?.createTime, 'yyyy-MM-dd') >= this.searchForm.value.fromDate
        })
      }
      if(this.searchForm.value.status){
        this.staticlist = this.staticlist.filter((res)=>{
          return String(this.searchForm.value.status).toLowerCase() == String(res.staticStatus).toLowerCase()
        })
      }
      if(this.searchForm.value.fromDate && this.searchForm.value.toDate){
        this.staticlist = this.staticlist.filter((res)=>{
          
          return this.date.transform(res?.createTime, 'yyyy-MM-dd') >= this.searchForm.value.fromDate && this.date.transform(res?.createTime, 'yyyy-MM-dd') <= this.searchForm.value.toDate
        })
      }
      this.totalItems =  this.staticlist.length

  }
  reset(){
   
    this.flip = false
    this.isSearched = false
    this. getSupportList()
    this.searchForm.reset()
   
  }
  viewDetails(id){
    this.router.navigate(['view-support'],{queryParams : {id : id}})
 }

 pagination(event){
   this.currentPage = event
 
 }
 
 updateStatus(stat,item){
  let url = "static/update-static-data?staticId="+item.staticId
  const data ={
   
    "emailAddress": item.emailAddress,
    "message": item.message,
    "name": item.name,
    "phone": item.phone,
    "staticStatus": stat,
    "subject": item.subject,
    
  }
  this.mainService.showSpinner()
  this.mainService.postApi(url,data,1).subscribe((res)=>{
    if(res.status == 200){
      this.mainService.hideSpinner()
      this.mainService.successToast(res.message)
    }
    else{
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.message)
    }
  })
 }
}
