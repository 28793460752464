import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqId: any;
  itemPerPage = 5;
  currentPage = 1;
  total: any;
  
  staticList: any=[
  
  ];
  faqList: any;

  constructor(private router: Router,
    public mainService: MainService) { 
      
    }

  ngOnInit(): void {
    this.getAllFaq()
  }
  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getAllFaq()
  }
  getAllFaq(){
    let url = "static/get-faq-detail-list"
   this.mainService.showSpinner()
   this.mainService.getApi(url,1).subscribe((res)=>{
     if(res.status == 200){
       this.faqList = res.data.filter((res)=>{
        
        return res.category[0].categoryStatus != 'DELETE' 
       })
       this.faqList = this.faqList.filter((res)=>{
        
        return res.product.isDeleted == false
       })
      this.total = this.faqList.length
       this.mainService.hideSpinner()
       this.mainService.successToast(res.message)
     }
     else{
        this.mainService.hideSpinner()
       this.mainService.errorToast(res.message)
     }
   })
  }
  addFaq(type) {
    this.router.navigate(['/add-faq'], { queryParams: { id: type } })
  }
  viewUser(id) {
    this.router.navigate(['view-faq'], { queryParams: { id: id } })
  }
  editFaq(id) {
    this.router.navigate(['/edit-faq'], { queryParams: { id: id } })
  }
  deleteFaqModal(id) {
    this.faqId = id
    $('#delete').modal('show')
  }

  deleteFaq() {
    let url = "static/delete-faq?faqId="+this.faqId
    const data ={

    }
    this.mainService.showSpinner()
    this.mainService.postApi(url,data,1).subscribe((res)=>{
      if(res.status == 200){
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
       
        this.getAllFaq()
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }
  hideModal(){
    $('#delete').modal('hide')

  }
  showModel(){
    $('#delete').modal('show')
  }
}
