import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-sub-admin-management',
  templateUrl: './sub-admin-management.component.html',
  styleUrls: ['./sub-admin-management.component.css']
})
export class SubAdminManagementComponent implements OnInit {

  
  currentPage: any = 1;
  itemPerPage: any = 5;
  total: any;
  subAdminForm : FormGroup
  userId : any
  flip : boolean = false
  resetPage : boolean= false
userStatus : any 
isSearched : boolean = false
  subAdminList : any = [
   
  ]

  constructor(private router: Router, private mainService: MainService, private datepipe : DatePipe) { }


  ngOnInit() {
    this.getSubAdminList()
     this.subAdminForm = new FormGroup({
       search : new FormControl(''),
 
     })
  }

  getSubAdminList(){
   let url = "account/admin/user-management/filter-user-details?page="+(this.currentPage-1)+"&pageSize="+(this.itemPerPage)+"&roleStatus=ADMIN"
   this.mainService.showSpinner()
   this.mainService.getApi(url,1).subscribe((res)=>{
     if(res.status == 200){
       this.subAdminList = res.data.list
       
       this.total = res.data.totalCount
      // this.mainService.successToast(res.message)

      this.mainService.hideSpinner()
     }else{
      // this.mainService.errorToast(res.message)
      this.mainService.hideSpinner()
     }
   },(err)=>{
    // this.mainService.errorToast(err)
    this.mainService.hideSpinner()
   })
  }
  /* search */
  search(){
    if(!this.subAdminForm.value.search){
      return
    }
    this.isSearched = true
    this.flip =  true
    if(!this.resetPage){
      this.currentPage = 1
    }

    let url = "account/admin/user-management/filter-user-details?page="+(this.currentPage-1)+"&pageSize="+(this.itemPerPage)+"&roleStatus=ADMIN&search="+this.subAdminForm.value.search
    this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      if(res.status == 200){
        this.subAdminList = res.data.list
        this.total = res.data.totalCount
       this.mainService.successToast(res.message)
 
       this.mainService.hideSpinner()
      }else{
       this.mainService.errorToast(res.message)
       this.mainService.hideSpinner()
      }
    },(err)=>{
     this.mainService.errorToast(err)
     this.mainService.hideSpinner()
    })
    this.resetPage = true
  }

  pagination(event) {
    this.currentPage = event;
    
if(this.flip){
this.search()
}else{
  this.getSubAdminList()
}
  
  }

  
 

 
  viewSubAdmin(id){
   
    this.router.navigate(["/view-sub-admin"],{queryParams:{id:id }})
  }
  editSubAdmin(id){
   
    this.router.navigate(["/edit-sub-admin"],{queryParams:{id:id}})
  }

  blockModal(id,stat){
    this.userId = id
    this.userStatus = stat
    console.log(this.userId);
    

  }
  blockSubAdmin(){
    let url
    if(this.userStatus == 'ACTIVE'){
       url = "account/admin/user-management/block-user"
    }else{
       url = "account/admin/user-management/unblock-user"
    }
    
    const data = {
      "blockedUserId" : this.userId
  }
  this.mainService.showSpinner()
  this.mainService.postApi(url,data,1).subscribe((res)=>{
    if(res.status){
      this.mainService.hideSpinner()
      this.mainService.successToast(res.message)
      this.getSubAdminList()
    }
    else{
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.message)
    }
  },(err)=>{
    this.mainService.hideSpinner()
      this.mainService.errorToast(err)
  })
  }
  reset(){
    if(!this.isSearched){
      return
    }
    this.isSearched = false
this.flip = false
this.resetPage = false
   this.subAdminForm.reset()
    this.getSubAdminList()
  }
  deleteModal(id){
    this.userId = id
  }
  deleteAdmin(){
   
  }
  exportAsXLSX() {
  
    let dataArr = [];
    this.subAdminList.forEach((element, ind) => {
      let obj = {}
      obj = {
        "S no": ind + 1,
        "Name" : element.firstName ? element.firstName : 'N/A',
        "Email": element.email ? element.email : 'N/A',

        "Phone No": element.phoneNo ? element.phoneNo : 'N/A',
        "Created At": element.createTime ?this.datepipe.transform(element.createTime ,'dd/MM/yyyy, hh:mm a')  : 'N/A',
        "Status": element.userStatus ? element.userStatus  : 'N/A',

      }
      dataArr.push(obj)
    })

    this.mainService.exportAsExcelFile(dataArr, 'Sub Admin Management');
  }
  currLat : any
  currLng : any
  ipAddress : any
  deleteSubAdmin(){
    let url = "account/admin/user-management/detele-staff"
    this.getCurrentLocation()
    const body = {
      "ipAddress": this.ipAddress,
      "location": this.currLat + ","+ this.currLng,
      "primaryIdCommonPerRequest":  this.userId
      
    }
    this.mainService.showSpinner()
    this.mainService.postApi(url,body,1).subscribe((res)=>{
      if (res.status == 200) {
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
       
        if(this.subAdminList.length == 1){
          this.currentPage = this.currentPage - 1
          
        }
        this.getSubAdminList()
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    },(err)=>{
      this.mainService.hideSpinner()
      this.mainService.errorToast(err)
    })
  }
  getCurrentLocation() {
    this.mainService.getPosition().then(pos=>
      {
        this.currLat = pos.lat
        this.currLng = pos.lng
        
      });
  }
  getIpAddress(){
    this.mainService.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
     
    })
  }
}
