import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-sub-admin',
  templateUrl: './edit-sub-admin.component.html',
  styleUrls: ['./edit-sub-admin.component.css']
})
export class EditSubAdminComponent implements OnInit {

  editform: FormGroup;
 permissionArr: any = [
    "DASHBOARD_MANAGEMENT",
    "CUSTOMER_MANAGEMENT",
    "SUB_ADMIN_MANAGEMENT",
    "CATEGORY_MANAGEMENT",
    "PRODUCT_MANAGEMENT",
    "ATTRIBUTE_MANAGEMENT",
    "BRAND_MANAGEMENT",
    "DISCOUNT_MANAGEMENT",
    "BANNER_MANAGEMENT",
    "ORDER_MANAGEMENT",
    "TESTIMONIAL_MANAGEMENT",
    "STATIC_CONTENT_MANAGEMENT",
    "FAQ",
    "SUPPORT",
  ];
  name : any = []
  privilage : any = []
  permArray = []
  subAdmin : any = [
    {
      _id : 14,
      name : "Mohit Kumar",
      email : "mohit@mailinator.com",
      mobileNumber : 9896452310,
      status : 'ACTIVE',
      lastLoggedIn : '14/08/2021. 12:16 AM'
    }
  ]
  check :any =[]
  arry = []
  subId: any;

 
  index = 0;



  constructor(private mainservice: MainService, private activatedRoute: ActivatedRoute, private router: Router) { 
    this.activatedRoute.queryParams.subscribe((res: any) => {
    this.subId = res.id
  }) }

  ngOnInit() {
    
    for(let i of this.permissionArr){
 
      this.name[this.index] = i.split('_').join(' ')
      this.index++
    }
    this.editform = new FormGroup({
      'name': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'phone': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/)]),
      'password': new FormControl('', [Validators.required]),
    })
    this.viewgetApi()
    this.index=0
  }
  viewgetApi() {
    let url = "account/admin/user-management/view-sub-admin?userId="+this.subId
    this.mainservice.showSpinner()
    this.mainservice.postApi(url,{},1).subscribe((res)=>{
      if(res.status == 200){
        this.mainservice.hideSpinner()
        this.subAdmin = res.data
        this.editform.patchValue({
          'name' : this.subAdmin.firstName,
          'phone' : this.subAdmin.phoneNo,
          'email' : this.subAdmin.email,

        })
        this.privilage = res.data.previlage
        console.log(this.privilage);
        this.permission()
        console.log(this.permArray);
        
        this.mainservice.successToast(res.message)
      }
      else{
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res.message)
      }
    },(err)=>{
      this.mainservice.hideSpinner()
      this.mainservice.errorToast(err)
    })
    }

  // editapi

  editSubAdmin() {
    console.log(this.privilage);
    let url = "account/admin/user-management/edit-sub-admin"
    const data = {
      userIdToUpdate : this.subId,
      "email": this.editform.value.email,
      "firstName": this.editform.value.name,
      
     
      "roleStatus": "SUBADMIN",
      "phoneNo": this.editform.value.phone,

     
      "previlage": this.privilage
    
    }
    this.mainservice.showSpinner()
    this.mainservice.postApi(url,data,1).subscribe((res)=>{
      if (res.status == 200) {
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res.message)
        this.router.navigate(['/subAdmin'])
      } else {
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res.message)
      }
    },(err)=>{
      this.mainservice.hideSpinner()
        this.mainservice.errorToast(err)
    })
    
  }
  checkPerm(permName,ch){
  
    console.log(permName,ch);
    if(ch){
      this.privilage.push(permName)
    }
    else{
     
      this.privilage.splice(this.privilage.indexOf(permName),1)
    }
    console.log(this.privilage);
    
   }

  permission(){
    let index = 0
    for(let item of this.permissionArr){
      console.log(this.privilage.indexOf(item))
      if(this.privilage.indexOf(item) >= 0){
        this.permArray.push(true)
      }
      else{
        this.permArray.push(false)
      }
    }
  }

}
