import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  
  userId: number;
  userData: any;
  
  constructor(private mainService : MainService,private activatedRoute : ActivatedRoute,private router : Router) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.userId = res.id
    })
  }

  ngOnInit(): void {
    this.getUserData()
  }
  getUserData(){
    let url ="account/my-account-p2p?userId="+this.userId
    
    // this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      if (res.status ==200) {
        this.userData = res.data
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  
}
back(){
  this.router.navigate(['/user-mangement'])

}
}
