<main class="middle-content">
    <div class="page_title_block1">
        <h1 class="page_title float-left" style="text-transform: capitalize;"> Edit {{addressType}} Address</h1>
        <small class="pad">
            <i class="fa fa-arrow-circle-left colr"></i>
            <!-- <a class="po" routerLink="/view-order-list">Back</a> -->
            <a class="po" (click)="back()">Back</a>

        </small>
        <div class="float-right">
            <button type="submit" name="save" class="btn btn-primary" (click)="updateAddress()">
                <i class="fa fa-floppy-o
              "></i>
                Update
            </button>
        </div>
    </div>

    <div class="content-section">
        <div class="order-view max-WT setting-page">
            <form [formGroup]="editForm">
                <div class="mb40">
                    <div class="add-store-block input-style mb20 ">
                        <!-- Full Name  -->
                        <div class="form-group row  view-label-align">
                            <label class="col-md-3 textalignright ">Full Name
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="Full name"
                                    formControlName="fullName">
                                <div *ngIf="editForm.get('fullName').invalid && (editForm.get('fullName').dirty || editForm.get('fullName').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('fullName').hasError('required')"> *Please enter full
                                        name.</span>
                                    <span *ngIf="editForm.get('fullName').hasError('pattern')"> *Please enter full valid
                                        name.</span>
                                </div>

                            </div>
                        </div>
                        <!-- Email -->
                        <!-- <div class="form-group row  view-label-align">
                            <label class="col-md-3 textalignright ">Email
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" maxlength="100" placeholder="Email address"
                                    formControlName="email">
                                <div *ngIf="editForm.get('email').invalid && (editForm.get('email').dirty || editForm.get('email').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('email').hasError('required')"> *Please enter email address.
                                    </span>
                                    <span *ngIf="editForm.get('email').hasError('pattern')"> *Please enter valid email address.
                                    </span>
                                </div>
                            </div>
                        </div> -->
                        <!-- Phone -->
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">Phone
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="Phone Number"
                                    formControlName="phone">


                                <div *ngIf="editForm.get('phone').invalid && (editForm.get('phone').dirty || editForm.get('phone').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('phone').hasError('required')"> *Please enter phone number.
                                    </span>
                                    <span *ngIf="editForm.get('phone').hasError('pattern')"> *Please enter valid phone number.
                                    </span>
                                </div>

                            </div>
                        </div>
                        <!-- Address -->
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">Address
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="Address" formControlName="address">


                                <div *ngIf="editForm.get('address').invalid && (editForm.get('address').dirty || editForm.get('address').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('address').hasError('required')"> *Please enter address.
                                    </span>
                                    <span *ngIf="editForm.get('address').hasError('pattern')"> *Please enter valid address.
                                    </span>
                                </div>


                            </div>
                        </div>
                        <!-- Area -->
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">Area
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="Area" formControlName="area">


                                <div *ngIf="editForm.get('area').invalid && (editForm.get('area').dirty || editForm.get('area').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('area').hasError('required')"> *Please enter area.
                                    </span>
                                    <span *ngIf="editForm.get('area').hasError('pattern')"> *Please enter valid area.
                                    </span>
                                </div>


                            </div>
                        </div>
                        <!-- Land Mark -->
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">Land Mark
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="Land Mark"
                                    formControlName="landMark">


                                <div *ngIf="editForm.get('landMark').invalid && (editForm.get('landMark').dirty || editForm.get('landMark').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('landMark').hasError('required')"> *Please enter landMark.
                                    </span>
                                    <span *ngIf="editForm.get('landMark').hasError('pattern')"> *Please enter valid landMark.
                                    </span>
                                </div>

                            </div>
                        </div>
                        <!-- City -->
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">City
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="City" formControlName="city">



                                <div *ngIf="editForm.get('city').invalid && (editForm.get('city').dirty || editForm.get('city').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('city').hasError('required')"> *Please enter city name.
                                    </span>
                                    <span *ngIf="editForm.get('city').hasError('pattern')"> *Please enter valid city name.
                                    </span>
                                </div>

                            </div>
                        </div>
                        <!-- State / province -->
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">State / province
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="State / province"
                                    formControlName="state">


                                <div *ngIf="editForm.get('state').invalid && (editForm.get('state').dirty || editForm.get('state').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('state').hasError('required')"> *Please enter state / province name.
                                    </span>
                                    <span *ngIf="editForm.get('state').hasError('pattern')"> *Please enter valid state / province name.
                                    </span>
                                </div>


                            </div>
                        </div>
                        <!-- Zip / postal code -->
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">Zip / postal code
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="Zip / postal code"
                                    formControlName="zipcode">


                                <div *ngIf="editForm.get('zipcode').invalid && (editForm.get('zipcode').dirty || editForm.get('zipcode').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('zipcode').hasError('required')"> *Please enter zipcode / postal code.
                                    </span>
                                    <span *ngIf="editForm.get('zipcode').hasError('pattern')"> *Please enter valid zipcode / postal code.
                                    </span>
                                </div>


                            </div>
                        </div>
                        <!-- Country -->
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">Country
                                <span>:</span></label>
                            <div class="col-md-9">
                                <input type="text" class="form-control" placeholder="Country" formControlName="country">


                                <div *ngIf="editForm.get('country').invalid && (editForm.get('country').dirty || editForm.get('country').touched)"
                                    class="text-danger">
                                    <span *ngIf="editForm.get('country').hasError('required')"> *Please enter country name.
                                    </span>
                                    <span *ngIf="editForm.get('country').hasError('pattern')"> *Please enter valid country name.
                                    </span>
                                </div>


                            </div>
                        </div>
                    </div>
                   
                </div>
            </form>
        </div>
    </div>
</main>