import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: FormGroup;
  email: any = atob(localStorage.getItem("email"));

  constructor(private activatedroute: ActivatedRoute, public mainService: MainService, public router: Router) {
    
  }

  ngOnInit() {

    this.resetPassword = new FormGroup({
      "password": new FormControl('', ([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)])),
      "confirmPassword": new FormControl('', [Validators.required])
    });

  }

  resetApi() {
    let url = "account/reset-password"
    const data = {
      "email": this.email,
      "password": this.resetPassword.value.password,
      "token": "string"
    }
    this.mainService.showSpinner()
    this.mainService.postApi(url,data,0).subscribe((res)=>{
      if(res.status == 200){
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
        localStorage.clear()
        this.router.navigate(['/login'])
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    },(err)=>{
      this.mainService.hideSpinner()
        this.mainService.errorToast(err)
    })
  }

}
