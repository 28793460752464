<main class="middle-content">
          <div class="page_title_block1">
            <h1 class="page_title float-left"> View transaction</h1>
                <small class="pad">
                <i  class="fa fa-arrow-circle-left colr"></i>
                <a class="po" routerLink="/transaction-management">Back</a>
                </small>
       </div>
    <div class="content-section">
        <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
            <div class="mb40">
                <div class="admin-profile">
                    <h4 class="mb20 text-center">View Transaction</h4>
                </div>

                <div class="add-store-block input-style mb20 mt20 ">
                    <div class="sheet">
                        <label class="textaligncenter labelone ">User Name
                        </label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p>{{transactionlist?.userName}}</p>
                        </div>

                    </div>
                    <div class="sheet">
                        <label class="textaligncenter labelone ">Email Address</label>
                        <label class="">:</label>
                        <div class="textaligncenter">
                            <p>{{transactionlist?.userEmail}}</p>
                        </div>
                    </div>

                   

                    <div class="sheet">
                        <label class="textaligncenter  labelone ">Transaction ID</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p>{{transactionlist?.txnId}}</p>


                        </div>
                    </div>
                    <div class="sheet">
                        <label class="textaligncenter labelone  ">Transaction Type</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p>{{transactionlist?.txnType}}</p>


                        </div>
                    </div>
                    <!-- <div class="sheet">
                        <label class="textaligncenter labelone  ">Address
                        </label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p style="word-break: break-all;">{{transactionlist?.address}}</p>


                        </div>
                    </div> -->

                    <div class="sheet">
                        <label class="textaligncenter labelone ">Transaction Hash</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p style="word-break: break-all;">{{transactionlist?.txnHash}}</p>


                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter labelone  ">Transaction Time&Date
                        </label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p>{{transactionlist?.txnTime}}</p>


                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter labelone ">Status</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p style="word-break: break-all;">{{transactionlist?.status}}</p>


                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter labelone ">TO Whom</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p style="word-break: break-all;">{{transactionlist?.address}}</p>


                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter labelone ">Current Type</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p style="word-break: break-all;">{{transactionlist?.coinType}}</p>


                        </div>
                    </div>

                    <div class="sheet">
                        <label class="textaligncenter labelone ">Amount</label>
                        <label class="">:</label>
                        <div class="textaligncenter">

                            <p style="word-break: break-all;">{{transactionlist?.fiatPrice}}</p>


                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="text-center mt40 mb40">
                <button class="btn btn-large  max-WT-150 btn-theme" (click)="changePassword()"
                    style="padding: 5px;">Change Password</button>
                <button class="btn btn-large  max-WT-150 btn-theme" (click)="editProfile()">Edit</button>
            </div> -->
           <!--  <div class="mt40 mb40 text-center">
                <button class="btn btn-theme ml-1" style="width: 15%" routerLink="/transaction-management">Back</button>
            </div> -->
        </div>
    </div>

</main>