<div>
    <main class="middle-content">
        <div class="page_title_block1">
            <h1 class="page_title float-left"> Edit Order</h1>
            <small class="pad">
                <i class="fa fa-arrow-circle-left colr"></i>
                <a class="po" (click)="back()">Back</a>
            </small>
            <div class="float-right">
                <button type="submit" name="save" [disabled]="editProfileForm.valid" (click)="editOrderList()"
                    class="btn btn-primary">
                    <i class="fa fa-floppy-o
                    "></i>
                    Update
                </button>
            </div>
        </div>
        <div class="pading20" id="accordion">
            <form [formGroup]="editProfileForm">
                <!-- info -->
                <div class="card mb7">
                    <div class="card-header" id="heading8">
                        <h5 class="mb-0">
                            <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse8"
                                aria-expanded="true" aria-controls="collapse8">
                                <i class="fa fa-info iconP"></i>Info
                            </button>
                        </h5>
                    </div>
                    <div id="collapse8" class="collapse show" aria-labelledby="heading8" data-parent="#accordion">
                        <div class="card-body">
                            <div class="card p-2">
                                <div class="row">
                                    <div class="col-md-3 text-left">
                                        <b>Order</b>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="">
                                            :
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                        {{buyOrderData?.randomOrderId}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3 text-left">
                                        <b>Created on
                                        </b>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="">
                                            :
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                        {{buyOrderData?.createTime | date : 'medium'}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3 text-left">
                                        <b>Customer</b>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="">
                                            :
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                        {{buyOrderData?.userName}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3 text-left">
                                        <b>Order status
                                        </b>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="">
                                            :
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                        {{buyOrderData?.orderStatus}}
                                    </div>
                                </div>
                            </div>


                            <!-- second card -->
                            <div class="card p-2 mt-3">
                                <div class="row">
                                    <div class="col-md-3 text-left">
                                        <b>Total Product price
                                        </b>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="">
                                            :
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                        ${{buyOrderData?.totalAmount}}
                                    </div>
                                </div>
                                <!-- discount -->
        <!--      <div class="row">
              <div class="col-md-3 text-left">
                <b>Discount
                </b>
              </div>
              <div class="col-md-1">
                <label for="">
                  :
                </label>
              </div>
              <div class="col-md-6 text-left">
                $ {{buyOrderData?.totalAmount}}
              </div>
            </div> -->

                                <div class="row">
                                    <div class="col-md-3 text-left">
                                        <b>Order shipping </b>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="">
                                            :
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                         $ {{buyOrderData?.totalShippingAmount}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3 text-left">
                                        <b>Order total
                                        </b>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="">
                                            :
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                         $ {{buyOrderData?.total}}
                                    </div>
                                </div>     

                                <div class="row">
                                    <div class="col-md-3 text-left">
                                        <b>Payment status
                                        </b>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="">
                                            :
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                     {{buyOrderData?.paymentStatus==='DONE' ? 'Success' :' Fail'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product Info -->
                <div class="card mb7">
                    <div class="card-header" id="heading7">
                        <h5 class="mb-0">
                            <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse7"
                                aria-expanded="false" aria-controls="collapse7">
                                <i class="fa fa-info iconP"></i>Product Info
                            </button>
                        </h5>
                    </div>
                    <div id="collapse7" class="collapse " aria-labelledby="heading7" data-parent="#accordion">
                        <div class="card-body">

                            <div class="table-responsive" style="margin-bottom: 30px;">
                                <table
                                    class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                    aria-describedby="Brand management list">
                                    <thead>
                                        <tr class="no_wrap_th text-center">
                                            <th scope="col" class="Sorting-img">Picture</th>
                                            <th scope="col" class="Sorting-img">Product name</th>
                                            <th scope="col" class="Sorting-img">Price</th>
                                            <th scope="col" class="Sorting-img">Quantity</th>
                                            <th scope="col" class="Sorting-img">Discount</th>
                                            <th scope="col" class="Sorting-img">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center"
                                            *ngFor="let item of orderProduct; let i = index">
                                            <td>
                                                <img [src]="item?.productImage[0]?.productImage" style="width: 20%;"
                                                    alt="" srcset="">
                                            </td>
                                            <td>
                                                <a style="color: blue;cursor: pointer;" routerLink="/view-product"
                                                    [queryParams]="{id : item.buyOrder.productId}">{{item.buyOrder.productName}}</a>
                                            </td>
                                            <td>
                                                {{item?.product[0].price}}
                                            </td>
                                            <td>
                                 {{item.buyOrder?.totalquantity}}
                                            </td>
                                            <td>
                                                {{item?.discount[0]?.discountPercentage}}%
                                            </td>
                                            <td>
                                                {{item?.buyOrder?.amount}}
                                            </td>
                                            

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Delivery Status -->
                <div class="card mb7">
                    <div class="card-header" id="heading6">
                        <h5 class="mb-0">
                            <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse6"
                                aria-expanded="false" aria-controls="collapse6">
                                <i class="fa fa-truck iconP"></i>Order Date & Status
                            </button>
                        </h5>
                    </div>
                    <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                        <div class="card-body">
                            <div class="sheet">
                                <label class="textaligncenter labelone  ">Order Date
                                </label>
                                <label class="">:</label>
                                <div class="textaligncenter">

                                    <p style="word-break: break-all;">{{buyOrderData?.createTime| date : 'dd/MM/yyyy, h:mm a'|| '--'}}</p>


                                </div>
                            </div>
                            <div class="sheet">
                                <label class="textaligncenter labelone ">Status</label>
                                <label class="">:</label>
                                <div class="textaligncenter">

                                    <p style="word-break: break-all;">{{OrderDataList?.productStatus|| '--'}}</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- Billing and shipping -->
    <div class="card mb7">
        <div class="card-header" id="heading9">
          <h5 class="mb-0">
            <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse9" aria-expanded="false"
              aria-controls="collapse6">
              <i class="fa fa-truck iconP"></i>Shipping & Billing Address
            </button>
          </h5>
        </div>
        <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordion">
          <div class="card-body">
  
            <div class="row justify-content-around">
              <div class="col-md-5">
  
                <div class="table-responsive" style="margin-bottom: 10px;">
                  <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                    aria-describedby="Brand management list">
                    <thead>
                      <tr class="no_wrap_th text-left">
                        <th scope="col" colspan="2" class="Sorting-img">Shipping address</th>
                      </tr>
                    </thead>
                    <tbody>
  
                      <tr class="text-center">
                        <td>
                          Full name
                        </td>
                        <td>
                          {{address.name}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Phone
                        </td>
                        <td>
                          {{address.phoneNumber || '--'}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Address
                        </td>
                        <td>
                          {{address?.address || '--'}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Area
                        </td>
                        <td>
                          {{address.area || '--'}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Land Mark
                        </td>
                        <td>
                          {{address.landmark || '--'}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          City
                        </td>
                        <td>
                          {{address.city}}
                        </td>
                      </tr>
  
                      <tr class="text-center">
                        <td>
                          State / province
                        </td>
                        <td>
                          {{address.state}}
                        </td>
                      </tr>
  
                      <tr class="text-center">
                        <td>
                          Zip / postal code
                        </td>
                        <td>
                          {{address.zipCode}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Country
                        </td>
                        <td>
                          {{address.country}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
  
                </div>
              </div>
  
              <div class="col-md-5">
  
                <div class="table-responsive" style="margin-bottom: 10px;">
                  <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                    aria-describedby="Brand management list">
                    <thead>
                      <tr class="no_wrap_th text-left">
                        <th scope="col" colspan="2" class="Sorting-img">Billing address</th>
                      </tr>
                    </thead>
                    <tbody>
  
                      <tr class="text-center">
                        <td>
                          Full name
                        </td>
                        <td>
                          {{billingAddress?.name}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Phone
                        </td>
                        <td>
                          {{billingAddress?.phoneNumber}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Address
                        </td>
                        <td>
                          {{billingAddress?.address || '--'}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Area
                        </td>
                        <td>
                          {{billingAddress?.area}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Land Mark
                        </td>
                        <td>
                          {{billingAddress?.landmark}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          City
                        </td>
                        <td>
                          {{billingAddress?.city}}
                        </td>
                      </tr>
  
                      <tr class="text-center">
                        <td>
                          State / province
                        </td>
                        <td>
                          {{billingAddress?.state}}
                        </td>
                      </tr>
  
                      <tr class="text-center">
                        <td>
                          Zip / postal code
                        </td>
                        <td>
                          {{billingAddress?.zipCode}}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td>
                          Country
                        </td>
                        <td>
                          {{billingAddress?.country}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
  
                </div>
              </div>
            </div>
            <div class="row justify-content-around">
              <div class="col-md-5">
                <button class="btn btn-theme" (click)="getUrl(0)" routerLink="/edit-address"
                  [queryParams]="{ address_type: 'billing'}">Edit</button>
              </div>
              <div class="col-md-5">
                <button class="btn btn-theme" (click)="getUrl(1)" routerLink="/edit-address"
                  [queryParams]="{ address_type: 'shipping'}">Edit</button>
              </div>
            </div>
  
  
          </div>
        </div>
      </div>

                <!-- <div class="card mb7">
                    <div class="card-header" id="heading2">
                        <h5 class="mb-0">
                            <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse2"
                                aria-expanded="false" aria-controls="collapse2">
                                <i class="fa fa-picture-o iconP"></i>Picture
                            </button>
                        </h5>
                    </div>
                    <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordion">
                        <div class="card-body">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">Upload Image <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">
                                        <p style="margin-top: 4px;">
                                            <input #file type="file" (change)="ValidateFileUpload($event)"
                                                accept='image/*' />
                                            <img [src]=" imgSrc ||'assets/img/dummy.png'" alt=""
                                                style="width: 14%; border: 1px solid black;" />

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="card mb7">
                    <div class="card-header" id="heading4">
                        <h5 class="mb-0">
                            <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse4"
                                aria-expanded="false" aria-controls="collapse4">
                                <i class="fa fa-usd iconP"></i>Prices
                            </button>
                        </h5>
                    </div>
                    <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
                        <div class="card-body">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Total quantity <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <p>{{buyOrderData?.totalQty}}</p>
                                        <!-- input type="tel" class="form-control" placeholder="Total Quantity"
                                            formControlName="totalQuantity"
                                            (keypress)="mainService.preventSpace($event)" maxlength="15"> -->
                                        <div class="text-danger">
                                            <!-- <span
                                                *ngIf="editProfileForm.get('totalQuantity').hasError('required') && (editProfileForm.get('totalQuantity').dirty || editProfileForm.get('totalQuantity').touched)">
                                                *Please enter you total quantity.
                                            </span> -->


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Product price <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <!-- <input type="tel" class="form-control" placeholder="Cost In Dollar"
                                            formControlName="costInDollar" (keypress)="mainService.preventSpace($event)"
                                            maxlength="15"> -->
                                            {{buyOrderData?.totalAmount}}
                                        <div class="text-danger">
                                            <!-- <span
                                                *ngIf="editProfileForm.get('costInDollar').hasError('required') && (editProfileForm.get('costInDollar').dirty || editProfileForm.get('costInDollar').touched)">
                                                *Please enter you cost in dollar.
                                            </span> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb7">
                    <div class="card-header" id="heading5">
                        <h5 class="mb-0">
                            <button class="btn btn-link btncolor" data-toggle="collapse" data-target="#collapse5"
                                aria-expanded="false" aria-controls="collapse5">
                                <i class="fa fa-line-chart iconP"></i>Status
                            </button>
                        </h5>
                    </div>
                    <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                        <div class="card-body">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Status<span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <div class="form-group">
                                            <select class="form-control form-select" formControlName="status">
                                                <option value="" selected>Select Status</option>
                                                <option value={{OrderDataList?.productStatus}}>
                                                    {{OrderDataList?.productStatus}}</option>

                                                <option value="Pending">Pending</option>
                                                <option value="Delivered">Delivered</option>
                                                <option value="Cancel">Cancel</option>
                                                <option value="Return">Return</option>
                                            </select>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </form>
        </div>
    </main>
</div>